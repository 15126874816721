import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import { DivOverlays } from "./SoftwareWebinarInterfaces";
import useTimeout from "../../helperFunctions/useTimeout";
import { useRef, useState } from "react";

export default function NextPartButton({
  divOverlay,
  onFinishedPart,
}: {
  divOverlay: DivOverlays;
  onFinishedPart: () => void;
}) {
  const theme = useTheme();
  const buttonContainer = useRef<any>();

  useTimeout(
    () => {
      buttonContainer.current.style.display = "flex";
    },
    divOverlay?.showTimeInSec ? divOverlay.showTimeInSec * 1000 : 0
  );

  const [hoverButton, setHoverButton] = useState(false);

  return (
    <div
      onMouseEnter={() => setHoverButton(true)}
      onMouseLeave={() => setHoverButton(false)}
      ref={buttonContainer}
      onClick={onFinishedPart}
      style={{
        position: "fixed",
        zIndex: 10000,
        bottom: 15,
        right: 15,
        width: 50,
        height: 50,
        background: theme.palette.primary.main,
        borderRadius: 10,
        display: "none",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        opacity: hoverButton ? 1 : 0.5,
      }}
    >
      <FontAwesomeIcon style={{ fontSize: 30 }} icon={faArrowRight} color={"white"} />
    </div>
  );
}
