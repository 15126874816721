import React, { useRef, useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import { TrackingType } from "./ProgressTracking";
import { v4 as uuidv4 } from "uuid";
import { useVisionMovieContext } from "../../VisionMovieContext";
import { useAuth } from "../../Firebase/Contexts/AuthContext";
import { firestore } from "../../Firebase/firebase";
import dayjs from "dayjs";

/* DATA STRUCTURE
export interface TrackingData {
    toDos: ToDo[];
  }
  
  export interface ToDo {
    id: string; 
    currentTrackingType: TrackingType;
    visionMovieId: string | null;
    visionMovieSegmentId: string;
    trackedPeriods: TrackingPeriod[];
  }
  
  export interface TrackingPeriod {
    id: string;
    beginningDate: Date;
    endDate: Date;
    trackingType: TrackingType;
    measurements: Measurement[];
  } */

export default function SelectField({ segment, trackingData, setTrackingData }) {
  const theme = useTheme();
  const { currentUser } = useAuth();
  const { currentlyActiveVisionMovieId } = useVisionMovieContext();

  const toDoObj = trackingData.toDos.find((elem) => elem.visionMovieSegmentId === segment.id);

  const [trackingTypeChoosen, setTrackingTypeChoosen] = useState(false);
  useEffect(() => {
    if (toDoObj) {
      setTrackingTypeChoosen(true);
    }
  }, [trackingData]);

  const handleChange = (event) => {
    console.log("USERID:");
    console.log(currentUser?.uid);
    //FIRESTORE DATA MANAGEMENT
    //ADD NEW TODOs or store changes
    const prevData = { ...trackingData };
    const toDoIndex = prevData.toDos.findIndex((elem) => elem.visionMovieSegmentId === segment.id);
    const objId = uuidv4();

    const noElementFoundRetunValue = -1;
    if (toDoIndex === noElementFoundRetunValue) {
      //Store new Todo
      firestore
        .collection("users")
        .doc(currentUser?.uid)
        .collection("TrackedToDos")
        .doc(objId)
        .set({
          id: objId,
          currentTrackingType: event.target.value,
          visionMovieId: currentlyActiveVisionMovieId,
          visionMovieSegmentId: segment.id,
          measurements: null,
          startTrackingDate: dayjs().add(1, "day").toString(), //.add() for testing purpose only
          //dayjs().add(1, "day").toString(), //always start tracking from day after coaching onwards   //TODO: Later implement that user can move first tracking date by himself/herself
        })
        .then(() => {
          //console.log("Document successfully written!");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    } else {
      //When a tracking Type is choosen for an element for the first time (no tracking before happened), but then is again removed -> no tracking shall happen in the end
      if (event.target.value === TrackingType.none && !toDoObj.measurements) {
        //TODO: Delete
        firestore
          .collection("users")
          .doc(currentUser?.uid)
          .collection("TrackedToDos")
          .doc(prevData.toDos[toDoIndex].id)
          .delete()
          .then(() => {
            console.log("ToDo successfully deleted!");
          })
          .catch((error) => {
            alert("Something went wrong deleting the ToDo");
            console.error("Error removing document: ", error);
          });
      } else {
        //Change Todos TrackingType
        prevData.toDos[toDoIndex].currentTrackingType = event.target.value;
        //Store new Todo
        firestore
          .collection("users")
          .doc(currentUser?.uid)
          .collection("TrackedToDos")
          .doc(prevData.toDos[toDoIndex].id)
          .set({
            ...prevData.toDos[toDoIndex],
            currentTrackingType: event.target.value,
          })
          .then(() => {
            //console.log("Document successfully written!");
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
      }
    }

    /*   setTrackingData(() => {
      const prevData = { ...trackingData };
      const toDoIndex = prevData.toDos.findIndex(
        (elem) => elem.visionMovieSegmentId === segment.id
      );

      const noElementFoundRetunValue = -1;
      if (toDoIndex === noElementFoundRetunValue) {
        //Create and append new ToDo
        prevData.toDos.push({
          id: objId,
          currentTrackingType: event.target.value,
          visionMovieId: currentlyActiveVisionMovieId,
          visionMovieSegmentId: segment.id,
          trackedPeriods: null,
        });
      } else {
        //When a tracking Type is choosen for an element for the first time (no tracking before happened), but then is again removed -> no tracking shall happen in the end
        if (event.target.value === TrackingType.none && !toDoObj.trackedPeriods) {
          prevData.toDos.splice(toDoIndex, 1);
        } else {
          //Change Todos TrackingType
          prevData.toDos[toDoIndex].currentTrackingType = event.target.value;
        }
      }
      return prevData;
    }); */

    //Reveals select field
    if (event.target.value !== TrackingType.none) {
      setTrackingTypeChoosen(true);
    } else {
      setTrackingTypeChoosen(false);
    }
  };

  return (
    <FormControl
      fullWidth
      style={{
        background: trackingTypeChoosen ? theme.palette.primary.opacity80 : "rgba(0, 0, 0, 0.6)",
        borderRadius: 4,
      }}
      sx={{ width: "60%", borderColor: "green" }}
    >
      <InputLabel
        sx={{
          color: "white",
          fontWeight: "bold",
          fontSize: 18,
          "&.Mui-focused": {
            color: "white",
            fontWeight: "bold",
          },
        }}
        id="demo-simple-select-label"
      >
        Tracking auswählen
      </InputLabel>
      <Select
        onClose={() => {
          setTimeout(() => {
            document.activeElement.blur();
          }, 0);
        }}
        sx={{
          fontSize: 18,
          "&:after": {
            borderBottomColor: "white",
          },
          "& .MuiSvgIcon-root": {
            color: "white",
          },
          fontWeight: "bold",
          color: "white",
        }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={toDoObj ? toDoObj.currentTrackingType : TrackingType.none}
        onChange={handleChange}
        autoWidth
        label="Tracking auswählen"
      >
        <MenuItem value={TrackingType.none}>
          <em>kein Tracking</em>
        </MenuItem>
        <MenuItem value={TrackingType.weekly_once}>{TrackingType.weekly_once}</MenuItem>
        <MenuItem value={TrackingType.weekly_multiple_times}>
          {TrackingType.weekly_multiple_times}
        </MenuItem>
        <MenuItem value={TrackingType.time_per_week}>{TrackingType.time_per_week}</MenuItem>
        <MenuItem value={TrackingType.daily_once}>{TrackingType.daily_once}</MenuItem>
        <MenuItem value={TrackingType.daily_multiple_times}>
          {TrackingType.daily_multiple_times}
        </MenuItem>
        <MenuItem value={TrackingType.time_per_day}>{TrackingType.time_per_day}</MenuItem>
        <MenuItem value={TrackingType.value}>{TrackingType.value}</MenuItem>
      </Select>
    </FormControl>
  );
}
