import React from "react";
import { useTheme } from "@mui/material/styles";

export default function MenuButton({ onClick }) {
  const theme = useTheme();
  const { palette } = theme;
  const lineStyle = {
    height: 3,
    backgroundColor: palette.primary.main,
    borderRadius: 1,
  };

  return (
    <div onClick={onClick} style={{ cursor: "pointer" }}>
      <div style={{ width: 26, marginBottom: 7, ...lineStyle }} />
      <div style={{ width: 16, marginBottom: 7, ...lineStyle }} />
      <div style={{ width: 21, ...lineStyle }} />
    </div>
  );
}
