import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function LowerBox({ optionsSelected, toBeChoosenOptions, finalizeVisionMovie }) {
  const theme = useTheme();

  const [text, setText] = useState("");
  useEffect(() => {
    if (optionsSelected === toBeChoosenOptions) {
      setText(`Du hast genügend ausgewählt`);
    } else {
      setText(`${optionsSelected} von ${toBeChoosenOptions} ausgewählt`);
    }
  }, [optionsSelected, toBeChoosenOptions]);

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        height: 75,
        background: "blue",
        borderTopColor: theme.palette.primary.main,
        borderTopWidth: 5,
        borderTopStyle: "solid",
        background: "white",
        display: "flex",
        alignItems: "center",
        paddingLeft: "5%",
      }}
    >
      <Typography
        style={{
          padding: 0,
          margin: 0,
          fontWeight: "bold",
          color:
            optionsSelected === toBeChoosenOptions
              ? theme.palette.green.main
              : theme.palette.grey.medium,
          userSelect: "none",
          marginRight: 50,
        }}
        variant="h3"
      >
        {text}
      </Typography>
      <Button
        onClick={finalizeVisionMovie}
        disabled={!(optionsSelected === toBeChoosenOptions)}
        variant="contained"
        disableElevation
        style={{ fontWeight: "bold", fontSize: 20, height: 50, paddingTop: 1, paddingBottom: 1 }}
      >
        MindMovie finalisieren
      </Button>
    </div>
  );
}
