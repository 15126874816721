import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";

export default function CoachingIndicator({ scalingFactor, date, coachings }) {
  const [coachingNumber, setCoachingNumber] = useState("");

  useEffect(() => {
    const coaching = coachings?.find((elem) => dayjs(elem.date).isSame(dayjs(date), "day"));
    if (coaching) {
      setCoachingNumber(coaching.number);
    } else setCoachingNumber(null);
  }, [date, coachings]);

  const theme = useTheme();

  if (coachingNumber) {
    return (
      <div
        style={{
          position: "absolute",
          marginTop: -12 * scalingFactor,
          left: "50%",
          bottom: -5 * scalingFactor,
          transform: "translateX(-50%)",
        }}
      >
        <div
          style={{
            borderRadius: 4 * scalingFactor,
            width: 24 * scalingFactor,
            textAlign: "center",
            //boxShadow: `0px ${4 * scalingFactor}px ${4 * scalingFactor}px rgba(0, 0, 0, 0.1)`,
            background: theme.palette.gradient.text,
            color: "white",
            fontSize: 12 * scalingFactor,
          }}
        >
          C{coachingNumber}
        </div>
      </div>
    );
  } else return null;
}
