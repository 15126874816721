import { AddButton } from "../backgroundSelection/AddButton";
import CropImageButton from "./CropImageButton";

export default function ImageCardOverlayButtons({ videoData, overCard }) {
  return (
    <>
      {overCard && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            direction: "row",
            position: "absolute",
            right: 11,
            top: 9,
            zIndex: 2,
          }}
        >
          {/* <CropImageButton videoData={videoData} /> */}
          <AddButton data={videoData} />
        </div>
      )}
    </>
  );
}
