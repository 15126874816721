import React, { useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import SelectField from "./SelectField";

export default function SetTracking({ segment, trackingData, setTrackingData }) {
  const toDoObj = trackingData.toDos.find((elem) => elem.visionMovieSegmentId === segment.id);
  const [trackingStarted, setTrackingStarted] = useState(() => toDoObj);

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 110,
        opacity: 1,
      }}
    >
      {trackingStarted ? (
        <SelectField
          segment={segment}
          trackingData={trackingData}
          setTrackingData={setTrackingData}
        />
      ) : (
        <Button
          onClick={() => {
            setTrackingStarted(true);
          }}
          style={{
            fontSize: 18,
            paddingLeft: 40,
            paddingRight: 40,
            fontWeight: "bold",
            textTransform: "none",
            color: "grey",
            background: "white",
            opacity: 0.8,
          }}
          variant="contained"
          disableElevation
        >
          Fortschritt aufzeichnen
        </Button>
      )}
    </Stack>
  );
}
