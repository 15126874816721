import { ImageClip } from "./VisionMovieSegment";

export default function TransformedImage({
  imageData,
  children,
  onLoad,
}: {
  imageData: ImageClip;
  children: any;
  onLoad: () => void;
}) {
  if (!imageData.cropParameters) return <>something went wrong</>;

  const croppedArea = imageData.cropParameters?.croppedArea;
  const scale = 100 / croppedArea?.width;
  const transform = {
    x: `${-croppedArea.x * scale}%`,
    y: `${-croppedArea.y * scale}%`,
    scale,
    width: "calc(100% + 0.5px)",
    height: "auto",
  };


  const imageStyle: any = {
    transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
    width: transform.width,
    height: transform.height,
    position: "absolute",
    top: 0,
    left: 0,
    transformOrigin: "top left",
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height:"100%",
        overflow: "hidden",
       // paddingBottom: `${50 / (1080 / 1920)}%`, //NOTE: I DON'T HAVE ANY IDEA WHERE THIS NUMBER IS COMING FROM BUT IT WORKS ON MY MACBOOK 13"
      }}
    >
      <img src={imageData.url} style={imageStyle} onLoad={onLoad} />
      {children}
    </div>
  );
}
