import { AuthProvider } from "./components/Firebase/Contexts/AuthContext";
import { MultiButtonStateProvider } from "./components/navigation/MultiButtonStateContext";
import { VisionMovieContextProvider } from "./components/VisionMovieContext";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./Theme";
import { BackgroundSelectionContextProvider } from "./components/backgroundSelection/BackgroundSelectionContext";
import { PlayerSetupContextProvider } from "./components/player/contexts/PlayerSetupContext";
import { BackgroundMusicProvider } from "./components/BackgroundMusic/Context/BackgroundMusicContext";
import { AffirmationDataContextProvider } from "./components/player/contexts/AffirmationDataContext";
import { WebinarContextProvider } from "./components/softwareWebinar/WebinarContext";

export default function Providers({ children }) {
  return (
    <AuthProvider>
      <WebinarContextProvider>
        <MultiButtonStateProvider>
          <ThemeProvider theme={theme}>
            <AffirmationDataContextProvider>
              <PlayerSetupContextProvider>
                <VisionMovieContextProvider>
                  <BackgroundSelectionContextProvider>
                    <BackgroundMusicProvider>{children}</BackgroundMusicProvider>
                  </BackgroundSelectionContextProvider>
                </VisionMovieContextProvider>
              </PlayerSetupContextProvider>
            </AffirmationDataContextProvider>
          </ThemeProvider>
        </MultiButtonStateProvider>
      </WebinarContextProvider>
    </AuthProvider>
  );
}
