import { useState, useLayoutEffect } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import DateTimePicker from "./DateTimePicker";
import NextCoachingNumberSelect from "./NextCoachingNumberSelect";
import TimePicker from "./TimePicker";

export default function SetNextCoachingDate({ nextCoaching, setNextCoaching }) {
  return (
    <Stack direction="row" spacing={4} alignItems="center">
      {nextCoaching?.firstTrackedCoaching ? (
        <>
          <Typography style={{ fontWeight: "bold" }} variant="h3">
            Welches war das heutige Coaching:
          </Typography>
          <NextCoachingNumberSelect nextCoaching={nextCoaching} setNextCoaching={setNextCoaching} />
          <TimePicker nextCoaching={nextCoaching} />
        </>
      ) : (
        <>
          <Typography style={{ fontWeight: "bold" }} variant="h3">
            Datum für nächstes Coaching Nr. {nextCoaching?.number} wählen:
          </Typography>
          <DateTimePicker nextCoaching={nextCoaching} />
        </>
      )}
    </Stack>
  );
}
