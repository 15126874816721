import PlaceholderAffirmation from "./PlaceholderAffirmation";
import { useVisionMovieContext } from "../VisionMovieContext";
import { useRef, useEffect, useState } from "react";
import TransformedImage from "../uploadImages/TransformedImage";

export default function ImagePlaceholder({ opacity }) {
  const { segmentToSetSettingsFor } = useVisionMovieContext();
  const imageSrc = segmentToSetSettingsFor?.video
    ? segmentToSetSettingsFor?.video?.image
    : segmentToSetSettingsFor?.image?.image;

  const imageRef = useRef();
  const [imageWidth, setImageWidth] = useState(0);

  useEffect(() => {
    imageRef.current && setImageWidth(imageRef.current.offsetWidth);
  }, [imageRef.current]);

  return (
    <div
      style={{
        width: "100%",
        height: imageWidth * (1080 / 1920),
        position: "relative",
        overflow: "hidden",
        borderRadius: 6,
      }}
    >
      <PlaceholderAffirmation imageWidth={imageWidth} />
      {/* CONTRAST LAYER */}
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundColor: "black",
          opacity: opacity,
          zIndex: 1,
        }}
      ></div>
      {/* PLACEHOLDER IMAGE TRANSFORMED */}
      {segmentToSetSettingsFor?.image?.transformDataObj && (
        <div style={{ width: "100%", height: "100%" }} ref={imageRef}>
          <TransformedImage imageData={segmentToSetSettingsFor.image} />
        </div>
      )}
      {/* PLACEHOLDER IMAGE NOT TRANSFORMED */}
      {!segmentToSetSettingsFor?.image?.transformDataObj && (
        <img
          ref={imageRef}
          style={{
            width: "100%",
            borderRadius: 6,
            transform: "scale(1)",
            objectPosition: "50% 50%",
            height: "100%",
            objectFit: "cover",
          }}
          src={imageSrc}
        />
      )}
    </div>
  );
}
