export interface IVideoPlaybackConfigProps {
  videoObj: any;
  videoIndex: number;
  activeIndex: number;
}

export enum PlayerToDo {
  None,
  Buffer,
  GetReady,
  Play,
}
export enum PlayerLoadingState {
  NotStartedLoading,
  Loading,
  Loaded,
}
