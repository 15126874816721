import { Grid, CircularProgress, Button } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Video } from "./Video";
import UploadVideoCard from "../uploadVideos/UploadVideoCard";
import UploadImageCard from "../uploadImages/UploadImageCard";
import ImageCard from "../uploadImages/ImageCard";

//ROW
export const ListRow =
  (
    gridCardSize,
    assetsToChooseFromInRows,
    assetsToChooseFromInRowsLength,
    currentPathname,
    loadingAssets,
    nextPage,
    scrollToTop,
    searchQuery
  ) =>
  ({ index, style }) => {
    const gridSpacing = 2;
    const videoCardHeight = style.height - gridSpacing * 8;

    //RETURNS LOADING SPINNER WHEN PAGE IS INITIALLY LOADED
    if (assetsToChooseFromInRows.length === 0 && !searchQuery) {
      return (
        <Grid container spacing={gridSpacing} style={{ ...style }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress thickness={6} />
          </div>
        </Grid>
      );
    }

    //RETURNS BACK TO THE TOP BUTTON WHEN NO NEXT PAGE AVAILABLE
    if (
      !nextPage &&
      assetsToChooseFromInRowsLength === index &&
      currentPathname !== "/backgroundselection/heartedvideos"
    ) {
      if (currentPathname !== "/backgroundselection/heartedvideos") {
        //if this is missing in /heartedvideos it shows the loading spinner or back to top button when no nextPage is set
        return (
          <Grid container spacing={gridSpacing} style={{ ...style }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{ textTransform: "none", borderRadius: "6px", fontWeight: "bold" }}
                variant="contained"
                disableElevation
                startIcon={<ArrowUpwardIcon />}
                onClick={scrollToTop}
              >
                move back to the top
              </Button>
            </div>
          </Grid>
        );
      } else {
        return null;
      }
    }

    //RETURNS LOADING SPINNER WHEN LOADING NEXT PAGE
    if (loadingAssets && assetsToChooseFromInRowsLength === index) {
      if (currentPathname !== "/backgroundselection/heartedvideos") {
        //if this is missing in /heartedvideos it shows the loading spinner or back to top button when no nextPage is set
        return (
          <Grid container spacing={gridSpacing} style={{ ...style }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress thickness={6} />
            </div>
          </Grid>
        );
      } else {
        return null;
      }
    }

    //THE ROWS WITH ELEMENTS
    return (
      <Grid container spacing={gridSpacing} style={{ ...style }}>
        {assetsToChooseFromInRows[index].map((videoData, columnIndex) => {
          return (
            <Grid key={columnIndex} item xs={gridCardSize}>
              <div
                style={{
                  background: "rgba(242, 242, 242, 1)",
                  overflow: "hidden",
                  borderRadius: 6,
                  height: videoCardHeight,
                  width: "100%",
                }}
              >
                {videoData.uploadingPlaceholder ? (
                  <>
                    {currentPathname === "/backgroundselection/uploadvideos" && (
                      <UploadVideoCard assetsToChooseFromInRows={assetsToChooseFromInRows} />
                    )}
                    {currentPathname === "/backgroundselection/uploadimages" && (
                      <UploadImageCard assetsToChooseFromInRows={assetsToChooseFromInRows} />
                    )}
                  </>
                ) : currentPathname === "/backgroundselection/uploadimages" ? (
                  <ImageCard videoData={videoData} />
                ) : (
                  <Video
                    videoData={videoData}
                    videoCardHeight={videoCardHeight}
                    currentPathname={currentPathname}
                  />
                )}
              </div>
            </Grid>
          );
        })}
      </Grid>
    );
  };
