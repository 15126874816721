const parameters = {
  paragraphAnimation: {
    fadeInDurationMs: 1500,
    fadeOutDurationMs: 1500,
    fadeInDelayMs: 500,
    fadeOutDelayMs: 500,
  },
  lineOpacity: {
    fadeInDelayMs: 1000,
    fadeInDurationMs: 1000,
    fadeOutDelayMs: 500,
    fadeOutDurationMs: 1500,
  },
  titleAnimation: {
    fadeInDurationMs: 1500,
    fadeOutDurationMs: 1500,
  },
  lineWidthAnimation: {
    startDelayMs: 1000,
    startDurationMs: 2000,
    endDelayMs: 500,
    endDurationMs: 1500,
  },
  general: {
    paddingLeft: 0.11,
  },
  line: {
    relativeTopMarginTitle: 0.095, //relative margin top of the line to the title
    relativeHeight: 0.018,
  },
  title: {
    fontSize: 170,
  },
  paragraph: {
    wrappingLineHeight: 1.4,
    maxRelativeWidth: 0.42, //percentage of the screen width before the text wraps
    relativeTopMarginParagraph: 0.08, //relative margin top of the paragraph to the line
    fontSize: 58,
  },
};

export default parameters;
