import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";
import ProgressTracking from "../components/progressTracking/setProgressTracking";

export default function ProgressSettingRoute() {
  const theme = useTheme();

  //Bug fix that makes the settings page scroll to the top when navigating to it
  //Before sometimes this was not the case
  useEffect(() => {
    document.body.scroll(0, 0);
  }, []);

  return (
    <div style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2) }}>
      <div style={{ marginTop: 10 }}>
        <ProgressTracking />
      </div>
    </div>
  );
}
