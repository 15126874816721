import PlayerSettings from "../PlayerSettings";

export default class ImageCanvas {
  private destroyed: boolean = false;
  ctx: CanvasRenderingContext2D;
  constructor(
    private image: HTMLImageElement,
    private canvas: HTMLCanvasElement
  ) {
    this.ctx = canvas.getContext("2d")!;
  }

  update(timeMs: number) {
    if (!this.destroyed) {
      const adjustedZoomValueForThisClass =
        PlayerSettings.imageZoomSpeed * 10000;
      let zoomFactor = 1 + timeMs / adjustedZoomValueForThisClass;
      this.ctx.setTransform(1, 0, 0, 1, 0, 0);
      const imageWidth = this.image.naturalWidth;
      const imageHeight = this.image.naturalHeight;
      const imageRatio = imageWidth / imageHeight;

      const zoomX =
        this.canvas!.width / 2 - (this.canvas!.width / 2) * zoomFactor;
      const zoomY =
        this.canvas!.height / 2 - (this.canvas!.height / 2) * zoomFactor;

      this.ctx.translate(zoomX, zoomY);
      this.ctx.scale(zoomFactor, zoomFactor);

      const newImageWidth = this.canvas!.width;
      const newImageHeight = this.canvas!.width / imageRatio;
      const newImageX = 0;
      const newImageY = (this.canvas!.height - newImageHeight) / 2;

      this.ctx.drawImage(
        this.image,
        newImageX,
        newImageY,
        newImageWidth,
        newImageHeight
      );
    }
  }

  destroy() {
    this.destroyed = true;
  }
}
