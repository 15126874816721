import { useEffect, useRef, useState } from "react";
import { easeLinear } from "d3-ease";
import _ from "lodash";
import { Spinner } from "react-bootstrap";
import CanvasOverlay from "./CanvasOverlay";
import { VisionMovieSegment } from "./VisionMovieSegment";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import useHTMLElementAddCurrentTime from "./helperFunctions/useHTMLElementAddCurrentTime";
import PlayerImageComponent from "./PlayerImageComponent";

const DEBUG = false;
const VIDEO_RATIO = 1080 / 1920;

interface ICanvasImagePlayer {
  activeElement: boolean;
  lastSegment: boolean;
  segmentsCoverSong?: boolean;
  setAudioVolume: (newVolume: number) => void;
  visionMovieSegment: VisionMovieSegment;
  width: number;
  height: number;
  onVideoFinished?: (lastSegment: boolean) => void;
  paused?: boolean;
  shouldPlay?: boolean;
  onPreBufferingComplete?: () => void;
  onPlaying?: () => void;
}

export default function CanvasImagePlayer({
  activeElement,
  lastSegment,
  segmentsCoverSong,
  setAudioVolume,
  visionMovieSegment,
  width,
  height,
  onVideoFinished,
  shouldPlay = true,
  onPreBufferingComplete,
  onPlaying,
  paused = false,
}: ICanvasImagePlayer) {
  const maxPlayingTimeMs = visionMovieSegment?.imageClip?.showingDurationInSec
    ? visionMovieSegment?.imageClip?.showingDurationInSec * 1000
    : 0;

  const imageRef = useRef<any>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPaused, setIsPaused] = useState(paused);
  const [videoHeight, setVideoHeight] = useState(window.innerWidth * VIDEO_RATIO);

  //Set new height on mobile device orientation change
  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    setVideoHeight(window.innerWidth * VIDEO_RATIO);
  });

  //This solves the bug that loading spinner is shown for 0.2 seconds when switching to this segment
  useEffect(() => {
    onPreBufferingComplete && onPreBufferingComplete();
  }, [onPreBufferingComplete]);

  useEffect(() => {
    if (imageRef.current && canvasRef.current) {
      //CREATE CANVAS ELEMENT
      const canvasOverlay = new CanvasOverlay(
        canvasRef.current!,
        imageRef.current!,
        visionMovieSegment,
        lastSegment
      );
      canvasOverlay.update();

      // clean up
      return () => {
        canvasOverlay.destroy();
      };
    }
  }, [imageRef.current, canvasRef.current]);

  const onPlay = () => {
    if (isPaused) {
      //stop player if it was initially played by autoplay but is already out of the pre-buffering phase
      //NOT NEEDED FOR IMAGE
      //Later necessary to pause zooming for img
      //TODO: implement stop zooming in on img here
      //...
    } else {
      setIsPaused(false);
      setIsLoading(false);
      onPlaying && onPlaying();
    }
  };

  //NOTE: FROM VIDEO COMPONENT -> TODO: LATER NEEDS TO BE IMPLEMENTED FOR ZOOMING IMG!
  //Starting and stopping to play image
  const [play, setPlay] = useState<boolean>(false);
  useEffect(() => {
    setIsPaused(paused);
    if (imageRef.current) {
      if (paused || !shouldPlay) {
        setPlay(false);
        console.log("trigger pause");
      } else if (shouldPlay && !paused) {
        setPlay(true);
        onPlay();
        console.log("trigger play");
      }
    }
  }, [paused, shouldPlay]);

  const currentTimeMs = useHTMLElementAddCurrentTime(play, imageRef.current);

  useEffect(() => {
    if (maxPlayingTimeMs && currentTimeMs >= maxPlayingTimeMs) {
      if (onVideoFinished) {
        onVideoFinished(lastSegment);
      }
    }

    //Fade out Audio when last segment and segments don't cover song
    if (lastSegment && !segmentsCoverSong) {
      fadeOutAudio(currentTimeMs);
    }
  }, [currentTimeMs]);

  const fadeOutAudio = (currentTimeMs: number) => {
    if (imageRef.current) {
      //Calculate fade
      const FADEOUT_AUDIO_DURATION_MS = 7000;
      const fadeoutTimeMS = visionMovieSegment.imageClip
        ? visionMovieSegment.imageClip.showingDurationInSec * 1000 - FADEOUT_AUDIO_DURATION_MS
        : 0;

      if (currentTimeMs > fadeoutTimeMS) {
        setAudioVolume(
          1 - _.clamp(easeLinear((currentTimeMs - fadeoutTimeMS) / FADEOUT_AUDIO_DURATION_MS), 0, 1)
        );
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        alignItems: "center",
        backgroundColor: "black",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: "100vw",
          height: videoHeight,
          overflow: "hidden",
        }}
      >
        {visionMovieSegment?.imageClip && (
          <PlayerImageComponent
            activeElement={activeElement}
            imageClip={visionMovieSegment.imageClip}
            imgRef={imageRef}
            //Leads to bug, because loading Spinner is always shown for 0.2 seconds when switching to this segment
            //onLoad={() => onPreBufferingComplete && onPreBufferingComplete()}
            onLoad={() => {}}
          />
        )}
        {isLoading && (
          <Spinner
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: "auto",
              width: (100 * window.innerWidth) / 1440,
              height: (100 * window.innerWidth) / 1440,
              borderWidth: (12 * window.innerWidth) / 1440,
              color: "rgba(255, 255, 255, 0.7)",
            }}
            animation={"border"}
          />
        )}
        <canvas style={{ position: "absolute", top: 0, left: 0 }} ref={canvasRef}></canvas>
      </div>
    </div>
  );
}
