export enum Action {
  "DeleteCard",
  "AddCard",
  "GoToSettingsPage",
  "GoToBackgroundSelectionPage",
  "GoToBackgroundMusicPage",
  "PlayMindMovie",
  "Logout",
  "AddUploadedImage",
  "AddPlatformVideo",
  "AddUploadedVideo",
}
