import { useState, useRef, useLayoutEffect } from "react";
import useResponsiveCardHeight from "../segments/helperHooks/useResponsiveCardHeight";
import { useTheme } from "@mui/material/styles";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import { generatePath, useNavigate } from "react-router-dom";

export default function Item({ data, index, dragging, resize }) {
  const theme = useTheme();
  const { palette } = theme;

  const cardRef = useRef(null);
  const [cardWidth, setCardWidth] = useState(0);

  useLayoutEffect(() => {
    setCardWidth(cardRef.current.offsetWidth);
    console.log("cardWidth", cardRef.current.offsetWidth);
  }, [cardRef, resize]);

  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    setCardWidth(cardRef.current.offsetWidth);
  });

  const CARD_BORDER_WIDTH = 3;

  const cardHeight = useResponsiveCardHeight();
  const cardStyle = {
    background: palette.gradient.cardBackgroundGradient,
    touchAction: dragging ? "none" : null, //disable touch action when dragging (needed for touch devices)
    fontSite: 1,
    position: "relative",
    display: "flex",
    height: cardHeight,
    width: "100%",
    opacity: dragging ? 0.4 : 1,
    position: "relative",
    //border: `${CARD_BORDER_WIDTH}px solid ${palette.primary.main}`,
    borderRadius: 5,
    outline: "none",
    boxSizing: "border-box",
    overflow: "hidden",
    WebkitUserSelect: "none",
  };

  const navigate = useNavigate();

  const handleProceed = () => {
    const { id, name } = data;
    id && navigate(generatePath("/editor/:name/:id", { name, id }));
  };

  return (
    <div
      style={{
        width: "100%",
        height: cardHeight,
        background: "white",
        borderRadius: 6,
        WebkitUserSelect: "none",
        cursor: "pointer",
      }}
    >
      <div style={cardStyle} ref={cardRef}>
        {/* CONTRAST LAYER */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: cardHeight,
            borderRadius: 6,
            zIndex: 1,
            WebkitUserSelect: "none",
          }}
          onClick={handleProceed}
        />
        <div
          style={{
            fontSize: 45,
            fontWeight: 500,
            color: theme.palette.darkBlue.main,
            margin: "auto",
          }}
        >
          {data.name}
        </div>
      </div>
    </div>
  );
}
