import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";
import "firebase/auth";
import { firebaseConfig } from "./firebaseConfig";

//firebase.functions().useEmulator("localhost", 5001);
const app = firebase.initializeApp(firebaseConfig);
export const auth = app.auth();
export const firebaseStorage = app.storage();
export const firestore = firebase.firestore();

// [START initialize_persistence]
firestore.enablePersistence().catch((err) => {
  if (err.code === "failed-precondition") {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    alert(
      "Attention: You have the VisionMovie website open in two tabs. This way we can't store your changes correctly. Please only use the website in one tab. Thank you :)"
    );
  } else if (err.code === "unimplemented") {
    // The current browser does not support all of the
    // features required to enable persistence
    alert(
      "Attention: You are using the VisionMovie website within a browser that doesn't support the needed functionality. Please use Google Chrome instead. Thank you :)"
    );
  }
});

export default app;
