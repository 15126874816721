import Navigation from "../components/navigation";
import { useTheme } from "@mui/material/styles";
import UploadVideos from "../components/uploadVideos/index";

export default function UploadVideosRoute() {
  const theme = useTheme();
  return (
    <div style={{ marginLeft: theme.spacing(2) }}>
      <Navigation />
      <div style={{ paddingTop: 60 }}>
        <UploadVideos />
      </div>
    </div>
  );
}
