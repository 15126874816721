import React, { useState, useEffect } from "react";
import SelectVisionMovie from "./SelectVisionMovie";
import VisionMovieProgressTracking from "./VisionMovieProgressTracking";
import { useAuth } from "../../Firebase/Contexts/AuthContext";
import { firestore } from "../../Firebase/firebase";
import { CoachingPrograms } from "./ProgressTracking";
import { v4 as uuidv4 } from "uuid";

export default function Index() {
  // Get current loggedIn user from firebase
  const { currentUser } = useAuth();
  console.log("currentUser?.uid");
  console.log(currentUser?.uid);

  const [visionMovieSelected, setVisionMovieSelected] = useState(false);

  const [trackingData, setTrackingData] = useState({ toDos: [] });
  const [nextCoaching, setNextCoaching] = useState(undefined);

  useEffect(() => {
    if (currentUser?.uid) {
      updateTrackingData();
      updateNextCoachingDate();
    }
  }, [currentUser]);

  const updateTrackingData = () => {
    firestore
      .collection("users")
      .doc(currentUser?.uid)
      .collection("TrackedToDos")
      .onSnapshot((docRef) => {
        const toDosArray = [];
        if (docRef?.docs) {
          docRef.docs.map((doc) => {
            toDosArray.push(doc.data());
          });
          console.log("NEW TODO DATA ARRAY:___");
          console.log(toDosArray);
          setTrackingData((prev) => {
            const newData = { ...prev };
            if (toDosArray) {
              newData.toDos = toDosArray;
              return newData;
            } else {
              return newData;
            }
          });
        }
      });
  };

  const updateNextCoachingDate = () => {
    firestore
      .collection("users")
      .doc(currentUser?.uid)
      .collection("Coachings")
      .onSnapshot((docRef) => {
        if (docRef?.docs.length === 0) {
          setNextCoaching({
            id: uuidv4(),
            number: 1,
            programm: CoachingPrograms.full_12_weeks_1on1,
            date: undefined,
            firstTrackedCoaching: true,
          });
        }
        if (docRef?.docs.length !== 0) {
          console.log(1);
          const latestCoaching = docRef.docs.reduce((previous, current) => {
            return current.data().number > previous.data().number ? current : previous;
          });
          //Last Coaching schon vergangen
          //-> Create next Coaching
          if (new Date(latestCoaching.data().date) < new Date()) {
            console.log(2);
            console.log(latestCoaching.data().number);
            setNextCoaching({
              id: uuidv4(),
              number: Number(latestCoaching.data().number) + 1,
              programm: CoachingPrograms.full_12_weeks_1on1,
              date: undefined,
            });
          }
          //Last Coaching noch bevorstehend
          //-> Change date
          else {
            console.log(3);
            setNextCoaching(latestCoaching.data());
          }
        }
      });
  };

  return (
    <div>
      {visionMovieSelected ? (
        <VisionMovieProgressTracking
          trackingData={trackingData}
          setTrackingData={setTrackingData}
          setVisionMovieSelected={setVisionMovieSelected}
        />
      ) : (
        <SelectVisionMovie
          nextCoaching={nextCoaching}
          setNextCoaching={setNextCoaching}
          trackingData={trackingData}
          setTrackingData={setTrackingData}
          setVisionMovieSelected={setVisionMovieSelected}
        />
      )}
    </div>
  );
}
