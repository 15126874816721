// sorting songs after getting from api
export const sortSongs = ({ platformSongs, userSongs, likedSongs, videoLength }) => {
    let heartedPlatformSongs = getSongsObjects(likedSongs, platformSongs)
    let heartedUserSongs = getSongsObjects(likedSongs, userSongs, true)
    let heartedSongs = [...heartedPlatformSongs, ...heartedUserSongs].sort(
        (a, b) => b.timestamp - a.timestamp
    )
    heartedSongs =
        heartedSongs.length > 0
            // eslint-disable-next-line array-callback-return
            ? heartedSongs.map((song) => {
                if (song) return song.song
            })
            : []
    heartedSongs =
        heartedSongs.length > 0
            ? heartedSongs.filter((song) => song !== undefined)
            : []
    let heartedSongsIds =
        heartedSongs.length > 0 ? heartedSongs.map((song) => song.id) : []
    let systemUploadedSongs = platformSongs.filter(
        (song) => !heartedSongsIds.includes(song.id)
    )
    systemUploadedSongs = systemUploadedSongs.sort(
        (a, b) => Math.abs(a.duration - videoLength) - Math.abs(b.duration - videoLength)
    )
    let userUploadedSongs = userSongs.filter(
        (song) => !heartedSongsIds.includes(song.id)
    )
    userUploadedSongs = userUploadedSongs.map((song) => {
        return { ...song, isUserUploaded: true }
    })
    return {
        sortedSongs: [
            ...heartedSongs,
            ...userUploadedSongs,
            ...systemUploadedSongs
        ],
        topSongsLength: heartedSongs.length + userUploadedSongs.length
    }
}

export const getSongsObjects = (
    likedSongs,
    userOrSystemUploadedSongs,
    isUserUploaded
) => {
    let songsIds = userOrSystemUploadedSongs.map((song) => song.id)
    // eslint-disable-next-line array-callback-return
    return likedSongs.map((song) => {
        if (songsIds.includes(song.songId)) {
            return {
                song: {
                    ...userOrSystemUploadedSongs.find((o) => o.id === song.songId),
                    hearted: true,
                    isUserUploaded
                },
                timestamp: song.timestamp
            }
        }
    })
}

// sorting sound cloud songs
export const sortSoundCloudSongs = ({ soundCloudSongs, heartedSoundCloudSongs, videoLength }) => {
    let likedSoundCloudSongs = getSoundCloudSongsObjects(heartedSoundCloudSongs, soundCloudSongs)
    likedSoundCloudSongs = likedSoundCloudSongs.sort(
        (a, b) => b.timestamp - a.timestamp
    );
    likedSoundCloudSongs = likedSoundCloudSongs.length > 0
        // eslint-disable-next-line array-callback-return
        ? likedSoundCloudSongs.map((song) => {
            if (song) return song.song
        }) : []
    likedSoundCloudSongs = likedSoundCloudSongs.length > 0
        ? likedSoundCloudSongs.filter((song) => song !== undefined)
        : []
    let heartedSongsIds =
        heartedSoundCloudSongs.length > 0 ? heartedSoundCloudSongs.map((song) => song.soundcloudId) : []
    let soundCloudMusics = soundCloudSongs.filter(
        (song) => !heartedSongsIds.includes(song.soundcloudId)
    )
    soundCloudMusics = soundCloudMusics.sort(
        (a, b) => Math.abs(a.duration - videoLength) - Math.abs(b.duration - videoLength)
    )
    return {
        sortedSongs: [
            ...likedSoundCloudSongs,
            ...soundCloudMusics,
        ],
        topSongsLength: likedSoundCloudSongs.length
    }
}

export const getSoundCloudSongsObjects = (
    likedSongs,
    songs,
) => {
    let songsIds = songs.map((song) => song.soundcloudId);
    // eslint-disable-next-line array-callback-return
    return likedSongs.map((song) => {
        if (songsIds.includes(song.soundcloudId)) {
            return {
                song: {
                    ...songs.find((o) => o.soundcloudId === song.soundcloudId),
                    hearted: true,
                },
                timestamp: song.timestamp
            }
        }
    })
}

// Get Time in hh:mm:ss or mm:ss
export const getHMS = (seconds) => {
    let hms = new Date(seconds * 1000).toISOString()
    return hms >= 3600 ? hms.substr(11, 8) : hms.substr(14, 5)
}

// Get file extension fron file
export const getFileExtension = (file) => {
    return file.substring(file.lastIndexOf('.') + 1);
}

// BootStrap Classes that usually used in Background Music styles
export const bootStrapClass = {
    center: "d-flex justify-content-between align-items-center",
    itemCenter: "d-flex align-items-center",
    columnCenter: "d-flex flex-column justify-content-center",
    rowCenter: "d-flex justify-content-center",
    fullCenter: "d-flex justify-content-center align-items-center"
}
