import { bootStrapClass as bs } from "../Utils/HelperFunctions";
import Button from "./BackgroundMusicButton";
import { useVisionMovieContext } from "../../VisionMovieContext";
import { useWebinarContext } from "../../softwareWebinar/WebinarContext";
import { Action } from "../../softwareWebinar/Actions";
import { useTranslation } from "react-i18next";

export default function PreviewAddButtons({ song }) {
  const { addSongToVisionMovie, addSongToVisionMovieForPreview } = useVisionMovieContext();
  const navigate = useWebinarContext().navigate_WebinarWrapper;
  const { actionEnabled } = useWebinarContext();

  const { t } = useTranslation();
  return (
    <span className={`col-md-6 ${bs.center}`}>
      {/* TODO: I would need to remove the preview song again in the VisionMovieContext, if I would like this button to work. This I am not doing because the button is not really absolutely necessary for now...
      <Button
        onClick={() => {
          addSongToVisionMovieForPreview(song);
          if (actionEnabled(Action.PlayMindMovie)) navigate("/newplayer");
        }}
      >
        preview with VisionMovie
      </Button> */}
      <Button onClick={() => addSongToVisionMovie(song)}> {t("addToVisionMovie")}</Button>
    </span>
  );
}
