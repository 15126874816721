import { useState } from "react";

import {
  DndContext,
  DragOverlay,
  closestCorners,
  useSensor,
  useSensors,
  TouchSensor,
  MouseSensor,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";

import { useVisionMovieContext } from "../VisionMovieContext";
import { findContainer } from "./helperFunctions";

import Card from "./Card";

export default function DnDContext({ children }) {
  const { visionMovieData, changeVisionMovieData, setVisionMovieData } = useVisionMovieContext();
  const [activeId, setActiveId] = useState();

  class MyMouseSensor extends MouseSensor {
    static activators = [
      {
        eventName: "onPointerDown",
        handler: ({ nativeEvent: event }) => {
          if (!event.isPrimary || event.button !== 0 || isInteractiveElement(event.target)) {
            return false;
          }
          return true;
        },
      },
    ];
  }

  function isInteractiveElement(element) {
    const interactiveElements = ["button", "svg", "path", "input", "textarea", "select", "option"];
    if (interactiveElements.includes(element.tagName.toLowerCase())) {
      return true;
    }
    return false;
  }

  const sensors = useSensors(
    useSensor(MyMouseSensor),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 500,
        tolerance: 10,
      },
    })
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      {children}
      <DragOverlay
        dropAnimation={{
          duration: 200,
          easing: "cubic-bezier(.8,.37,.57,.8)",
        }}
      >
        {activeId ? (
          <Card
            id={activeId}
            isDragOverlay={true}
            segment={visionMovieData[findContainer(activeId, visionMovieData)].find(
              (segment) => segment.id === activeId
            )}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );

  function handleDragStart(event) {
    const { active } = event;
    const { id } = active;
    setActiveId(id);
  }

  function handleDragOver(event) {
    const { active, over, draggingRect } = event;
    const { id } = active;
    const { id: overId } = over;

    // Find the containers
    const activeContainer = findContainer(id, visionMovieData);
    const overContainer = findContainer(overId, visionMovieData);

    if (!activeContainer || !overContainer || activeContainer === overContainer) {
      return;
    }

    const newVisionMovieData = JSON.parse(JSON.stringify(visionMovieData));
    setVisionMovieData((prev) => {
      const activeItems = newVisionMovieData[activeContainer];
      const overItems = newVisionMovieData[overContainer];

      // Find the indexes for the items
      const activeIndex = activeItems.findIndex((item) => item.id === id);
      const overIndex = overItems.findIndex((item) => item.id === overId);

      let newIndex;

      if (overId in prev) {
        newIndex = overItems.length + 1;
      } else {
        const isBelowOverItem =
          over &&
          active.rect.current.translated &&
          active.rect.current.translated.top > over.rect.top + over.rect.height;

        const modifier = isBelowOverItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...newVisionMovieData,
        [activeContainer]: [
          ...newVisionMovieData[activeContainer].filter((item) => item.id !== active.id),
        ],
        [overContainer]: [
          ...newVisionMovieData[overContainer].slice(0, newIndex),
          visionMovieData[activeContainer][activeIndex],
          ...newVisionMovieData[overContainer].slice(
            newIndex,
            newVisionMovieData[overContainer].length
          ),
        ],
      };
    });
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over;

    const activeContainer = findContainer(id, visionMovieData);
    const overContainer = findContainer(overId, visionMovieData);

    if (!activeContainer || !overContainer || activeContainer !== overContainer) {
      return;
    }

    const activeIndex = visionMovieData[activeContainer].findIndex((item) => item.id === id);
    const overIndex = visionMovieData[overContainer].findIndex((item) => item.id === overId);

    const newVisionMovieData = JSON.parse(JSON.stringify(visionMovieData));
    changeVisionMovieData({
      ...newVisionMovieData,
      [overContainer]: arrayMove(newVisionMovieData[overContainer], activeIndex, overIndex),
    });
    setActiveId(null);
  }
}
