import { useEffect, useRef, useState, useLayoutEffect } from "react";
import { ShakaVideo } from "./ShakaVideo";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import { useFullscreen } from "rooks";
import { useWebinarContext } from "../softwareWebinar/WebinarContext";
import PlayerCloseButton from "./PlayerCloseButton";
import LoadingSpinner from "./LoadingSpinner";

const videosArray = [
  {
    kaleidoscope: true,
    url: "https://firebasestorage.googleapis.com/v0/b/visionmovie-94a95.appspot.com/o/assetsForTesting%2Fkaleidoscope%20zum%20testen.mp4?alt=media&token=ac76d486-52fa-47bb-a6d9-94ccdda4041b",
    duration: 18,
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/visionmovie-94a95.appspot.com/o/assetsForTesting%2F7.mp4?alt=media&token=a910c462-f973-4147-b4ca-c43011ee6f02",
    duration: 6,
    statement: {
      header: "I AM",
      hideAffirmationAtSec: 4,
      paragraph:
        "is growing and helping so many people\n to live the life of their dreams. This is so wonderful and bringing me so great joy :)",
      showAffirmationAtSec: 1,
    },
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/visionmovie-94a95.appspot.com/o/assetsForTesting%2F2.mp4?alt=media&token=3d625e6f-f7a0-42e4-bc5e-5fc1c5723963",
    duration: 6,
    statement: {
      header: "I WILL",
      hideAffirmationAtSec: 4,
      paragraph: "be exactly where I need to be",
      showAffirmationAtSec: 1,
    },
  },
  {
    url: "https://player.vimeo.com/external/422176234.hd.mp4?s=8180e27edd4ef747f5453533baadbe1a2f05fdf0&profile_id=175&oauth2_token_id=57447761",
    duration: 6,
    statement: {
      header: "MY LIFE",
      hideAffirmationAtSec: 4,
      paragraph: "is an absoulte blast",
      showAffirmationAtSec: 1,
    },
  },
  {
    url: "https://player.vimeo.com/external/517816974.hd.mp4?s=058e8cd79b26b12c416361e97f7c9ec751eb8cfa&profile_id=175&oauth2_token_id=57447761",
    duration: 6,
    statement: {
      header: "I AM",
      hideAffirmationAtSec: 4,
      paragraph: "getting this stuff done :)",
      showAffirmationAtSec: 1,
    },
  },
  {
    kaleidoscope: true,
    url: "https://firebasestorage.googleapis.com/v0/b/visionmovie-94a95.appspot.com/o/assetsForTesting%2Fkaleidoscope%20zum%20testen.mp4?alt=media&token=ac76d486-52fa-47bb-a6d9-94ccdda4041b",
    duration: 18,
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/visionmovie-94a95.appspot.com/o/assetsForTesting%2F7.mp4?alt=media&token=a910c462-f973-4147-b4ca-c43011ee6f02",
    duration: 6,
    statement: {
      header: "I AM",
      hideAffirmationAtSec: 4,
      paragraph:
        "is growing and helping so many people\n to live the life of their dreams. This is so wonderful and bringing me so great joy :)",
      showAffirmationAtSec: 1,
    },
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/visionmovie-94a95.appspot.com/o/assetsForTesting%2F2.mp4?alt=media&token=3d625e6f-f7a0-42e4-bc5e-5fc1c5723963",
    duration: 6,
    statement: {
      header: "I WILL",
      hideAffirmationAtSec: 4,
      paragraph: "be exactly where I need to be",
      showAffirmationAtSec: 1,
    },
  },
  {
    kaleidoscope: true,
    url: "https://firebasestorage.googleapis.com/v0/b/visionmovie-94a95.appspot.com/o/assetsForTesting%2Fkaleidoscope%20zum%20testen.mp4?alt=media&token=ac76d486-52fa-47bb-a6d9-94ccdda4041b",
    duration: 18,
  },
];

const fadoutAudio = true;
const debug = false;

export default function App() {
  const navigate = useWebinarContext()?.navigate_WebinarWrapper;
  const fullscreenContainerRef = useRef<HTMLDivElement>(null);
  const { enableFullscreen, isFullscreenEnabled } = useFullscreen({
    target: fullscreenContainerRef,
  });

  useEffect(() => {
    if (!isFullscreenEnabled) {
      enableFullscreen();
    }
  }, []);

  //SET VIDEO DIMENSIONS_______
  const [videoHeight, setVideoHeight] = useState<number>();
  const [videoWidth, setVideoWidth] = useState<number>();
  const [scalingFactor, setScalingFactor] = useState(1);
  //Determine width and height that the video shall have to be shown in the biggest size possible. This needs to works for both more narrow or wide devices
  const determineVideoSize = () => {
    //If width determines height
    const height = (window.innerWidth * 1080) / 1920;
    if (height <= window.innerHeight) {
      return {
        width: window.innerWidth,
        height,
      };
    } else {
      return {
        width: (window.innerHeight * 1920) / 1080,
        height: window.innerHeight,
      };
    }
  };

  //Set video dimensions on window resize and when component mounts
  useLayoutEffect(() => {
    const videoSize = determineVideoSize();
    setVideoHeight(videoSize.height);
    setVideoWidth(videoSize.width);
    setScalingFactor(videoSize.width / 1440);
    //Print out window width and height
  }, []);
  //Set video dimensions on window resize with react-beautiful-hooks
  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    const videoSize = determineVideoSize();
    setVideoHeight(videoSize.height);
    setVideoWidth(videoSize.width);
    setScalingFactor(videoSize.width / 1440);
  });
  //END SET VIDEO DIMENSIONS_______

  //PLAYER CONTROLLER CODE_______
  const audioRef = useRef<any>();
  const videoRefs = useRef([]);
  //const [audioReadyToPlay, setAudioReadyToPlay] = useState(false);
  //const [videoReadyToPlay, setVideoReadyToPlay] = useState(false);
  const [loading, setLoading] = useState(true);

  function playVideo(activeIndex: number) {
    const activeVideo: any = videoRefs.current[activeIndex];
    const activeVideoEventManager = activeVideo.videoElementRef.current;
    const nextVideo: any = videoRefs.current[activeIndex + 1];
    const nextVideoEventManager = nextVideo?.videoElementRef.current;

    //For starting first video. All others are already starting to play below in the function when former video asks them to take over.
    //Since this is the first video no former video asked this one to take over / triggers play
    if (activeIndex === 0) {
      //TODO: Set Loading spinner here after 0.5sec waiting
      //Remove Loading setTimeout + loading state if set to true
      //...
      activeVideoEventManager?.addEventListener(
        "playerLoaded",
        () => {
          debug && console.log("PLAYER LOADED");
          activeVideo.play();
          activeVideo.containerRef.current.style.opacity = 1;
        },
        {
          once: true,
        }
      );
      //activeVideo.play();

      activeVideoEventManager?.addEventListener(
        "videoStartsPlaying",
        () => {
          setLoading(false);
          debug && console.log("VIDEO STARTS PLAYING");
          //If is first video and NOT kaleidoscope -> start audio
          !videosArray[0].kaleidoscope && audioRef.current.play();
        },
        { once: true }
      );
    }

    //Listen to activeVideo loading event
    const handleActiveVideoLoading = () => {
      setLoading(true);
      console.log("LOADING video: ", activeIndex);
      activeVideoEventManager?.addEventListener(
        "playingAfterWaitingForData",
        () => {
          console.log("LOADING ENDED");
          setLoading(false);
        },
        { once: true }
      );
    };
    activeVideoEventManager?.addEventListener("waitingForData", handleActiveVideoLoading);

    //For not first or last video
    if (activeIndex < videoRefs.current.length - 1) {
      //Let audio and video fadeout, if next video is kaleidoscope
      videosArray[activeIndex + 1].kaleidoscope && activeVideo.notifyThatItShallFadeOutVideo();
      videosArray[activeIndex + 1].kaleidoscope &&
        fadoutAudio &&
        activeVideo.notifyThatItShallFadeOutAudio();

      //When active video is fully buffered it starts buffering next Video
      const bufferNextVideo = () => {
        //Load next video
        debug &&
          console.log("video: ", activeIndex, "buffered now buffer video: ", activeIndex + 1);
        nextVideo.bufferVideo();
      };
      activeVideoEventManager?.addEventListener("completelyBuffered", bufferNextVideo, {
        once: true,
      });

      //This eventListener triggers 0.49 seconds before "reachedDuration" is being triggered
      //This resets the nextVideo. This is done a bit before the switch to give the browser enough time to jump back to currentTime = 0 on the video or if not being prebuffered before to init and load Player already
      activeVideoEventManager?.addEventListener(
        "nextVideoGetReady",
        () => {
          //No need anymore for buffering nextVideo on activeVideo fully buffered when already needs to get ready
          activeVideoEventManager?.removeEventListener("completelyBuffered", bufferNextVideo);
          //NextVideo shall get ready for playback
          debug && console.log("video: ", activeIndex + 1, " gets Ready");
          nextVideo.getReadyForPlayback();
        },
        { once: true }
      );

      //Triggers when videos should switch / playing duration of activeVideo is reached
      activeVideoEventManager?.addEventListener(
        "reachedDuration",
        () => {
          debug && console.log("video: ", activeIndex, "reached duration");

          //TODO 1: Event when activeVideo stops playing => Loading spinner
          //Hau rebuffering goal direkt rein (weil ja jetzt beim alten video wartet)
          //Adapt for duration === videoLength Edge case in ShakaVideo component

          //Triggers when new video is playing / new video can take over
          nextVideoEventManager?.addEventListener(
            "playingAndReadyToBeShown",
            () => {
              activeVideoEventManager.removeEventListener(
                "waitingForData",
                handleActiveVideoLoading
              );
              loading && setLoading(false);
              //TODO 1: Remove EventListener when activeVideo stops playing due to timing issues
              //TODO: Remove waitingForData EventListener
              //Next video is taking over and old video is unloaded & destroyed
              const shallFadeIn = videosArray[activeIndex].kaleidoscope;
              !shallFadeIn && nextVideo.show();
              //Start playing audio if former video was kaleidoscope
              videosArray[activeIndex].kaleidoscope && audioRef.current.play();
              activeVideo.hide();
              //Recursive function call
              playVideo(activeIndex + 1);
            },
            { once: true }
          );
          videosArray[activeIndex].kaleidoscope && nextVideo.notifyThatItShallFadeInVideo();
          nextVideo.play();
        },
        { once: true }
      );
    }

    //IF LAST VIDEO
    if (activeIndex === videoRefs.current.length - 1) {
      debug && console.log("LAST VIDEO");
      //Notify that this video shall fadeout
      //Fadeout only if not kaleidoscope
      !videosArray[activeIndex].kaleidoscope && activeVideo.notifyThatItShallFadeOutVideo();
      !videosArray[activeIndex].kaleidoscope &&
        fadoutAudio &&
        activeVideo.notifyThatItShallFadeOutAudio();

      activeVideoEventManager?.addEventListener(
        "reachedDuration",
        () => {
          activeVideo.pauseLastVideo();
        },
        { once: true }
      );
    }
  }

  useEffect(() => {
    playVideo(0);
  }, []);

  /*   useEffect(() => {
    //console.log all state variables
    console.log("PLAYER RENDER");
    console.log("loading: ", loading);
    console.log("videoHeight: ", videoHeight);
    console.log("videoWidth: ", videoWidth);
    console.log("scalingFactor: ", scalingFactor);
  }); */

  return (
    <div className="App" ref={fullscreenContainerRef}>
      <button
        onClick={() => {
          debug && console.log("START VIDEO");
          playVideo(0);
        }}
      >
        play video
      </button>
      <button
        onClick={() => {
          enableFullscreen();
        }}
      >
        fullscreen
      </button>
      <button onClick={() => navigate && navigate("/player4start")}>
        navigate back to startpage
      </button>
      <audio
        ref={audioRef}
        controls
        src="https://firebasestorage.googleapis.com/v0/b/visionmovie-94a95.appspot.com/o/assetsForTesting%2FJakarta.mp3?alt=media&token=86bee2bc-cb91-4e91-a639-3b6336cf1308"
      />
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "black",
        }}
      >
        <PlayerCloseButton />
        <LoadingSpinner loading={loading} />
        <div
          style={{
            position: "relative",
            width: videoWidth,
            height: videoHeight,
          }}
        >
          {videosArray.map((videoObj, index) => {
            function addToRef(ref: never) {
              videoRefs.current[index] = ref;
            }
            return (
              <ShakaVideo
                videoIndex={index}
                key={index + "videoElement"}
                ref={addToRef}
                videoObj={videoObj}
                scalingFactor={scalingFactor}
                audioRef={audioRef}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
