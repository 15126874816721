export enum TrackingBoxType {
  placeholder, //Done
  done, //done
  missed, //done
  empty, //Done
  number, //Done
  timesVisionMovieWatched, //Done
  time,
  done_weekly_first,
  done_weekly_middle,
  done_weekly_end,
  missed_weekly_first,
  missed_weekly_middle,
  missed_weekly_end,
}
