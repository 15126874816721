import { Text, SettingContainer } from "./OpenCardWithSettingsStyled";
import MinTextField from "./MinTextField";

export default function KaleidoscopeTextFields({
  middleTextFieldDisabled,
  kaleidoscopeVideoLength,
  setKaleidoscopeVideoLength,
  marginTop,
}) {
  const bigTextField =
    window.innerWidth >= 1073 || (window.innerWidth < 900 && window.innerWidth > 536)
      ? true
      : false;
  const ShowLongText =
    window.innerWidth > 1376 || (window.innerWidth < 900 && window.innerWidth > 688) ? true : false;

  return (
    <SettingContainer marginTop={marginTop}>
      <div
        style={{
          height: "100%",
          display: "flex",
          paddingBottom: 6,
          alignItems: "end",
        }}
      >
        <Text style={{ marginRight: 10 }}>{ShowLongText && "Kaleidoscope "}Length</Text>
      </div>
      <MinTextField
        marginRight={15}
        marginLeft={10}
        bigTextField={bigTextField}
        label={"start"}
        min={0}
        max={5}
        step={1}
        state={kaleidoscopeVideoLength.start}
        setState={(newState) => {
          const newkaleidoscopeVideoLengthObj = JSON.parse(JSON.stringify(kaleidoscopeVideoLength));
          const newStateRounded = Math.floor(newState); //Math.floor(newState / 2) * 2;
          newkaleidoscopeVideoLengthObj.start = newStateRounded;
          setKaleidoscopeVideoLength(newkaleidoscopeVideoLengthObj);
        }}
      />
      <MinTextField
        bigTextField={bigTextField}
        marginRight={15}
        label={"middle"}
        min={0}
        max={3}
        step={1}
        state={kaleidoscopeVideoLength.middle}
        setState={(newState) => {
          const newkaleidoscopeVideoLengthObj = JSON.parse(JSON.stringify(kaleidoscopeVideoLength));
          newkaleidoscopeVideoLengthObj.middle = Math.floor(newState);
          setKaleidoscopeVideoLength(newkaleidoscopeVideoLengthObj);
        }}
        disabled={middleTextFieldDisabled}
      />
      <MinTextField
        bigTextField={bigTextField}
        label={"end"}
        min={0}
        max={3}
        step={1}
        state={kaleidoscopeVideoLength.end}
        setState={(newState) => {
          const newkaleidoscopeVideoLengthObj = JSON.parse(JSON.stringify(kaleidoscopeVideoLength));
          newkaleidoscopeVideoLengthObj.end = Math.floor(newState);
          setKaleidoscopeVideoLength(newkaleidoscopeVideoLengthObj);
        }}
      />
    </SettingContainer>
  );
}
