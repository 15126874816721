import { useState, useEffect } from "react";
import useInterval from "../../../helperFunctions/useInterval";

export default function useHTMLElementAddCurrentTime(
  play: boolean,
  imageHTMLElement: any,
  fps = 60
) {
  //Add 0 on initial render so that currentTime is not undefined
  useEffect(() => {
    if (imageHTMLElement) {
      imageHTMLElement.currentTime = 0;
    }
  }, []);

  const [timePlayedInMS, setTimePlayedInMS] = useState<number>(0);
  useInterval(
    () => {
      const intervalTimeMS = 1000 / fps;
      setTimePlayedInMS((prev) => prev + intervalTimeMS);
      if (imageHTMLElement) {
        imageHTMLElement.currentTime = timePlayedInMS / 1000 + intervalTimeMS / 1000;
      }
    },
    play ? 1000 / fps : null
  );
  return timePlayedInMS;
}
