import { useState, useEffect } from "react";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import { columnNumberBreakpoints } from "../../../Theme";

export default function useCalculateGridCardSize() {
  function calculategridCardSize() {
    if (window.innerWidth < columnNumberBreakpoints.twoColumns) {
      return 6;
    } else if (window.innerWidth < columnNumberBreakpoints.threeColumns) {
      return 4;
    } else {
      return 3;
    }
  }

  const [gridCardSize, setGridCardSize] = useState(() => calculategridCardSize());

  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    setGridCardSize(calculategridCardSize());
  });

  useEffect(() => {
    setGridCardSize(calculategridCardSize());
  }, []);

  return gridCardSize;
}
