import Login from "../components/Firebase/Authentication/Login";
import { useWebinarContext } from "../components/softwareWebinar/WebinarContext";
import { Container } from "react-bootstrap";

export default function LoginRoute() {
  const navigate = useWebinarContext().navigate_WebinarWrapper;

  function onLoggedIn() {
    navigate("/");
  }

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <Login onLoggedIn={onLoggedIn} />
    </Container>
  );
}
