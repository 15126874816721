import { useState } from "react";
import { useAuth } from "../components/Firebase/Contexts/AuthContext";
import { firestore } from "../components/Firebase/firebase";

export function useIsProAccount() {
  //Get if proAccount or not
  const { currentUser } = useAuth();
  const userId = currentUser?.uid;
  const [proAccount, setProAccount] = useState(false);

  firestore
    .collection("users")
    .doc(userId)
    .collection("userDetails")
    .doc("main")
    .get()
    .then((doc) => {
      setProAccount(doc.data().proAccount);
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });

  return proAccount;
}
