import { useState, useRef, useLayoutEffect } from "react";
import useResponsiveCardHeight from "../../segments/helperHooks/useResponsiveCardHeight";
import { useTheme } from "@mui/material/styles";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import { getModifiedPosterImageLink } from "../../backgroundSelection/helperFunctions";
import Affirmation from "../../segments/Affirmation";
import TransformedImage from "../../uploadImages/TransformedImage";

export default function Item({ segment }) {
  const theme = useTheme();
  const { palette } = theme;
  console.log("segment: ");
  console.log(segment);

  const cardRef = useRef(null);
  const [cardWidth, setCardWidth] = useState(0);

  useLayoutEffect(() => {
    setCardWidth(cardRef.current.offsetWidth);
  }, [cardRef]);

  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    setCardWidth(cardRef.current.offsetWidth);
  });

  const scalingFactor = cardWidth / 1440;
  //const [hasVideoOrImage, setHasVideoOrImage] = useState(false);
  const hasVideoOrImage = segment?.video?.placeholder || !segment?.video?.image ? false : true;
  //Später ersetzen und muss von item kommen
  const videoPlaceholderUrl =
    segment?.video?.placeholder || !segment?.video?.image //if no background
      ? null
      : segment?.image?.uploadedImage //if image background
      ? segment?.image?.image
      : getModifiedPosterImageLink(segment.video, 500); //if video background

  const CARD_BORDER_WIDTH = 3;

  const gradientBackground = {
    background: palette.gradient.cardBackgroundGradient,
  };
  const toBeAddedBackground = hasVideoOrImage ? null : gradientBackground;

  const cardHeight = (cardWidth * 1080) / 1920;

  const cardStyle = {
    ...toBeAddedBackground,
    fontSite: 1,
    position: "relative",
    display: "flex",
    height: cardHeight,
    width: "100%",
    opacity: 1,
    position: "relative",
    border: hasVideoOrImage ? null : `${CARD_BORDER_WIDTH}px solid ${palette.primary.main}`,
    borderRadius: 5,
    outline: "none",
    boxSizing: "border-box",
    overflow: "hidden",
    WebkitUserSelect: "none",
  };

  return (
    <div
      style={{
        width: "100%",
        height: cardHeight,
        background: "white",
        borderRadius: 10,
        WebkitUserSelect: "none",
      }}
    >
      <div style={cardStyle} ref={cardRef}>
        {/* CONTRAST LAYER */}
        <div
          style={{
            backgroundColor: `rgba(0, 0, 0, ${segment?.contrastLayerOpacityValue})`,
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: cardHeight,
            borderRadius: 10,
            zIndex: 1,
            WebkitUserSelect: "none",
          }}
        />
        {!segment?.video?.placeholder &&
          segment?.video?.image &&
          !segment?.image?.transformDataObj && (
            <img
              src={videoPlaceholderUrl}
              style={{
                transform: "scale(1)",
                objectPosition: "50% 50%",
                height: cardHeight,
                width: "100%",
                objectFit: "cover",
                borderRadius: 10,
                WebkitUserSelect: "none",
              }}
            />
          )}
        {/* Transformed Image */}
        {segment?.image?.transformDataObj && <TransformedImage imageData={segment.image} />}
        {/* Text */}
        <Affirmation
          hasVideoOrImage={hasVideoOrImage}
          segment={segment}
          scalingFactor={scalingFactor}
        />
      </div>
    </div>
  );
}
