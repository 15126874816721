import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import useCalculateGridCardSize from "../segments/helperHooks/useCalculateGridCardSize";
import Card from "./Card";
import AddTextEditorCard from "./AddTextEditorCard";
import { useAuth } from "../Firebase/Contexts/AuthContext";
import { firestore } from "../Firebase/firebase";

const employeesDummyData = [{ id: 1, name: "Loading...", content: null }];

export default function Employees() {
  const [employeesData, setEmployeesData] = useState([]);
  const { currentUser } = useAuth();
  const userId = currentUser?.uid;

  useEffect(() => {
    updateDocsData();
  }, []);

  console.log(userId);

  const updateDocsData = () => {
    firestore
      .collection("users")
      .doc(userId)
      .collection("textDocuments")
      .doc("allDocsNames")
      .onSnapshot((snapshot) => {
        const data = snapshot?.data()?.data ? snapshot?.data()?.data : [];
        setEmployeesData(data);
      });
  };

  const gridCardSize = useCalculateGridCardSize();
  //Make cursor visible if was set to not show when player was closed
  document.body.style.cursor = "auto";

  //Shows cursor again, if it was hidden in "/player" before
  useEffect(() => {
    document.body.style.cursor = "auto";
  }, []);

  const [resize, setResize] = useState(false);
  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    setResize((prev) => !prev);
  });

  return (
    <div>
      <Grid container spacing={2}>
        {employeesData?.map((item, index) => (
          <Grid key={item.id + "gridItem"} item xs={gridCardSize}>
            <Card key={item.id + "card"} data={item} index={index} resize={resize} />
          </Grid>
        ))}
        <Grid key={"AddCard"} item xs={gridCardSize}>
          <AddTextEditorCard data={employeesData} />
        </Grid>
      </Grid>
    </div>
  );
}
