import React, { useEffect, useRef, useLayoutEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import DayNumber from "./DayNumber";
import Stack from "@mui/material/Stack";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import { TrackingBoxType } from "./TrackingBoxType";
import CoachingIndicator from "./CoachingIndicator";
import dayjs from "dayjs";
import { rowMarginParameters } from "./rowMarginParameters";
import { firestore } from "../../Firebase/firebase";
import { useAuth } from "../../Firebase/Contexts/AuthContext";

const VmwatchedN = 5;

export default function Box({
  scrollContainer,
  toDo,
  elem,
  index,
  scalingFactor,
  coachings,
  setCircleWidthForButton,
  handleRowGetsVisible,
  boxesArrayLength,
  placeholdersAndMeasurements,
}) {
  const theme = useTheme();
  const { currentUser } = useAuth();

  const { date, boxType, value } = elem;
  const type = boxType;

  const circle = useRef();
  const [circleWidth, setCircleWidth] = useState(0);
  useLayoutEffect(() => {
    const width = circle.current.offsetWidth;
    //index === 0 && setCircleWidthForButton(width);
    setCircleWidth(width);
  }, [circle, scalingFactor]);

  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    const width = circle.current.offsetWidth;
    //index === 0 && setCircleWidthForButton(width);
    setCircleWidth(width);
  });

  return (
    <div
      //ref={elementRef}
      //Delete last ToDo measurement
      onDoubleClick={() => {
        const indexOfLastPlaceholder = placeholdersAndMeasurements.findLastIndex(
          (elem) => elem.boxType === 0
        );
        if (index === boxesArrayLength - 1 && index > indexOfLastPlaceholder) {
          toDo.measurements.pop();
          if (toDo.measurements.length === 0) toDo.measurements = null;
          firestore
            .collection("users")
            .doc(currentUser?.uid)
            .collection("TrackedToDos")
            .doc(String(toDo.id))
            .set(toDo)
            .then(() => {
              console.log("LAST TODO MEASUREMENT DELETED");
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        }
      }}
      //END: Delete last ToDo measurement
      style={{
        fontWeight: "bold",
        position: "relative",
        width: `${79 / 7}%`,
        marginTop: rowMarginParameters.marginTopNOTFirstRow * scalingFactor,
        /*   index <= 6
            ? rowMarginParameters.marginTopFirstRow * scalingFactor
            : rowMarginParameters.marginTopNOTFirstRow * scalingFactor, */
        marginRight: (index + 1) % 7 === 0 ? 0 : "3.5%",
        height: circleWidth,
      }}
    >
      <DayNumber day={dayjs(date).date()} scalingFactor={scalingFactor} type={type} />
      <CoachingIndicator scalingFactor={scalingFactor} coachings={coachings} date={date} />

      {/* PLACEHOLDER & EMPTY BOX */}
      {(type === TrackingBoxType.placeholder || type === TrackingBoxType.empty) && (
        <div
          ref={circle}
          style={{
            borderColor: "#E0E0E0",
            borderWidth: 4 * scalingFactor,
            borderStyle: "solid",
            borderRadius: 1000,
            background: "white",
            height: circleWidth,
          }}
        />
      )}

      {/* NUMBER BOX */}
      {type === TrackingBoxType.number && (
        <Stack
          justifyContent="center"
          alignItems="center"
          direction="row"
          ref={circle}
          style={{
            borderColor: "#E0E0E0",
            borderWidth: 4 * scalingFactor,
            borderStyle: "solid",
            borderRadius: 1000,
            width: "100%",
            height: circleWidth,
            fontSize: 33 * scalingFactor,
            backgroundImage: theme.palette.gradient.text,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            userSelect: "none",
          }}
        >
          {value}
        </Stack>
      )}

      {/* TIMES VM WATCHED BOX */}
      {type === TrackingBoxType.timesVisionMovieWatched && (
        <Stack
          justifyContent="center"
          alignItems="center"
          direction="row"
          ref={circle}
          style={{
            color: "white",
            borderRadius: 1000,
            width: "100%",
            height: circleWidth,
            fontSize: 43 * scalingFactor,
            userSelect: "none",
            fontWeight: "bolder",
            background:
              VmwatchedN === 0
                ? theme.palette.primary.main
                : VmwatchedN === 1
                ? theme.palette.orange.main
                : theme.palette.green.gradient,
          }}
        >
          <div style={{ marginTop: 2 * scalingFactor }}>{VmwatchedN}</div>
        </Stack>
      )}

      {/* CONTAINER DONE & MISSED BOX */}
      {(type === TrackingBoxType.done || type === TrackingBoxType.missed) && (
        <Stack
          justifyContent="center"
          alignItems="center"
          direction="row"
          ref={circle}
          style={{
            borderRadius: 1000,
            width: "100%",
            background:
              type === TrackingBoxType.done
                ? theme.palette.green.gradient
                : theme.palette.gradient.text,
            height: circleWidth,
          }}
        >
          {/* DONE BOX */}
          {type === TrackingBoxType.done && (
            <img
              style={{
                width: 48 * scalingFactor,
                marginTop: 10 * scalingFactor,
                userSelect: "none",
              }}
              src={"../../../thumbsUp.svg"}
              alt="+"
            />
          )}

          {/* MISSED BOX */}
          {type === TrackingBoxType.missed && (
            <img
              style={{
                width: 48 * scalingFactor,
                marginTop: 17 * scalingFactor,
                userSelect: "none",
              }}
              src={"../../../thumbsDown.svg"}
              alt="-"
            />
          )}
        </Stack>
      )}
    </div>
  );
}
