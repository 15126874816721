import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useUploadedImagesContext } from "./UploadedImagesContext";
import CropperComponent from "./CropperComponent";
import { useTheme } from "@mui/material/styles";
import Slider from "../settings/Slider";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "6px",
};

export default function BasicModal({ videoData }) {
  const theme = useTheme();
  const { palette } = theme;
  const { openModal, setOpenModal, saveTransformDataForCroppedImage, zoom, setZoom } =
    useUploadedImagesContext();
  const modalWidth = window.innerWidth * 0.95;
  const modalHeight = window.innerHeight * 0.9;

  //Regular zomm value is stored in context
  const [sliderZoomValue, setSliderZoomValue] = useState(1);
  const [changedSliderZoomValue, setChangedSliderZoomValue] = useState(false);
  const [changedZoomValueInCropper, setChangedZoomValueInCropper] = useState(false);
  useEffect(() => {
    if (changedSliderZoomValue) {
      setZoom((sliderZoomValue + 70) / 70);
    } else {
      setChangedSliderZoomValue(false);
    }
  }, [sliderZoomValue]);

  useEffect(() => {
    if (changedZoomValueInCropper) {
      setSliderZoomValue(zoom * 70 - 70);
      setChangedZoomValueInCropper(false);
    }
  }, [zoom]);

  return (
    <div>
      <Modal
        id={videoData?.id}
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: modalWidth, height: modalHeight }}>
          <div style={{ width: "100%", height: modalHeight - 150 }}>
            <CropperComponent
              zoom={zoom}
              setZoom={setZoom}
              setChangedZoomValueInCropper={setChangedZoomValueInCropper}
            />
          </div>
          <div
            style={{
              height: 150,
              display: "flex",
              flexDirection: "column",
              paddingTop: 16,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginBottom: 16,
              }}
            >
              <div
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: palette.primary.main,
                  WebkitUserSelect: "none",
                  marginRight: 32,
                }}
              >
                zoom picture:
              </div>
              <div style={{ width: "50%" }}>
                <Slider
                  sliderValue={sliderZoomValue}
                  setSliderValue={setSliderZoomValue}
                  setChangedSliderValue={setChangedSliderZoomValue}
                />
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
              <Button
                variant="contained"
                style={{ textTransform: "none", borderRadius: 6, width: "30%" }}
                disableElevation
                onClick={() => {
                  saveTransformDataForCroppedImage();
                  setOpenModal(false);
                }}
              >
                save
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
