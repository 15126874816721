import { useEffect } from "react";
import Navigation from "../components/navigation";
import Segments from "../components/segments";
import { useTheme } from "@mui/material/styles";
import { useVisionMovieContext } from "../components/VisionMovieContext";

export default function SegmentsRoute() {
  const theme = useTheme();
  const { scrollPositionInSegments } = useVisionMovieContext();
  //scroll window to a position when having been in segments before
  useEffect(() => {
    document.body.scroll(0, scrollPositionInSegments);
  }, []);

  return (
    <div
      style={{
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
      }}
    >
      <Navigation />
      <div style={{ paddingTop: 60, overflow: "hidden" }}>
        <Segments />
      </div>
    </div>
  );
}
