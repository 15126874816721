import { SoftwarePart } from "./SoftwareWebinarInterfaces";
import useTimeout from "../../helperFunctions/useTimeout";
import AudioElements from "./AudioElements";
import { useWebinarContext } from "./WebinarContext";
import { useEffect } from "react";
import SoftwareComponent from "./SoftwareComponent";
import DivOverlays from "./DivOverlays";

export default function SoftwarePartComponent({
  softwarePart,
  onFinishedPart,
}: {
  softwarePart: SoftwarePart;
  onFinishedPart: () => void;
}) {
  //GO to next part after set TIME NOT USER ACTION
  useTimeout(
    () => {
      onFinishedPart();
    },
    softwarePart?.showingDuration ? softwarePart?.showingDuration * 1000 : null
  );

  /*   const setRouteString = useWebinarContext()?.setRouteString;

  useEffect(() => {
    setRouteString(softwarePart.routeString);
  }, []); */

  return (
    <div>
      <AudioElements softwarePart={softwarePart} />
      <SoftwareComponent onFinishedPart={onFinishedPart} />
      <DivOverlays softwarePart={softwarePart} onFinishedPart={onFinishedPart} />
    </div>
  );
}
