import { AvailableVideoFilesFromAPI } from "../VisionMovieSegment";

//Get best video src videoLink
//TODO get best src videoLink according to device with (for mobile device according to bigger size (note: because orientation changes are possible at every time))
export default function getFittingVideoLinkForDeviceDimension(
  availableVideoFilesFromAPI: AvailableVideoFilesFromAPI[]
): string {
  const height = window.innerHeight;
  const width = window.innerWidth;
  const isPhone = height < 500 || width < 500;
  //GET SD video URL for phones
  if (isPhone) {
    return getClosestLink(availableVideoFilesFromAPI, 1280);
  }

  //GET best HD video URL for desktop & tablets
  if (!isPhone) {
    return getClosestLink(availableVideoFilesFromAPI, 1920);
  }
  //Default
  return availableVideoFilesFromAPI[0]?.videoLink;
}

function getClosestLink(linksArray: AvailableVideoFilesFromAPI[], idealWidth: number): string {
  const perfectLink = linksArray.filter((element) => element.width === idealWidth);
  if (perfectLink.length !== 0) {
    return perfectLink[0].videoLink;
  }

  if (perfectLink.length === 0) {
    //ABOVE idealWidth width objects_______
    //Get objects with width above idealWidth and sort them in ascending order
    const linksAboveIdealWidth = linksArray.filter((element) => element.width > idealWidth);
    //Sort objects by width, ascending
    if (linksAboveIdealWidth.length > 0) {
      linksAboveIdealWidth.sort((a, b) => {
        return a.width - b.width;
      });
    }
    //BELOW idealWidth width objects_______
    //Get objects with width below idealWidth and sort them in descending order
    const linksBelowIdealWidth = linksArray.filter((element) => element.width < idealWidth);
    //Sort objects by width descending
    linksBelowIdealWidth.sort((a, b) => {
      return b.width - a.width;
    });

    var i = 1;
    while (true) {
      //Check above
      if (linksAboveIdealWidth[0]?.width < idealWidth + i * 50) {
        return linksAboveIdealWidth[0].videoLink;
      }

      // Check below (35 is choosen here to prefer using higher resolution links)
      if (linksBelowIdealWidth[0]?.width > idealWidth - i * 30) {
        return linksBelowIdealWidth[0].videoLink;
      }
      i = i + 1;
    }
  }
  //Get first file as default (should not be used)
  return linksArray[0].videoLink;
}
