//Helper function to split videos array that is comping from the server into rows of 4
export function splitInRows(items, size) {
  const chunks = [];
  items = [].concat(...items);

  while (items.length) {
    chunks.push(items.splice(0, size));
  }

  return chunks;
}

//This gives back the poster image and transforms the link according to
//the resolution one sets through the image width property in the function
export const getModifiedPosterImageLink = (data, toBeSetHeight) => {
  //If the video is uploaded the image URL doesn't need to be transformed because it allready is at a certain dimension.
  //Since it is straight uploaded to firebase storage transforming it with the URL is also not available.
  if (data?.uploadedVideo || data?.uploadedImage) {
    return data.image;
  }
  const imageURL = data.image;
  const resultingImageWidth = (toBeSetHeight * 1920) / 1080;
  const modifiedImagedURL = `${
    imageURL.split("?")[0]
  }?fit=crop&w=${resultingImageWidth}&h=${toBeSetHeight}&auto=compress&cs=tinysrgb`;
  return modifiedImagedURL;
};

export const getFittingVideoLink = (data) => {
  const videoFilesArray = data.video_files;
  const preferedVideoResolutions = [338, 360, 506, 540];

  for (let i = 0; i < preferedVideoResolutions.length; i++) {
    const resolutionObj = videoFilesArray.find(
      (elem) => elem.height === preferedVideoResolutions[i]
    );
    if (resolutionObj) {
      return resolutionObj.link;
    }
  }
  return videoFilesArray[0].link;
};
