import { useTheme } from "@mui/material/styles";

export default function ProgressBar({ uploadStatus, uploadProgress }) {
  const theme = useTheme();

  return (
    <div className="progress uploadProgress">
      <div
        className="progress-bar progressBar"
        role="progressbar"
        style={{
          width: `${uploadProgress}%`,
          backgroundColor:
            uploadStatus === "done" ? theme.palette.green.main : theme.palette.primary.main,
        }}
        // eslint-disable-next-line jsx-a11y/aria-proptypes
        aria-valuenow={`${uploadProgress}`}
        aria-valuemin="0"
        aria-valuemax="100"
      >
        {uploadStatus === "done" ? "Song Uploaded Successfully" : "Uploading Song"}
      </div>
    </div>
  );
}
