//Context API template
import React, { useContext, useEffect, useState } from "react";
import { firebaseStorage, firestore } from "../Firebase/firebase";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../Firebase/Contexts/AuthContext";

const UploadedImagesContext = React.createContext({
  currentTime: undefined,
  setCurrentTime: undefined,
});

export function useUploadedImagesContext() {
  return useContext(UploadedImagesContext);
}

//Creating the logic and the Providers
export function UploadedImagesContextProvider({ children, uploadedImagesInRows }) {
  // Get current loggedIn user from firebase
  const { currentUser } = useAuth();
  const userId = currentUser?.uid;

  const [uploadStatus, setUploadStatus] = useState();
  const [uploadProgress, setUploadProgress] = useState(undefined);
  const [errors, setErrors] = useState([]);

  // if error occur while uploading...
  const handleError = (err) => {
    setUploadStatus("error");
    setErrors([...errors, err]);
  };

  //Reset states and variables related to upload song to storage and firestore
  const resetUploadingStates = () => {
    setTimeout(() => {
      setUploadStatus(undefined);
      setUploadProgress(undefined);
      setErrors([]);
    }, 4500);
  };

  //2d array -> 1d array and placeholder on position 0 removed
  var formerUploadedImages = [].concat(...uploadedImagesInRows);
  formerUploadedImages.shift(); //remove placeholder obj in array that is needed for upload card

  //Upload video to firebase storage and firestore & create thumbnail
  function handleImageSelected(event) {
    const imageFile = event.target.files[0];
    //Check if file is already uploaded
    if (formerUploadedImages.filter((image) => image.fileName === imageFile.name).length != 0) {
      alert("This image is already uploaded!");
      return;
    }
    //Checks if file size is under 10mb
    if (imageFile.size < 4000000) {
      uploadToStorage(imageFile); //upload image to firebase storage
    } else {
      alert(
        "Your image is too big. Please upload a image with under 4MB. You can compress the image by using https://compressjpeg.com."
      );
    }
  }

  // Uploading videoFile to storage...
  const uploadToStorage = (imageFile) => {
    // Create the file metadata. Needed for uploading to firestore
    var metadata = {
      contentType: imageFile.type,
    };

    // Location in storage you want to create/send file to
    const storagePath = `users/${userId}/uploadedImages/${imageFile.name}`;
    const uploadingImageFileHandler = firebaseStorage.ref(storagePath).put(imageFile, metadata);
    setUploadStatus("inProgress");
    uploadingImageFileHandler.on(
      "state_changed",
      (snap) => handleProgress(snap),
      (err) => handleError(err),
      () => {
        uploadingImageFileHandler.snapshot.ref
          .getDownloadURL()
          .then((storageLink) => {
            createDataObj(storageLink, imageFile);
          })
          .catch((err) => handleError(err));
      }
    );
  };

  // handle progress of uploading image
  const handleProgress = ({ bytesTransferred, totalBytes }) => {
    let tranferedBytes = bytesTransferred ? bytesTransferred : 0;
    let totalByte = totalBytes ? totalBytes : 0;
    const percentUploaded = totalBytes !== 0 ? Math.round((tranferedBytes / totalByte) * 100) : 0;
    setUploadProgress(percentUploaded);
  };

  // handle when song uploaded to Storage
  const createDataObj = (storageLink, imageFile) => {
    const dataObj = [
      ...formerUploadedImages,
      {
        uploadedImage: true,
        image: storageLink,
        duration: 1000000000, //dummy duration, since a picture can be shown as long as the user wants
        fileName: imageFile.name,
        id: uuidv4(),
      },
    ];
    console.log(dataObj);
    saveToFirestore(dataObj);
  };

  // firebase firestore reference
  const firestoreRef = firestore.collection("users").doc(userId);
  //save Song data to firestore
  const saveToFirestore = (imageData) => {
    firestoreRef
      .collection("UploadedImages")
      .doc("all")
      .set({ uploadedImages: imageData })
      .then(() => {
        console.log("STORED IN FIREBASE");
        setUploadStatus("uploadCompleted");
        resetUploadingStates();
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
        setUploadStatus("error");
      });
  };

  //PARTS NEEDED FOR CROPPING IMAGE
  //Things needed to work with the crop modal and actually croping the image
  const [openModal, setOpenModal] = useState(false);
  const [imageToCrop, setImageToCrop] = useState();

  //Loads in transform data when a new image shall be cropped
  useEffect(() => {
    if (imageToCrop?.transformDataObj) {
      setCrop(imageToCrop.transformDataObj.crop);
      setZoom(imageToCrop.transformDataObj.zoom);
    } else {
      setCrop({ x: 0, y: 0 });
      setZoom(1);
    }
  }, [imageToCrop]);

  //Store transform data in context when image was cropped
  const [croppedArea, setCroppedArea] = useState(undefined);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(undefined);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const handOverCropDataToContext = (croppedArea, croppedAreaPixels, crop, zoom) => {
    setCroppedArea(croppedArea);
    setCroppedAreaPixels(croppedAreaPixels);
    setCrop(crop);
    setZoom(zoom);
  };

  //Save transform data to cropped image when the crop settings are saved
  const saveTransformDataForCroppedImage = () => {
    const imageToCropIndex = formerUploadedImages.findIndex((image) => image.id === imageToCrop.id);
    //create deep copy of uploadedImages
    const newUploadedImages = [...formerUploadedImages];
    //update the image that is being cropped
    newUploadedImages[imageToCropIndex].transformDataObj = {
      crop,
      zoom,
      croppedArea,
      croppedAreaPixels,
    };

    firestoreRef
      .collection("UploadedImages")
      .doc("all")
      .set({ uploadedImages: newUploadedImages })
      .then()
      .catch((error) => {
        console.error("Error writing document: ", error);
        setUploadStatus("error");
      });
  };

  const functions = {
    handleImageSelected,
    uploadStatus,
    uploadProgress,
    openModal,
    setOpenModal,
    imageToCrop,
    setImageToCrop,
    saveTransformDataForCroppedImage,
    handOverCropDataToContext,
    zoom,
    setZoom,
    crop,
    setCrop,
  };

  return (
    <UploadedImagesContext.Provider value={functions}>{children}</UploadedImagesContext.Provider>
  );
}
