import _ from "lodash";
import { easeCubic, easeCubicInOut, easeLinear } from "d3-ease";
//Find out more about easing/animation functions here: https://www.npmjs.com/package/d3-ease

const default_easing_function = easeCubicInOut;

export const createEaseInFunction =
  (startTimeMs: number, durationMs: number) => (timeMs: number) => {
    if (timeMs < startTimeMs + durationMs) {
      return _.clamp(default_easing_function((timeMs - startTimeMs) / durationMs), 0, 1);
    } else {
      return 1;
    }
  };

export const createEaseInOutFunction = (
  startTime1Ms: number,
  duration1Ms: number,
  startTime2Ms: number,
  duration2Ms: number
) => {
  return (timeMs: number) => {
    if (timeMs < startTime1Ms + duration1Ms) {
      const timeSinceStartMs = timeMs - startTime1Ms;
      const progress = timeSinceStartMs / duration1Ms;
      return _.clamp(default_easing_function(progress), 0, 1);
    } else if (timeMs < startTime2Ms + duration2Ms) {
      const timeSinceStartMs = timeMs - startTime2Ms;
      const progress = timeSinceStartMs / duration2Ms;
      return _.clamp(default_easing_function(1 - progress), 0, 1);
    } else {
      return 0;
    }
  };
};
