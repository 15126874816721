interface IPlayerSettings {
  //IMAGE ZOOM
  //Zoom Speed
  imageRatio: number;
  imageZoomSpeed: number;

  //VIDEO & AUDIO FADES
  //KALEIDOSCOPE VIDEOS
  //FadeIns
  firstKaleidoscopeVideoFadeInDuration_VIDEO_Ms: number;
  firstKaleidoscopeVideoFadeInDuration_AUDIO_Ms: number;

  middleAndEndkaleidoscopeVideoFadeInDuration_VIDEO_Ms: number;
  middleAndEndkaleidoscopeVideoFadeInDuration_AUDIO_Ms: number;
  //FadeOuts
  kaleidoscopeFadeOutDuration_VIDEO_Ms: number;
  kaleidoscopeFadeOutDuration_AUDIO_Ms: number;

  //VISIONMOVIES
  //FadeIns
  visionMovieFadeInDurationMs_VIDEO_Ms: number;
  visionMovieFadeInDurationMs_AUDIO_Ms: number;
  //FadeOuts
  visionMovieFadeOutDurationMs_VIDEO_Ms: number;
  visionMovieFadeOutDurationMs_AUDIO_Ms: number;
}

const PlayerSettings: IPlayerSettings = {
  imageRatio: 16/9,
  imageZoomSpeed: 3.5, // scale: 0 (fast) -> 10 (very slow)
  //KALEIDOSCOPE VIDEOS
  //FadeIns
  firstKaleidoscopeVideoFadeInDuration_VIDEO_Ms: 50, //0 -> no fadeIn
  firstKaleidoscopeVideoFadeInDuration_AUDIO_Ms: 500, //0 -> no fadeIn
  middleAndEndkaleidoscopeVideoFadeInDuration_VIDEO_Ms: 4000,
  middleAndEndkaleidoscopeVideoFadeInDuration_AUDIO_Ms: 4000,
  //FadeOuts
  kaleidoscopeFadeOutDuration_VIDEO_Ms: 4000,
  kaleidoscopeFadeOutDuration_AUDIO_Ms: 4000,

  //VISIONMOVIES
  //FadeIns
  visionMovieFadeInDurationMs_VIDEO_Ms: 4000,
  visionMovieFadeInDurationMs_AUDIO_Ms: 4000,
  //FadeOuts
  visionMovieFadeOutDurationMs_VIDEO_Ms: 8000,
  visionMovieFadeOutDurationMs_AUDIO_Ms: 8000,
};

export default PlayerSettings;
