import { useEffect, useLayoutEffect, useState } from "react";
import { firestore } from "../Firebase/firebase";
import { useAuth } from "../Firebase/Contexts/AuthContext";
import { useLocation } from "react-router-dom";
import { splitInRows } from "../backgroundSelection/helperFunctions";
import { FixedSizeList as List } from "react-window";
import { ListRow } from "../backgroundSelection/ListRow";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import { UploadedImagesContextProvider } from "./UploadedImagesContext";
import CropModal from "./CropModal";
import useCalculateGridCardSize from "../segments/helperHooks/useCalculateGridCardSize";

export default function UploadImages() {
  const gridCardSize = useCalculateGridCardSize();

  //HEIGHT & WIDTH____
  //Calculates width, height + resulting rowHeight
  //It recalculates these values when window is resized
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  });
  function calculateRowHeight(windowWidth) {
    const number = gridCardSize === 3 ? 4 : gridCardSize === 4 ? 3 : 1.96;
    return (((windowWidth - 16 * 4) / number) * 1080) / 1920 + 16;
  }
  //____

  const currentPathname = useLocation().pathname;

  // Get current loggedIn user from firebase
  const { currentUser } = useAuth();
  const userId = currentUser?.uid;

  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadedImagesInRows, setUploadedImagesInRows] = useState([
    [{ uploadingPlaceholder: true }],
  ]);

  useEffect(() => {
    //Add placeholder to List of Images
    const preparedUploadedImages =
      uploadedImages?.length !== 0
        ? [{ uploadingPlaceholder: true }, ...uploadedImages]
        : [{ uploadingPlaceholder: true }];
    //Split list of video in rows for the List
    const responsiveNrOfVideosPerRow = gridCardSize === 3 ? 4 : gridCardSize === 4 ? 3 : 2;
    const uploadedImagesSplitInRows = splitInRows(
      preparedUploadedImages,
      responsiveNrOfVideosPerRow
    );
    setUploadedImagesInRows(uploadedImagesSplitInRows);
  }, [uploadedImages, gridCardSize]);

  //Path to uploaded Images array in firebase
  const uploadedImagesRef = firestore
    .collection("users")
    .doc(userId)
    .collection("UploadedImages")
    .doc("all");
  //Setup snapshot and get uploaded Images data from firebase before component renders
  useLayoutEffect(() => {
    if (currentUser?.uid) {
      uploadedImagesRef.onSnapshot((docRef) => {
        if (docRef?.data()) {
          setUploadedImages(docRef.data().uploadedImages);
        }
      });
    }
  }, [currentUser]);

  return (
    <UploadedImagesContextProvider uploadedImagesInRows={uploadedImagesInRows}>
      <CropModal />
      <List
        height={windowHeight - 60}
        itemCount={uploadedImagesInRows.length}
        itemSize={calculateRowHeight(windowWidth)}
        overscanCount={7}
      >
        {ListRow(gridCardSize, uploadedImagesInRows, uploadedImagesInRows.length, currentPathname)}
      </List>
    </UploadedImagesContextProvider>
  );
}
