import styled from "@emotion/styled";
import { fontWeight } from "@mui/system";
import { Breakpoint, up, down } from "./Breakpoint";
const spacing = 8;

export const Card = styled.div((props) => ({
  position: "relative",
  width: "100%",
  height: props.cardHeight,
  borderRadius: props.borderRadius ? props.borderRadius : spacing * 2,
  background: props.backgroundColor,
  /*   [down(800)]: {
    color: "black",
  }, */
}));

export const ElementsContainer = styled.div((props) => ({
  width: "100%",
  height: "100%",
  paddingLeft: spacing * 3,
  paddingRight: spacing * 3,
  paddingTop: spacing * 2,
  paddingBottom: spacing * 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: props.spacebetween && "space-between",
  zIndex: 2,
  position: "absolute",
  top: 0,
  left: 0,
}));

export const VisionMovieTitle = styled.div((props) => ({
  fontSize: "1.7rem",
  color: "white",
  fontWeight: 600,
}));

export const ButtonsContainer = styled.div((props) => ({
  display: "flex",
  justifyContent: "space-between",
  color: "white",
  fontSize: 26,
}));

export const PlayButtonContainer = styled.div((props) => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
}));
