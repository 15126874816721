import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useMonthContext } from "./MonthContext";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
const monthStrings = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];

const monthStringsEn = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function MonthName({ scalingFactor, placeholdersAndMeasurements }) {
  const { i18n } = useTranslation();
  const { month, setMonth } = useMonthContext();
  useEffect(() => {
    const dayToPickMonthFrom =
      placeholdersAndMeasurements &&
      placeholdersAndMeasurements[placeholdersAndMeasurements?.length - 1];
    if (dayToPickMonthFrom) {
      const monthIndex = dayjs(dayToPickMonthFrom?.date).get("month");
      const monthStringsInRightLanguage = i18n.language === "de-DE" ? monthStrings : monthStringsEn;
      const monthString = monthStringsInRightLanguage[monthIndex];
      setMonth(monthString);
    }
  }, [placeholdersAndMeasurements]);

  return (
    <Typography
      style={{
        fontSize: scalingFactor ? 28 * scalingFactor : 28,
        fontWeight: "bold",
        marginBottom: 15,
        userSelect: "none",
        opacity: month === "Placeholder" ? 0 : 1,
      }}
      variant="h3"
    >
      {month}
    </Typography>
  );
}
