import { easeLinear } from "d3-ease";
import _ from "lodash";
import { createEaseInOutFunction } from "../animationUtils";
import Line from "../CanvasElements/Line";
import MultiLineString from "../CanvasElements/MultiLineString";
import SingleLineString from "../CanvasElements/SingleLineString";
import computeFadeValueForCurrentTimeMs from "../helperFunctions/computeFadeValue";
import { VisionMovieSegment } from "../interfaces/VisionMovieSegment";
import PlayerSettings from "../PlayerSettings";
import parameters from "./CanvasOverlayParameters";

export default class CanvasOverlay {
  private context: CanvasRenderingContext2D | null | any = null;
  private ratio = 1;

  private title: string;
  private paragraph: string;
  private overlayAlpha: number;

  private videoWidth: number;
  private videoHeight: number;
  private offsetTop: number;

  private lastTimestampUpdate: number = 0;

  private segmentClipObj: any;

  private lineElement: Line | null = null;
  private titleElement: SingleLineString | null = null;
  private paragraphElement: MultiLineString | null = null;

  private animationOpacityTitle: (timeMs: number) => number = (timeMs: number) => 1;
  private animationOpacityPararaph: (timeMs: number) => number = (timeMs: number) => 1;
  private animationOpacityLine: (timeMs: number) => number = (timeMs: number) => 1;
  private animationLengthOfLine: (timeMs: number) => number = (timeMs: number) => 1;
  private destroyed: boolean = false;
  isActive: boolean = true;

  constructor(
    private canvas: HTMLCanvasElement,
    private htmlElement: any,
    private visionMovieSegment: VisionMovieSegment,
    private fadeIn: boolean = false,
    private fadeOut: boolean = false,
    private index: number
  ) {
    this.visionMovieSegment = visionMovieSegment;
    this.fadeIn = fadeIn;
    this.fadeOut = fadeOut;
    this.index = index;
    this.segmentClipObj = visionMovieSegment?.videoClip
      ? visionMovieSegment?.videoClip
      : visionMovieSegment?.imageClip;

    //Initiate header & paragraph
    //If no header & paragraph is choosen && no video -> show placeholder text
    const placeholderTextNeeded =
      this.segmentClipObj?.placeholder &&
      visionMovieSegment.statement.header === "" &&
      visionMovieSegment.statement.paragraph === "";

    this.title = placeholderTextNeeded
      ? "YOU DIDN'T"
      : visionMovieSegment.statement.header.toUpperCase();
    this.paragraph = placeholderTextNeeded
      ? "TYPE IN ANY TEXT YET. DO THIS AND IT WILL BE SHOWN HERE"
      : visionMovieSegment.statement.paragraph;

    this.overlayAlpha = visionMovieSegment.contrastLayerOpacityValue;

    this.canvas = canvas;
    this.htmlElement = htmlElement;

    this.context = canvas.getContext("2d")!;

    if (!this.canvas || !this.htmlElement || !this.context) {
      console.error("CanvasOverlay failed to initialize");
    }

    const elementBounds = this.htmlElement.getBoundingClientRect();
    this.videoWidth = elementBounds.width;
    this.videoHeight = elementBounds.height;
    this.offsetTop = elementBounds.top;
    //get currentOffsetTop for video element
    this.canvas.style.top = elementBounds.top + "px";

    this.adjustToDevicePixelRatio(this.videoWidth, this.videoHeight);

    this.initializeAnimations();

    this.updateLayout();
  }
  initializeAnimations() {
    this.animationOpacityTitle = createEaseInOutFunction(
      this.visionMovieSegment.statement.showAffirmationAtSec * 1000,
      parameters.titleAnimation.fadeInDurationMs,
      this.visionMovieSegment.statement.hideAffirmationAtSec * 1000,
      parameters.titleAnimation.fadeOutDurationMs
    );
    this.animationOpacityPararaph = createEaseInOutFunction(
      this.visionMovieSegment.statement.showAffirmationAtSec * 1000 +
        parameters.paragraphAnimation.fadeInDelayMs,
      parameters.paragraphAnimation.fadeInDurationMs,
      this.visionMovieSegment.statement.hideAffirmationAtSec * 1000 +
        parameters.paragraphAnimation.fadeOutDelayMs,
      parameters.paragraphAnimation.fadeOutDurationMs
    );
    this.animationOpacityLine = createEaseInOutFunction(
      this.visionMovieSegment.statement.showAffirmationAtSec * 1000 +
        parameters.lineOpacity.fadeInDelayMs,
      parameters.lineOpacity.fadeInDurationMs,
      this.visionMovieSegment.statement.hideAffirmationAtSec * 1000 +
        parameters.lineOpacity.fadeOutDelayMs,
      parameters.lineOpacity.fadeOutDurationMs
    );
    this.animationLengthOfLine = createEaseInOutFunction(
      this.visionMovieSegment.statement.showAffirmationAtSec * 1000 +
        parameters.lineWidthAnimation.startDelayMs,
      parameters.lineWidthAnimation.startDurationMs,
      this.visionMovieSegment.statement.hideAffirmationAtSec * 1000 +
        parameters.lineWidthAnimation.endDelayMs,
      parameters.lineWidthAnimation.endDurationMs
    );
  }

  updateLayout() {
    const scaleFactor = 1 / this.ratio;

    //compute title element
    const fontSizeTitle = (parameters.title.fontSize * (scaleFactor * this.canvas.width)) / 1920;
    const titleFont = `700 ${fontSizeTitle}px Poppins`;
    this.context.font = titleFont;
    this.context.fillStyle = "white";
    this.context.textAlign = "left";
    const titleDimensions = this.context.measureText(this.title);

    const marginLeft = this.canvas.width * parameters.general.paddingLeft * scaleFactor;
    this.titleElement = {
      x: marginLeft,
      y: 0,
      text: this.title,
      font: titleFont,
      color: "#ffffff",
      width: titleDimensions.width,
      height: titleDimensions.actualBoundingBoxAscent,
    };

    //compute line element

    //width of line is at least length of title, but at most length of string "I AM"
    const maxAbsoluteLineWidth = Math.min(
      titleDimensions.width,
      this.context.measureText("I AM").width
    );

    const yLine =
      this.titleElement.y +
      this.titleElement.height +
      this.canvas.height * parameters.line.relativeTopMarginTitle * scaleFactor;

    this.lineElement = {
      x: marginLeft,
      y: yLine,
      color: "#fffff",
      width: maxAbsoluteLineWidth,
      height: this.canvas.height * parameters.line.relativeHeight * scaleFactor,
    };

    //compute paragraph element
    const maxWidthParagraph = this.canvas.width * parameters.paragraph.maxRelativeWidth;

    const fontSizeParagraph =
      (parameters.paragraph.fontSize * (scaleFactor * this.canvas.width)) / 1920;

    this.paragraphElement = this.createMultiLineCanvasElement(
      this.paragraph,
      fontSizeParagraph,
      maxWidthParagraph,
      marginLeft,
      this.lineElement.y +
        this.lineElement.height +
        this.canvas.height * parameters.paragraph.relativeTopMarginParagraph * scaleFactor
    );

    const minY = this.titleElement.y;
    const maxY = this.paragraphElement.y + this.paragraphElement.height;
    const totalVerticalSpacing = this.canvas.height * scaleFactor - (maxY - minY);
    const offsetTop = totalVerticalSpacing * 0.5; //centers the content vertically

    this.titleElement.y += offsetTop;
    this.lineElement.y += offsetTop;
    this.paragraphElement.y += offsetTop;
  }

  createMultiLineCanvasElement(
    text: string,
    fontSize: number,
    maxWidth: number,
    x: number,
    y: number
  ): MultiLineString {
    const singleLineStrings: SingleLineString[] = [];
    const font = `500 ${fontSize}px Poppins`;
    this.context.font = font;
    this.context.textAlign = "left";
    const textMetrics = this.context.measureText(text);
    const textWidth = textMetrics.width;
    //if textWidth is more then X% of the canvas width, draw multiple lines

    let paragraphHeight = 0;
    let paragraphWidth = 0;
    if (textWidth > maxWidth) {
      //split by space or \n
      const _words = text.split(" ");
      const words = []; ///add newlines as individual words
      for (let i = 0; i < _words.length; i++) {
        const word = _words[i];
        const splitByNewLine = word.split("\n");
        if (splitByNewLine.length > 1) {
          for (let j = 0; j < splitByNewLine.length; j++) {
            words.push(splitByNewLine[j]);
            if (j < splitByNewLine.length - 1) {
              words.push("\n");
            }
          }
        } else {
          words.push(word);
        }
      }

      let line = "";
      let currentY = 0;
      for (let n = 0; n < words.length; n++) {
        const isNewLine = words[n] === "\n";
        const word = words[n];
        let testLine: string = "";
        if (isNewLine) {
          testLine = line;
        } else {
          if (line.length > 0) {
            testLine = line + " " + word;
          } else {
            testLine = word;
          }
        }

        const metrics = this.context.measureText(testLine);
        const testWidth = metrics.width;
        //split if line is too long or newline character is found
        if ((testWidth > maxWidth && n > 0) || isNewLine) {
          singleLineStrings.push({
            x: 0,
            y: currentY,
            text: line,
            color: "#ffffff",
            font: font,
            width: metrics.width,
            height: metrics.actualBoundingBoxAscent,
          });

          paragraphWidth = _.max([metrics.width, paragraphWidth]);
          paragraphHeight += fontSize * parameters.paragraph.maxRelativeWidth;
          this.context.fillText(line, x, y);
          if (isNewLine) {
            line = "";
          } else {
            line = words[n];
          }
          currentY += fontSize * parameters.paragraph.wrappingLineHeight;
        } else {
          line = testLine;
        }
      }

      singleLineStrings.push({
        x: 0,
        y: currentY,
        text: line,
        color: "#ffffff",
        font: font,
        width: this.context.measureText(line).width,
        height: this.context.measureText(line).actualBoundingBoxAscent,
      });

      this.context.fillText(line, x, y);
    } else {
      const metrics = this.context.measureText(text);
      singleLineStrings.push({
        x: 0,
        y: 0,
        text: text,
        width: metrics.width,
        font: font,
        color: "#ffffff",
        height: metrics.actualBoundingBoxAscent,
      });
      paragraphHeight = metrics.actualBoundingBoxAscent;
      paragraphWidth = metrics.width;
    }

    return {
      x: x,
      y: y,
      width: paragraphWidth,
      height: paragraphHeight,
      lines: singleLineStrings,
    };
  }

  updateCanvasSize() {
    const elementBounds = this.htmlElement.getBoundingClientRect();

    const width = elementBounds.width;
    const height = elementBounds.height;
    const top = elementBounds.top;

    if (this.videoWidth !== width || this.videoHeight !== height) {
      this.videoWidth = width;
      this.videoHeight = height;

      this.adjustToDevicePixelRatio(this.videoWidth, this.videoHeight);
      this.updateLayout();
    }

    if (this.offsetTop !== top) {
      this.offsetTop = top;
      this.canvas.style.top = this.offsetTop + "px";
    }
  }

  adjustToDevicePixelRatio(width: number, height: number) {
    //taken from https://gist.github.com/callumlocke/cc258a193839691f60dd

    if (this.context) {
      const devicePixelRatio = window.devicePixelRatio || 1;

      // determine the 'backing store ratio' of the canvas context
      const backingStoreRatio =
        this.context.webkitBackingStorePixelRatio ||
        this.context.mozBackingStorePixelRatio ||
        this.context.msBackingStorePixelRatio ||
        this.context.oBackingStorePixelRatio ||
        this.context.backingStorePixelRatio ||
        1;

      // determine the actual ratio we want to draw at
      var ratio;
      let os = navigator.userAgent;
      if (os.search("Windows") !== -1 || (os.search("Linux") !== -1 && os.search("X11") !== -1)) {
        ratio = (devicePixelRatio / backingStoreRatio) * 2;
      } else {
        ratio = devicePixelRatio / backingStoreRatio;
      }

      if (devicePixelRatio !== backingStoreRatio) {
        // set the 'real' canvas size to the higher width/height
        this.canvas.width = width * ratio;
        this.canvas.height = height * ratio + 1; // +1 because sometimes there is a gap

        // ...then scale it back down with CSS
        this.canvas.style.width = width + "px";
        this.canvas.style.height = height + 1 + "px"; //+1 because sometimes there is a gap
      } else {
        // this is a normal 1:1 device; just scale it simply
        this.canvas.width = width;
        this.canvas.height = height + 2;
        this.canvas.style.width = "";
        this.canvas.style.height = "";
      }

      // scale the drawing context so everything will work at the higher ratio
      this.context.scale(ratio, ratio);
      this.ratio = ratio;
    }
  }

  destroy() {
    this.destroyed = true;
  }

  drawTitle(currentTimeMs: number) {
    if (this.titleElement) {
      this.context.globalAlpha = this.animationOpacityTitle(currentTimeMs);
      this.context.font = this.titleElement?.font;
      this.context.fillStyle = this.titleElement?.color;
      this.context.textAlign = "left";
      this.context.fillText(
        this.titleElement?.text,
        this.titleElement?.x,
        this.titleElement?.y + this.titleElement.height
      );
    }
  }

  drawLine(currentTimeMs: number) {
    if (this.lineElement) {
      this.context.globalAlpha = this.animationOpacityLine(currentTimeMs);

      //minimum length of line is header, maximum length is length of "I AM"
      this.context.fillStyle = this.lineElement.color;
      const lineWidth = this.animationLengthOfLine(currentTimeMs) * this.lineElement.width;
      this.context.strokeStyle = "green";
      this.context.beginPath();
      this.context.fillRect(
        this.lineElement.x,
        this.lineElement.y,
        lineWidth,
        this.lineElement.height
      );

      this.context.closePath();
    }
  }

  drawParagraph(currentTimeMs: number) {
    if (this.paragraphElement) {
      this.context.globalAlpha = this.animationOpacityPararaph(currentTimeMs);
      let globalX = this.paragraphElement.x;
      let globalY = this.paragraphElement.y;

      for (const line of this.paragraphElement.lines) {
        this.context.font = line.font;
        this.context.textAlign = "left";
        this.context.fillStyle = line.color;
        let x = globalX + line.x;
        let y = globalY + line.y + line.height;
        this.context.fillText(line.text, x, y);
      }
    }
  }

  _update(currentTimeMs: number) {
    if (currentTimeMs < this.lastTimestampUpdate) {
      console.warn(
        "Preventing canvas from updating due to safari video stuttering" +
          " (before :" +
          this.lastTimestampUpdate.toString() +
          " now: " +
          currentTimeMs.toString() +
          ")"
      );
      /*
      this is a workaround for an issue with playing videos in safari
      it turns out that the video time does not progress linearly but sometimes "stutters" and jumps to an earlier position.
      this causes the fading animation to jump back and forth which looks irritating
      */
      return;
    }

    this.updateCanvasSize();
    //If video is choosen, but no text => don't show text
    const dontShowText =
      !this.segmentClipObj?.placeholder &&
      this.visionMovieSegment.statement.header === "" &&
      this.visionMovieSegment.statement.paragraph === "";
    const showText = !dontShowText;

    if (this.context) {
      //clear everything
      this.context.beginPath();
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

      //Draw overlay mask______________
      //Set FadeValues according to video type and placement
      //FADEINS
      //if(true) -> is kaleidoscope video or FIRST VisionMovie Segment
      //Otherwise apply no fades
      var videoFadeInDurationMs = null;
      if (this.fadeIn) {
        const isKaleidoscopeVideo = this.visionMovieSegment?.videoClip?.isKaleidoscope;
        if (isKaleidoscopeVideo) {
          //Is first Kaleidoscope
          if (this.index === 0) {
            videoFadeInDurationMs = PlayerSettings.firstKaleidoscopeVideoFadeInDuration_VIDEO_Ms;
          }
          //Is middle or end Kaleidoscope Video
          else {
            videoFadeInDurationMs =
              PlayerSettings.middleAndEndkaleidoscopeVideoFadeInDuration_VIDEO_Ms;
          }
        }
        //Is first VisionMovieSegment
        else {
          videoFadeInDurationMs = PlayerSettings.visionMovieFadeInDurationMs_VIDEO_Ms;
        }
      }
      //FADEOUTS
      //if(true) -> is kaleidoscope video or LAST VisionMovie Segment
      //Otherwise apply no fades
      var videoFadeOutDurationMs = null;
      if (this.fadeOut) {
        //Is Kaleidoscope
        if (this.visionMovieSegment.videoClip?.isKaleidoscope) {
          videoFadeOutDurationMs = PlayerSettings.kaleidoscopeFadeOutDuration_VIDEO_Ms;
        }
        //Is Last VisionMovieSegment
        else {
          videoFadeOutDurationMs = PlayerSettings.visionMovieFadeOutDurationMs_VIDEO_Ms;
        }
      }

      //Compute overlay opacity value based on currentTime
      let newAlpha = _.clamp(
        1 -
          computeFadeValueForCurrentTimeMs(
            this.segmentClipObj?.showingDurationInSec * 1000,
            currentTimeMs,
            videoFadeInDurationMs,
            videoFadeOutDurationMs
          ),
        this.overlayAlpha,
        1
      );

      //Set Overlay opacity on the canvas
      this.context.globalAlpha = newAlpha;
      this.context.fillStyle = "black";
      this.context.fillRect(0, 0, this.canvas.width, this.canvas.height + 1); //+1 because sometimes there is a 1px gap between the canvas and the video

      if (showText) {
        this.drawTitle(currentTimeMs);
        this.drawLine(currentTimeMs);
        this.drawParagraph(currentTimeMs);
      }
    }

    this.lastTimestampUpdate = currentTimeMs;
  }

  setActive(isActive: boolean) {
    this.isActive = isActive;
  }

  update() {
    const currentTimeMs = this.htmlElement?.currentTime * 1000;
    if (this.isActive) {
      this._update(currentTimeMs);
    } else {
      if (this.visionMovieSegment.videoClip) {
        this._update(this.visionMovieSegment.videoClip.startOffsetInSec * 1000);
      } else {
        this._update(0);
      }
    }

    if (!this.destroyed) {
      window.requestAnimationFrame(() => this.update());
    }
  }
}
