import { SoftwarePart } from "./SoftwareWebinarInterfaces";
import { DivOverlayType } from "./SoftwareWebinarInterfaces";
import NextPartButton from "./NextPartButton";

export default function DivOverlays({
  softwarePart,
  onFinishedPart,
}: {
  softwarePart: SoftwarePart;
  onFinishedPart: () => void;
}) {
  if (!softwarePart?.divOverlays) {
    return <></>;
  }

  return (
    <>
      {softwarePart?.divOverlays.map((divOverlay, index) => {
        if (divOverlay.type === DivOverlayType.NextPartButton) {
          return (
            <NextPartButton
              key={index + "divOverlay"}
              divOverlay={divOverlay}
              onFinishedPart={onFinishedPart}
            />
          );
        }
      })}
    </>
  );
}
