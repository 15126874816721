import { bootStrapClass as bs } from "./HelperFunctions";
import Button from "../Components/BackgroundMusicButton";
import { useTranslation } from "react-i18next";

export default function LinkButtons({ song }) {
  const { t } = useTranslation();

  return (
    <div className="col-md-12 d-flex">
      <a
        href={song.platformToBuyFromLink}
        target="blank"
        style={{
          textDecoration: "none",
          marginRight: 16,
        }}
        //className={`btn btn-default ${bs.itemCenter} songItemPreviewButton soundCloudLinksButton`}
      >
        <Button>{t("buySong")}</Button>
      </a>
      <a
        href={song.youtubeLink}
        target="blank"
        style={{
          textDecoration: "none",
          marginRight: 16,
        }}
      >
        <Button>{t("songOnYoutube")}</Button>
      </a>
      <a
        href={song.soundcloudLink}
        target="blank"
        style={{
          textDecoration: "none",
        }}
      >
        <Button>{t("songOnSoundcloud")}</Button>
      </a>
    </div>
  );
}
