import Grid from "@mui/material/Grid";
import VisionMovieCard from "./VisionMovieCard";
import NewVisionMovieCard from "./NewVisionMovieCard";
import { useVisionMovieContext } from "../VisionMovieContext";
import { useEffect } from "react";
import { Breakpoint, useAbove } from "./Breakpoint";

export default function Index() {
  const { visionMovies, setCurrentlyActiveVisionMovieId } = useVisionMovieContext();
  useEffect(() => {
    setCurrentlyActiveVisionMovieId(null);
    //Shows cursor again, if it was hidden in "/player" before
    document.body.style.cursor = "auto";
  }, []);

  const bigDevice = useAbove(Breakpoint.desktop);

  return (
    <Grid container spacing={4}>
      {visionMovies.map((visionMovie, index) => {
        return (
          <Grid key={index + "card"} item xs={12} md={6}>
            <VisionMovieCard data={visionMovie} bigDevice={bigDevice} />
          </Grid>
        );
      })}
      {bigDevice && (
        <Grid item xs={12} md={6}>
          <NewVisionMovieCard bigDevice={bigDevice} />
        </Grid>
      )}
    </Grid>
  );
}
