import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState, useRef } from "react";
import GrowingInputField from "./GrowingInputField";
import { firestore } from "../Firebase/firebase";
import Stack from "@mui/material/Stack";

export default function NameField({ data, userId }) {
  const [over, setOver] = useState(false);
  const [name, setName] = useState(data?.name);
  const { id, content } = data;

  /*   useEffect(() => {
    setName(data?.name);
  }, [data]); */

  function changeName(newName) {
    setName(newName);
  }

  const firstRender = useRef(false);

  useEffect(() => {
    if (!over && firstRender.current) {
      //Store name in both data structures
      firestore
        .collection("users")
        .doc(userId)
        .collection("textDocuments")
        .doc("allDocsNames")
        .get()
        .then((doc) => {
          const data = doc.data();
          const index = data.data.findIndex((elem) => elem.id === id);
          data.data[index].name = name;
          firestore
            .collection("users")
            .doc(userId)
            .collection("textDocuments")
            .doc("allDocsNames")
            .set(data)
            .then((response) => {
              console.log("success creating new document");
            })
            .catch((error) => console.log("error occured: " + error));
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
      firestore
        .collection("users")
        .doc(userId)
        .collection("textDocuments")
        .doc(id)
        .set({ name, id, content })
        .then((response) => {
          console.log("success creating new document");
        })
        .catch((error) => console.log("error occured: " + error));
    }
  }, [over]);

  useEffect(() => {
    firstRender.current = true;
  }, []);

  return (
    <Stack
      style={{ marginBottom: 30 }}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <div
        onMouseEnter={() => setOver(true)}
        onMouseLeave={() => setOver(false)}
        style={{
          width: "fit-content",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <GrowingInputField
          value={name}
          onChange={(e) => changeName(e.target.value)}
          style={{ fontWeight: [700], marginRight: 8 }}
        />
      </div>
      <Stack direction="row" justifyContent="flex-start" alignItems="center">
        <h1 style={{ fontWeight: "bold", margin: 0 }}>- My CV and how I help</h1>
      </Stack>
    </Stack>
  );
}
