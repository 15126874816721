import { ElementsContainer, ButtonsContainer, PlayButtonContainer } from "./VisionMovieCardStyled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit, faCopy, faSlidersH } from "@fortawesome/free-solid-svg-icons";
import ImageGridCardBackground from "./ImageGridCardBackground";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DeleteModal from "./DeleteModal";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../Firebase/Contexts/AuthContext";
import { firestore } from "../Firebase/firebase";
import VisionMovieName from "./VisionMovieName";
import { useVisionMovieContext } from "../VisionMovieContext";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const VIDEO_RATIO = 1080 / 1920;

export default function ClosedCard({
  data,
  cardHeight,
  overCard,
  setClosedCard,
  bigDevice,
  nextCoaching,
  setVisionMovieSelected,
}) {
  const currentPathname = useLocation().pathname;
  const navigate = useNavigate();
  const {
    setCurrentlyActiveVisionMovieId,
    moveDisplayIndexInAppOfOldVMs,
    setAllowSnapshotUpdates,
  } = useVisionMovieContext();
  //Data
  const [imagesArray, setImagesArray] = useState([]);
  //Get imagesArray
  useEffect(() => {
    if (data.visionMovie.length === 0) {
      setImagesArray([]);
    }
    if (data.visionMovie.length !== 0) {
      let newImagesArray = [];
      data.visionMovie.map((segment) => {
        if (newImagesArray.length <= 4) {
          segment?.image?.uploadedImage && newImagesArray.push(segment.image.image);
          segment?.video?.image && newImagesArray.push(segment.video.image);
        }
      });
      setImagesArray(newImagesArray);
    }
  }, [data]);

  const { currentUser } = useAuth();
  const userId = currentUser?.uid;

  async function handleDuplicateVM() {
    setAllowSnapshotUpdates(false);
    await moveDisplayIndexInAppOfOldVMs();
    setAllowSnapshotUpdates(true);
    const visionMovieId = uuidv4();
    firestore
      .collection("users")
      .doc(userId)
      .collection("VisionMovies")
      .doc(visionMovieId)
      .set({
        ...data,
        displayIndexInApp: 0,
        visionMovieId: visionMovieId,
        name: data.name + " v2",
        playbackParameters: {
          playVisionMovieNumberOfTimes: 1,
          playKaleidoscopeVideo: true,
          startKaleidoscopeVideoLength: 4,
          middleKaleidoscopeVideoLength: 0.6,
          endKaleidoscopeVideoLength: 0,
          showStatements: true,
        },
      })
      .then((response) => {
        console.log("success duplicating VM");
      })
      .catch((error) => console.log("error occured: " + error));
  }

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <ImageGridCardBackground imagesArray={imagesArray} />
      <ElementsContainer spacebetween>
        <VisionMovieName data={data} userId={userId} />
        {currentPathname === "/fortschritt" ? (
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Button
              onClick={() => {
                setCurrentlyActiveVisionMovieId(data.visionMovieId);
                setVisionMovieSelected(true);
              }}
              style={{ width: "40%", fontSize: 22, fontWeight: "bold", textTransform: "none" }}
              variant="contained"
              disabled={nextCoaching?.date ? false : true}
              disableElevation
            >
              auswählen
            </Button>
          </Stack>
        ) : (
          <PlayButtonContainer>
            <div
              style={{
                width: (cardHeight / VIDEO_RATIO) * 0.263,
              }}
            >
              <img
                onClick={() => {
                  setCurrentlyActiveVisionMovieId(data.visionMovieId);
                  navigate("/newplayer");
                }}
                style={{
                  width: "100%",
                  objectFit: "cover",
                  objectPosition: "50% 50%",
                  cursor: "pointer",
                }}
                src="../../bigPlayButton.svg"
                alt=""
              />
            </div>
          </PlayButtonContainer>
        )}
        <ButtonsContainer>
          {overCard && bigDevice && currentPathname !== "/fortschritt" ? (
            <>
              <FontAwesomeIcon
                icon={faEdit}
                onClick={() => {
                  setCurrentlyActiveVisionMovieId(data.visionMovieId);
                  console.log(data.visionMovieId);
                  navigate("/edit");
                }}
                style={{
                  cursor: "pointer",
                }}
              />
              {/* <FontAwesomeIcon
                onClick={() => setClosedCard(false)}
                icon={faSlidersH}
                style={{
                  cursor: "pointer",
                }}
              /> */}
              <FontAwesomeIcon
                icon={faCopy}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => handleDuplicateVM()}
              />
              <FontAwesomeIcon
                icon={faTrashAlt}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setModalOpen(true)}
              />
            </>
          ) : (
            <>{bigDevice && <FontAwesomeIcon icon={faEdit} style={{ opacity: 0 }} />}</>
          )}
          {/* {!bigDevice && (
            <FontAwesomeIcon
              onClick={() => setClosedCard(false)}
              icon={faSlidersH}
              style={{
                cursor: "pointer",
              }}
            />
          )} */}
        </ButtonsContainer>
      </ElementsContainer>
      <DeleteModal
        visionMovieId={data.visionMovieId}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
    </>
  );
}
