import React, { useState } from "react";
import { affirmationStyles } from "./StatementStyles";

export default function Line({ scalingFactor, lineRef }) {
  return (
    <div
      ref={lineRef}
      style={{
        width: 0,
        opacity: 0,
        height: scalingFactor * affirmationStyles.lineHeight,
        marginTop: scalingFactor * affirmationStyles.lineMarginTop,
        marginBottom: scalingFactor * affirmationStyles.lineMarginBottom,
        borderRadius: affirmationStyles.lineBorderRadius,
        background: "white",
      }}
    />
  );
}
