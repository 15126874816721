import useWindowResize from "beautiful-react-hooks/useWindowResize";
import { useEffect, useState } from "react";

export enum Breakpoint {
  "mobile" = 0,
  "tablet" = 640,
  "laptop" = 950,
  "desktop" = 1200,
  "xs" = 0,
  "sm" = 600,
  "md" = 900,
  "lg" = 1200,
  "xl" = 1536,
}

export function up(px: number) {
  return `@media (min-width: ${px}px)`;
}

export function down(px: number) {
  return `@media (max-width: ${px}px)`;
}

export function useAbove(breakpoint: Breakpoint | number) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    setWindowWidth(window.innerWidth);
  });

  const [above, setAbove] = useState<boolean | undefined>();
  useEffect(() => {
    if (windowWidth > breakpoint) {
      setAbove(true);
    } else {
      setAbove(false);
    }
  }, [windowWidth]);
  return above;
}
