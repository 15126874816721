import { useState, useEffect } from "react";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import { columnNumberBreakpoints } from "../../../Theme";

export default function useResponsiveCardHeight() {
  function calculatecardHeight() {
    if (window.innerWidth < columnNumberBreakpoints.twoColumns) {
      return "26.7vw";
    } else if (window.innerWidth < columnNumberBreakpoints.threeColumns) {
      return "18vw";
    } else {
      return "13.4vw";
    }
  }

  const [cardHeight, setCardHeight] = useState(() => calculatecardHeight());

  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    setCardHeight(calculatecardHeight());
  });

  useEffect(() => {
    setCardHeight(calculatecardHeight());
  }, []);

  return cardHeight;
}
