export function findContainer(id, visionMovieData) {
  if (visionMovieData.visionMovie.find((item) => item.id === id)) {
    //console.log("visionMovie");
    return "visionMovie";
  } else if (visionMovieData.archive.find((item) => item.id === id)) {
    //console.log("archive");
    return "archive";
  } else {
    return;
  }
}
