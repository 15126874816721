import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck, faSquarePlus } from "@fortawesome/free-solid-svg-icons";

export default function SelectorButton({ option, addOption, addedOptions }) {
  const theme = useTheme();
  const optionName = option.name;
  const selected = addedOptions.find((name) => name === optionName);

  return (
    <div
      onClick={() => addOption(optionName)}
      style={{
        cursor: "pointer",
        paddingLeft: selected ? 15 : 12,
        paddingRight: selected ? 25 : 23,
        paddingTop: selected ? 12 : 10,
        paddingBottom: selected ? 12 : 10,
        marginBottom: 25,
        background: selected ? theme.palette.gradient.cardBackgroundGradient20 : "white",
        borderRadius: 12,
        width: "max-content",
        display: "flex",
        alignItems: "center",
        borderStyle: "solid",
        borderColor: theme.palette.grey.medium,
        borderWidth: selected ? 0 : 2,
      }}
    >
      <FontAwesomeIcon
        style={{ fontSize: 35, marginRight: 19 }}
        icon={selected ? faSquareCheck : faSquarePlus}
        color={selected ? "white" : theme.palette.grey.medium}
      />
      <Typography
        style={{
          padding: 0,
          margin: 0,
          fontWeight: "bold",
          color: selected ? "white" : theme.palette.grey.medium,
          userSelect: "none",
        }}
        variant="h3"
      >
        {optionName}
      </Typography>
    </div>
  );
}
