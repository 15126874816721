import { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { firestore } from "../../Firebase/firebase";
import { Card, Button } from "react-bootstrap";
import { useWebinarContext } from "../../softwareWebinar/WebinarContext";

export default function SortPlatformSongs() {
  const navigate = useWebinarContext().navigate_WebinarWrapper;

  //TODO: DummyData needs to be changes with data from the server
  const [items, setItems] = useState([]);

  //Get data from firestore with snapshot
  useEffect(() => {
    const unsubscribe = firestore
      .collection("music")
      .doc("backgroundMusicOnThePlatform")
      .onSnapshot((snapshot) => {
        const newItems = snapshot.data().list;
        setItems(newItems);
      });
    return () => unsubscribe();
  }, []);

  //Reorders the resultsArray
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    //If dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedSongsArray = reorder(items, result.source.index, result.destination.index);
    //store array in firestore
    setItems(reorderedSongsArray);
    firestore
      .collection("music")
      .doc("backgroundMusicOnThePlatform")
      .set({ list: reorderedSongsArray })
      .then(() => {
        //console.log("Document successfully written!");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }

  function deleteSong(song) {
    //Delete song from firestore
    const newItems = items.slice();
    const index = newItems.findIndex(
      (item) => item.artistName === song.artistName && item.songTitle === song.songTitle
    );
    newItems.splice(index, 1); //remove song from array
    firestore
      .collection("music")
      .doc("backgroundMusicOnThePlatform")
      .set({ list: newItems })
      .then(() => {
        //console.log("Document successfully written!");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }

  return (
    <>
      <Button onClick={() => navigate("/uploadplatformsong")}>go to upload platform songs</Button>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((song, index) => (
                <Draggable
                  id={`${index}id`}
                  key={`${index}key`}
                  draggableId={`${index}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Card
                        style={{
                          marginBottom: 20,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <p>{song.artistName + "-" + song.songTitle}</p>
                        <Button
                          className="btn-delete"
                          size="sm"
                          style={{
                            color: "white",
                            background: "rgba(255, 0, 90, 1)",
                            marginLeft: 20,
                          }}
                          onClick={() => deleteSong(song)}
                        >
                          delete
                        </Button>
                      </Card>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}
