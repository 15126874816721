import { useEffect } from "react";
import Navigation from "../components/navigation";
import BackgroundMusic from "../components/BackgroundMusic";
import { useTheme } from "@mui/material/styles";
import { usePlayerSetupContext } from "../components/player/contexts/PlayerSetupContext";

export default function BackroundMusicRoute() {
  const theme = useTheme();
  const { showPlayer } = usePlayerSetupContext();
  useEffect(() => {
    document.body.scroll(0, 0);
  }, []);

  return (
    <div style={{ marginLeft: theme.spacing(2), display: showPlayer ? "none" : "block" }}>
      <Navigation />
      <div style={{ marginTop: 80 }}>
        <BackgroundMusic />
      </div>
    </div>
  );
}
