import { useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import { TextFieldLabel } from "./OpenCardWithSettingsStyled";
import TextField from "@mui/material/TextField";

export default function MinTextField({
  style,
  state,
  setState,
  label,
  min,
  max,
  step,
  disabled,
  bigTextField,
  marginLeft,
  marginRight,
}: {
  style: any;
  state: any;
  setState: any;
  label: string;
  min: number;
  max: number;
  step: number;
  disabled: boolean;
  bigTextField: boolean;
  marginLeft?: number;
  marginRight?: number;
}) {
  const theme = useTheme();

  function handleChange(length: number) {
    length >= min && length <= max && setState(length);
  }

  return (
    <div
      style={{
        marginLeft: marginLeft,
        marginRight: marginRight,
      }}
    >
      <TextFieldLabel>{label}</TextFieldLabel>
      {bigTextField ? (
        <OutlinedInput
          disabled={disabled}
          type="number"
          sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "none" } }}
          inputProps={{
            step: step,
            style: {
              outline: "none",
              color: theme.palette.primary.main,
              fontWeight: "bold",
              fontSize: 20,
              paddingTop: 7,
              paddingBottom: 4,
            },
          }}
          style={{
            border: "none",
            background: "white",
            borderRadius: 4,
            width: 115,
            ...style,
          }}
          size="small"
          value={state}
          onChange={(e) => handleChange(Number(e.target.value))}
          endAdornment={<InputAdornment position="end">min</InputAdornment>}
        />
      ) : (
        <TextField
          value={state}
          sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "none" } }}
          inputProps={{
            step: step,
            style: {
              outline: "none",
              color: theme.palette.primary.main,
              fontWeight: "bold",
              fontSize: 20,
              paddingTop: 7,
              paddingBottom: 4,
            },
          }}
          id="outlined-number"
          type="number"
          style={{
            border: "none",
            background: "white",
            borderRadius: 4,
            width: 80,
            ...style,
          }}
          size="small"
          onChange={(e) => handleChange(Number(e.target.value))}
        />
      )}
    </div>
  );
}
