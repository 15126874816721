import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../Contexts/AuthContext";
import { Link } from "react-router-dom";

export default function Login({ onLoggedIn }) {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      onLoggedIn && onLoggedIn();
    } catch {
      setError("Failed to log in");
    }

    setLoading(false);
  }

  return (
    <div className="w-100" style={{ maxWidth: "400px" }}>
      <Card style={{ padding: 30 }}>
        <Card.Body>
          <h2 className="text-center mb-4">Log In</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group style={{ marginBottom: 15 }} id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control className="form-control" type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group style={{ marginBottom: 30 }} id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            <Button
              disabled={loading}
              className="w-100 btn-primary"
              style={{ color: "white" }}
              type="submit"
            >
              Log In
            </Button>
          </Form>
          <div className="w-100 text-center mt-3 ">
            <Link className="primary-font-color" to="/forgot-password">
              Forgot Password?
            </Link>
          </div>
        </Card.Body>
      </Card>
      {/* <div className="w-100 text-center mt-2">
          Need an account?{" "}
          <Link className="primary-font-color" to="/signup">
            Sign Up
          </Link>
        </div> */}
    </div>
  );
}
