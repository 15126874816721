import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useBackgroundSelectionContext } from "../backgroundSelection/BackgroundSelectionContext";
import { useTranslation } from "react-i18next";

export default function BackButton() {
  const currentPathname = useLocation().pathname;
  const { rememberBackgroundSelectionListScrollPosition } = useBackgroundSelectionContext();
  const { t } = useTranslation();
  {
    t("back");
  }

  const [linkTo, setLinkTo] = useState("/eidt");

  useEffect(() => {
    switch (currentPathname) {
      case "/backgroundselection":
        return setLinkTo("/edit");
      case "/settings":
        return setLinkTo("/edit");
      case "/backgroundselection/heartedvideos":
        return setLinkTo("/backgroundselection");
      case "/backgroundselection/allheartedvideos":
        return setLinkTo("/backgroundselection");
      case "/backgroundselection/uploadvideos":
        return setLinkTo("/backgroundselection");
      case "/backgroundselection/uploadimages":
        return setLinkTo("/backgroundselection");
      default:
        return setLinkTo("/edit");
    }
  }, []);

  return (
    <Link to={linkTo}>
      <Button
        style={{ textTransform: "none", borderRadius: "6px", fontWeight: "bold" }}
        variant="contained"
        disableElevation
        startIcon={<ArrowBackIcon />}
        onClick={() => {
          if (currentPathname === "/backgroundselection")
            rememberBackgroundSelectionListScrollPosition();
        }}
      >
        {t("back")}
      </Button>
    </Link>
  );
}
