//CALCULATE ESTIMATED VIDEO LENGTH
export default function videoLengthWithoutEmptyElements(segments, videoLengthForEmptyElements) {
  var newEstimatedVideoLength = 0;
  for (let i = 0; i < segments.length; i++) {
    const elem = segments[i];
    if (elem?.video?.placeholder) {
      newEstimatedVideoLength += videoLengthForEmptyElements;
    } else {
      newEstimatedVideoLength += elem?.video
        ? elem.video.showingDuration
        : elem.image.showingDuration;
    }
  }
  return newEstimatedVideoLength;
}
