import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { firestore } from "../../Firebase/firebase";
import { useWebinarContext } from "../../softwareWebinar/WebinarContext";
import { useTranslation } from "react-i18next";

export default function UploadSoundcloudSong() {
  const navigate = useWebinarContext().navigate_WebinarWrapper;

  const { t } = useTranslation();

  //Files
  const [artistName, setArtistName] = useState("");
  const [songTitle, setSongTitle] = useState("");
  const [soundcloudId, setSoundcloudId] = useState("");
  const [soundcloudLink, setSoundcloudLink] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [duration, setDuration] = useState(undefined);
  const [platformToBuyFrom, setPlatformToBuyFrom] = useState("");
  const [platformToBuyFromLink, setPlatformToBuyFromLink] = useState("");
  const [price, setPrice] = useState(undefined);

  const handleUpload = (e) => {
    e.preventDefault();
    //Create object of all state variables
    const soundcloudSong = {
      artistName: artistName,
      songTitle: songTitle,
      soundcloudId: soundcloudId,
      soundcloudLink: soundcloudLink,
      youtubeLink: youtubeLink,
      duration: duration,
      platformToBuyFrom: platformToBuyFrom,
      platformToBuyFromLink: platformToBuyFromLink,
      price: price,
    };
    //get current array of songs from firestore
    const soundcloudSongsRef = firestore.collection("music").doc("soundcloudSongs");

    soundcloudSongsRef.get().then((doc) => {
      console.log(doc.data());
      const newSoundCloudSongsList = doc.data().list;
      //Add new song to array
      newSoundCloudSongsList.push(soundcloudSong);
      //Upload new array to firestore
      soundcloudSongsRef.set({ list: newSoundCloudSongsList });
    });
  };

  function resetForm() {
    setArtistName("");
    setSongTitle("");
    setSoundcloudId("");
    setSoundcloudLink("");
    setYoutubeLink("");
    setDuration("");
    setPlatformToBuyFrom("");
    setPlatformToBuyFromLink("");
    setPrice("");
  }

  return (
    <div className="w-100" style={{ maxWidth: "500px", paddingBottom: 70 }}>
      <Button onClick={() => navigate("/sortsoundcloudsongs")}>go to list</Button>
      <Form onSubmit={handleUpload}>
        <Form.Group style={{ marginBottom: 15 }} id="header">
          <Form.Label>Artist</Form.Label>
          <Form.Control
            className="form-control"
            type="text"
            value={artistName}
            onChange={(event) => setArtistName(event.target.value)}
          />
        </Form.Group>
        <Form.Group style={{ marginBottom: 15 }} id="header">
          <Form.Label> {t("songTitle")}</Form.Label>
          <Form.Control
            className="form-control"
            type="text"
            value={songTitle}
            onChange={(event) => setSongTitle(event.target.value)}
            required
          />
        </Form.Group>
        <Form.Group style={{ marginBottom: 10 }} id="header">
          <Form.Label>Soundcloud Id</Form.Label>
          <Form.Control
            className="form-control"
            type="text"
            value={soundcloudId}
            onChange={(event) => setSoundcloudId(event.target.value)}
            required
          />
        </Form.Group>
        <Form.Group style={{ marginBottom: 10 }} id="header">
          <Form.Label>Soundcloud Link</Form.Label>
          <Form.Control
            className="form-control"
            type="text"
            value={soundcloudLink}
            onChange={(event) => setSoundcloudLink(event.target.value)}
            required
          />
        </Form.Group>
        <Form.Group style={{ marginBottom: 10 }} id="header">
          <Form.Label>Youtube Link</Form.Label>
          <Form.Control
            className="form-control"
            type="text"
            value={youtubeLink}
            onChange={(event) => setYoutubeLink(event.target.value)}
            required
          />
        </Form.Group>
        <Form.Group style={{ marginBottom: 10 }} id="header">
          <Form.Label>Duration</Form.Label>
          <Form.Control
            className="form-control"
            type="text"
            value={duration}
            onChange={(event) => setDuration(event.target.value)}
            required
          />
        </Form.Group>
        <Form.Group style={{ marginBottom: 10 }} id="header">
          <Form.Label>Platform to Buy From</Form.Label>
          <Form.Control
            className="form-control"
            type="text"
            value={platformToBuyFrom}
            onChange={(event) => setPlatformToBuyFrom(event.target.value)}
            required
          />
        </Form.Group>
        <Form.Group style={{ marginBottom: 10 }} id="header">
          <Form.Label>Platform to Buy From Link</Form.Label>
          <Form.Control
            className="form-control"
            type="text"
            value={platformToBuyFromLink}
            onChange={(event) => setPlatformToBuyFromLink(event.target.value)}
            required
          />
        </Form.Group>
        <Form.Group style={{ marginBottom: 10 }} id="header">
          <Form.Label>Price</Form.Label>
          <Form.Control
            className="form-control"
            type="text"
            value={price}
            onChange={(event) => setPrice(event.target.value)}
            required
          />
        </Form.Group>
        <Button variant="primary" onClick={resetForm} style={{ marginBottom: 10 }}>
          reset form
        </Button>
        <Button
          className="btn-primary"
          style={{ color: "white", background: "rgba(255, 0, 90, 1)" }}
          type="submit"
        >
          upload
        </Button>
      </Form>
    </div>
  );
}
