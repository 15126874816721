import React, { useState, useRef, useEffect } from "react";
import { Modal, Row, Form, InputGroup, Col } from "react-bootstrap";
import Button from "./BackgroundMusicButton";
import { bootStrapClass as bs } from "../Utils/HelperFunctions";
import LinkButtons from "../Utils/SoundCloudLinksButton";
import { useBackgroundMusicContext } from "../Context/BackgroundMusicContext";
import ProgressBar from "../Utils/ProgressBar";
import { useTranslation } from "react-i18next";

export default function SoundCloudSongModal({ song }) {
  const { t } = useTranslation();

  const soundcloudSongUploadSuccessImage =
    "https://visionmovie.imgix.net/songReadyPic.png?h=270&w=650&q=40&fit=crop";
  let initialValidations = {
    urlError: "",
    fileError: "",
  };
  const [validations, setValidations] = useState(initialValidations);
  const [songToUploadUrl, setSongToUploadUrl] = useState("");
  let songToUploadFile = useRef(undefined);

  const { urlError, fileError } = validations;

  const { uploadToStorage, uploadStatus, uploadProgress } = useBackgroundMusicContext();

  // state for show and hide soundcloud getsong Modal
  const [showGetSongModal, setShowGetSongModal] = useState(false);

  //This is used to hide the soundcloud getsong Modal
  const closeGetSongModal = () => setShowGetSongModal(false);

  //This is used to show the soundcloud getsong Modal
  const openGetSongModal = () => setShowGetSongModal(true);

  const handleChange = (e) => {
    setSongToUploadUrl(e.target.value);
    songToUploadFile.current = e.target.files[0];
    setValidations({ urlError: "", fileError: "" });
  };

  const HasErrors = () => {
    setValidations({
      urlError: songToUploadUrl === "" ? "Please Select Song File." : "",
      fileError: !songToUploadFile.current
        ? "Song File not selected Correctly. Please Select Again."
        : "",
    });
    if (!songToUploadFile.current || songToUploadUrl === "") {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let hasErrors = HasErrors();
    if (!hasErrors) {
      let songToUpload = {
        artistName: song.artistName,
        songTitle: song.songTitle,
        url: songToUploadUrl,
        file: songToUploadFile.current,
      };
      setSongToUploadUrl("");
      songToUploadFile.current = undefined;
      uploadToStorage(songToUpload);
    }
  };

  useEffect(() => {
    if (uploadStatus === "done") {
      setTimeout(() => {
        closeGetSongModal();
      }, 2000);
    }
  }, [uploadStatus]);

  return (
    <>
      <Button onClick={openGetSongModal}> {t("buySong")}</Button>
      <Modal
        keyboard={false}
        animation={false}
        show={showGetSongModal}
        onHide={closeGetSongModal}
        centered
        as="div"
        dialogClassName={`soundCloudModal`}
      >
        {uploadStatus === "done" ? (
          <Modal.Body className="p-0 m-0">
            <img
              src={soundcloudSongUploadSuccessImage}
              alt={""}
              width={650}
              height={270}
              className="successModal "
            />
          </Modal.Body>
        ) : (
          <>
            <Modal.Header className="border-0">
              <div>
                <span className="soundCloudHeading">Song: </span>
                <span className="soundCloudSongName">
                  {song.artistName} - {song.songTitle}
                </span>
              </div>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={closeGetSongModal}
                style={{ marginRight: 4 }}
              />
            </Modal.Header>
            <Modal.Body>
              <LinkButtons song={song} />
              <Form className="soundCloudSongUploadRow" onSubmit={handleSubmit}>
                <Row>
                  <Form.Group as={Col} md="8">
                    <InputGroup hasValidation>
                      <Form.Control
                        name="url"
                        className="input"
                        value={songToUploadUrl}
                        type="file"
                        accept=".AAC,.FLAC,.wav,.mp3"
                        onChange={handleChange}
                        hidden={!(uploadStatus !== "inProgress" && !uploadProgress)}
                        isInvalid={urlError !== "" || fileError !== ""}
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {urlError || fileError}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  {uploadStatus !== "inProgress" && !uploadProgress ? (
                    <Col md="4">
                      <button className="form-control soundCloudUploadButton" type="submit">
                        {t("upload")}
                      </button>
                    </Col>
                  ) : (
                    <Col md="10" className={bs.columnCenter}>
                      <ProgressBar uploadStatus={uploadStatus} uploadProgress={uploadProgress} />
                    </Col>
                  )}
                </Row>
              </Form>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
}
