import { Block, BlockNoteEditor, PartialBlock } from "@blocknote/core";
import "@blocknote/core/fonts/inter.css";
import { BlockNoteView } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAuth } from "../Firebase/Contexts/AuthContext";
import { firestore } from "../Firebase/firebase";
import { useNavigate } from "react-router-dom";
import { template } from "./template";
import NameField from "./NameField";

export default function App() {
  const navigate = useNavigate();
  const { id, name } = useParams();

  const { currentUser } = useAuth();
  const userId = currentUser?.uid;
  const [loading, setLoading] = useState(false);

  const [initialContent, setInitialContent] = useState<PartialBlock[] | undefined | "loading">(
    "loading"
  );

  // Loads the previously stored editor contents.
  useEffect(() => {
    firestore
      .collection("users")
      .doc(userId)
      .collection("textDocuments")
      .doc(id)
      .onSnapshot((snapshot) => {
        if (snapshot?.data()) {
          const content = snapshot?.data()?.content;
          //Work with content
          //console.log(JSON.stringify(content));

          setInitialContent(content);
        } else {
          const templateContent: any = JSON.parse(JSON.stringify(template));
          console.log(templateContent);
          for (let i = 0; i < templateContent.length; i++) {
            for (let y = 0; y < templateContent[i]?.content?.length; y++) {
              templateContent[i].content[y].text = templateContent[i]?.content[y]?.text.replace(
                "employeename",
                name
              );
            }
          }
          console.log("RENDER");
          setInitialContent(templateContent);
        }
      });
  }, []);

  // Creates a new editor instance.
  // We use useMemo + createBlockNoteEditor instead of useCreateBlockNote so we
  // can delay the creation of the editor until the initial content is loaded.
  const editor = useMemo(() => {
    if (initialContent === "loading") {
      return undefined;
    }
    return BlockNoteEditor.create({ initialContent });
  }, [initialContent]);

  if (editor === undefined) {
    return "Loading content...";
  }

  function handleSaveAndBack() {
    setLoading(true);
    firestore
      .collection("users")
      .doc(userId)
      .collection("textDocuments")
      .doc(id)
      .set({ name, id, content: editor?.document })
      .then((response) => {
        console.log("success creating new document");
        navigate(-1);
        setLoading(false);
      })
      .catch((error) => console.log("error occured: " + error));
  }

  return (
    <div className={"wrapper"}>
      <LoadingButton
        color="primary"
        onClick={handleSaveAndBack}
        loading={loading}
        loadingPosition="start"
        startIcon={<ArrowBackIcon />}
        variant="contained"
        style={{ marginBottom: 18 }}
        disableElevation
      >
        <span>Save</span>
      </LoadingButton>
      <NameField data={{ id, name, content: editor?.document }} userId={userId} />
      <div className={"item"}>
        <BlockNoteView editor={editor} onChange={() => {}} />
      </div>
    </div>
  );
}
