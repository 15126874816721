import { useTheme } from "@mui/material/styles";
import Slider from "./Slider";
import { useState } from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

import { useVisionMovieContext } from "../VisionMovieContext";
import ImagePlaceholder from "./ImagePlaceholder";

export default function Settings() {
  const { segmentToSetSettingsFor, saveOpacityValueForSegment } = useVisionMovieContext();

  const theme = useTheme();
  const { palette } = theme;

  const [sliderValue, setSliderValue] = useState(
    segmentToSetSettingsFor?.contrastLayerOpacityValue
      ? segmentToSetSettingsFor.contrastLayerOpacityValue * 200
      : 0
  );

  const [changedSliderValue, setChangedSliderValue] = useState(false);
  function handleSave() {
    saveOpacityValueForSegment(segmentToSetSettingsFor, sliderValue / 200);
    setChangedSliderValue(false);
  }

  return (
    <div
      style={{
        paddingTop: 60,
      }}
    >
      <div style={{ width: "100%", display: "flex" }}>
        {/* LEFT SIDE */}
        <div style={{ width: "67%", height: "100%" }}>
          <ImagePlaceholder opacity={sliderValue / 200} />
        </div>
        {/* RIGHT SIDE */}
        <div style={{ width: "33%", display: "flex", justifyContent: "center" }}>
          <div
            style={{
              width: "100%",
              paddingInline: 32,
              paddingTop: 16,
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: palette.primary.main,
                  WebkitUserSelect: "none",
                }}
              >
                readability
              </div>
              <div
                style={{
                  marginLeft: 32,
                  width: "100%",
                }}
              >
                <Slider
                  sliderValue={sliderValue}
                  setSliderValue={setSliderValue}
                  setChangedSliderValue={setChangedSliderValue}
                />
              </div>
            </div>
            {changedSliderValue ? (
              <Link
                style={{
                  textDecoration: "none",
                }}
                to="/edit"
              >
                <Button
                  variant="contained"
                  disableElevation
                  style={{
                    marginTop: 24,
                    textTransform: "none",
                    borderRadius: 6,
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                  onClick={handleSave}
                >
                  save
                </Button>
              </Link>
            ) : (
              <Button
                variant="contained"
                disableElevation
                disabled
                style={{
                  marginTop: 24,
                  textTransform: "none",
                  borderRadius: 6,
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                save
              </Button>
            )}
          </div>
        </div>
        {/* RIGHT SIDE END____*/}
      </div>
    </div>
  );
}
