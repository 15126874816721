import { useState, useLayoutEffect, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideo,
  faHeart,
  faPause,
  faPlay,
  faArrowUpFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartAlt } from "@fortawesome/free-regular-svg-icons";
import Waveform from "../Utils/Waveform";
import { getHMS, bootStrapClass as bs } from "../Utils/HelperFunctions";
import { useBackgroundMusicContext } from "../Context/BackgroundMusicContext";
import PreviewAddButtons from "./PreviewAddButtons";
import { usePlayerSetupContext } from "../../player/contexts/PlayerSetupContext";
import { useTheme } from "@mui/material/styles";

const SongItem = ({ song, number, isLast, isChoosenSongItem }) => {
  const theme = useTheme();
  const {
    videoLength,
    currentPlayingSong,
    setCurrentPlayingSong,
    addSongToFavorities,
    removeSongFromFavorities,
  } = useBackgroundMusicContext();

  const { id, songTitle, artistName, duration, hearted, isUserUploaded } = song;
  const songId = isChoosenSongItem ? id + "choosenItem" : id;
  const [playing, setPlaying] = useState(false);
  const [currentPlayingTime, setCurrentPlayingTime] = useState(0);
  // const [duration, setDuration] = useState(0)
  const [readyToPlay, setReadyToPlay] = useState(false);
  const [liked, setLiked] = useState(false);

  const onPressHeart = () => {
    if (liked) {
      removeSongFromFavorities(songId);
    } else {
      addSongToFavorities(songId);
    }
    setLiked(!liked);
  };

  const playPauseToggle = () => {
    if (!playing) {
      setPlaying(true);
      setCurrentPlayingSong(songId);
    } else {
      setPlaying(false);
    }
  };

  useLayoutEffect(() => {
    setLiked(hearted);
  }, [hearted]);

  //When playing the VisionMovie on the backgroundMusic page the currentPlayingSong is paused
  //Otherwise the on the backgroundMusic page playing songs continue to play
  const { showPlayer } = usePlayerSetupContext();
  useEffect(() => {
    if (showPlayer && playing) {
      setPlaying(false);
    }
  }, [showPlayer]);

  return (
    <div className={`row mainRow rowHeight ${isLast ? "borderBottom" : ""}`}>
      <div className="col-md-5">
        <span className="row">
          <span className={`col-md-3 ${bs.center} p-0 rowHeight`}>
            {/* NUMBER || UPLOAD SIGN || DANCING PLAYING SYMBOL */}
            {isUserUploaded ? (
              <div
                style={{
                  width: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FontAwesomeIcon icon={faArrowUpFromBracket} color={theme.palette.primary.main} />
              </div>
            ) : (
              <span
                style={{
                  width: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className={`songCount ${bs.rowCenter}`}
              >
                {hearted ? "" : number}
              </span>
            )}
            {/* CHOSEN ICON || HEART ICON */}
            {isChoosenSongItem ? (
              <FontAwesomeIcon
                icon={faVideo}
                color={theme.palette.green.main}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <FontAwesomeIcon
                icon={liked ? faHeart : faHeartAlt}
                color={theme.palette.primary.main}
                style={{ cursor: "pointer" }}
                onClick={onPressHeart}
              />
            )}
            {/* PLAY || PAUSE BUTTON*/}
            <div
              style={{
                width: 30,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {playing ? (
                <FontAwesomeIcon
                  icon={faPause}
                  color={theme.palette.primary.main}
                  style={{ cursor: "pointer", fontSize: 26 }}
                  onClick={playPauseToggle}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faPlay}
                  color={theme.palette.primary.main}
                  style={{ cursor: "pointer", fontSize: 26 }}
                  onClick={playPauseToggle}
                />
              )}
            </div>
          </span>

          <span className={`col-md-9 songDetail ${bs.center}`}>
            {/* ARTIST & SONGTITLE */}
            <span className={`${bs.columnCenter}`}>
              <span className="songTitle">{songTitle}</span>
              <span className="songArtist">{artistName}</span>
            </span>

            {/* DURATION */}
            <span className="songDuration">
              {playing ? `${getHMS(currentPlayingTime)} | ${getHMS(duration)}` : getHMS(duration)}
            </span>
          </span>
        </span>
      </div>
      <div className="col-md-7">
        <span className="row">
          <span className={`col-md-6 ${bs.itemCenter}`}>
            <Waveform
              id={songId}
              song={song}
              videoLength={videoLength}
              playing={playing}
              setPlaying={setPlaying}
              setReadyToPlay={setReadyToPlay}
              time={currentPlayingTime}
              setCurrentPlayingTime={setCurrentPlayingTime}
              currentPlayingSong={currentPlayingSong}
            />
          </span>
          <PreviewAddButtons song={song} />
        </span>
      </div>
    </div>
  );
};
export default SongItem;
