import React, { useEffect, useRef, useState } from "react";
import { VisionMovie } from "./VisionMovie";
import { VisionMovieSegment } from "./VisionMovieSegment";
import _ from "lodash";
import CanvasVideoPlayer from "./CanvasVideoPlayer";
import { PlayArrow } from "@mui/icons-material";
import { useWebinarContext } from "../softwareWebinar/WebinarContext";
import WebFont from "webfontloader";
import CloseIcon from "@mui/icons-material/Close";
import PlayerCloseButton from "./PlayerCloseButton";
import { useFullscreen } from "rooks";
import CanvasImagePlayer from "./CanvasImagePlayer";

const TIMEOUT_PLAYING_WITHOUT_SECOND_VIDEO_LOADING = 4000; //start playing even though second video has not buffered yet
const DEBUG = false;

function computeShouldPlay(
  activeVideoIndex: number,
  currentVideoIndex: number,
  startVideoIndex: number,
  firstVideoBuffered: boolean,
  secondVideoBuffered: boolean
) {
  const isStartVideo = currentVideoIndex === 0;
  const bufferedStartingVideos = firstVideoBuffered && secondVideoBuffered; //TODO check if not first video
  //TODO after going back, onBufferComplete is not called
  //only play if currently active video and buffered maxBufferingVideos videos
  return (
    activeVideoIndex === currentVideoIndex &&
    (!isStartVideo || (isStartVideo && bufferedStartingVideos))
  );
}

export default function VideoSequencePlayer({
  visionMovie,
  minBufferTimeMs = 3000,
  width,
  height,
}: {
  visionMovie: VisionMovie;
  minBufferTimeMs?: number;
  width: number;
  height: number;
}) {
  //Load in font for statement style
  //Hint: Google fonts needs to be mentioned in data privacy declaration
  WebFont.load({
    google: {
      families: ["Poppins" + ":500,700"],
    },
  });

  console.log("visionMovie:");

  console.log(visionMovie);

  const navigate = useWebinarContext()?.navigate_WebinarWrapper;
  const fullscreenContainerRef = useRef<any>(null);
  const [activeVideoIndex, setActiveVideoIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [isBuffering, setIsBuffering] = useState(false);
  const [videoPlayers, setVideoPlayers] = useState<JSX.Element[]>([]);
  const [firstVideoBuffered, setFirstVideoBuffered] = useState(false);
  const [screenInitialized, setScreenInitialized] = useState(false);
  //if there is no second video, set second video buffered to true
  const [secondVideoBuffered, setSecondVideoBuffered] = useState(visionMovie.segments.length < 2);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  const {
    isFullscreenAvailable,
    enableFullscreen,
    disableFullscreen,
    isFullscreenEnabled,
    toggleFullscreen,
  } = useFullscreen({ target: fullscreenContainerRef });

  useEffect(() => {
    if (!isFullscreenEnabled) {
      enableFullscreen();
      setScreenInitialized(true);
    }
  }, []);

  const navigatedBack = useRef(false);
  useEffect(() => {
    if (audioRef.current && visionMovie.segments.length > 0) {
      const isVideoPlaying =
        computeShouldPlay(
          activeVideoIndex,
          activeVideoIndex,
          0,
          firstVideoBuffered,
          secondVideoBuffered
        ) &&
        !isPaused &&
        !isBuffering;
      if (isVideoPlaying && !isAudioPlaying) {
        console.log("audioRef play");
        audioRef.current
          .play()
          .then(() => {
            setIsAudioPlaying(true);
          })
          .catch((e) => {
            console.error(e);
          });
      } else if (!isVideoPlaying && isAudioPlaying) {
        console.log("audioRef pause");
        audioRef.current.pause();
        setIsAudioPlaying(false);
      }
    }

    const onVideoPlaying = () => {
      if (
        audioRef.current &&
        firstVideoBuffered &&
        secondVideoBuffered &&
        !isPaused &&
        !isBuffering &&
        !isAudioPlaying
      ) {
        console.log("audioRef play");
        audioRef.current
          .play()
          .then(() => {
            setIsAudioPlaying(true);
          })
          .catch((e) => {
            console.error(e);
          });
      }
      setIsBuffering(false);
    };

    const onVideoBuffering = () => {
      if (audioRef.current) {
        audioRef.current.pause();
        setIsAudioPlaying(false);
      }
      setIsBuffering(true);
    };

    const onVideoFinished = (lastSegment: boolean) => {
      if (lastSegment) {
        //Navigate back to "/" screen when finished playing VisionMovie with delay of 2500MS
        setTimeout(() => {
          if (!navigatedBack.current) {
            navigatedBack.current = true;
            navigate && navigate(-1);
          }
        }, 2500);
      } else {
        nextVideo();
      }
    };

    const nextVideo = () => {
      if (activeVideoIndex < visionMovie.segments.length - 1) {
        setActiveVideoIndex(activeVideoIndex + 1);
      }
    };

    const prevVideo = () => {
      if (activeVideoIndex > 0) {
        //setBufferState({});
        setActiveVideoIndex(activeVideoIndex - 1);
      }
    };

    const setAudioVolume = (newVolume: number) => {
      if (audioRef.current) {
        audioRef.current.volume = newVolume;
      }
    };

    const createVideoPlayer = (
      visionMovieSegment: VisionMovieSegment,
      index: number,
      lastSegment: boolean
    ) => {
      const shouldPlay = computeShouldPlay(
        activeVideoIndex,
        index,
        0,
        firstVideoBuffered,
        secondVideoBuffered
      );
      const isDummy = index < activeVideoIndex || index > activeVideoIndex + 1; //only show 2 videos at a time
      const zIndex = activeVideoIndex === index ? 1 : 0;

      if (isDummy) {
        return (
          <div
            key={index}
            style={{
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: zIndex,
            }}
          ></div>
        );
      } else {
        return (
          <div
            key={index}
            style={{
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: zIndex,
            }}
          >
            {visionMovieSegment?.videoClip ? (
              <CanvasVideoPlayer
                lastSegment={lastSegment}
                segmentsCoverSong={visionMovie.audio?.segmentsCoverSong}
                setAudioVolume={setAudioVolume}
                key={index}
                visionMovieSegment={visionMovieSegment}
                width={width}
                height={height}
                minBufferTimeMs={minBufferTimeMs}
                onVideoFinished={onVideoFinished}
                onPreBufferingComplete={() => {
                  if (index <= 1) {
                    //only await buffering of first 2 videos
                    if (index === activeVideoIndex) {
                      setFirstVideoBuffered(true);
                    } else if (index === activeVideoIndex + 1) {
                      setSecondVideoBuffered(true);
                    }
                  }
                }}
                onBuffering={() => {
                  index == activeVideoIndex && onVideoBuffering();
                }}
                onPlaying={() => {
                  index == activeVideoIndex && onVideoPlaying();
                }}
                shouldPlay={shouldPlay}
                paused={isPaused}
              />
            ) : (
              <CanvasImagePlayer
                activeElement={index === activeVideoIndex}
                lastSegment={lastSegment}
                segmentsCoverSong={visionMovie.audio?.segmentsCoverSong}
                setAudioVolume={setAudioVolume}
                key={index}
                visionMovieSegment={visionMovieSegment}
                width={width}
                height={height}
                onVideoFinished={onVideoFinished}
                onPreBufferingComplete={() => {
                  if (index <= 1) {
                    //only await buffering of first 2 videos
                    if (index === activeVideoIndex) {
                      setFirstVideoBuffered(true);
                    } else if (index === activeVideoIndex + 1) {
                      setSecondVideoBuffered(true);
                    }
                  }
                }}
                onPlaying={() => {
                  index == activeVideoIndex && onVideoPlaying();
                }}
                shouldPlay={shouldPlay}
                paused={isPaused}
              />
            )}
          </div>
        );
      }
    };

    const handleKeyPress = (event: any) => {
      //if left arrow key pressed
      if (DEBUG && event.key === "ArrowLeft") {
        prevVideo();
      } else if (DEBUG && event.key === "ArrowRight") {
        nextVideo();
      } else if (event.key === " ") {
        setIsPaused(!isPaused);
      } else if (event.key === "Escape") {
        //navigate to last page
        window.history.back(); //alternatively use navigate("/");
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    const _videoPlayers = visionMovie.segments.map((segment, index) =>
      createVideoPlayer(segment, index, index === visionMovie.segments.length - 1)
    );
    setVideoPlayers(_videoPlayers);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [
    visionMovie,
    activeVideoIndex,
    isPaused,
    firstVideoBuffered,
    secondVideoBuffered,
    isBuffering,
    audioRef.current,
  ]);

  useEffect(() => {
    if (screenInitialized && !isFullscreenEnabled) {
      //if fullscreen was disabled, the escape key was pressed => go back to previous page
      navigate && navigate(-1);
    }
  }, [isFullscreenEnabled]);

  /*safety check to start playing if second video has not buffered yet*/
  useEffect(() => {
    const timeoutId = window.setTimeout(() => {
      if (firstVideoBuffered && !secondVideoBuffered) {
        setSecondVideoBuffered(true);
        console.warn(
          "had to start video manually even though second video not buffered yet (probably prohibited by the browser"
        );
      }
    }, TIMEOUT_PLAYING_WITHOUT_SECOND_VIDEO_LOADING);

    return () => {
      window.clearTimeout(timeoutId);
    };
  });

  const togglePause = () => {
    console.log("togglePaused");
    setIsPaused(!isPaused);
  };

  return (
    <div ref={fullscreenContainerRef} onClick={togglePause}>
      <PlayerCloseButton />
      {isPaused && (
        <PlayArrow
          style={{
            fontSize: 70,
            zIndex: 1000,
            color: "white",
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            cursor: "pointer",
            margin: "auto",
          }}
          onClick={togglePause}
        />
      )}
      <div>{videoPlayers}</div>
      <audio
        id="audioElement"
        ref={audioRef}
        muted={false}
        preload="auto"
        autoPlay={false}
        src={visionMovie.audio?.audioUrl}
      ></audio>
    </div>
  );
}
