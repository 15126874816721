import { useState, useLayoutEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartAlt } from "@fortawesome/free-regular-svg-icons";
import { bootStrapClass as bs } from "../Utils/HelperFunctions";
import { useBackgroundMusicContext } from "../Context/BackgroundMusicContext";
import SoundCloudSongModal from "./SoundCloudSongModal";
import { useTheme } from "@mui/material/styles";

const SoundCloudSongItem = ({ song, number, isLast }) => {
  const theme = useTheme();
  const { addSoundCloudSongToFavorities, removeSoundCloudSongFromFavorities } =
    useBackgroundMusicContext();

  const { soundcloudId, hearted } = song;
  const [liked, setLiked] = useState(false);

  const onPressHeart = () => {
    if (liked) {
      removeSoundCloudSongFromFavorities(soundcloudId);
    } else {
      addSoundCloudSongToFavorities(soundcloudId);
    }
    setLiked(!liked);
  };

  useLayoutEffect(() => {
    setLiked(hearted);
  }, [hearted]);

  return (
    <div className={`row mainRow rowHeight ${isLast ? "borderBottom" : ""}`}>
      <div className="col-md-10">
        <span className="row">
          <span className={`col-md-1 ${bs.itemCenter} p-0 rowHeight`}>
            {/* NUMBER*/}
            <span className={`songCount ${bs.rowCenter}`}>{hearted ? "" : number}</span>
            {/* HEART */}
            <FontAwesomeIcon
              icon={liked ? faHeart : faHeartAlt}
              color={theme.palette.primary.main}
              style={{ cursor: "pointer", marginLeft: 30 }}
              onClick={onPressHeart}
            />
          </span>
          {/* SOUND CLOUD SONG */}
          <span className={`col-md-11 ${bs.itemCenter}`}>
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${song.soundcloudId}&color=%23e13163&inverse=false&auto_play=false&show_user=true`}
            ></iframe>
          </span>
        </span>
      </div>
      {/* GetSongButton */}
      <div className={`col-md-2 ${bs.fullCenter}`}>
        <SoundCloudSongModal song={song} />
      </div>
    </div>
  );
};
export default SoundCloudSongItem;
