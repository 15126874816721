import { easeLinear, easeQuadInOut } from "d3-ease";
import { useEffect, useRef, useState } from "react";
import CanvasOverlay from "../Canvas/CanvasOverlay";
import ImageCanvas from "../Canvas/ImageCanvas";
import computeFadeValueForCurrentTimeMs from "../helperFunctions/computeFadeValue";
import {
  ImageClip,
  VisionMovieSegment,
} from "../interfaces/VisionMovieSegment";
import PlayerSettings from "../PlayerSettings";

interface IImageCanvasPlayerParameters {
  isActive: boolean;
  paused?: boolean;
  segment: VisionMovieSegment;
  onLoading?: () => void;
  onLoaded?: () => void;
  onFinished?: () => void;
  setAudioVolume?: (newVolume: number) => void;
  fadeInVideo?: boolean;
  fadeOutVideo?: boolean;
  fadeOutAudio?: boolean;
  fadeInAudio?: boolean;
  fadeOutAudioDuration?: number;
  imageClip: ImageClip;
  width?: number;
  height?: number;
  index: number;
}

class Timer {
  timeMs: number;
  paused: boolean;
  handler: (time: number) => void = (time: number) => {};
  interval: number;
  constructor() {
    this.timeMs = 0;
    this.paused = true;
    this.interval = window.setInterval(() => {
      if (!this.paused) {
        this.timeMs += 1000 / 60;
      }
      this.handler(this.timeMs);
    }, 1000 / 60);
  }

  onUpdate(handler: (time: number) => void) {
    this.handler = handler;
  }

  getTime(): number {
    return this.timeMs;
  }

  pause() {
    this.paused = true;
  }

  start() {
    this.paused = false;
  }

  destroy() {
    window.clearInterval(this.interval);
  }
}

export default function ImageCanvasPlayer({
  isActive,
  paused,
  onLoading,
  segment,
  onLoaded,
  onFinished,
  setAudioVolume,
  fadeInVideo = false,
  fadeOutVideo = false,
  fadeInAudio = false,
  fadeOutAudio = false,
  imageClip,
  index,
}: IImageCanvasPlayerParameters) {
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasImageRef = useRef<HTMLCanvasElement>(null);
  const canvasOverlayRef = useRef<HTMLCanvasElement>(null);
  const [image, setImage] = useState<any>(null);
  const [timer, setTimer] = useState(new Timer());

  useEffect(() => {
    if (paused) {
      timer.pause();
    } else {
      if (isActive) {
        timer.start();
      }
    }
  }, [paused, isActive]);
  useEffect(() => {

    if (!image) {
      const newImage = new Image();
      newImage.src = imageClip.url;
      setImage(newImage);
    }

    let canvas = canvasImageRef.current;
    let imageCanvas: ImageCanvas | null = null;
    let overlay: CanvasOverlay | null = null;
    if (
      canvas &&
      canvasOverlayRef.current &&
      containerRef.current &&
      image
    ) {
      overlay = new CanvasOverlay(
        canvasOverlayRef.current,
        canvas,
        segment,
        fadeInVideo,
        fadeOutVideo,
        index
      );
     
      const canvasWidth= containerRef.current!.offsetWidth;
      canvas.width =canvasWidth;
      //apply canvasHeight to 4:3 ratio
      canvas.height = canvasWidth * 1/PlayerSettings.imageRatio;

      const initializeCanvas = () => {
        onLoaded && onLoaded();
        imageCanvas = new ImageCanvas(image, canvas!);
        //Important: trigger initial canvas draw once all data is available to avoid black screen
        imageCanvas?.update(0);
        overlay?._update(0);
      };

      initializeCanvas();

      timer.onUpdate((currentTimeMs) => {
        const canvas=canvasImageRef.current!;
        const canvasWidth= containerRef.current!.offsetWidth;
        canvas.width =canvasWidth;
        canvas.height = canvasWidth * 1/PlayerSettings.imageRatio;
        imageCanvas?.update(currentTimeMs);
        overlay?._update(currentTimeMs);
        if (currentTimeMs > imageClip.showingDurationInSec * 1000) {
          timer.pause();
          onFinished && onFinished();
          timer.destroy();
        }

        //update audio volume
        let fadeInDuration: number | null = null;
        let fadeOutDuration: number | null = null;
        if (fadeInAudio) {
          fadeInDuration = PlayerSettings.visionMovieFadeInDurationMs_AUDIO_Ms;
        }
        if (fadeOutAudio) {
          fadeOutDuration =
            PlayerSettings.visionMovieFadeOutDurationMs_AUDIO_Ms;
        }

        const newAudioVolume = computeFadeValueForCurrentTimeMs(
          segment.imageClip!.showingDurationInSec * 1000,
          currentTimeMs,
          fadeInDuration,
          fadeOutDuration
        );
        if (setAudioVolume) {
          setAudioVolume(newAudioVolume);
        }
      });
    }

    return () => {
      if (imageCanvas) {
        imageCanvas.destroy();
      }
      if (overlay) {
        overlay.destroy();
      }
    };
  }, [canvasImageRef, isActive, image]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "black",
        maxHeight: "100%",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      ref={containerRef}
    >
      <canvas ref={canvasImageRef} />
      <canvas
        style={{position: "absolute", top: 0, left: 0 }}
        ref={canvasOverlayRef}
      />
    </div>
  );
}
