import { Spinner } from "react-bootstrap";

export default function LoadingSpinner({ loading }) {
  return (
    <>
      {loading && (
        <Spinner
          animation="border"
          className="position-absolute"
          style={{
            zIndex: 1000,
            position: "absolute",
            width: (100 * window.innerWidth) / 1440,
            height: (100 * window.innerWidth) / 1440,
            borderWidth: (12 * window.innerWidth) / 1440,
            color: "rgba(255, 255, 255, 0.7)",
          }}
        />
      )}
    </>
  );
}
