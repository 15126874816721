import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
import { useVisionMovieContext } from "../VisionMovieContext";
import useResponsiveCardheight from "./helperHooks/useResponsiveCardHeight";
import { useWebinarContext } from "../softwareWebinar/WebinarContext";
import { Action } from "../softwareWebinar/Actions";
import WebinarButtonWrapper from "../softwareWebinar/WebinarButtonWrapper";
import { useTranslation } from "react-i18next";

export default function AddCard({ visionMovieData }) {
  const { i18n } = useTranslation();
  const theme = useTheme();
  const { actionEnabled } = useWebinarContext();
  const { palette } = theme;
  const { createNewCard, howManySegmentsStillToBeChoosenForSong, segmentsCoverSong } =
    useVisionMovieContext();
  const cardHeight = useResponsiveCardheight();

  const [minAdd, setMinAdd] = useState(howManySegmentsStillToBeChoosenForSong?.minAdd);
  const [maxRemove, setMaxRemove] = useState(howManySegmentsStillToBeChoosenForSong?.maxRemove);
  const [maxAdd, setMaxAdd] = useState(howManySegmentsStillToBeChoosenForSong?.maxAdd);
  const [minRemove, setMinRemove] = useState(howManySegmentsStillToBeChoosenForSong?.minRemove);

  //Set values when howManySegmentsStillToBeChoosenForSong changes
  useEffect(() => {
    setMinAdd(howManySegmentsStillToBeChoosenForSong?.minAdd);
    setMaxRemove(howManySegmentsStillToBeChoosenForSong?.maxRemove);
    setMaxAdd(howManySegmentsStillToBeChoosenForSong?.maxAdd);
    setMinRemove(howManySegmentsStillToBeChoosenForSong?.minRemove);
  }, [howManySegmentsStillToBeChoosenForSong]);

  const Container = styled.div({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: cardHeight,
    borderRadius: 5,
    background: palette.gradient.cardBackgroundGradient,
    cursor: "pointer",
  });

  const plus = {
    fontSize: "7vw",
    fontFamily: "Poppins",
    fontWeight: 300,
    cursor: "pointer",
    background:
      minAdd <= 0
        ? palette.grey.normal
        : "linear-gradient(104.33deg, #FF005A 3.51%, #FF7B44 98.53%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    WebkitUserSelect: "none",
  };

  const AddSegments = () => {
    return (
      <>
        {i18n.language === "de-DE" ? (
          <div style={{ WebkitUserSelect: "none" }}>
            füge {minAdd != maxAdd && minAdd + " - "}
            {maxAdd}
             Segment{maxAdd != 1 && "e"} hinzu
          </div>
        ) : (
          <div style={{ WebkitUserSelect: "none" }}>
            add {minAdd != maxAdd && minAdd + " - "}
            {maxAdd}
             segments
          </div>
        )}
      </>
    );
  };

  const FittingSegmentsPerfectly = () => {
    return (
      <>
        {i18n.language === "de-DE" ? (
          <div style={{ color: palette.green.main }}>
            genügend Segment für diesen Song ausgewählt
          </div>
        ) : (
          <div style={{ color: palette.green.main }}>enough segments selected</div>
        )}
      </>
    );
  };

  const FittingAndAddSegments = () => {
    return (
      <>
        {i18n.language === "de-DE" ? (
          <div style={{ WebkitUserSelect: "none" }}>
            <div style={{ color: palette.green.main }}>
              genügend Segment für diesen Song ausgewählt
            </div>
            <div style={{ fontWeight: "normal" }}>
              (optional: füge bis zu {maxAdd} Segmente hinzu)
            </div>
          </div>
        ) : (
          <div style={{ WebkitUserSelect: "none" }}>
            <div style={{ color: palette.green.main }}>enough segments selected</div>
            <div style={{ fontWeight: "normal" }}>(optional: add up to {maxAdd} segments)</div>
          </div>
        )}
      </>
    );
  };

  const AddAndRemoveSegments = () => {
    const maxAdd = howManySegmentsStillToBeChoosenForSong.maxAdd;
    const maxRemove = howManySegmentsStillToBeChoosenForSong.maxRemove;
    return (
      <>
        {i18n.language === "de-DE" ? (
          <div style={{ WebkitUserSelect: "none" }}>
            <div style={{ color: palette.green.main }}>
              genügend Segment für diesen Song ausgewählt
            </div>
            <div style={{ fontWeight: "normal" }}>
              (optional: lösche {maxRemove + " oder "}
              füge {maxAdd}
               Segment{maxAdd != 1 && "e"} hinzu)
            </div>
          </div>
        ) : (
          <div style={{ WebkitUserSelect: "none" }}>
            <div style={{ color: palette.green.main }}>enough segments selected</div>
            <div style={{ fontWeight: "normal" }}>
              (optional: delte {maxRemove + " or "}
              add {maxAdd}
              segments)
            </div>
          </div>
        )}
      </>
    );
  };

  const RemoveSegmentsIfNeeded = () => {
    return (
      <>
        {i18n.language === "de-DE" ? (
          <div style={{ WebkitUserSelect: "none" }}>
            <div style={{ color: palette.green.main }}>
              genügend Segment für diesen Song ausgewählt
            </div>
            <div style={{ fontWeight: "normal" }}>
              (optional: lösche bis zu{" "}
              {minRemove != maxRemove && minRemove != 0 && Math.abs(minRemove) + " - "}
              {Math.abs(maxRemove)}
               Segment{(maxRemove != 1 || maxAdd != 1) && "e"})
            </div>
          </div>
        ) : (
          <div style={{ WebkitUserSelect: "none" }}>
            <div style={{ color: palette.green.main }}>enough segments selected</div>
            <div style={{ fontWeight: "normal" }}>
              (optional: delete up to{" "}
              {minRemove != maxRemove && minRemove != 0 && Math.abs(minRemove) + " - "}
              {Math.abs(maxRemove)}
               segments)
            </div>
          </div>
        )}
      </>
    );
  };

  const gradientText = {
    background: "linear-gradient(104.33deg, #FF005A 3.51%, #FF7B44 98.53%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  };

  const RemoveSegments = () => {
    return (
      <>
        {i18n.language === "de-DE" ? (
          <div style={gradientText}>
            lösche {minRemove != maxRemove && Math.abs(minRemove) + " - "}
            {Math.abs(maxRemove)}
             Segmente
          </div>
        ) : (
          <div style={gradientText}>
            delete {minRemove != maxRemove && Math.abs(minRemove) + " - "}
            {Math.abs(maxRemove)}
            segments
          </div>
        )}
      </>
    );
  };

  return (
    <WebinarButtonWrapper actionType={Action.AddCard}>
      <Container
        onClick={() => {
          if (actionEnabled(Action.AddCard)) createNewCard();
        }}
      >
        <div
          style={{
            height: "6vw",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            WebkitUserSelect: "none",
          }}
        >
          <div style={plus}>+</div>
        </div>
        {howManySegmentsStillToBeChoosenForSong && visionMovieData?.backgroundMusic && (
          <div
            style={{
              fontWeight: "bold",
              color: palette.grey.normal,
              textAlign: "center",
              paddingInline: "3vw",
              WebkitUserSelect: "none",
            }}
          >
            {minAdd > 0 && <AddSegments />}
            {minAdd <= 0 && maxAdd > 0 && maxRemove < 1 ? (
              <FittingAndAddSegments />
            ) : maxRemove > 0 && minRemove <= 0 && maxAdd < 1 ? (
              <RemoveSegmentsIfNeeded />
            ) : null}
            {minAdd === 0 && maxAdd === 0 && minRemove === 0 && maxRemove === 0 && (
              <FittingSegmentsPerfectly />
            )}
            {maxRemove > 0 && maxAdd > 0 && <AddAndRemoveSegments />}
            {minRemove > 0 && <RemoveSegments />}
          </div>
        )}
      </Container>
    </WebinarButtonWrapper>
  );
}
