import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useWebinarContext } from "../../../components/softwareWebinar/WebinarContext";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function NotAllDaysFullyTrackedModal({
  modalOpen,
  setModalOpen,
  moveToFirstToDoWithMissingMesaurements,
}) {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const navigate = useWebinarContext().navigate_WebinarWrapper;

  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            style={{ color: palette.primary.main }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {t("notAllValuesEntered")}
          </Typography>
          <Typography
            style={{ marginBottom: 25, color: palette.grey[600] }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            {t("goBackAnyhow")}
          </Typography>
          <Button
            style={{ width: 130, marginRight: 20 }}
            onClick={() => {
              setModalOpen(false);
              navigate("/");
            }}
            startIcon={<ArrowBackIcon />}
            disableElevation
            variant="outlined"
          >
            {t("back")}
          </Button>
          <Button
            onClick={() => {
              setModalOpen(false);
              moveToFirstToDoWithMissingMesaurements();
            }}
            style={{ width: 170 }}
            disableElevation
            variant="contained"
          >
            {t("complete")}
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
