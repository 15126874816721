import { useRef, useEffect, useState, useLayoutEffect, memo } from "react";
import { FixedSizeList } from "react-window";
import Stack from "@mui/material/Stack";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import AutoSizer from "react-virtualized-auto-sizer";
import Box from "./Box";
import { TrackingType } from "../setProgressTracking/ProgressTracking";
import DoneNotDoneButton from "./DoneNotDoneButton";
import AddNumber from "./AddNumber";
import { rowMarginParameters } from "./rowMarginParameters";
import { useElementOnScreen } from "./useElementOnScreen";
import dayjs from "dayjs";
import { useMonthContext } from "./MonthContext";
import { checkIfDateADayBeforeNow } from "./checkIfDateADayBeforeNow";
import { useTranslation } from "react-i18next";

const monthStrings = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];

const monthStringsEn = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

//MOVED TO ANOTHER COMPONENT ONLY HERE AS BACKUP IF BUGS OCCUR
/* function checkIfDateADayBeforeNow(dateBefore) {
  const date1 = dayjs(dateBefore).format("MM-DD-YYYY");
  const dayDate1 = dayjs(date1);
  const date2 = dayjs().add(1, "day").format("MM-DD-YYYY");
  const dayDate2 = dayjs(date2);
  return dayDate1.diff(dayDate2, "day") < 0;
} */

const ListContainer = ({
  placeholdersAndMeasurements,
  scalingFactor,
  coachings,
  toDo,
  showTrackingButtons,
  setActiveToDoIndex,
  nextClicked,
}) => {
  return (
    <Stack style={{ width: "100%", height: "100%" }}>
      <AutoSizer>
        {({ height, width }) => (
          <ScrollList
            placeholdersAndMeasurements={placeholdersAndMeasurements}
            scalingFactor={scalingFactor}
            coachings={coachings}
            toDo={toDo}
            height={height}
            width={width}
            showTrackingButtons={showTrackingButtons}
            setActiveToDoIndex={setActiveToDoIndex}
            nextClicked={nextClicked}
          />
        )}
      </AutoSizer>
    </Stack>
  );
};

export default ListContainer;

function ScrollList({
  placeholdersAndMeasurements,
  scalingFactor,
  coachings,
  toDo,
  height,
  width,
  showTrackingButtons,
  setActiveToDoIndex,
  nextClicked,
}) {
  const { i18n } = useTranslation();
  const { setMonth } = useMonthContext();
  //The buttons for these tracking types are wider than the others and hence need more space
  const trackingTypesWhereButtonsNeedTwoSpaces = [
    TrackingType.daily_once,
    TrackingType.weekly_multiple_times,
  ];
  const wideAddButton = trackingTypesWhereButtonsNeedTwoSpaces.includes(toDo?.currentTrackingType);

  const lastMeasurementBeforeToday = checkIfDateADayBeforeNow(
    placeholdersAndMeasurements &&
      placeholdersAndMeasurements[placeholdersAndMeasurements?.length - 1]?.date
  );

  const showAddButtonOrField = lastMeasurementBeforeToday;
  const spaceNeededForAddButton =
    wideAddButton && showAddButtonOrField ? 2 : showAddButtonOrField ? 1 : 0; //If button is not shown no extra space is needed for it

  const rowCount = Math.ceil((placeholdersAndMeasurements?.length + spaceNeededForAddButton) / 7);

  const listRef = useRef();

  function onItemsRendered(e) {
    const rowAfterVisibleStartIndexOrNot = e.visibleStopIndex - e.visibleStartIndex === 1 ? 0 : 1;
    const rowIndexToGetDate = e.visibleStartIndex + rowAfterVisibleStartIndexOrNot;
    const dayToPickMonthFrom = placeholdersAndMeasurements?.slice(
      rowIndexToGetDate * 7 + 2,
      rowIndexToGetDate * 7 + 3
    )[0];
    if (dayToPickMonthFrom) {
      const monthIndex = dayjs(dayToPickMonthFrom?.date).get("month");
      const monthStringsInRightLanguage = i18n.language === "de-DE" ? monthStrings : monthStringsEn;
      const monthString = monthStringsInRightLanguage[monthIndex];
      setMonth(monthString);
    }
  }

  const scrollToBottom = () => listRef?.current.scrollToItem(rowCount);

  useEffect(() => {
    if (listRef?.current) scrollToBottom();
  }, [listRef.current, placeholdersAndMeasurements]);

  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    if (listRef?.current) scrollToBottom();
  });

  /*   const getSize = (index) => {
    return index === 0
      ? Math.floor(height / 2) - rowMarginParameters.marginTopFirstRow * scalingFactor
      : Math.floor(height / 2);
  }; */

  return (
    <FixedSizeList
      ref={listRef}
      height={Math.floor(height / 2) * 2}
      width={width}
      itemCount={rowCount}
      showTrackingButtons={showTrackingButtons}
      itemData={{
        toDo,
        placeholdersAndMeasurements,
        scalingFactor,
        coachings,
        setActiveToDoIndex,
        nextClicked,
      }}
      itemSize={Math.floor(height / 2)}
      onItemsRendered={onItemsRendered}
    >
      {showTrackingButtons ? Row : NonTrackingRow}
    </FixedSizeList>
  );
}

const Row = ({ index, style, data }) => {
  const {
    toDo,
    placeholdersAndMeasurements,
    scalingFactor,
    coachings,
    setCircleWidthForButton,
    setActiveToDoIndex,
    nextClicked,
  } = data;
  const rowElements = placeholdersAndMeasurements.slice(index * 7, index * 7 + 7);
  //const wideAddButton = trackingTypesWhereButtonsNeedTwoSpaces.includes(toDo?.currentTrackingType);

  //const buttonBoxIndex = index * 7 + rowElemIndex;

  const lastMeasurementBeforeToday = checkIfDateADayBeforeNow(
    placeholdersAndMeasurements[placeholdersAndMeasurements.length - 1]?.date
  );

  return (
    <Stack
      justifyContent="start"
      alignItems="center"
      direction="row"
      style={{
        ...style,
        paddingRight: scalingFactor ? 20 * scalingFactor : 20,
        //background: index % 2 === 0 ? "grey" : "pink",
      }}
    >
      {rowElements.map((elem, rowElemIndex) => {
        const boxIndex = index * 7 + rowElemIndex;
        return (
          <Box
            scalingFactor={scalingFactor}
            elem={elem}
            key={boxIndex}
            index={boxIndex}
            coachings={coachings}
            boxesArrayLength={placeholdersAndMeasurements.length}
            placeholdersAndMeasurements={placeholdersAndMeasurements}
            toDo={toDo}
          />
        );
      })}
      {lastMeasurementBeforeToday &&
        rowElements.length < 6 &&
        toDo?.currentTrackingType === TrackingType.daily_once && (
          <DoneNotDoneButton
            toDo={toDo}
            notDoneAvailable={true}
            scalingFactor={scalingFactor}
            setActiveToDoIndex={setActiveToDoIndex}
          />
        )}
      {lastMeasurementBeforeToday &&
        rowElements.length < 6 &&
        toDo?.currentTrackingType === TrackingType.weekly_multiple_times && (
          <DoneNotDoneButton
            toDo={toDo}
            notDoneAvailable={false}
            scalingFactor={scalingFactor}
            setActiveToDoIndex={setActiveToDoIndex}
          />
        )}
      {lastMeasurementBeforeToday &&
        rowElements.length < 7 &&
        toDo?.currentTrackingType === TrackingType.value && (
          <AddNumber
            index={placeholdersAndMeasurements.length}
            scalingFactor={scalingFactor}
            toDoData={toDo}
            setActiveToDoIndex={setActiveToDoIndex}
            nextClicked={nextClicked}
          />
        )}
    </Stack>
  );
};

const NonTrackingRow = ({ index, style, data }) => {
  const { toDo, placeholdersAndMeasurements, scalingFactor, coachings, setCircleWidthForButton } =
    data;
  const rowElements = placeholdersAndMeasurements.slice(index * 7, index * 7 + 7);
  //const wideAddButton = trackingTypesWhereButtonsNeedTwoSpaces.includes(toDo?.currentTrackingType);

  //const buttonBoxIndex = index * 7 + rowElemIndex;

  const lastMeasurementBeforeToday = checkIfDateADayBeforeNow(
    placeholdersAndMeasurements[placeholdersAndMeasurements.length - 1]?.date
  );

  return (
    <Stack
      justifyContent="start"
      alignItems="center"
      direction="row"
      style={{
        ...style,
        paddingRight: scalingFactor ? 20 * scalingFactor : 20,
        //background: index % 2 === 0 ? "grey" : "pink",
      }}
    >
      {rowElements.map((elem, rowElemIndex) => {
        const boxIndex = index * 7 + rowElemIndex;
        return (
          <Box
            scalingFactor={scalingFactor}
            elem={elem}
            key={boxIndex}
            index={boxIndex}
            coachings={coachings}
          />
        );
      })}
    </Stack>
  );
};
