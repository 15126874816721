import Navigation from "../components/navigation";
import { useTheme } from "@mui/material/styles";
import BackgroundSelection from "../components/backgroundSelection/AssetsToChooseFromList";
import { useEffect } from "react";

export default function BackroundMusicRoute() {
  const theme = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div style={{ marginLeft: theme.spacing(2) }}>
      <Navigation />
      <div style={{ paddingTop: 60 }}>
        <BackgroundSelection />
      </div>
    </div>
  );
}
