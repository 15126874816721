import { PlayCircle } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import _, { last } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useFullscreen } from "rooks";
import { VisionMovie } from "./interfaces/VisionMovie";
import { VisionMovieSegment } from "./interfaces/VisionMovieSegment";
import ImageCanvasPlayer from "./Players/ImageCanvasPlayer";
import VideoJsPlayer from "./Players/VideoJsPlayer";
const LOADING_STATE_DELAY_MS = 400; //delay before loading state is set to true (to prevent flickering and to make smoother audio play as well as avoid ultra short spinner)

export default function VisionMoviePlayer({
  visionMovie,
  segmentsCoverBackgroundMusic,
  onFinishedVisionMovie,
}: {
  visionMovie: VisionMovie;
  segmentsCoverBackgroundMusic: boolean;
  onFinishedVisionMovie: () => void;
}) {
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  const [activeVideoFullyBuffered, setActiveVideoFullyBuffered] = React.useState<boolean>(false);
  const [currentSegmentAlmostFinished, setCurrentSegmentAlmostFinished] = useState(false);
  const [players, setPlayers] = useState<JSX.Element[]>([]);
  const [paused, setPaused] = useState(false);
  const [loading, setLoading] = useState(true);
  const audioRef = useRef<HTMLAudioElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);
  const [loadingTimeout, setLoadingTimeout] = useState(-1); //reference to timeout id for delaying loading state
  const [audioReady, setAudioReady] = useState(false); //is only ready once audio was buffered enough

  const setAudioVolume = (volume: number) => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  };

  useEffect(() => {
    //if is not iphone
    if (!/iPhone/.test(navigator.userAgent)) {
      //enableFullscreen();
    }
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      //make play -> pause in order to prevent browser from blocking delayed replay
      const initialAudioVolume = audioRef.current.volume;
      audioRef.current.volume = 0.01;
      audioRef.current.play();
      audioRef.current!.pause();
      audioRef.current!.currentTime = 0;
      audioRef.current!.volume = initialAudioVolume;
      //when audio is ready
      audioRef.current!.oncanplaythrough = () => {
        setAudioReady(true);
      };
    }
  }, [audioRef]);

  useEffect(() => {
    if (audioRef.current) {
      const activeVideoIsKaleidoscopeVideo =
        visionMovie.segments[activeIndex]?.videoClip?.isKaleidoscope;
      if (paused || loading) {
        audioRef.current.pause();
      } else {
        !activeVideoIsKaleidoscopeVideo && audioRef.current.play();
      }
    }
  }, [paused, loading]);

  useEffect(() => {
    let lastSegment: null | VisionMovieSegment = null;
    if (activeIndex > 0) {
      lastSegment = visionMovie.segments[activeIndex - 1];
    }
    const currentSegment = visionMovie.segments[activeIndex];

    //COMPUTE FADES FOR SEGMENTS AND AUDIO
    var fadeInVideo = false;
    var fadeOutVideo = false;
    var fadeOutAudio = false;
    var fadeInAudio = false;
    //Kaleidoscope Video
    if (visionMovie.segments[activeIndex]?.videoClip?.isKaleidoscope) {
      fadeInVideo = true;
      fadeInAudio = true;
      fadeOutVideo = true;
      fadeOutAudio = true;
    }

    //VisionMovieSegments____________
    if (!visionMovie.segments[activeIndex]?.videoClip?.isKaleidoscope) {
      //FADEIN VIDEO
      if (activeIndex === 0) {
        fadeInVideo = false;
      } else if (visionMovie.segments[activeIndex - 1]?.videoClip?.isKaleidoscope) {
        //TODO 4 PATRICK : DECIDE IF I WANT FIRST SEGMENTS FADEINs
        fadeInVideo = true;
        fadeInAudio = true;
      } else {
        fadeInVideo = false;
      }

      //FADEOUT VIDEO
      //Is last video of VisionMovie?
      if (
        visionMovie.segments.length === activeIndex + 1 ||
        visionMovie.segments[activeIndex + 1]?.videoClip?.isKaleidoscope
      ) {
        fadeOutVideo = true;
        fadeOutAudio = segmentsCoverBackgroundMusic ? false : true;
      } else {
        //Not last video
        fadeOutVideo = false;
        fadeOutAudio = false;
      }
      //END: VisionMovieSegments____________
    }

    let nextSegment: null | VisionMovieSegment = null;
    if (activeIndex + 1 < visionMovie.segments.length) {
      nextSegment = visionMovie.segments[activeIndex + 1];
    }

    //Pause video on press spacebar
    const handleKeyPress = (event: any) => {
      //if left arrow key pressed
      if (event.key === " ") {
        setPaused(!paused);
      }
    };
    document.addEventListener("keydown", handleKeyPress);

    const onBuffering = () => {
      if (loadingTimeout) {
        window.clearTimeout(loadingTimeout);
      }
      if (!loading) {
        const timeoutId = window.setTimeout(() => {
          setLoading(true);
        }, LOADING_STATE_DELAY_MS);
        setLoadingTimeout(timeoutId);
      }
    };

    const onPlaying = () => {
      if (!loading && loadingTimeout !== -1) {
        window.clearTimeout(loadingTimeout);
        setLoadingTimeout(-1);
      }
      setLoading(false);
    };

    const onActiveSegmentFullyLoaded = () => {
      if (!activeVideoFullyBuffered) {
        setActiveVideoFullyBuffered(true);
      }
    };

    const onActiveSegmentFinished = () => {
      setActiveIndex(activeIndex + 1);
      setCurrentSegmentAlmostFinished(false);
      if (activeIndex + 1 === visionMovie.segments.length) {
        onFinishedVisionMovie();
      }
      setActiveVideoFullyBuffered(false);
    };

    //TODO render second video always first and then render active video on top of it (no layout shift needed when new video becomes active)
    const playerObjects: JSX.Element[] = [];
    if (currentSegment && currentSegment.videoClip) {
      const videoClip = currentSegment.videoClip;
      const isKaleidoscope = videoClip?.isKaleidoscope || false;

      if (isKaleidoscope) {
        audioRef.current!.pause();
      } else {
        audioReady && !paused && !loading && audioRef.current!.play();
      }

      playerObjects.push(
        <VideoJsPlayer
          isActive={true}
          segment={currentSegment}
          fadeInVideo={fadeInVideo} //fade out video on last video
          fadeOutVideo={fadeOutVideo} //fade out video on last video
          fadeOutAudio={fadeOutAudio} //fade out audio on last video
          index={activeIndex}
          audioRef={audioRef}
          onAlmostFinished={() => {
            setCurrentSegmentAlmostFinished(true);
          }}
          fadeInAudio={fadeInAudio}
          muted={!isKaleidoscope}
          paused={paused || !audioReady}
          key={"video__" + activeIndex.toString()}
          videoClip={videoClip!}
          onBuffering={onBuffering}
          onPlaying={onPlaying}
          setAudioVolume={setAudioVolume}
          passiveBuffering={false}
          onBufferedUntilEnd={onActiveSegmentFullyLoaded}
          onVideoFinished={onActiveSegmentFinished}
        />
      );
    } else if (currentSegment && currentSegment.imageClip) {
      !loading && !paused && audioRef.current!.play(); //in case that it was stopped by kaleidoscope video
      playerObjects.push(
        <ImageCanvasPlayer
          width={window.innerWidth}
          height={window.innerHeight}
          key={"image__" + activeIndex.toString()}
          segment={currentSegment}
          imageClip={currentSegment.imageClip!}
          fadeInAudio={fadeInAudio}
          fadeOutAudio={fadeOutAudio}
          fadeInVideo={fadeInVideo}
          fadeOutVideo={fadeOutVideo}
          setAudioVolume={setAudioVolume}
          isActive={true}
          paused={paused || !audioReady}
          onLoaded={() => {
            setLoading(false);
            onActiveSegmentFullyLoaded();
          }}
          onFinished={onActiveSegmentFinished}
          index={activeIndex}
        />
      );
    }

    if (currentSegment && lastSegment && lastSegment.videoClip) {
      /** keep last video in DOM to prevent layout shift when new video becomes active (disposing video element somehow takes some time and/or causes layout shifts) -> once video is in background this seems not to be a problem anymore
       * This problem is more dominant on Safari, but can also be spotted in chrome
       *
       */
      playerObjects.push(
        <VideoJsPlayer
          isActive={false}
          segment={lastSegment}
          fadeInVideo={fadeInVideo} //fade out video on last video
          fadeOutVideo={fadeOutVideo} //fade out video on last video
          fadeOutAudio={fadeOutAudio} //fade out audio on last video
          index={activeIndex - 1}
          //audioRef={audioRef}
          onAlmostFinished={() => {
            //setCurrentSegmentAlmostFinished(true);
          }}
          muted={true}
          paused={true}
          key={"video__" + (activeIndex - 1).toString()}
          videoClip={lastSegment.videoClip!}
          onBuffering={() => {}}
          onPlaying={() => {}}
          passiveBuffering={false}
          onBufferedUntilEnd={() => {}}
        />
      );
    }

    //Render next video
    if (nextSegment && (nextSegment.videoClip || nextSegment.kaleidoscopeClip)) {
      const videoClip = nextSegment.videoClip;
      if (activeVideoFullyBuffered) {
        playerObjects.push(
          <VideoJsPlayer
            index={activeIndex + 1}
            segment={nextSegment}
            key={"video__" + (activeIndex + 1).toString()}
            videoClip={videoClip!}
            isActive={false}
            passiveBuffering={true}
            onBufferedUntilEnd={() => null}
            prepareForPlayback={currentSegmentAlmostFinished}
          />
        );
      }
    } else if (nextSegment && nextSegment.imageClip) {
      playerObjects.push(
        <ImageCanvasPlayer
          width={window.innerWidth}
          height={window.innerHeight}
          segment={nextSegment}
          key={"image__" + (activeIndex + 1).toString()}
          imageClip={nextSegment.imageClip!}
          paused={paused}
          isActive={false}
          index={activeIndex + 1}
        />
      );
    }

    setPlayers(
      _.reverse(playerObjects).map((player, index) => {
        return (
          <div
            className="playerWrapper"
            key={player.key}
            style={{
              position: "absolute",
              opacity: 1,
              top: 0,
              left: 0,
              backgroundColor: "black",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {player}
          </div>
        );
      })
    );

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [activeIndex, loadingTimeout, activeVideoFullyBuffered, paused, audioReady]);

  return (
    <div
      onClick={() => setPaused(!paused)}
      ref={rootRef}
      style={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
        display: "flex",
        /*flex fill full space*/
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "black",
      }}
    >
      {players}
      <audio ref={audioRef} src={visionMovie.audio!.audioUrl} controls={false} autoPlay={false} />
      {paused && (
        <FontAwesomeIcon
          icon={faPlay}
          color={"white"}
          style={{
            fontSize: 100,
            zIndex: 1000,
            paddingLeft: 15,
            cursor: "pointer",
          }}
          onClick={() => setPaused(false)}
        />
      )}
      {loading && (
        <Spinner
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto",
            width: (100 * window.innerWidth) / 1440,
            height: (100 * window.innerWidth) / 1440,
            borderWidth: (12 * window.innerWidth) / 1440,
            color: "rgba(255, 255, 255, 0.7)",
          }}
          animation={"border"}
        />
      )}
    </div>
  );
}
