import Pay from "./Pay";
import { useWebinarContext } from "../softwareWebinar/WebinarContext";
import Typography from "@mui/material/Typography";
import { Products } from "./Products";

export default function GetYourAccountPage() {
  const navigate = useWebinarContext()?.navigate_WebinarWrapper;

  function onPayed() {
    navigate && navigate("/paymentsuccessful");
  }

  return (
    <div>
      <div>ThankYouWebinarPage</div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "70vw" }}>Your benefits</div>
        <div
          style={{
            width: "30vw",
            paddingLeft: 50,
            paddingRight: 50,
          }}
        >
          <Typography style={{ fontWeight: "bold", marginBottom: 30 }} variant="h3">
            Get your premium access
          </Typography>
          <Pay product={Products.Mastermind} onPayed={onPayed} />
        </div>
      </div>
    </div>
  );
}
