import Navigation from "../components/navigation";
import { useTheme } from "@mui/material/styles";
import Editor from "../components/coaching/Editor.tsx";

export default function EditorRoute() {
  const theme = useTheme();

  return (
    <div
      style={{
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(8),
      }}
    >
      <Editor />
    </div>
  );
}
