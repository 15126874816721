import React, { useEffect } from "react";
import { ImageClip } from "./VisionMovieSegment";
import TransformedImage from "./TransformedImage";
import "./ImgZoom.css";

export default function PlayerImageComponent({
  activeElement,
  imageClip,
  imgRef,
  onLoad,
}: {
  activeElement: boolean;
  imageClip: ImageClip;
  imgRef?: any;
  onLoad: () => void;
}) {
  //Zoom in on image

  //If no transform data is available / the image is not cropped
  if (!imageClip?.cropParameters) {
    return (
      <img
        ref={imgRef}
        onLoad={onLoad}
        src={imageClip.url}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "50% 50%",
        }}
        //Just add this line to enable zoom in on images (easy zoom in for now, before more advanced capabilities will be available)
        //className={activeElement ? "imgZoom" : "imgZoomDefault"}
      />
    );
  }

  //If the image is cropped, the image gets transformed
  if (imageClip?.cropParameters) {
    return (
      <div style={{ height: "100%" }} ref={imgRef}>
        <TransformedImage imageData={imageClip} children={null} onLoad={onLoad} />
      </div>
    );
  }
  return <></>;
}
