import { useState, useRef, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { HeartButton } from "./HeartButton";
import { AddButton } from "./AddButton";
import { getModifiedPosterImageLink, getFittingVideoLink } from "./helperFunctions";
import { useVisionMovieContext } from "../VisionMovieContext";
import { findContainer } from "../segments/helperFunctions";

//VIDEO
export const Video = ({ videoData, videoCardHeight, currentPathname }) => {
  const videoRef = useRef();
  const posterLink =
    currentPathname !== "/backgroundselection/uploadvideos"
      ? getModifiedPosterImageLink(videoData, 400)
      : videoData.image;

  const [videoLoading, setVideoLoading] = useState(false);
  const [startedToPlay, setStartedToPlay] = useState(false);
  const [overCard, setOverCard] = useState(false);

  const { toggleVideoHeartedState, segmentToChooseBackgroundFor, visionMovieData } =
    useVisionMovieContext();
  const [videoHearted, setVideoHearted] = useState(videoInHeartedVideos());
  const [videoHeartedStateChanged, setVideoHeartedStateChanged] = useState(false);

  //VIDEO HEARTED OR NOT CHECK
  //This function checks if this video was hearted. Returns true || false
  function videoInHeartedVideos() {
    //Find Container of segment
    const container = findContainer(segmentToChooseBackgroundFor.id, visionMovieData); /* 
    console.log("container: ", container);
    console.log("segmentToChooseBackgroundFor.id: ", segmentToChooseBackgroundFor.id);
    console.log("visionMovieData: ", visionMovieData); */
    //Find Index
    if (container) {
      const index = visionMovieData[container].findIndex(
        (segment) => segment.id === segmentToChooseBackgroundFor.id
      );
      if (visionMovieData[container][index].heartedVideos) {
        if (
          visionMovieData[container][index].heartedVideos.find(
            (element) => element.id === videoData.id
          )
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }

  useEffect(() => {
    if (overCard) {
      if (!videoData.uploadedImage) playVideo(videoRef.current);
    } else {
      pauseVideo(videoRef.current);
      //Save hearted change, if changed
      if (!overCard && videoHeartedStateChanged) {
        toggleVideoHeartedState(videoData);
        setVideoHeartedStateChanged(false);
      }
    }
  }, [overCard]);

  const playVideo = (video) => {
    setVideoLoading(true);
    var playPromise = video.play();

    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          setVideoLoading(false);
          setStartedToPlay(true);
          // Automatic playback started!
          // Show playing UI.
        })
        .catch((error) => {
          setVideoLoading(false);
          // Auto-play was prevented
          // Show paused UI.
        });
    }
  };

  const pauseVideo = (video) => {
    video.pause();
  };

  return (
    <div
      onMouseEnter={() => setOverCard(true)}
      onMouseOver={() => setOverCard(true)}
      onMouseLeave={() => setOverCard(false)}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      {videoLoading && (
        <CircularProgress
          style={{ color: "white", zIndex: 2, position: "absolute", opacity: 0.6 }}
          thickness={6}
        />
      )}
      {!startedToPlay && (
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            overflow: "hidden",
          }}
        >
          <img
            id={videoData.id + "thumbnail"}
            key={videoData.id + "thumbnail"}
            src={posterLink}
            style={{
              height: videoCardHeight,
              width: "auto%",
            }}
          />
        </div>
      )}
      {/* For uploaded there is no picture available*/}
      {currentPathname === "/backgroundselection/uploadvideos" ? (
        <video
          id={videoData.id + "video"}
          key={videoData.id + "video"}
          ref={videoRef}
          style={{ height: videoCardHeight }}
          src={getFittingVideoLink(videoData)}
          muted
          preload="none"
        />
      ) : (
        <video
          id={videoData.id + "video"}
          key={videoData.id + "video"}
          ref={videoRef}
          style={{ height: videoCardHeight }}
          src={getFittingVideoLink(videoData)}
          //poster={posterLink}
          muted
          preload="none"
        />
      )}

      {overCard && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            direction: "row",
            position: "absolute",
            right: 11,
            top: 9,
            zIndex: 2,
          }}
        >
          {(currentPathname !== "/backgroundselection/uploadvideos" ||
            currentPathname !== "/backgroundselection/uploadimages") && (
            <HeartButton
              videoData={videoData}
              videoHearted={videoHearted}
              setVideoHearted={setVideoHearted}
              setVideoHeartedStateChanged={setVideoHeartedStateChanged}
              toggleVideoHeartedState={toggleVideoHeartedState}
            />
          )}
          <AddButton data={videoData} />
        </div>
      )}
    </div>
  );
};
