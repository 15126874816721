import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as emptyHeart } from "@fortawesome/free-regular-svg-icons";
import { useLocation } from "react-router-dom";

export const HeartButton = ({
  videoData,
  videoHearted,
  setVideoHearted,
  setVideoHeartedStateChanged,
  toggleVideoHeartedState,
}) => {
  const theme = useTheme();
  const { palette } = theme;
  const currentPathname = useLocation().pathname;

  return (
    <FontAwesomeIcon
      onClick={() => {
        //In "/heartedvideos" the heartedvideos get removed on click
        if (currentPathname === "/backgroundselection/heartedvideos") {
          setVideoHearted((prev) => !prev);
          toggleVideoHeartedState(videoData);
        }
        //In "/backgroundselection" the changed hearted state gets stored when leaving the video card
        else {
          setVideoHearted((prev) => !prev);
          setVideoHeartedStateChanged((prev) => !prev);
        }
      }}
      style={{
        fontSize: 20,
        cursor: "pointer",
        marginRight: 18,
        paddingBottom: 0,
        color: palette.primary.main,
      }}
      icon={videoHearted ? faHeart : emptyHeart}
    />
  );
};
