import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import { useRef } from "react";
import { useUploadedImagesContext } from "./UploadedImagesContext";
import { useTranslation } from "react-i18next";

export default function UploadImageCard() {
  const theme = useTheme();
  const { palette } = theme;
  const { t } = useTranslation();

  const inputRef = useRef();

  const { handleImageSelected, uploadStatus, uploadProgress } = useUploadedImagesContext();

  function handleCardClick() {
    if (!uploadStatus) inputRef.current.click();
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        cursor: "pointer",
      }}
      onClick={handleCardClick}
    >
      <input
        ref={inputRef}
        accept=".jpg, .jpeg, .png"
        onChange={handleImageSelected}
        type="file"
        id="input_file"
        hidden
      ></input>
      <FontAwesomeIcon
        style={{
          color: uploadStatus === "uploadCompleted" ? palette.green.main : palette.primary.main,
          fontSize: "5vw",
          padding: "1vw",
          marginLeft: "-1vw",
        }}
        icon={faCloudUploadAlt}
      />
      <div
        style={{
          fontSize: "1.5vw",
          fontWeight: "semibold",
          fontFamily: "Poppins",
          cursor: "pointer",
          WebkitUserSelect: "none",
          color: palette.grey.normal,
        }}
      >
        {uploadStatus === "uploadCompleted"
          ? t("pictureUploaded")
          : uploadStatus === "inProgress"
          ? `${uploadProgress}${t("percentUploadedPicture")}`
          : t("uploadPicture")}
      </div>
    </div>
  );
}
