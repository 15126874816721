import { useDroppable } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";

export default function SortableContainer({ id, items, children }) {
  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <SortableContext id={id} items={items}>
      <div ref={setNodeRef}>{children}</div>
    </SortableContext>
  );
}
