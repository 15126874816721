import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark as CloseIcon } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import { useState, useEffect, useRef } from "react";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import React from "react";

export default function PlayerCloseButton() {
  const theme = useTheme();
  const { palette } = theme;
  const [hoverOverCloseButton, setHoverOverCloseButton] = useState(false);

  //This is needed to place the X correctly inside the video in the player
  const [blackStripesHeight, setBlackStripesHeight] = useState<number>();

  function recalculatingCloseButtonPlacement() {
    const height = window.innerHeight;
    const width = window.innerWidth;
    const videoHeight = (width * 1080) / 1920;
    setBlackStripesHeight((height - videoHeight) / 2);
  }

  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    recalculatingCloseButtonPlacement();
  });

  useEffect(() => {
    recalculatingCloseButtonPlacement();
  }, []);

  //TODO:
  // 2) calculate "X" size according to device (laptop, tablet, phones)

  //SHOW AND HIDE CURSOR AND CLOSE BUTTON______
  const closeButtonContainerRef = useRef<HTMLDivElement>(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    //On initial render
    hideCloseButtonAndCursorAfterDelay();
    //On component unmount
    return () => clearTimeout(timeout.current as NodeJS.Timeout);
  }, []);

  //On MouseMouve
  window.addEventListener("mousemove", () => {
    if (closeButtonContainerRef.current) {
      closeButtonContainerRef.current.style.opacity = "1";
      document.body.style.cursor = "auto";
      clearTimeout(timeout.current as NodeJS.Timeout);
      hideCloseButtonAndCursorAfterDelay();
    }
  });

  function hideCloseButtonAndCursorAfterDelay() {
    timeout.current = setTimeout(() => {
      if (closeButtonContainerRef.current) {
        closeButtonContainerRef.current.style.opacity = "0";
        document.body.style.cursor = "none";
      }
    }, 3000);
  }
  //END: SHOW AND HIDE CURSOR AND CLOSE BUTTON______

  return (
    <div
      ref={closeButtonContainerRef}
      onMouseEnter={() => setHoverOverCloseButton(true)}
      onMouseLeave={() => setHoverOverCloseButton(false)}
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
        window.history.back();
      }}
      style={{
        position: "absolute",
        top: blackStripesHeight,
        right: 0,
        zIndex: 2,
        paddingRight: 20,
        paddingTop: 10,
        transition: "opacity 1s ease-out",
      }}
    >
      <FontAwesomeIcon
        icon={CloseIcon}
        color={palette.primary.main}
        style={{ fontSize: "3.5vw", cursor: "pointer", opacity: hoverOverCloseButton ? 1 : 0.4 }}
      />
    </div>
  );
}
