import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
import useResponsiveCardheight from "./helperHooks/useResponsiveCardHeight";
import { useTranslation } from "react-i18next";

export default function ArchivePlaceholderCard() {
  const theme = useTheme();
  const { palette } = theme;
  const { t } = useTranslation();

  const cardHeight = useResponsiveCardheight();
  const Container = styled.div({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: cardHeight,
    borderRadius: 5,
    background: palette.gradient.cardBackgroundGradient,
  });

  const Text = styled.div({
    fontSize: "1.8vw",
    fontFamily: "Poppins",
    fontWeight: 400,
    WebkitUserSelect: "none",
    margin: 0,
    padding: 0,
    cursor: "pointer",
    background: "linear-gradient(104.33deg, #FF005A 3.51%, #FF7B44 98.53%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  });

  return (
    <Container>
      <Text> {t("moveHere")}</Text>
    </Container>
  );
}
