import React from "react";
import Navigation from "../components/navigation";
import { useTheme } from "@mui/material/styles";

export default function KaleidoscopeRoute() {
  const theme = useTheme();
  return (
    <div style={{ margin: theme.spacing(2) }}>
      <Navigation />
      <div style={{ marginTop: 60 }}>Kaleidoscope videos coming soon :)</div>
    </div>
  );
}
