import { affirmationStyles } from "./StatementStyles";
import Header from "./Header";
import Paragraph from "./Paragraph";
import Line from "./Line";
import React, { useEffect } from "react";

export default function Statement({
  statementData,
  headerRef,
  lineRef,
  paragraphRef,
  scalingFactor,
}) {
  useEffect(() => {
    //console.log("STATEMENT RENDER");
  });

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 1000,
        height: "100%",
        display: "flex",
        alignItems: "left",
        justifyContent: "center",
        flexDirection: "column",
        marginLeft: affirmationStyles.affirmationContainerMarginLeft,
        width: affirmationStyles.affirmationContainerWidth,
        textAlign: "left",
      }}
    >
      <Header headerRef={headerRef} statementData={statementData} scalingFactor={scalingFactor} />
      <Line lineRef={lineRef} headerRef={headerRef} scalingFactor={scalingFactor} />
      <Paragraph
        paragraphRef={paragraphRef}
        statementData={statementData}
        scalingFactor={scalingFactor}
      />
    </div>
  );
}
