import { useLayoutEffect, useState, useRef, useEffect } from "react";
import { Card } from "./VisionMovieCardStyled";
import { useTheme } from "@mui/material/styles";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../Firebase/Contexts/AuthContext";
import { firestore } from "../Firebase/firebase";
import { useVisionMovieContext } from "../VisionMovieContext";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";

const VIDEO_RATIO = 1080 / 1920;
export default function NewVisionMovieCard() {
  const { t } = useTranslation();

  const theme = useTheme();
  //Height setup
  const cardRef = useRef();
  const [cardHeight, setCardHeight] = useState(0);
  useLayoutEffect(() => {
    cardRef.current && setCardHeight(cardRef.current.offsetWidth * VIDEO_RATIO);
  }, [cardRef.current]);
  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    cardRef.current && setCardHeight(cardRef.current.offsetWidth * VIDEO_RATIO);
  });

  const plus = {
    fontSize: "7vw",
    fontFamily: "Poppins",
    fontWeight: 300,
    background: "linear-gradient(104.33deg, #FF005A 3.51%, #FF7B44 98.53%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    WebkitUserSelect: "none",
  };

  const [showPlus, setShowPlus] = useState(true);
  const [visionMovieName, setVisionMovieName] = useState("");
  const { currentUser } = useAuth();
  const userId = currentUser?.uid;
  const { moveDisplayIndexInAppOfOldVMs, setAllowSnapshotUpdates } = useVisionMovieContext();

  async function createNewVisionMovie() {
    const visionMovieId = uuidv4();
    setAllowSnapshotUpdates(false);
    await moveDisplayIndexInAppOfOldVMs();
    setAllowSnapshotUpdates(true);
    firestore
      .collection("users")
      .doc(userId)
      .collection("VisionMovies")
      .doc(visionMovieId)
      .set({
        displayIndexInApp: 0,
        visionMovieId: visionMovieId,
        name: visionMovieName,
        playbackParameters: {
          playVisionMovieNumberOfTimes: 1,
          playKaleidoscopeVideo: true,
          startKaleidoscopeVideoLength: 4,
          middleKaleidoscopeVideoLength: 1,
          endKaleidoscopeVideoLength: 0,
          showStatements: true,
        },
        visionMovie: [],
        archive: [{ id: "archivePlaceholder" }],
        backgroundMusic: null,
      })
      .then((response) => {
        console.log("success creating new VM");
      })
      .catch((error) => console.log("error occured: " + error));
  }

  const [loading, setLoading] = useState(false);
  const [showToShortNameAlert, setShowToShortNameAlert] = useState(false);
  function handleSetShowToShortNameAlert() {
    setShowToShortNameAlert(true);
  }

  return (
    <Card
      ref={cardRef}
      cardHeight={cardHeight}
      backgroundColor={theme.palette.gradient.cardBackgroundGradient}
    >
      {showPlus ? (
        <div
          onClick={() => setShowPlus(false)}
          style={{
            cursor: "pointer",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            WebkitUserSelect: "none",
          }}
        >
          <div style={plus}>+</div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            {showToShortNameAlert && (
              <Alert severity="info" style={{ marginBottom: 20 }}>
                {t("chooseLongerName")}
              </Alert>
            )}
            <TextField
              id="outlined-basic"
              label={t("visionMovieName")}
              variant="outlined"
              sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "none" } }}
              style={{
                borderRadius: 4,
                fontSize: "1.5rem",
                background: "white",
                marginBottom: 20,
              }}
              value={visionMovieName}
              onChange={(e) => {
                setVisionMovieName(e.target.value);
                setShowToShortNameAlert(false);
              }}
            />
            <LoadingButton
              style={{ width: 140, textTransform: "none", fontSize: 20 }}
              variant="contained"
              disableElevation
              onClick={() => {
                if (visionMovieName.length > 3) {
                  setLoading(true);
                  createNewVisionMovie().then(() => {
                    setLoading(false);
                    setShowPlus(true);
                  });
                } else {
                  handleSetShowToShortNameAlert();
                }
              }}
              loading={loading}
              //loadingPosition="start"
            >
              {t("create")}
            </LoadingButton>
          </div>
        </div>
      )}
    </Card>
  );
}
