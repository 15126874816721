import { useState } from "react";
import { Grid } from "@mui/material";
import SelectorButton from "./SelectorButton";
import { visionMovieGeneratorData } from "./visionMovieGeneratorData";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import LowerBox from "./LowerBox";

const toBeChoosenOptions = 5;

export default function Index() {
  const theme = useTheme();

  const [addedOptions, setAddedOptions] = useState([]);

  function addOption(optionName) {
    const newAddedOptions = JSON.parse(JSON.stringify(addedOptions));
    if (addedOptions.find((name) => name === optionName)) {
      setAddedOptions(newAddedOptions.filter((name) => name !== optionName));
    } else {
      if (toBeChoosenOptions === addedOptions.length) {
        alert(`Du kannst nur ${toBeChoosenOptions} auswählen.`);
        return;
      }
      newAddedOptions.push(optionName);
      setAddedOptions(newAddedOptions);
    }
  }

  function finalizeVisionMovie() {
    alert("Apply choosen segments");
    //TODO: Add to VM
  }

  /*   function addOptionOld(optionName) {
    function onSide(side) {
      return visionMovieGeneratorData[side].findIndex((category) => {
        const insideOptions = category.options.find((option) => {
          return option.name === optionName;
        });
        if (insideOptions) {
          return category.categoryName;
        }
      });
    }

    const categoryIndexLeft = onSide("left");
    const categoryIndexRight = onSide("right");
    console.log(categoryIndexLeft);
    console.log(categoryIndexRight);

    if (categoryIndexLeft >= 0) {
      console.log(visionMovieGeneratorData.left[categoryIndexLeft]);
      const optionsIndex = visionMovieGeneratorData.left[categoryIndexLeft].options.findIndex(
        (option) => {
          return option.name === optionName;
        }
      );
      const newVisionMovieGeneratorData = JSON.parse(JSON.stringify(visionMovieGeneratorData));
      if (newVisionMovieGeneratorData.left[categoryIndexLeft].options[optionsIndex].selected) {
        newVisionMovieGeneratorData.left[categoryIndexLeft].options[optionsIndex].selected = false;
      } else {
        newVisionMovieGeneratorData.left[categoryIndexLeft].options[optionsIndex].selected = true;
      }
      console.log(newVisionMovieGeneratorData);
      setVisionMovieGeneratorData(newVisionMovieGeneratorData);
    }

     if (categoryIndexRight  >= 0) {
      const optionsIndex = visionMovieGeneratorData.left[categoryIndexRight].findIndex((option) => {
        return option.name === optionName;
      });
      setVisionMovieGeneratorData(
        (prev) => (prev.right[categoryIndexRight].options[optionsIndex].name = optionName)
      ); 
  } */

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ width: "90%", position: "relative", paddingTop: 40, paddingBottom: 110 }}>
        <LowerBox
          optionsSelected={addedOptions.length}
          toBeChoosenOptions={toBeChoosenOptions}
          finalizeVisionMovie={finalizeVisionMovie}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            {visionMovieGeneratorData.left.map((categoryData) => {
              return (
                <div style={{ marginBottom: 45 }}>
                  <Typography
                    style={{
                      padding: 0,
                      margin: 0,
                      fontWeight: "bold",
                      color: theme.palette.grey.medium,
                      marginBottom: 20,
                    }}
                    variant="h3"
                  >
                    {categoryData.categoryName}
                  </Typography>
                  {categoryData.options.map((option) => {
                    return (
                      <SelectorButton
                        option={option}
                        addOption={addOption}
                        addedOptions={addedOptions}
                      />
                    );
                  })}
                </div>
              );
            })}
          </Grid>
          <Grid item xs={12} lg={6}>
            {visionMovieGeneratorData.right.map((categoryData) => {
              return (
                <div style={{ marginBottom: 10 }}>
                  <Typography
                    style={{
                      padding: 0,
                      margin: 0,
                      fontWeight: "bold",
                      color: theme.palette.grey.medium,
                      marginBottom: 20,
                    }}
                    variant="h3"
                  >
                    {categoryData.categoryName}
                  </Typography>
                  {categoryData.options.map((option) => {
                    return (
                      <SelectorButton
                        option={option}
                        addOption={addOption}
                        addedOptions={addedOptions}
                      />
                    );
                  })}
                </div>
              );
            })}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
