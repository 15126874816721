import { Action } from "./Actions";

export interface WebinarData {
  forMobileDevicesVideo: VideoPart;
  parts: (VideoPart | SoftwarePart)[];
}

export interface VideoPart {
  url: string;
  //If not the whole video shall be shown.
  //Otherwise will go to next part when video ended
  showingDuration?: number;
}

export interface SoftwarePart {
  routeString: string;
  actions?: Actions;
  //Two possibilities when to move to the next part
  //1. ShowingDuration is over
  //2. An action has been taken e.g. created an account
  showingDuration?: number;
  //Possibly several audio overlays can be implemented at different times
  audioOverlays?: AudioOverlay[];
  divOverlays?: DivOverlays[];
}

export interface Actions {
  disabledActions?: Action[];
  enabledActions?: Action[];
  controllingActions?: {
    actionType: Action;
    timesActionAllowed: number;
    timesActionPerformed?: number;
  }[];
}

export interface AudioOverlay {
  url: string;
  startTime: number;
}

export interface DivOverlays {
  type: DivOverlayType;
  showTimeInSec?: number;
}

export enum DivOverlayType {
  "NextPartButton",
}

//Typecheck helper functions
export function isVideoPart(toBeDetermined: any): boolean {
  if ("url" in (toBeDetermined as any)) {
    return true;
  }
  return false;
}

export function isSoftwarePart(toBeDetermined: any): toBeDetermined is SoftwarePart {
  if ("routeString" in (toBeDetermined as any)) {
    return true;
  }
  return false;
}
