export function prepareDataForPlayer(visionMovieData, aufgerufenString) {
  const idealSegmentLength = 14;
  const maxSegmentLength = 16;
  const segments = JSON.parse(JSON.stringify(visionMovieData.visionMovie));
  const songLength = visionMovieData.backgroundMusic?.duration
    ? visionMovieData.backgroundMusic?.duration
    : 1000;

  var preparedData = allocateTimeToSegments(segments, songLength, maxSegmentLength);

  if (preparedData.allocatedTime <= songLength) {
    //Videos cover song
    preparedData = allocateTimeToSegments(segments, songLength, idealSegmentLength);
  }

  if (preparedData.allocatedTime > songLength + 0.5) {
    //Doesn't cover video - longer than song
    preparedData = allocateTimeToSegments(segments, songLength + 1000, idealSegmentLength);
  }

  //ADD SHOWAFFIRMATION & HIDEAFFIRMATION TIMES
  var finalSegmentsData = preparedData.segments;
  for (let i = 0; i < finalSegmentsData.length; i++) {
    //If no startOffset is set for a video it is set to 0 here
    if (finalSegmentsData[i].video && !finalSegmentsData[i].video?.startOffset) {
      finalSegmentsData[i].video.startOffset = 0;
    }
    //ONLY A CHEAP FIX COULD BE DONE NICER
    if (finalSegmentsData[i].image && !finalSegmentsData[i].image?.startOffset) {
      finalSegmentsData[i].image.startOffset = 0;
    }

    if (finalSegmentsData[i].affirmation) {
      finalSegmentsData[i].affirmation.showAffirmation = 1;

      const showingDuration = segments[i]?.video
        ? segments[i].video.showingDuration
        : segments[i].image.showingDuration;
      switch (true) {
        case showingDuration > 12:
          finalSegmentsData[i].affirmation.hideAffirmation = 9;
          break;
        case showingDuration > 9:
          finalSegmentsData[i].affirmation.hideAffirmation = showingDuration - 3;
          break;
        default:
          finalSegmentsData[i].affirmation.hideAffirmation = showingDuration - 2.2;
      }
    }
  }
  return finalSegmentsData;
}

function allocateTimeToSegments(segments, songLength, maxSegmentLength) {
  //SET PARAMETERS
  const minShowingDuration = 8; //Also videos that come from pexels API are filtered to not be shorter than 8 seconds
  const showingDurationOfCardsWidthoutBackground = 8;

  //Initial time distribution
  var allocatedTime = 0;
  //Every showingDuration is set to minimum
  for (let i = 0; i < segments.length; i++) {
    //For only text or empty card without backgroundAsset
    if (segments[i]?.video?.placeholder) {
      segments[i].video.showingDuration = showingDurationOfCardsWidthoutBackground;
      allocatedTime += showingDurationOfCardsWidthoutBackground;
    }
    //For cards with image background
    else if (segments[i]?.image?.uploadedImage) {
      segments[i].image.showingDuration = minShowingDuration;
      allocatedTime += minShowingDuration;
    }
    //For card with video background
    else {
      segments[i].video.showingDuration = minShowingDuration;
      allocatedTime += minShowingDuration;
    }
  }
  //Applying rounds of adding video length
  return distributeTimeToSegmentsRound(segments, songLength, allocatedTime, maxSegmentLength);
}

function distributeTimeToSegmentsRound(segments, songLength, allocatedTime, maxSegmentLength) {
  var finishedSegments = 0;
  for (let i = 0; i < segments.length; i++) {
    if (allocatedTime < songLength) {
      //For cards with video background
      if (
        !segments[i]?.video?.placeholder &&
        segments[i]?.video?.showingDuration < maxSegmentLength
      ) {
        //Card with background
        //ADD TIME IF VIDEO LONGER
        if (segments[i].video.duration > segments[i].video.showingDuration + 0.5) {
          segments[i].video.showingDuration += 0.5;
          allocatedTime += 0.5;
        }
        //VIDEO LENGTH REACHED
        else {
          allocatedTime += segments[i].video.duration - segments[i].video.showingDuration;
          segments[i].video.showingDuration = segments[i].video.duration;
          finishedSegments++;
        }
      }
      //For cards with image background
      else if (
        segments[i]?.image?.uploadedImage &&
        segments[i]?.image?.showingDuration < maxSegmentLength
      ) {
        segments[i].image.showingDuration += 0.5;
        allocatedTime += 0.5;
      }
      //Only text or empty card
      else {
        finishedSegments++;
      }
    } else {
      finishedSegments = segments.length;
    }
  }

  //RECURSIVE FUNCTION CALL
  if (finishedSegments < segments.length && allocatedTime < songLength) {
    return distributeTimeToSegmentsRound(segments, songLength, allocatedTime, maxSegmentLength);
  } else {
    return { segments, allocatedTime, maxSegmentLength };
  }
}
