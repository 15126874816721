import { useRef } from "react";
import { SoftwarePart } from "./SoftwareWebinarInterfaces";
import useTimeout from "../../helperFunctions/useTimeout";

export default function AudioElements({ softwarePart }: { softwarePart: SoftwarePart }) {
  return (
    <div hidden>
      {softwarePart.audioOverlays?.map((elem, index) => {
        return <AudioElement key={index} url={elem.url} playDelay={elem.startTime} index={index} />;
      })}
    </div>
  );
}

const AudioElement = ({
  url,
  playDelay,
  index,
}: {
  url: string;
  playDelay: number;
  index: number;
}) => {
  const audioRef = useRef<any>();

  useTimeout(() => {
    if (audioRef.current) audioRef.current.play();
  }, playDelay * 1000);

  return <audio key={index + "-audio"} id={index + "-audio"} ref={audioRef} src={url}></audio>;
};
