import { useState, useRef, useEffect } from "react";
import { css } from "@emotion/css";
import { up, Breakpoint } from "./Breakpoint";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import { useLocation } from "react-router-dom";

export default function GrowingUnstyledInputField({ style, value, onChange }) {
  const currentPathname = useLocation().pathname;
  const [width, setWidth] = useState(0);
  const span = useRef();

  useEffect(() => {
    setWidth(span.current.offsetWidth);
  }, [value]);
  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    setWidth(span.current.offsetWidth);
  });

  const responsiveFontSize = {
    fontSize: 20,
    [up(500)]: { fontSize: 25 },
    [up(600)]: { fontSize: 28 },
    [up(700)]: { fontSize: 31 },
    [up(800)]: { fontSize: 35 },
    //Two cards in one row
    [up(Breakpoint.md)]: { fontSize: 20 },
    [up(1000)]: { fontSize: 25 },
    [up(1100)]: { fontSize: 28 },
    [up(1200)]: { fontSize: 31 },
    [up(1400)]: { fontSize: 32 },
    [up(1600)]: { fontSize: 36 },
    [up(1800)]: { fontSize: 40 },
  };

  return (
    <>
      <span
        id="hide"
        style={{
          position: "absolute",
          opacity: 1,
          zIndex: currentPathname === "/fortschritt" ? 100 : -100,
          userSelect: "none",
          whiteSpace: "pre",
          ...style,
        }}
        className={css(responsiveFontSize)}
        ref={span}
      >
        {value}
      </span>
      <input
        type="text"
        value={value}
        style={{
          width,
          minWidth: 1,
          padding: 0,
          borderTopStyle: "hidden",
          borderRightStyle: "hidden",
          borderLeftStyle: "hidden",
          borderBottomStyle: "hidden",
          background: "transparent",
          ...style,
        }}
        className={css(responsiveFontSize)}
        onChange={(evt) => onChange(evt)}
      />
    </>
  );
}
