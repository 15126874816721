import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import useResponsiveCardheight from "./helperHooks/useResponsiveCardHeight";

export default function SortableItem({ id, disabled, children }) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
    disabled,
    transition: {
      duration: 800, // milliseconds
      easing: "cubic-bezier(0.65,0.05,0.36,1)",
    },
  });

  const cardHeight = useResponsiveCardheight();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    height: cardHeight,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {React.cloneElement(children, {
        dragging: isDragging,
      })}
    </div>
  );
}
