import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useVisionMovieContext } from "../VisionMovieContext";
import { useWebinarContext } from "../softwareWebinar/WebinarContext";
import { Action } from "../softwareWebinar/Actions";
import WebinarButtonWrapper from "../softwareWebinar/WebinarButtonWrapper";
import { useTranslation } from "react-i18next";

export const AddButton = ({ data }) => {
  const { actionEnabled, navigate_WebinarWrapper } = useWebinarContext();
  const { t } = useTranslation();

  const theme = useTheme();
  const { palette } = theme;
  const { addBackgroundToSegment } = useVisionMovieContext();

  const [overButton, setOverButton] = useState(false);

  function dataActionType(data) {
    if (data.uploadedImage) {
      return Action.AddUploadedImage;
    } else if (data.uploadedVideo) {
      return Action.AddUploadedVideo;
    } else {
      return Action.AddPlatformVideo;
    }
  }

  return (
    <WebinarButtonWrapper actionType={dataActionType(data)}>
      <div
        onClick={() => {
          if (actionEnabled(dataActionType(data))) {
            addBackgroundToSegment(data);
            navigate_WebinarWrapper("/edit");
          }
        }}
        onMouseEnter={() => setOverButton(true)}
        onMouseOver={() => setOverButton(true)}
        onMouseLeave={() => setOverButton(false)}
        style={{
          borderStyle: "solid",
          borderWidth: 2,
          transition: "all 0.15s ease-in-out",
          borderRadius: 5,
          paddingLeft: 10,
          paddingRight: 10,
          cursor: "pointer",
          paddingTop: 1,
          paddingBottom: 1,
          fontSize: 13,
          color: overButton ? "white" : palette.primary.main,
          borderColor: palette.primary.main,
          background: overButton ? palette.primary.main : palette.primary.opacity15,
          fontWeight: "bold",
        }}
      >
        {t("select")}
      </div>
    </WebinarButtonWrapper>
  );
};
