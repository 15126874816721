import { useEffect, useRef, useState } from "react";
import { easeLinear } from "d3-ease";
import _ from "lodash";
import { Spinner } from "react-bootstrap";
import CanvasOverlay from "./CanvasOverlay";
import { VisionMovieSegment } from "./VisionMovieSegment";
import getFittingVideoLinkForDeviceDimension from "./helperFunctions/getFittingVideoLinkForDeviceDimension";
import useWindowResize from "beautiful-react-hooks/useWindowResize";

const DEBUG = false;
const VIDEO_RATIO = 1080 / 1920;

interface INewVideoPlayer {
  lastSegment: boolean;
  segmentsCoverSong?: boolean;
  setAudioVolume: (newVolume: number) => void;
  visionMovieSegment: VisionMovieSegment;
  width: number;
  height: number;
  minBufferTimeMs?: number;
  onVideoFinished?: (lastSegment: boolean) => void;
  paused?: boolean;
  shouldPlay?: boolean;
  onPreBufferingComplete?: () => void;
  onBuffering?: () => void;
  onPlaying?: () => void;
}

export default function CanvasVideoPlayer({
  lastSegment,
  segmentsCoverSong,
  setAudioVolume,
  visionMovieSegment,
  width,
  height,
  minBufferTimeMs = 3000,
  onVideoFinished,
  shouldPlay = true,
  onPreBufferingComplete,
  onBuffering,
  onPlaying,
  paused = false,
}: INewVideoPlayer) {
  const url = visionMovieSegment?.videoClip?.availableVideoFilesFromAPI
    ? getFittingVideoLinkForDeviceDimension(visionMovieSegment.videoClip.availableVideoFilesFromAPI)
    : "";
  const maxPlayingTimeMs = visionMovieSegment?.videoClip?.showingDurationInSec
    ? visionMovieSegment?.videoClip?.showingDurationInSec * 1000
    : 0;

  console.log("url");
  console.log(url);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isPreBuffering, setIsPreBuffering] = useState(true); //prebuffering = playing first seconds of video to fill buffer before actually playing
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [bufferedTimeMs, setBufferedTimeMs] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isPaused, setIsPaused] = useState(paused);
  const [waitingTimeStamp, setWaitingTimeStamp] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const [videoHeight, setVideoHeight] = useState(window.innerWidth * VIDEO_RATIO);
  //Set new hight on mobile device orientation change
  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    setVideoHeight(window.innerWidth * VIDEO_RATIO);
  });

  const onFinishedSendToParentComponent = useRef(false);
  useEffect(() => {
    if (videoRef.current && canvasRef.current) {
      //CREATE CANVAS ELEMENT
      const canvasOverlay = new CanvasOverlay(
        canvasRef.current!,
        videoRef.current!,
        visionMovieSegment,
        lastSegment
      );
      canvasOverlay.update();

      const onPlay = () => {
        if (!isPreBuffering && isPaused) {
          //stop player if it was initially played by autoplay but is already out of the pre-buffering phase
          videoRef.current!.pause();
        } else {
          DEBUG && console.log("onPlay");
          setIsPaused(false);
          setIsPlaying(true);
          setIsLoading(false);
          onPlaying && onPlaying();
          if (isLoading && shouldPlay && waitingTimeStamp > 0) {
            console.log("waited ", Date.now() - waitingTimeStamp);
          }
        }
      };

      const onWaiting = () => {
        DEBUG && console.log("onWaiting");
        if (
          videoRef.current &&
          videoRef.current!.currentTime <
            Math.min(maxPlayingTimeMs ?? Infinity, videoRef.current!.duration)
        ) {
          console.log("buffering ", url);
          onBuffering && onBuffering();
        }
        setWaitingTimeStamp(Date.now());
        setIsLoading(true);
      };

      const onProgress = () => {
        if (!videoRef.current || !videoRef.current!.buffered) {
          return;
        }

        if (videoRef.current!.buffered.length > 0) {
          const bufferedEnd = videoRef.current!.buffered.end(videoRef.current!.buffered.length - 1);
          setBufferedTimeMs(bufferedEnd * 1000);
          updatePreBuffering();
        }
      };

      const updatePreBuffering = () => {
        if (videoRef.current && isPreBuffering) {
          const bufferedEnd = videoRef.current.buffered.end(videoRef.current.buffered.length - 1);
          if (bufferedEnd > Math.min(minBufferTimeMs / 1000, videoRef.current.duration)) {
            DEBUG && console.log("onBufferComplete");
            setIsPreBuffering(false);
            onPreBufferingComplete && onPreBufferingComplete();
            console.log("prebuffering complete");
            videoRef.current.currentTime = 0;
            setIsLoading(false);

            if (shouldPlay) {
              //keep playing
            } else {
              //video was only supposed to play to fill buffer, now pause
              console.log("pausing video");
              videoRef.current.pause();
            }
          }
        }
      };

      const onTimeUpdate = () => {
        if (!videoRef.current || !videoRef.current.buffered) {
          return;
        }

        if (shouldPlay) {
          //only update canvasOverlay if video is active one on the top
        }

        if (isPreBuffering && videoRef.current!.buffered.length) {
          updatePreBuffering();
        }

        const duration = videoRef.current!.duration;

        if (
          !isPreBuffering &&
          (videoRef.current.currentTime >= duration ||
            (maxPlayingTimeMs && videoRef.current.currentTime * 1000 >= maxPlayingTimeMs))
        ) {
          if (onVideoFinished && !onFinishedSendToParentComponent.current) {
            onVideoFinished(lastSegment);
            onFinishedSendToParentComponent.current = true;
            setIsComplete(true);
          }
        }

        //Fadeout Audio if segments don't cover song
        if (lastSegment && !segmentsCoverSong) {
          fadeOutAudio();
        }
      };

      const fadeOutAudio = () => {
        if (videoRef.current) {
          //Calculate fade
          const FADEOUT_AUDIO_DURATION_MS = 7000;
          const currentTimeMs = videoRef.current.currentTime * 1000;
          const fadeoutTimeMs = visionMovieSegment.videoClip
            ? visionMovieSegment.videoClip.showingDurationInSec * 1000 - FADEOUT_AUDIO_DURATION_MS
            : 0;

          if (currentTimeMs > fadeoutTimeMs) {
            const audioValue = easeLinear(
              (currentTimeMs - fadeoutTimeMs) / FADEOUT_AUDIO_DURATION_MS
            );
            //Needed because otherwise audio is not fade-out completely
            const adjustedAudioValue = audioValue > 0.98 ? 1 : audioValue;

            setAudioVolume(1 - _.clamp(adjustedAudioValue, 0, 1));
          }
        }
      };

      videoRef.current.addEventListener("progress", onProgress);
      videoRef.current.addEventListener("waiting", onWaiting);
      videoRef.current.addEventListener("playing", onPlay);
      videoRef.current.addEventListener("timeupdate", onTimeUpdate);

      // clean up
      return () => {
        //console.log("destroy video: " + url);
        if (videoRef.current) {
          videoRef.current.removeEventListener("waiting", onWaiting);
          videoRef.current.removeEventListener("playing", onPlay);
          videoRef.current.removeEventListener("progress", onProgress);
          videoRef.current.removeEventListener("timeupdate", onTimeUpdate);
        }
        canvasOverlay.destroy();
      };
    }
  });

  //Starting and stopping to play video
  useEffect(() => {
    setIsPaused(paused);
    if (videoRef.current) {
      if (paused || !shouldPlay) {
        videoRef.current.pause();
        console.log("trigger pause");
      } else if (shouldPlay && !paused) {
        console.log("trigger play");
        videoRef.current
          .play()
          .then(() => {})
          .catch(() => {}); //can be interrupted by pause statement
      }
    }
  }, [paused, shouldPlay]);

  //Starting to play to prebuffer video
  useEffect(() => {
    setIsPreBuffering(true);
    setIsLoading(true);
    if (url && videoRef.current && !isPlaying && !isPaused && shouldPlay) {
      console.log("start playing");
      videoRef.current
        .play()
        .then(() => {})
        .catch(() => {}); //can be interrupted by pause statement
    }
    //setReadyForPlay(false);
  }, [url]);

  useEffect(() => {
    if (videoRef.current) {
      if (!isPaused && shouldPlay) {
        console.log("trigger play");
        videoRef.current
          .play()
          .then(() => {})
          .catch(() => {}); //can be interrupted by pause statement
      } else if (!isPreBuffering && (isPaused || !shouldPlay)) {
        console.log("pause");
        videoRef.current.pause();
      }
    }
  }, [shouldPlay]);

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        alignItems: "center",
        backgroundColor: "black",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: "100vw",
          height: videoHeight,
          //position: "absolute",
        }}
      >
        <video
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          ref={videoRef}
          playsInline={true}
          muted={true}
          src={url}
          autoPlay={true}
        ></video>
        {isLoading && (
          <Spinner
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: "auto",
              width: (100 * window.innerWidth) / 1440,
              height: (100 * window.innerWidth) / 1440,
              borderWidth: (12 * window.innerWidth) / 1440,
              color: "rgba(255, 255, 255, 0.7)",
            }}
            animation={"border"}
          />
        )}
        <canvas style={{ position: "absolute", top: 0, left: 0 }} ref={canvasRef}></canvas>
        {DEBUG && isPreBuffering && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: width,
              height: height,
              backgroundColor: "rgba(0,0,0,0.6)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              fontSize: 20,
            }}
          >
            Filling Buffer...
          </div>
        )}
      </div>
    </div>
  );
}
