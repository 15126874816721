import React, { useContext, useState } from "react";

export const MonthContext = React.createContext();

export function useMonthContext() {
  return useContext(MonthContext);
}

//Creating the logic and the Providers
export function MonthContextProvider({ children }) {
  //Enabling and Disabeling Actions______________
  const [month, setMonth] = useState("Placeholder");

  const parametersToBePassed = {
    month,
    setMonth,
  };

  return <MonthContext.Provider value={parametersToBePassed}>{children}</MonthContext.Provider>;
}
