import React, { useRef, useState } from "react";
import VideoPartComponent from "./VideoPartComponent";
import { VideoPart, isVideoPart } from "./SoftwareWebinarInterfaces";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";

export default function StartScreen({
  forMobileDevicesVideo,
  part,
  onFinishedPart,
}: {
  forMobileDevicesVideo: VideoPart;
  part: VideoPart;
  onFinishedPart: () => void;
}) {
  const viewedOnMobileDevice = window.innerWidth < 1100;

  const startPart = useRef<() => void>(() => {});
  function startWebinar() {
    startPart.current();
  }

  return (
    <div>
      <StartOverlay startWebinar={startWebinar} />
      {isVideoPart(part) && (
        <VideoPartComponent
          firstPart={true}
          forMobileDevicesVideo={viewedOnMobileDevice}
          part={viewedOnMobileDevice ? forMobileDevicesVideo : (part as VideoPart)}
          startPart={startPart}
          onFinishedPart={onFinishedPart}
        />
      )}
    </div>
  );
}

function StartOverlay({ startWebinar }: { startWebinar: () => void }) {
  const theme = useTheme();
  const [showStartOverlay, setShowStartOverlay] = useState(true);

  function handlePlayButtonClick() {
    setShowStartOverlay(false);
    startWebinar();
  }

  return (
    <>
      {showStartOverlay && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100vw",
            zIndex: 2,
            fontFamily: "Poppins",
            color: "white",
            background: "",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h1
              style={{
                width: "100%",
                paddingLeft: "1%",
                paddingRight: "1%",
                fontSize: "5vw",
                fontWeight: 900,
                textAlign: "center",
              }}
            >
              Your MindMovie Journey
            </h1>
            <FontAwesomeIcon
              icon={faPlay}
              color={theme.palette.primary.main}
              style={{
                paddingTop: "2vw",
                paddingBottom: "2vw",
                cursor: "pointer",
                fontSize: "10vw",
                textAlign: "center",
              }}
              onClick={handlePlayButtonClick}
            />
            <p style={{ fontSize: "2vw", fontWeight: 800 }}>start now</p>
          </div>
        </div>
      )}
    </>
  );
}
