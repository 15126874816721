import React from "react";
import { affirmationStyles } from "./StatementStyles";

export default function Paragraph({ statementData, scalingFactor, paragraphRef }) {
  var paragraph = statementData.paragraph;

  if (paragraph === "" && statementData.header === "") {
    paragraph = "type in any text yet. Do this and it will be shown here";
  }

  return (
    <div
      ref={paragraphRef}
      style={{
        opacity: 0,
        fontFamily: affirmationStyles.fontFamily,
        fontSize: scalingFactor * affirmationStyles.paragraphTextSize,
        fontWeight: affirmationStyles.paragraphFontWeight,
        color: "white",
        //opacity: opacity,
        lineHeight: scalingFactor * affirmationStyles.paragraphLineHeight,
        display: "inline",
        whiteSpace: "pre-wrap",
        WebkitUserSelect: "none",
      }}
    >
      {paragraph}
    </div>
  );
}
