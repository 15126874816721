import React, { useState, useEffect } from "react";
import { useAuth } from "../../Firebase/Contexts/AuthContext";
import { firestore } from "../../Firebase/firebase";
import Stack from "@mui/material/Stack";
import Stepper from "./Stepper";
import Card from "./Card";
import Calendar from "./Calendar";
import SaveButton from "./SaveButton";
import AutoSizer from "react-virtualized-auto-sizer";

export default function Index() {
  // Get current loggedIn user from firebase
  const { currentUser } = useAuth();
  /*   console.log("currentUser?.uid");
  console.log(currentUser?.uid); */

  const [nextClicked, setNextClicked] = useState(false);

  const [activeToDoIndex, setActiveToDoIndex] = React.useState(0);
  const [currentSegment, setCurrentSegment] = useState();
  const [toDos, setTodos] = useState([]);
  const [coachings, setCoachings] = useState(undefined);
  const [visionMoviesSegments, setVisionMoviesSegments] = useState([]);

  useEffect(() => {
    if (
      toDos[activeToDoIndex]?.visionMovieSegmentId &&
      toDos.length !== 0 &&
      visionMoviesSegments.length !== 0
    ) {
      const visionMovieSegmentId = toDos[activeToDoIndex]?.visionMovieSegmentId;
      const visionMovieId = toDos[activeToDoIndex]?.visionMovieId;

      const segment = visionMoviesSegments.find((elem) => elem.id === visionMovieSegmentId);
      console.log("DATA");
      console.log(segment);
      setCurrentSegment(segment);
      console.log(visionMovieSegmentId);
      console.log(visionMoviesSegments);
    }
  }, [activeToDoIndex, toDos, visionMoviesSegments]);

  useEffect(() => {
    /*   console.log("currentSegment____");
    console.log(currentSegment); */
  }, [currentSegment]);

  useEffect(() => {
    if (currentUser?.uid) {
      updateTrackingData();
      updateCoachings();
      updateVisionMoviesData();
    }
  }, []);
  console.log("currentUser?.uid");

  console.log(currentUser?.uid);

  const updateTrackingData = () => {
    firestore
      .collection("users")
      .doc(currentUser?.uid)
      .collection("TrackedToDos")
      .onSnapshot((docRef) => {
        const toDosArray = [];
        if (docRef?.docs) {
          docRef.docs.map((doc) => {
            toDosArray.push(doc.data());
          });
          setTodos(toDosArray);
        }
      });
  };

  const updateCoachings = () => {
    firestore
      .collection("users")
      .doc(currentUser?.uid)
      .collection("Coachings")
      .onSnapshot((docRef) => {
        const coachingsArray = [];
        if (docRef?.docs) {
          docRef.docs.map((doc, index) => {
            coachingsArray.push(doc.data());
          });

          //Sort VisionMovies by displayIndexInApp
          coachingsArray.sort((a, b) => {
            return a.number - b.number;
          });
          setCoachings(coachingsArray);
        }
      });
  };

  const updateVisionMoviesData = () => {
    firestore
      .collection("users")
      .doc(currentUser?.uid)
      .collection("VisionMovies")
      .onSnapshot((docRef) => {
        const itemsArray = [];
        if (docRef?.docs) {
          const visionMoviesArray = docRef.docs
            .map((e) => e.data())
            .sort((a, b) => a.displayIndexInApp - b.displayIndexInApp);

          for (let i = 0; i < visionMoviesArray.length; i++) {
            visionMoviesArray[i].visionMovie.map((elem) =>
              itemsArray.push({
                id: elem.id,
                image: elem.image,
                contrastLayerOpacityValue: elem.contrastLayerOpacityValue,
                video: { image: elem?.video?.image, uploadedVideo: elem?.video?.uploadedVideo },
                affirmation: elem.affirmation,
              })
            );
            visionMoviesArray[i].archive.map((elem) =>
              itemsArray.push({
                id: elem.id,
                image: elem.image,
                contrastLayerOpacityValue: elem.contrastLayerOpacityValue,
                video: { image: elem?.video?.image, uploadedVideo: elem?.video?.uploadedVideo },
                affirmation: elem.affirmation,
              })
            );
          }
          setVisionMoviesSegments(itemsArray);
        }
      });
  };

  return (
    <Stack
      sx={{
        width: "100%",
        minHeight: "100vh",
        paddingLeft: { xs: 2, lg: 5 },
        paddingRight: { xs: 2, lg: 5 },
        background: "#F4F6F9",
      }}
      spacing={{ xs: 2, md: 8 }}
      alignItems="center"
      justifyContent={{ xs: "space-between", lg: "flex-start" }}
    >
      <Stack
        direction={"column"}
        style={{
          width: "100%",
          maxWidth: 1500,
          paddingTop: "min(100px, 4.5%)",
        }}
        spacing={{ xs: 0, md: 4 }}
      >
        <SaveButton
          toDos={toDos}
          activeToDoIndex={activeToDoIndex}
          setActiveToDoIndex={setActiveToDoIndex}
        />
        <Stack
          direction={{ xs: "column", lg: "row" }}
          style={{
            width: "100%",
          }}
          spacing={{ xs: 4, md: 8 }}
        >
          <Stack sx={{ width: { xs: "100%", lg: "45%" } }}>
            {currentSegment && <Card segment={currentSegment} />}
          </Stack>

          <Stack sx={{ width: { xs: "100%", lg: "55%" } }}>
            <Calendar
              activeToDoIndex={activeToDoIndex}
              toDo={toDos[activeToDoIndex]}
              coachings={coachings}
              showTrackingButtons={true}
              setActiveToDoIndex={setActiveToDoIndex}
              nextClicked={nextClicked}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stepper
        maxSteps={toDos?.length}
        activeStep={activeToDoIndex}
        setActiveStep={setActiveToDoIndex}
        setNextClicked={setNextClicked}
      />
    </Stack>
  );
}
