import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useEffect, useRef } from "react";
import app from "../Firebase/firebase";
const paypalCreateOrder = app.functions().httpsCallable("paypalCreateOrder");
const paypalHandleOrder = app.functions().httpsCallable("paypalHandleOrder");

export default function PaypalButtons({ currentUser, onPayed, price, discountCode }) {
  //STRANGE WORKAROUND NEEDED BECAUSE OTERWISE DATA WAS NOT TRANSFERED TO PAYPALBUTTONS
  const priceRef = useRef();
  useEffect(() => {
    priceRef.current = price;
  }, [price]);
  const discountCodeRef = useRef();
  useEffect(() => {
    discountCodeRef.current = discountCode;
  }, [discountCode]);

  return (
    <PayPalScriptProvider
      options={{
        "client-id":
          "AfwTlIrwCrV-mqTOQAtS4iiUFHlNDOU-90DCu8ldiVZ5nCq4dc8evIpSJm7YoU2WqwJ-_RhLzzfR-Z8T",
        currency: "EUR",
        intent: "capture",
      }}
    >
      <PayPalButtons
        style={{ width: "100%" }}
        createOrder={(data, actions) => {
          return paypalCreateOrder({ price: priceRef.current }).then((response) => {
            return response.data.id;
          });
        }}
        onApprove={(data, actions) => {
          paypalHandleOrder({
            orderId: data.orderID,
            userId: currentUser.uid,
            price: priceRef.current,
            discountCode: discountCodeRef.current !== "" ? discountCodeRef.current : "",
          }).then((data) => {
            onPayed();
          });
        }}
      />
    </PayPalScriptProvider>
  );
}
