import React from "react";
import { useTheme } from "@mui/material/styles";
import { TrackingBoxType } from "./TrackingBoxType";

export default function DayNumber({ day, scalingFactor, type }) {
  const theme = useTheme();
  const text = {
    fontSize: 16 * scalingFactor,
    userSelect: "none",
    fontWeight: "bold",
  };
  const gradientText = {
    ...text,
    backgroundImage: theme.palette.gradient.text,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  };
  const greyText = {
    ...text,
    color: theme.palette.grey.lightTextGrey,
  };

  return (
    <div
      style={{
        position: "absolute",
        marginTop: -12 * scalingFactor,
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      <div
        style={{
          borderRadius: 1000,
          width: 29 * scalingFactor,
          textAlign: "center",
          boxShadow: `0px ${4 * scalingFactor}px ${4 * scalingFactor}px rgba(0, 0, 0, 0.1)`,
          background: "white",
        }}
      >
        <div style={type === TrackingBoxType.placeholder ? greyText : gradientText}>{day}</div>
      </div>
    </div>
  );
}
