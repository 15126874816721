import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useAuth } from "./components/Firebase/Contexts/AuthContext";

//Different Routes
import LoginRoute from "./routes/LoginRoute";
import "./App.css";
import SortPlatformSongs from "./components/BackgroundMusic/upload/SortPlatformSongs";
import UploadVideosRoute from "./routes/UploadVideosRoute";
import SortSoundcloudSongs from "./components/BackgroundMusic/upload/SortSoundcloudSongs";
import UploadPlatformSong from "./components/BackgroundMusic/upload/UploadPlatformSong";
import UploadSoundcloudSong from "./components/BackgroundMusic/upload/UploadSoundcloudSong";
import ForgotPassword from "./components/Firebase/Authentication/ForgotPassword";
import Signup from "./components/Firebase/Authentication/Signup";
import NewPlayer from "./components/newplayer/Player";
import BackgroundSelectionRoute from "./routes/BackgroundSelectionRoute";
import BackroundMusicRoute from "./routes/BackroundMusicRoute";
import HeartedVideosRoute from "./routes/HeartedVideosRoute";
import KaleidoscopeRoute from "./routes/KaleidoscopeRoute";
import SegmentsRoute from "./routes/SegmentsRoute";
import SettingsRoute from "./routes/SettingsRoute";
import EmployeesRoute from "./routes/EmployeesRoute";
import EditorRoute from "./routes/EditorRoute";
import UploadImagesRoute from "./routes/UploadImagesRoute";
import GetYourAccountPage from "./components/payAccount/GetYourAccountPage";
import SoftwareWebinar from "./components/softwareWebinar";
import PaymentSuccessful from "./components/payAccount/PaymentSuccessful";
import VisionMoviesRoute from "./routes/VisionMoviesRoute";
import { useVisionMovieContext } from "./components/VisionMovieContext";
import VisionMovieGenerator from "./components/visionMovieGenerator";
import Player3 from "./components/player3/Player3";
import Player3Start from "./components/player3/Player3Start";
import Player4 from "./components/player4/Player";
import Start from "./components/player4/Start";
import ProgressSettingRoute from "./routes/ProgressSettingRoute";
import ProgressTrackingRoute from "./routes/ProgressTrackingRoute";
import SeeProgess from "./routes/SeeProgess";
import { useIsProAccount } from "./helperFunctions/useIsProAccount";

function App() {
  const proAccount = useIsProAccount();
  return (
    <>
      <Routes>
        <Route path="/webinar" element={<SoftwareWebinar />} />
        <Route path="/fortschritt" element={<ProgressSettingRoute />} />
        <Route path="/fortschrittmessen" element={<ProgressTrackingRoute />} />
        <Route path="/generator" element={<VisionMovieGenerator />} />
        <Route path="/getyouraccount" element={<GetYourAccountPage />} />
        <Route path="/paymentsuccessful" element={<PaymentSuccessful />} />
        <Route path="/newplayer" element={<NewPlayer />} />
        <Route path="/player3" element={<Player3 />} />
        <Route path="/player3start" element={<Player3Start />} />
        <Route path="/player4" element={<Player4 />} />
        <Route path="/player4start" element={<Start />} />
        <Route path="/login" element={<LoginRoute />} />
        <Route path="/signup333" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        {/* PRIVATE ROUTES */}
        <Route element={<PrivateOutlet />}>
          <Route path="/meinfortschritt" element={<SeeProgess />} />
        </Route>
        <Route element={<PrivateOutlet />}>
          <Route index element={<VisionMoviesRoute proAccount={proAccount} />} />
        </Route>
        <Route element={<PrivateOutlet />}>
          <Route path="/edit" element={<SegmentsRoute />} />
        </Route>
        <Route element={<PrivateOutlet />}>
          <Route path="/employees" element={<EmployeesRoute proAccount={proAccount} />} />
        </Route>
        <Route element={<PrivateOutlet />}>
          <Route path="/editor/:name/:id" element={<EditorRoute />} />
        </Route>
        <Route element={<PrivateOutlet />}>
          <Route path="/backgroundmusic" element={<BackroundMusicRoute />} />
        </Route>
        <Route element={<PrivateOutlet />}>
          <Route path="/sortsoundcloudsongs" element={<SortSoundcloudSongs />} />
        </Route>
        <Route element={<PrivateOutlet />}>
          <Route path="/sortplatformsongs" element={<SortPlatformSongs />} />
        </Route>
        <Route element={<PrivateOutlet />}>
          <Route path="/uploadsoundcloudsong" element={<UploadSoundcloudSong />} />
        </Route>
        <Route element={<PrivateOutlet />}>
          <Route path="/uploadplatformsong" element={<UploadPlatformSong />} />
        </Route>
        <Route element={<PrivateOutlet />}>
          <Route path="/kaleidoscope" element={<KaleidoscopeRoute />} />
        </Route>
        <Route element={<PrivateOutlet />}>
          <Route path="/backgroundselection" element={<BackgroundSelectionRoute />} />
        </Route>
        <Route element={<PrivateOutlet />}>
          <Route path="/backgroundselection/heartedvideos" element={<HeartedVideosRoute />} />
        </Route>
        <Route element={<PrivateOutlet />}>
          <Route path="/backgroundselection/allheartedvideos" element={<HeartedVideosRoute />} />
        </Route>
        <Route element={<PrivateOutlet />}>
          <Route path="/backgroundselection/uploadvideos" element={<UploadVideosRoute />} />
        </Route>
        <Route element={<PrivateOutlet />}>
          <Route path="/backgroundselection/uploadimages" element={<UploadImagesRoute />} />
        </Route>
        <Route element={<PrivateOutlet />}>
          <Route path="/settings" element={<SettingsRoute />} />
        </Route>
      </Routes>
      {
        //windowWidth < 1200 && <MobileNotAvailableYet />
      }
    </>
  );
}

function PrivateOutlet() {
  const auth = useAuth();
  return auth.currentUser ? <Outlet /> : <Navigate to="/login" />;
}

export default App;
