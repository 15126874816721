import { interpolate } from "./interpolate";
import { Easing } from "./Easing";

export function animationValueFunction(
  currentTime: number,
  showStatementAtSec: number,
  hideStatementAtSec: number,
  startToShowStatementOffset: number = 0,
  startToHideStatementOffset: number = 0,
  maxValue: number = 1,
  fadeInStatementDuration: number = 1,
  fadeOutStatementDuration: number = 1,
  startOffset: number = 0,
  showBezierFunction = Easing.bezier(0.8, 0.22, 0.96, 0.65),
  hideBezierFunction = Easing.bezier(0.8, 0.22, 0.96, 0.65)
) {
  //Helper functions to show and hide Affirmation
  const showAffirmationIncludingStartOffset = showStatementAtSec + startOffset;
  const hideAffirmationIncludingStartOffset = hideStatementAtSec + startOffset;
  function show() {
    return (
      currentTime > showAffirmationIncludingStartOffset &&
      currentTime < hideAffirmationIncludingStartOffset
    );
  }
  function hide() {
    return currentTime > hideAffirmationIncludingStartOffset;
  }

  var currentValue = 0;
  //Show
  if (show()) {
    currentValue = interpolate(
      currentTime,
      [
        showAffirmationIncludingStartOffset + startToShowStatementOffset,
        showAffirmationIncludingStartOffset + startToShowStatementOffset + fadeInStatementDuration,
      ],
      [0, maxValue],
      {
        easing: showBezierFunction,
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
      }
    );
  }

  //Hide
  if (hide()) {
    currentValue = interpolate(
      currentTime,
      [
        hideAffirmationIncludingStartOffset + startToHideStatementOffset,
        hideAffirmationIncludingStartOffset + startToHideStatementOffset + fadeOutStatementDuration,
      ],
      [maxValue, 0],
      {
        easing: hideBezierFunction,
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
      }
    );
  }
  return currentValue;
}
