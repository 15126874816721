import { Statement } from "typescript";
import { KaleidoscopeVideo, VisionMovie } from "./interfaces/VisionMovie";
import { ImageClip, VideoClip, VisionMovieSegment } from "./interfaces/VisionMovieSegment";

export const exampleImageSegment: VisionMovieSegment = {
  contrastLayerOpacityValue: 0.3,
  id: "e2-4446-8cd5-3dc6e46ebb5d",
  statement: {
    header: "I WILL",
    hideAffirmationAtSec: 6,
    paragraph: "do XYZ ",
    showAffirmationAtSec: 1,
  },
  imageClip: {
    showingDurationInSec: 10,
    url: "https://www.pennlive.com/resizer/yLUMG51sJ9nTEKD6MBSaPAFo0c4=/1280x0/smart/cloudfront-us-east-1.images.arcpublishing.com/advancelocal/CYL7QDPNWNBJREHCTGY2TB525M.jpg",
    cropParameters: {
      zoom: 1,
      crop: { x: 0, y: 42 },
      croppedArea: {
        height: 81.19525047905832,
        width: 100,
        x: 0,
        y: 2.2704149308100647,
      },
      croppedAreaPixels: {
        height: 2966,
        width: 5273,
        x: 0,
        y: 0,
      },
    },
  },
};

const exampleVideoSegment1: VisionMovieSegment = {
  contrastLayerOpacityValue: 0.2,
  id: "asdfasdf",
  statement: {
    header: "I WILL",
    hideAffirmationAtSec: 6,
    paragraph:
      "custom newline\nthis is a really really really really really really really really really long text ",
    showAffirmationAtSec: 1,
  },
  videoClip: {
    availableVideoFilesFromAPI: [
      {
        fileType: "video/mp4",
        height: 1080,
        quality: "hd",
        videoLink:
          "https://player.vimeo.com/external/422176234.hd.mp4?s=8180e27edd4ef747f5453533baadbe1a2f05fdf0&profile_id=175&oauth2_token_id=57447761",
        width: 1920,
      },
    ],
    durationOfVideoFileInSec: 35,
    placeholder: undefined,
    showingDurationInSec: 10,
    startOffsetInSec: 0,
  },
};

const exampleVideoSegment2: VisionMovieSegment = {
  contrastLayerOpacityValue: 0.2,
  id: "asdfasdf",
  statement: {
    header: "I WILL",
    hideAffirmationAtSec: 6,
    paragraph: "do XYZ ",
    showAffirmationAtSec: 1,
  },
  videoClip: {
    availableVideoFilesFromAPI: [
      {
        fileType: "video/mp4",
        height: 1080,
        quality: "hd",
        videoLink:
          "https://player.vimeo.com/external/517816974.hd.mp4?s=058e8cd79b26b12c416361e97f7c9ec751eb8cfa&profile_id=175&oauth2_token_id=57447761",
        width: 1920,
      },
    ],
    durationOfVideoFileInSec: 35,
    placeholder: undefined,
    showingDurationInSec: 10,
    startOffsetInSec: 0,
  },
};

const exampleVideoSegment3: VisionMovieSegment = {
  contrastLayerOpacityValue: 0.2,
  id: "asdfasdf",
  statement: {
    header: "I WILL",
    hideAffirmationAtSec: 6,
    paragraph: "do XYZ ",
    showAffirmationAtSec: 1,
  },
  videoClip: {
    availableVideoFilesFromAPI: [
      {
        fileType: "video/mp4",
        height: 1080,
        quality: "hd",
        videoLink:
          "https://player.vimeo.com/external/387693095.hd.mp4?s=6ba7101498a78d8f833f6e44e1cf2246423ae022&profile_id=175&oauth2_token_id=57447761",
        width: 1920,
      },
    ],
    durationOfVideoFileInSec: 35,
    placeholder: undefined,
    showingDurationInSec: 10,
    startOffsetInSec: 0,
  },
};

export const exampleKaleidoscopeSegment = {
  contrastLayerOpacityValue: 0.0,
  id: "asdfasdf",
  statement: {
    header: "",
    hideAffirmationAtSec: 6,
    paragraph: "",
    showAffirmationAtSec: 1,
  },
  videoClip: {
    isKaleidoscope: true,
    availableVideoFilesFromAPI: [
      {
        fileType: "video/mp4",
        height: 1080,
        quality: "hd",
        videoLink:
          "https://player.vimeo.com/external/219330776.hd.mp4?s=be98d6c252f7d595cd9e9a2c7d8372600f0bf880&profile_id=119&oauth2_token_id=57447761",
        width: 1920,
      },
    ],
    durationOfVideoFileInSec: 35,
    placeholder: undefined,
    showingDurationInSec: 10,
    startOffsetInSec: 0,
  },
};

export const exampleVisionMovieWithKaleidoscope: VisionMovie = {
  audio: {
    audioDuration: "119",
    audioUrl:
      "https://firebasestorage.googleapis.com/v0/b/visionmovie-94a95.appspot.com/o/music%2FplatformSongs%2Fmp3Files%2Fsong5.mp3?alt=media&token=b50a2c6e-1125-48bc-8381-f4bf369e87e2",
    segmentsCoverSong: false,
  },
  segments: [
    exampleImageSegment,
    exampleVideoSegment3,
    exampleKaleidoscopeSegment,
    /*exampleVideoSegment1,
    //exampleKaleidoscopeSegment,
    exampleImageSegment,
    exampleVideoSegment3,
    exampleImageSegment,
    exampleVideoSegment1,
    exampleKaleidoscopeSegment,*/
  ],
};
