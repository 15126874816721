import Button from "@mui/material/Button";
import UploadIcon from "@mui/icons-material/CloudUploadRounded";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useBackgroundSelectionContext } from "../backgroundSelection/BackgroundSelectionContext";
import { useTranslation } from "react-i18next";

export default function UploadVideosButton() {
  const currentPathname = useLocation().pathname;
  const { rememberBackgroundSelectionListScrollPosition } = useBackgroundSelectionContext();
  const { t } = useTranslation();

  return (
    <Link to="/backgroundselection/uploadvideos">
      <Button
        style={{ textTransform: "none", borderRadius: "6px", fontWeight: "bold" }}
        variant="contained"
        disableElevation
        startIcon={<UploadIcon />}
        onClick={() => {
          if (currentPathname === "/backgroundselection")
            rememberBackgroundSelectionListScrollPosition();
        }}
      >
        {t("videos")}
      </Button>
    </Link>
  );
}
