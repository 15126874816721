import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import DayNumber from "./DayNumber";
import Stack from "@mui/material/Stack";
import { TrackingBoxType } from "./TrackingBoxType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../Firebase/Contexts/AuthContext";
import dayjs from "dayjs";
import recordAndStoreToDoMeasurement from "./recordAndStoreToDoMeasurement";
import { useElementOnScreen } from "./useElementOnScreen";
import { rowMarginParameters } from "./rowMarginParameters";

const borderRadius = 10;

export default function DoneNotDoneButton({
  index,
  toDo,
  notDoneAvailable,
  scalingFactor,
  height,
  scrollContainer,
  unlockScrolling,
  setActiveToDoIndex,
}) {
  const theme = useTheme();
  const { currentUser } = useAuth();

  const lastMeasurement = toDo?.measurements?.slice(-1)[0];
  const lastMeasurementDayJsObj = lastMeasurement
    ? dayjs(lastMeasurement.date).add(1, "day")
    : dayjs(toDo.startTrackingDate);
  const dateNumber = lastMeasurementDayJsObj.date();
  const daysInMonth = lastMeasurementDayJsObj.daysInMonth();
  const day = dateNumber <= daysInMonth ? dateNumber : 1;

  function handleClick(trackingBoxType, value) {
    recordAndStoreToDoMeasurement(currentUser, toDo, trackingBoxType, value, setActiveToDoIndex);
  }

  return (
    <Stack
      //ref={buttonRef}
      justifyContent="center"
      alignItems="center"
      direction="row"
      style={{
        position: "relative",
        marginTop: rowMarginParameters.marginTopNOTFirstRow * scalingFactor,
        /*   index <= 6
            ? rowMarginParameters.marginTopFirstRow * scalingFactor
            : rowMarginParameters.marginTopNOTFirstRow * scalingFactor, */
        paddingLeft: 8 * scalingFactor,
        height: 73 * scalingFactor,
      }}
    >
      <div style={{ position: "absolute", top: 0 }}>
        <DayNumber day={day} scalingFactor={scalingFactor} />
      </div>

      {notDoneAvailable && (
        <>
          <Stack
            onClick={() => handleClick(TrackingBoxType.missed, false)}
            justifyContent="center"
            alignItems="center"
            style={{
              background: theme.palette.gradient.text,
              borderRadius: `${borderRadius * scalingFactor}px 0px 0px ${
                borderRadius * scalingFactor
              }px`,
              width: 65 * scalingFactor,
              height: "80%",
              cursor: "pointer",
            }}
          >
            <img
              style={{
                width: 48 * scalingFactor,
                marginTop: 12 * scalingFactor,
                userSelect: "none",
              }}
              src={"../../../thumbsDown.svg"}
              alt="-"
            />
          </Stack>
          <div style={{ width: 5 * scalingFactor, height: "100%" }} />
          <Stack
            onClick={() => handleClick(TrackingBoxType.done, true)}
            justifyContent="center"
            alignItems="center"
            style={{
              background: theme.palette.green.gradient,
              borderRadius: `0px ${borderRadius * scalingFactor}px ${
                borderRadius * scalingFactor
              }px 0px`,
              width: 65 * scalingFactor,
              height: "80%",
              cursor: "pointer",
            }}
          >
            <img
              style={{
                width: 48 * scalingFactor,
                marginTop: 8 * scalingFactor,
                userSelect: "none",
              }}
              src={"../../../thumbsUp.svg"}
              alt="-"
            />
          </Stack>
        </>
      )}
      {!notDoneAvailable && (
        <>
          <Stack
            onClick={() => handleClick(TrackingBoxType.done, true)}
            justifyContent="center"
            alignItems="center"
            style={{
              background: theme.palette.green.gradient,
              borderRadius: `${borderRadius * scalingFactor}px 0px 0px ${
                borderRadius * scalingFactor
              }px`,
              width: 65 * scalingFactor,
              height: "80%",
              cursor: "pointer",
            }}
          >
            <img
              style={{
                width: 48 * scalingFactor,
                marginTop: 8 * scalingFactor,
                userSelect: "none",
              }}
              src={"../../../thumbsUp.svg"}
              alt="-"
            />
          </Stack>
          <div style={{ width: 5 * scalingFactor, height: "100%" }} />
          <Stack
            onClick={() => handleClick(TrackingBoxType.empty, null)}
            justifyContent="center"
            alignItems="center"
            direction="row"
            style={{
              background: "white",
              borderColor: "#E0E0E0",
              borderWidth: 2 * scalingFactor,
              borderStyle: "solid",
              borderRadius: `0px ${borderRadius * scalingFactor}px ${
                borderRadius * scalingFactor
              }px 0px`,
              width: 65 * scalingFactor,
              height: "80%",
              cursor: "pointer",
            }}
          >
            <FontAwesomeIcon
              style={{ fontSize: 35 * scalingFactor }}
              icon={faArrowRight}
              color={theme.palette.grey.medium}
            />
          </Stack>
        </>
      )}
    </Stack>
  );
}
