import React, { useEffect, useRef } from "react";
import { VideoPart } from "./SoftwareWebinarInterfaces";

export default function VideoPartComponent({
  firstPart,
  forMobileDevicesVideo = false,
  part,
  startPart,
  onFinishedPart,
}: {
  firstPart?: boolean;
  forMobileDevicesVideo?: boolean;
  part: VideoPart;
  startPart?: any;
  onFinishedPart: () => void;
}) {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const prePlaying = useRef<boolean>(true);

  useEffect(() => {
    if (videoRef.current) {
      //Pass play video function to parent component and then to StartOverlay.tsx
      if (firstPart) {
        startPart.current = () => {
          if (videoRef.current) {
            //Needed when webinar muted playing video___
            prePlaying.current = false;
            videoRef.current.currentTime = 0;
            videoRef.current.style.filter = `brightness(1)`;
            videoRef.current.muted = false;
            videoRef.current.loop = false;
          }
        };
      }
      //Is next part -> play directly
      if (!firstPart) {
        prePlaying.current = false;
        videoRef.current.style.filter = `brightness(1)`;
        videoRef.current.muted = false;
        videoRef.current.loop = false;
        videoRef.current.play();
      }
      //Go to next part when this one is finished
      videoRef.current.addEventListener("ended", () => {
        console.log("ENDED");
        !prePlaying.current && !forMobileDevicesVideo && onFinishedPart();
      });
    }
    return () => {
      if (videoRef.current)
        videoRef.current.removeEventListener("ended", () => {
          !prePlaying.current && !forMobileDevicesVideo && onFinishedPart();
        });
    };
  }, [videoRef.current]);

  return (
    <div style={{ background: "black", width: "100vw", height: "100vh" }}>
      <video
        muted
        autoPlay
        loop
        ref={videoRef}
        style={{ width: "100%", height: "100%", filter: "brightness(0.4)" }}
        src={part.url}
      />
    </div>
  );
}
