import { useEffect, useState } from "react";

export default function useSegmentsCoverSong(songLength, currentVideoLength) {
  const [segmentsCoverSong, setSegmentsCoverSong] = useState(false);

  //Check if segments cover song
  const secSegmentsAreAllowedToBeShorterThanSongDuration = 3;

  useEffect(() => {
    if (
      currentVideoLength >= songLength - secSegmentsAreAllowedToBeShorterThanSongDuration &&
      currentVideoLength - 0.5 <= songLength
    ) {
      setSegmentsCoverSong(true);
    } else {
      setSegmentsCoverSong(false);
    }
  }, [currentVideoLength, songLength]);

  return segmentsCoverSong;
}
