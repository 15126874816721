import React from "react";
import { useWebinarContext } from "../softwareWebinar/WebinarContext";

export default function Player3Start() {
  const navigate = useWebinarContext().navigate_WebinarWrapper;
  return (
    <div>
      HELLO
      <button onClick={() => navigate("/player3")}>PLAYER STARTEN</button>
    </div>
  );
}
