import { VisionMovie } from "../VisionMovie";

export default function transformDataIntoPlayerDataFormat(
  visionMovieContextOutput: any
): VisionMovie {
  const visionMovieData: any = visionMovieContextOutput.visionMovieData;
  const visionMovieDataInPlayerFormat: VisionMovie = {
    audio: {
      audioUrl: visionMovieData.backgroundMusic?.storageLink,
      audioDuration: visionMovieData.backgroundMusic?.duration,
      segmentsCoverSong: visionMovieContextOutput.segmentsCoverSong,
    },
    segments: [
      ...visionMovieData.visionMovie.map((segment: any) => {
        if (segment?.video) {
          return {
            id: segment.id,
            statement: {
              header: segment.affirmation.header,
              paragraph: segment.affirmation.paragraph,
              showAffirmationAtSec: segment.affirmation.showAffirmation,
              hideAffirmationAtSec: segment.affirmation.hideAffirmation,
            },
            contrastLayerOpacityValue: segment.contrastLayerOpacityValue,
            videoClip: {
              placeholder: segment.video?.placeholder,
              durationOfVideoFileInSec: segment.video.duration,
              showingDurationInSec: segment.video.showingDuration,
              startOffsetInSec: segment.video.startOffset,
              availableVideoFilesFromAPI: [
                ...segment.video.video_files.map((file: any) => {
                  return {
                    height: file.height,
                    width: file.width,
                    videoLink: file.link,
                    fileType: file.file_type,
                    quality: file.quality,
                  };
                }),
              ],
            },
          };
        }
        if (segment?.image) {
          return {
            id: segment.id,
            statement: {
              header: segment.affirmation.header,
              paragraph: segment.affirmation.paragraph,
              showAffirmationAtSec: segment.affirmation.showAffirmation,
              hideAffirmationAtSec: segment.affirmation.hideAffirmation,
            },
            contrastLayerOpacityValue: segment.contrastLayerOpacityValue,
            imageClip: {
              showingDurationInSec: segment.image.showingDuration,
              url: segment.image.image,
              cropParameters: segment.image.transformDataObj,
            },
          };
        }
      }),
    ],
  };

  return visionMovieDataInPlayerFormat;
}

export interface ImageClip {
  showingDurationInSec: number;
  url: string;
  cropParameters?: CropParameters;
}

interface CropParameters {
  crop: {
    x: number;
    y: number;
  };
  croppedArea: {
    height: number;
    width: number;
    x: number;
    y: number;
  };
  croppedAreaPixels: {
    height: number;
    width: number;
    x: number;
    y: number;
  };
  zoom: number;
}
