import Button from "@mui/material/Button";

export default function BackgroundMusicButton({ children, onClick }) {
  return (
    <Button
      onClick={onClick}
      style={{
        textTransform: "none",
        borderRadius: "6px",
        fontWeight: "bold",
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 10,
        paddingRight: 10,
      }}
      variant="outlined"
      disableElevation
    >
      {children}
    </Button>
  );
}
