import React, { useEffect, useRef } from "react";
import { Whisper, Popover } from "rsuite";
import { Action } from "./Actions";
import "rsuite/dist/rsuite.css";
import { useWebinarContext } from "./WebinarContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

export default function WebinarButtonWrapper({
  actionType,
  children,
}: {
  actionType: Action;
  children: any;
}) {
  const actionEnabled = useWebinarContext()?.actionEnabled;
  const showOverlay = actionEnabled && !actionEnabled(actionType);
  return <>{showOverlay ? <Overlay>{children}</Overlay> : children}</>;
}

function Overlay({ children }: { children: any }) {
  const currentPathname = useLocation().pathname;
  const theme = useTheme();
  const navigate = useNavigate();

  const whisperRef = useRef<any>();
  const timeoutId = useRef<any>();

  function triggerClosePopover() {
    if (whisperRef.current) {
      timeoutId.current = setTimeout(
        () => {
          whisperRef.current.close();
        },
        currentPathname === "/webinar" ? 4500 : 8000
      );
    }
  }

  useEffect(() => {
    return () => {
      if (timeoutId.current) clearTimeout(timeoutId.current);
    };
  }, []);

  const overlayDefaultText = "I disabled this button for this part of the webinar";
  const webinarSpeaker = <Popover title={overlayDefaultText} style={{ width: 200 }}></Popover>;

  const overlayTrialAccountText = "Get your premium account and support us to touch more lives";
  const trialAccountSpeaker = (
    <Popover style={{ width: 300 }}>
      <div style={{ marginBottom: 8 }}>
        <div style={{ display: "inline", fontWeight: "bold", fontSize: 14 }}>
          {overlayTrialAccountText}
        </div>
        <FontAwesomeIcon
          style={{
            display: "inline",
            marginLeft: 5,
            fontSize: 14,
            color: theme.palette.primary.main,
          }}
          icon={faHeart}
        />
      </div>
      <button
        style={{
          borderRadius: 4,
          fontWeight: "bold",
          color: "white",
          paddingTop: 4,
          paddingBottom: 4,
          paddingLeft: 12,
          paddingRight: 12,
          background: theme.palette.primary.main,
        }}
        onClick={() => {
          navigate("/getyouraccount");
        }}
      >
        support now
      </button>
    </Popover>
  );

  return (
    <Whisper
      ref={whisperRef}
      onOpen={triggerClosePopover}
      onClose={() => {
        if (timeoutId.current) clearTimeout(timeoutId.current);
      }}
      preventOverflow
      trigger="click"
      controlId="control-id-container"
      speaker={currentPathname === "/webinar" ? webinarSpeaker : trialAccountSpeaker}
      placement="auto"
    >
      {children}
    </Whisper>
  );
}
