import { useWebinarContext } from "./WebinarContext";
import Signup from "../Firebase/Authentication/Signup";
import SegmentsRoute from "../../routes/SegmentsRoute";
import BackgroundSelectionRoute from "../../routes/BackgroundSelectionRoute";
import HeartedVideosRoute from "../../routes/HeartedVideosRoute";
import UploadVideosRoute from "../../routes/UploadVideosRoute";
import SettingsRoute from "../../routes/SettingsRoute";
import NewPlayer from "../newplayer/Player";

export default function SoftwareComponent({ onFinishedPart }: { onFinishedPart: () => void }) {
  const routeString = useWebinarContext()?.routeString;

  console.log("routeString: ", routeString);
  switch (routeString) {
    case "/signup":
      return <Signup createdAccount={onFinishedPart} />;
    case "/":
      return <SegmentsRoute />;
    case "/newplayer":
      return <NewPlayer />;
    case "/backgroundselection":
      return <BackgroundSelectionRoute />;
    case "/backgroundselection/heartedvideos":
      return <HeartedVideosRoute />;
    case "/backgroundselection/uploadvideos":
      return <UploadVideosRoute />;
    case "/settings":
      return <SettingsRoute />;
    default:
      return null;
  }
}
