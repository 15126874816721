import * as React from "react";
import shaka from "shaka-player";
import { PlayerToDo, PlayerLoadingState } from "./ShakaVideoTypes";
import Statement from "./Statement/Statement";
import { animationValueFunction } from "./Statement/animationValueFunction";
import _ from "lodash";
const debug = false;

export class ShakaVideo extends React.Component {
  player = null;
  videoShown = false;
  showStatement = false;
  lineWidth;
  url;
  duration;
  statementData;
  videoIndex;
  playingInterval;
  bufferingInterval;
  opacity;
  shallFadeOutVideo = false;
  shallFadeOutAudio = false;
  shallFadeInVideo = false;
  videoFinished = false;
  containerRef = React.createRef(null);
  videoElementRef = React.createRef(null);
  headerRef = React.createRef(null);
  lineRef = React.createRef(null);
  paragraphRef = React.createRef(null);
  audioRef;
  isKaleidoscopeVideo = false;

  playerToDo = PlayerToDo.None;
  playerLoadingState = PlayerLoadingState.NotStartedLoading;

  //Custom events created
  //Note these need to be tied to the video DOM element not the class component
  //Hence add dispatch Events and in the consuming components EventListeners to: ReactDOM.findDOMNode(this)
  playerLoadedEvent = new Event("playerLoaded");
  videoStartsPlayingEvent = new Event("videoStartsPlaying");
  completelyBufferedEvent = new Event("completelyBuffered");
  waitingForDataEvent = new Event("waitingForData");
  playingAfterWaitingForDataEvent = new Event("playingAfterWaitingForData");
  nextVideoGetReady = new Event("nextVideoGetReady");
  reachedDurationEvent = new Event("reachedDuration");
  playingAndReadyToBeShown = new Event("playingAndReadyToBeShown");
  stoppedPlayingAfterFinished = new Event("stoppedPlayingAfterFinished");
  fadeoutAudio = new Event("fadeoutAudio");

  constructor(props) {
    super(props);
    this.videoIndex = props.videoIndex;
    this.url = props.videoObj.url;
    this.duration = props.videoObj.duration;
    this.isKaleidoscopeVideo = props.videoObj.kaleidoscope;
    this.statementData = props.videoObj.statement;
    this.audioRef = props.audioRef;
  }

  componentDidMount() {
    // Install built-in polyfills to patch browser incompatibilities.
    if (this.videoIndex === 0) {
      shaka.polyfill.installAll();
      this.loadPlayer();
      //Trigger play video element on Pageload. LoadingPlayer might take too long and browser
      //might then block playing unmuted video programatically at this point in time.
      let videoElem = this.videoElementRef.current;
      videoElem.play();
    }
  }

  //Cancel all intervals when element is removed / happens when player is closed
  componentWillUnmount() {
    clearInterval(this.bufferingInterval);
    clearInterval(this.playingInterval);
  }

  render() {
    //console.log("video renders: " + this.videoIndex + " " + this.playerToDo);
    return (
      <div ref={this.containerRef} style={{ opacity: 0 }}>
        {!this.props.videoObj.kaleidoscope && (
          <Statement
            headerRef={this.headerRef}
            lineRef={this.lineRef}
            paragraphRef={this.paragraphRef}
            statementData={this.statementData}
            scalingFactor={this.props.scalingFactor}
          />
        )}
        <video
          style={{ position: "absolute", top: 0, left: 0 }}
          ref={this.videoElementRef}
          width="100%"
          height="100%"
          controls={false}
          preload="auto"
          muted={
            this.props.videoObj.kaleidoscope && this.playerToDo === PlayerToDo.Play ? false : true
          }
        />
      </div>
    );
  }

  //__PLAYER SETUP______
  initPlayer = () => {
    this.player = new shaka.Player(this.videoElementRef.current);
    // Listen for error events.
    this.player.addEventListener("error", this.onErrorEvent);
    this.player.configure({
      streaming: {
        bufferingGoal: this.duration,
        rebufferingGoal: 2,
      },
    });
  };

  //Initial loading of video
  loadVideo = () => {
    this.player
      .load(this.url)
      .then(() => {
        // This runs if the asynchronous load is successful.
        debug && console.log("The video: ", this.videoIndex, " has now been loaded!");
        this.playerLoadingState = PlayerLoadingState.Loaded;
        this.videoElementRef.current?.dispatchEvent(this.playerLoadedEvent);
      })
      .catch(this.onError);
  };

  //Completely sets up Player and:
  // 1) sets playerLoadingState = PlayerLoadingState.Loading
  // 2) Triggers playerLoadedEvent
  loadPlayer = () => {
    this.playerLoadingState = PlayerLoadingState.Loading;
    this.initPlayer();
    this.loadVideo();
  };

  //END__PLAYER SETUP______
  //__HELPER FUNCTION TO EXECUTE ONLY RIGHT PLAYERTODO AND ONLY AFTER PLAYER LOADED______
  //This functions makes sure that handleBuffer(), handleGetReady(), handlePlay() only get executed after player is loaded.
  //If waiting
  executeAfterPlayerLoaded = (func, toBeExecuteToDo) => {
    const eventManager = this.videoElementRef.current;
    if (this.playerLoadingState === PlayerLoadingState.Loading) {
      //Listen to eventListener Loaded
      eventManager?.addEventListener(
        "playerLoaded",
        () => {
          if (toBeExecuteToDo === this.playerToDo) func();
        },
        {
          once: true,
        }
      );
    }
    if (this.playerLoadingState === PlayerLoadingState.NotStartedLoading) {
      //Load Player
      this.loadPlayer();
      //Listen to eventListener Loaded
      eventManager?.addEventListener(
        "playerLoaded",
        () => {
          if (toBeExecuteToDo === this.playerToDo) func();
        },
        { once: true }
      );
    }
    if (this.playerLoadingState === PlayerLoadingState.Loaded) {
      //execute func
      if (toBeExecuteToDo === this.playerToDo) func();
    }
  };
  //END__HELPER FUNCTION TO EXECUTE ONLY RIGHT PLAYERTODO AND ONLY AFTER PLAYER LOADED______

  //__BUFFERING VIDEO IF NEXT VIDEO AND FORMER VIDEO FULLY BUFFERED______
  //For init Player, load Player for first video + buffer videos with preplay mechanism
  bufferVideo = () => {
    if (this.playerToDo === PlayerToDo.None) {
      this.playerToDo = PlayerToDo.Buffer;
    }
    this.executeAfterPlayerLoaded(this.handleBufferVideo, PlayerToDo.Buffer);
  };
  handleBufferVideo = () => {
    console.log("start buffering process for video: ", this.videoIndex);
    let videoElem = this.videoElementRef.current;
    videoElem.play();
    debug && console.log("setting up buffer Interval for video: ", this.videoIndex);
    this.bufferingInterval = setInterval(() => {
      const bufferingDuration = videoElem.buffered.end(0);
      //console.log("video: ", this.videoIndex, " buffers interval run");
      if (bufferingDuration > this.duration) {
        videoElem.pause();
        console.log("Video: ", this.videoIndex, " fully buffered -> pause");
        clearInterval(this.bufferingInterval);
      }
    }, 1000);
  };
  //END__BUFFERING VIDEO IF NEXT VIDEO AND FORMER VIDEO FULLY BUFFERED______

  //__MAKE VIDEO READY FOR PLAYBACK______
  //This is needed because browser needs a bit of time to reset video to currentTime = 0
  getReadyForPlayback = () => {
    debug && console.log("clear Interval in getReady: ");
    debug && console.log(this.bufferingInterval);
    clearInterval(this.bufferingInterval);
    if (this.playerToDo === PlayerToDo.None || this.playerToDo === PlayerToDo.Buffer) {
      this.playerToDo = PlayerToDo.GetReady;
    }
    this.executeAfterPlayerLoaded(this.handleGetReadyForPlayback, PlayerToDo.GetReady);
  };
  handleGetReadyForPlayback = () => {
    let videoElem = this.videoElementRef.current;
    videoElem.pause();
    videoElem.currentTime = 0;
    debug &&
      console.log(
        "video: ",
        this.videoIndex,
        " ready for playback + back to currentTime = 0 + pause"
      );
    debug && console.log("Cleared buffer interval for video: ", this.videoIndex);
  };
  //END__MAKE VIDEO READY FOR PLAYBACK______

  //__PLAY NEW VIDEO______
  play = () => {
    debug && console.log("clear Interval in play: ");
    debug && console.log(this.bufferingInterval);
    clearInterval(this.bufferingInterval);
    this.playerToDo = PlayerToDo.Play;
    this.executeAfterPlayerLoaded(this.handlePlay, PlayerToDo.Play);
  };
  handlePlay = () => {
    debug && console.log("PLAY");
    const videoElem = this.videoElementRef.current;
    videoElem.play();
    videoElem.addEventListener(
      "timeupdate",
      () => {
        debug && console.log("video: ", this.videoIndex, " now playing");
        this.videoElementRef.current?.dispatchEvent(this.videoStartsPlayingEvent);
        this.videoElementRef.current?.dispatchEvent(this.playingAndReadyToBeShown);
        this.initTimeEventsInterval();
        clearInterval(this.bufferingInterval);
      },
      { once: true }
    );
  };

  //Variables for handleLoading function
  lastCurrentTime = 0;
  timesNoCurrentTimeChange = 0;
  videoLoading = false;

  initTimeEventsInterval = () => {
    const videoElem = this.videoElementRef.current;

    const handleLoading = (currentTime) => {
      if (currentTime > this.lastCurrentTime) {
        this.lastCurrentTime = currentTime;
        if (this.timesNoCurrentTimeChange >= 4) {
          //RESTARTS PLAYING
          //Trigger waitingForData event with false
          this.videoElementRef.current?.dispatchEvent(this.playingAfterWaitingForDataEvent);
          this.videoLoading = false;
        }
        this.timesNoCurrentTimeChange = 0;
      }
      //Count how many times currentTime didn't change
      if (currentTime === this.lastCurrentTime && currentTime < this.duration) {
        this.timesNoCurrentTimeChange = this.timesNoCurrentTimeChange + 1;
      }

      //If time didn't change for 2 frames -> trigger loading
      if (!this.videoFinished && !this.videoLoading && this.timesNoCurrentTimeChange >= 4) {
        //NOW LOADING
        this.videoLoading = true;
        //Trigger waitingForData event with true
        this.videoElementRef.current?.dispatchEvent(this.waitingForDataEvent);
      }
    };

    const videoCompletelyBuffered = () => {
      const bufferingDuration = videoElem.buffered.end(0);
      if (bufferingDuration > this.duration) {
        this.videoElementRef.current?.dispatchEvent(this.completelyBufferedEvent);
      }
    };

    const nextVideoGetReady = (currentTime) => {
      //Important note! Needs to be smaller than rebufferingGoal: 1 in player config. Otherwise a bug is introduced
      const timeNextVideoHasToBeResetToInitialPlaybackTime_Sec = 0.49;
      if (currentTime >= this.duration - timeNextVideoHasToBeResetToInitialPlaybackTime_Sec) {
        this.videoElementRef.current?.dispatchEvent(this.nextVideoGetReady);
      }
    };

    const checkIfDurationReached = (currentTime) => {
      if (currentTime >= this.duration) {
        this.videoElementRef.current?.dispatchEvent(this.reachedDurationEvent);
      }
    };

    //TODO: TO BE IMPLEMENTED STILL
    //Stoped playing because no switch occured
    /* const videoStopsPlayingAfterDurationPlus = (currentTime: number) => {
      let timeVideoPlaysLongerIfPossible = 1.5;
      if (currentTime >= this.duration + timeVideoPlaysLongerIfPossible) {
        ReactDOM.findDOMNode(this).dispatchEvent(this.reachedDurationEvent);
      }
    }; */

    const fadeInVideo = (currentTime) => {
      const startOffset = 0; //can later come from data, if available
      const videoFadeLengthInSec = 3;
      if (currentTime <= videoFadeLengthInSec + startOffset) {
        debug && console.log("fade in video now");
        const opacity = _.clamp((currentTime - startOffset) / videoFadeLengthInSec, 0, 1);
        this.containerRef.current.style.opacity = opacity;
      }
    };

    const fadeOutVideo = (currentTime) => {
      const startOffset = 0; //can later come from data, if available
      const videoFadeLengthInSec = 5;
      if (currentTime >= this.duration - videoFadeLengthInSec) {
        debug && console.log("fade out video now");
        this.containerRef.current.style.opacity = _.clamp(
          (this.duration + startOffset - currentTime) / videoFadeLengthInSec,
          0,
          1
        );
      }
    };

    //__FADEOUT AUDIO EVENT______
    const fadeOutAudio = (currentTime) => {
      const audioFadeLengthInSec = 5;
      if (currentTime >= this.duration - audioFadeLengthInSec) {
        const startOffset = 0;
        const volume = _.clamp(
          (this.duration + startOffset - currentTime) / audioFadeLengthInSec,
          0,
          1
        );
        this.audioRef.current.volume = volume;
        if (volume === 0) {
          this.audioRef.current.pause();
          //Reset, if it is being played after another kaleidoscope video
          this.audioRef.current.volume = 1;
          this.audioRef.current.currentTime = 0;
        }
      }
    };
    //END__FADEOUT AUDIO EVENT______

    //ANIMATE STATEMENT CONTROLER_____
    const animateStatementController = (currentTime) => {
      //change opacity of heradeRef.current
      this.headerRef.current.style.opacity = animationValueFunction(
        currentTime,
        this.statementData.showAffirmationAtSec,
        this.statementData.hideAffirmationAtSec
      );
      this.paragraphRef.current.style.opacity = animationValueFunction(
        currentTime,
        this.statementData.showAffirmationAtSec,
        this.statementData.hideAffirmationAtSec
      );

      //Determine (max) width of line
      const headerWidth = this.headerRef.current?.getBoundingClientRect().width;
      const lengthOfLineForIAM = 281;
      const lineWidth =
        lengthOfLineForIAM * this.props.scalingFactor < headerWidth
          ? lengthOfLineForIAM * this.props.scalingFactor
          : headerWidth;

      this.lineRef.current.style.width = `${animationValueFunction(
        currentTime,
        this.statementData.showAffirmationAtSec,
        this.statementData.hideAffirmationAtSec,
        1,
        0.5,
        lineWidth
      )}px`;
      this.lineRef.current.style.opacity = animationValueFunction(
        currentTime,
        this.statementData.showAffirmationAtSec,
        this.statementData.hideAffirmationAtSec,
        1,
        0.5
      );
    };
    //END__ANIMATE STATEMENT CONTROLER_____

    this.playingInterval = setInterval(() => {
      let currentTime = videoElem.currentTime;
      handleLoading(currentTime);
      nextVideoGetReady(currentTime); //TODO: kann auf nur einmal feuern reduziert werden
      checkIfDurationReached(currentTime); //TODO: kann auf nur einmal feuern reduziert werden
      !this.isKaleidoscopeVideo && animateStatementController(currentTime);
      !this.isKaleidoscopeVideo && this.shallFadeInVideo && fadeInVideo(currentTime);
      this.shallFadeOutVideo && fadeOutVideo(currentTime);
      this.shallFadeOutAudio && fadeOutAudio(currentTime);
      //videoStopsPlayingAfterDurationPlus(currentTime);
      videoCompletelyBuffered(); //TODO: kann auf nur einmal feuern reduziert werden
    }, 1000 / 30);
  };
  //END__PLAY NEW VIDEO______

  //__SHOW VIDEO WHEN BECOMING ACTIVE VIDEO______
  show = () => {
    this.containerRef.current.style.opacity = 1;
  };

  //__GETTER: CURRENT TIME (e.g. can be used by affirmation component)______
  getCurrentTime = () => {
    const video = this.videoElementRef.current;
    return video.currentTime;
  };

  notifyThatItShallFadeInVideo = () => {
    this.shallFadeInVideo = true;
  };
  notifyThatItShallFadeOutVideo = () => {
    this.shallFadeOutVideo = true;
  };
  notifyThatItShallFadeOutAudio = () => {
    this.shallFadeOutAudio = true;
  };

  //__HIDE & DESTROY PLAYER FOR COMPLETELY PLAYED ELEMENTS______
  hide = () => {
    this.videoFinished = true;
    clearInterval(this.playingInterval);
    let videoElem = this.videoElementRef.current;
    videoElem.pause();
    this.destroyShakaPlayer();
    debug && console.log("video: ", this.videoIndex, " now hidden and destroyed");
  };

  destroyShakaPlayer = () => {
    this.player.unload(); //Test if I can remove this?
    this.player.destroy();
  };
  //END__HIDE & DESTROY PLAYER FOR COMPLETELY PLAYED ELEMENTS______

  //__PAUSE LAST VIDEO______
  pauseLastVideo = () => {
    let videoElem = this.videoElementRef.current;
    videoElem.pause();
    this.videoFinished = true;
    clearInterval(this.playingInterval);
  };

  //__ERROR HANDLING______
  onErrorEvent(event) {
    // Extract the shaka.util.Error object from the event.
    this.onError(event.detail);
  }

  onError(error) {
    // Log the error.
    console.error("Error code", error.code, "object", error);
  }
}
