import { useEffect, useState } from "react";
import videoLengthWithoutEmptyElements from "./videoLengthWithoutEmptyElements";

export default function useVideoLengths(visionMovieData) {
  //VIDEO TIME ALLOCATION AND CHOOSING ELEMENTS
  const [currentVideoLength, setCurrentVideoLength] = useState(0);
  const [estimatedVideoLength, setEstimatedVideoLength] = useState(0);

  const estimatedAverageElementsVideoLength = 12.5; //FOR ELEMENTS WITH NO BACKGROUND
  const segments = visionMovieData.visionMovie;

  useEffect(() => {
    setCurrentVideoLength(calculateCurrentVideoLength(segments));
    setEstimatedVideoLength(
      videoLengthWithoutEmptyElements(segments, estimatedAverageElementsVideoLength)
    );
  }, [visionMovieData]);

  function calculateCurrentVideoLength(segments) {
    const arrayOfShowingDurations = segments.map((elem) => {
      if (elem?.image?.showingDuration) return elem?.image?.showingDuration;
      return elem?.video?.showingDuration;
    });
    const newCurrentVideoLength = arrayOfShowingDurations.reduce(
      (total, showingDuration) => total + showingDuration,
      0
    );
    return newCurrentVideoLength;
  }

  return { currentVideoLength, estimatedVideoLength };
}
