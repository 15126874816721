import React, { useContext, useState, useEffect } from "react";
import WebFont from "webfontloader";

import { AffirmationStyles } from "../../affirmations/AffirmationStyles";
import useWindowResize from "beautiful-react-hooks/useWindowResize";

export const AffirmationDataContext = React.createContext({
  activeSegment: undefined,
  setActiveSegment: undefined,
  fontType: undefined,
  headerTextSize: undefined,
  headerFontWeight: undefined,
  headerWidth: undefined,
  setHeaderWidth: undefined,
  paragraphTextSize: undefined,
  paragraphLineHeight: undefined,
  paragraphFontWeight: undefined,
  lineMarginTop: undefined,
  lineMarginBottom: undefined,
  lineHeight: undefined,
  lineBorderRadius: undefined,
  headerLineHeight: undefined,
});

export function useAffirmationDataContext() {
  return useContext(AffirmationDataContext);
}

WebFont.load({
  google: {
    families: [AffirmationStyles.standardStyles.fontType + ":300,400,500,600,700"],
  },
});

//Creating the logic and the Providers
export function AffirmationDataContextProvider({ children }) {
  const [activeSegment, setActiveSegment] = useState(placeholderDataForAffirmationContext);

  //SCALING FACTOR FOR AFFIRMATIONS INSIDE FULLSCREEN PLAYER
  const [scalingFactor, setScalingFactor] = useState(0);
  function calculateScalingFactor() {
    const referenceWidth = 1440; // Is the width at a 13" Macbook Air, the one I am working on
    const videoWidth = window.innerWidth;
    setScalingFactor(videoWidth / referenceWidth);
  }
  useEffect(() => {
    calculateScalingFactor();
  });
  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    calculateScalingFactor();
  });

  const [headerWidth, setHeaderWidth] = useState(0);

  const styling = AffirmationStyles.standardStyles;

  const functions = {
    activeSegment,
    setActiveSegment,
    fontType: styling.fontType,
    headerTextSize: styling.headerTextSize * scalingFactor,
    headerFontWeight: styling.headerFontWeight,
    headerLineHeight: styling.headerLineHeight,
    headerWidth,
    setHeaderWidth,
    paragraphTextSize: styling.paragraphTextSize * scalingFactor,
    paragraphLineHeight: styling.paragraphLineHeight,
    paragraphFontWeight: styling.paragraphFontWeight,
    lineMarginTop: styling.lineMarginTop * scalingFactor,
    lineMarginBottom: styling.lineMarginBottom * scalingFactor,
    lineHeight: styling.lineHeight * scalingFactor,
    lineBorderRadius: styling.lineBorderRadius * scalingFactor,
    scalingFactor,
  };

  return (
    <AffirmationDataContext.Provider value={functions}>{children}</AffirmationDataContext.Provider>
  );
}

const placeholderDataForAffirmationContext = {
  video: {
    video_files: [
      {
        link: "https://player.vimeo.com/external/269971860.hd.mp4?s=eae965838585cc8342bb5d5253d06a52b2415570&profile_id=174&oauth2_token_id=57447761",
      },
    ],
    hdSrc:
      "https://player.vimeo.com/external/269971860.hd.mp4?s=eae965838585cc8342bb5d5253d06a52b2415570&profile_id=174&oauth2_token_id=57447761",
    startOffset: 1,
    duration: 5,
  },
  affirmation: {
    showAffirmation: 1,
    hideAffirmation: 4,
    header: "I AM",
    paragraph: "valuable and wonderful the way I already am",
    styling: {
      affirmationStyle: undefined,
      readability: undefined,
      headerSize: undefined,
      paragraphSize: undefined,
      fontColor: undefined,
      font: undefined,
    },
  },
  constrastLayer: 0,
};
