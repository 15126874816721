import styled from "@emotion/styled";
import { fontWeight } from "@mui/system";
import { Breakpoint, up, down } from "../Breakpoint";
import { theme } from "../../../Theme";
const spacing = 8;

export const Text = styled.div((props) => ({
  fontSize: "1.4rem",
  color: "white",
  fontWeight: 600,
  paddingTop: props.topPadding && 3,
}));

export const SettingContainer = styled.div((props) => ({
  width: "100%",
  display: "flex",
  marginTop: props.marginTop,
}));

export const TextFieldLabel = styled.div((props) => ({
  fontSize: "0.8rem",
  color: "white",
  fontWeight: 600,
  marginLeft: 5,
  marginBottom: 2,
}));

export function OpenCardWithSettingsDiv({ fixedPosition, children }) {
  return (
    <div
      style={
        fixedPosition
          ? {
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              zIndex: 100,
              background: "white",
            }
          : { display: "flex" }
      }
    >
      <div
        style={
          fixedPosition
            ? {
                paddingTop: 100,
                width: "100%",
                height: "100%",
                background: theme.palette.gradient.cardBackgroundGradient80,
              }
            : {}
        }
      >
        {children}
      </div>
    </div>
  );
}
