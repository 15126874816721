export default function ImageGridCardBackground({ imagesArray }: { imagesArray: string[] }) {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        borderRadius: 16,
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          background: "black",
          opacity: 0.3,
          borderRadius: 16,
        }}
      />
      <div style={{ width: "100%", height: "50%" }}>
        {imagesArray[0] ? (
          <img
            style={{
              borderRadius: "16px 0px 0px 0px",
              width: "50%",
              height: "100%",
              objectFit: "cover",
            }}
            src={imagesArray[0]}
          />
        ) : (
          <div
            style={{
              width: "50%",
              height: "100%",
              borderRadius: "16px 0px 0px 0px",
            }}
          />
        )}
        {imagesArray[1] ? (
          <img
            style={{
              borderRadius: "0px 16px 0px 0px",
              width: "50%",
              height: "100%",
              objectFit: "cover",
            }}
            src={imagesArray[1]}
          />
        ) : (
          <div
            style={{
              width: "50%",
              height: "100%",
              borderRadius: "0px 16px 0px 0px",
            }}
          />
        )}
      </div>
      <div
        style={{
          width: "100%",
          height: "50%",
          display: "flex",
        }}
      >
        {imagesArray[3] ? (
          <img
            style={{
              borderRadius: "0px 0px 0px 16px",
              width: "50%",
              height: "100%",
              objectFit: "cover",
            }}
            src={imagesArray[3]}
          />
        ) : (
          <div
            style={{
              width: "50%",
              height: "100%",
              borderRadius: "0px 0px 0px 16px",
            }}
          />
        )}
        {imagesArray[2] ? (
          <img
            style={{
              borderRadius: "0px 0px 16px 0px",
              width: "50%",
              height: "100%",
              objectFit: "cover",
            }}
            src={imagesArray[2]}
          />
        ) : (
          <div
            style={{
              width: "50%",
              height: "100%",
              borderRadius: "0px 0px 16px 0px",
            }}
          />
        )}
      </div>
    </div>
  );
}
