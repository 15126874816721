import { useState, useRef, useEffect } from "react";
import { FixedSizeList as List } from "react-window";
import { ListRow } from "./ListRow";
import { useBackgroundSelectionContext } from "./BackgroundSelectionContext";
import { useVisionMovieContext } from "../VisionMovieContext";
import { splitInRows } from "./helperFunctions";
import useCalculateGridCardSize from "../segments/helperHooks/useCalculateGridCardSize";
import { useLocation } from "react-router-dom";
import { ConstructionOutlined } from "@mui/icons-material";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import { useTranslation } from "react-i18next";

//LIST
export default function AssetsToChooseFromList() {
  const { segmentToChooseBackgroundFor, visionMovieData } = useVisionMovieContext();
  const gridCardSize = useCalculateGridCardSize();
  const { t } = useTranslation();

  //ASSETS
  //Imports from BackgroundSelectionContext
  const {
    assetsToChooseFromInRows,
    loadingAssets,
    getNextPage,
    nextPage,
    searchQuery,
    newSearch,
    setNewSearch,
    listRef,
    scrollPositionInBackgroundSelectionList,
  } = useBackgroundSelectionContext();

  //This sets the list to the former scroll position when user comes back to list
  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTo(scrollPositionInBackgroundSelectionList.current);
    }
  }, [listRef]);

  //HEIGHT & WIDTH
  //Calculates width, height + resulting rowHeight
  //It recalculates these values when window is resized
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  });
  function calculateRowHeight(windowWidth) {
    const number = gridCardSize === 3 ? 4 : gridCardSize === 4 ? 3 : 1.96;
    return (((windowWidth - 16 * 4) / number) * 1080) / 1920 + 16;
  }

  //SCROLLING
  //This calculates at which YOffset the next page should be loaded
  function calculateWhenToLoadNewPage() {
    //When scrolling trough 70% of the loaded assests the next ones are loaded
    const rowHeight = calculateRowHeight(windowWidth);
    const loadingOffsetRowNumbers = 9;
    const yOffsetWhenNextPageShouldLoad =
      rowHeight * assetsToChooseFromInRows.length - loadingOffsetRowNumbers * rowHeight;

    return yOffsetWhenNextPageShouldLoad;
  }

  const YOffsetWhenToLoadNextPage = useRef();
  //For setting up when needing to load new assets
  useEffect(() => {
    if (assetsToChooseFromInRows) {
      YOffsetWhenToLoadNextPage.current = calculateWhenToLoadNewPage();
    }
  }, [assetsToChooseFromInRows]);

  onWindowResize(() => {
    YOffsetWhenToLoadNextPage.current = calculateWhenToLoadNewPage();
  });

  //This checks when nextPage needs to be loaded and triggers nextPage load
  const scrollHandler = (event) => {
    //console.log(YOffsetWhenToLoadNextPage.current);
    if (event.scrollOffset >= YOffsetWhenToLoadNextPage.current) {
      if (loadingAssets) return;
      getNextPage();
    }
  };

  //SEARCHING RESET
  //Function to scroll list back to the top
  const scrollToTop = () => {
    if (!listRef.current) return;
    listRef.current.scrollTo(0);
  };

  //When active searchQuery changes ()== new assets are loaded) then the list scrolls back to the top
  useEffect(() => {
    if (newSearch) {
      scrollToTop();
      setNewSearch(false);
    }
  }, [newSearch]);

  //PLACEHOLDER WHEN NO ASSETS TO DISPLAY IN LIST
  const PlaceholderRow = ({ text }) => {
    return (
      <div
        style={{
          width: "80%",
          margin: "auto",
          display: "flex",
          height: calculateRowHeight(windowWidth),
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2 style={{ textAlign: "center", color: "grey" }}>{text}</h2>
      </div>
    );
  };

  //PREPARE assetsToChooseFrom ACCORDING TO PATH || SHOW PLACEHOLDER WHEN NO ASSETS AVAILABLE
  const currentPathname = useLocation().pathname;
  var assetsToChooseFrom = [];
  if (currentPathname === "/backgroundselection") {
    assetsToChooseFrom = assetsToChooseFromInRows;
    //PLACEHOLDER FOR LIST WHEN WHEN NO VIDEOS FOUND
    if (assetsToChooseFrom.length === 0) {
      return <PlaceholderRow text={t("noVideosFound")} />;
    }
  }
  if (currentPathname === "/backgroundselection/heartedvideos") {
    const responsiveNrOfVideosPerRow = gridCardSize === 3 ? 4 : gridCardSize === 4 ? 3 : 2;
    assetsToChooseFrom = segmentToChooseBackgroundFor?.heartedVideos
      ? splitInRows(segmentToChooseBackgroundFor.heartedVideos, responsiveNrOfVideosPerRow)
      : [];
    //PLACEHOLDER FOR LIST WHEN WHEN NO VIDEOS HEARTED
    if (assetsToChooseFrom.length === 0) {
      return <PlaceholderRow text={t("noVideosLiked")} />;
    }
  }
  if (currentPathname === "/backgroundselection/allheartedvideos") {
    const responsiveNrOfVideosPerRow = gridCardSize === 3 ? 4 : gridCardSize === 4 ? 3 : 2;
    const allHeartedVideos = visionMovieData.allHeartedVideos
      ? visionMovieData.allHeartedVideos
      : [];
    const allHeartedVideosWithoutSegmentHeartedVideos = segmentToChooseBackgroundFor?.heartedVideos
      ? allHeartedVideos.filter(
          (filterEl) =>
            !segmentToChooseBackgroundFor.heartedVideos.some((el) => el.id === filterEl.id)
        )
      : allHeartedVideos;
    assetsToChooseFrom = allHeartedVideosWithoutSegmentHeartedVideos
      ? splitInRows(allHeartedVideosWithoutSegmentHeartedVideos, responsiveNrOfVideosPerRow)
      : [];
    //PLACEHOLDER FOR LIST WHEN WHEN NO VIDEOS HEARTED
    if (assetsToChooseFrom.length === 0) {
      return <PlaceholderRow text={t("noVideosLiked")} />;
    }
  }

  //LIST WHEN ELEMENTS AVAILABLE
  return (
    <List
      ref={listRef}
      height={windowHeight - 60}
      itemCount={
        loadingAssets || (!nextPage && currentPathname !== "/backgroundselection/heartedvideos")
          ? assetsToChooseFrom.length + 1
          : assetsToChooseFrom.length
      }
      itemSize={calculateRowHeight(windowWidth)}
      overscanCount={7}
      onScroll={scrollHandler}
    >
      {ListRow(
        gridCardSize,
        assetsToChooseFrom,
        assetsToChooseFrom.length,
        currentPathname,
        loadingAssets,
        nextPage,
        scrollToTop,
        searchQuery
      )}
    </List>
  );
}
