import Grid from "@mui/material/Grid";
import VisionMovieCard from "../../visionmoviesScreen/VisionMovieCard";
import { useVisionMovieContext } from "../../VisionMovieContext";
import { useEffect } from "react";
import { Breakpoint, useAbove } from "../../visionmoviesScreen/Breakpoint";
import SetNextCoachingDate from "./SetNextCoachingDate";
import Button from "@mui/material/Button";

export default function SelectVisionMovie({
  nextCoaching,
  setNextCoaching,
  setVisionMovieSelected,
}) {
  const { visionMovies, setCurrentlyActiveVisionMovieId } = useVisionMovieContext();
  useEffect(() => {
    setCurrentlyActiveVisionMovieId(null);
    //Shows cursor again, if it was hidden in "/player" before
    document.body.style.cursor = "auto";
  }, []);

  const bigDevice = useAbove(Breakpoint.desktop);

  return (
    <div style={{ marginTop: 20, paddingBottom: 80 }}>
      <Button
        onClick={() => {}}
        style={{
          fontSize: 22,
          paddingLeft: 40,
          paddingRight: 40,
          marginBottom: 40,
          fontWeight: "bold",
          textTransform: "none",
        }}
        variant="contained"
        disabled={nextCoaching?.date ? false : true}
        disableElevation
      >
        speichern
      </Button>
      <SetNextCoachingDate setNextCoaching={setNextCoaching} nextCoaching={nextCoaching} />
      <Grid container spacing={4} style={{ marginTop: 16 }}>
        {visionMovies.map((visionMovie, index) => {
          return (
            <Grid key={index + "card"} item xs={12} md={6}>
              <VisionMovieCard
                setVisionMovieSelected={setVisionMovieSelected}
                data={visionMovie}
                bigDevice={bigDevice}
                nextCoaching={nextCoaching}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
