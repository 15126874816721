import React, { useEffect } from "react";
import { useState } from "react";
import { exampleVisionMovieWithKaleidoscope } from "./ExampleData";
import { Spinner } from "react-bootstrap";
import PlayerCloseButton from "./PlayerCloseButton";

import VisionMoviePlayer from "./VisionMoviePlayer";
import { useFullscreen } from "rooks";
import { Navigate, useNavigate } from "react-router-dom";
export default function Player3() {
  const [ready, setReady] = useState(true);
  const navigate = useNavigate();
  const fullscreenContainerRef = React.useRef(null);
  const [fullScreenInitialized, setFullScreenInitialized] = useState(false);
  const {
    isFullscreenAvailable,
    enableFullscreen,
    disableFullscreen,
    isFullscreenEnabled,
    toggleFullscreen,
  } = useFullscreen({ target: fullscreenContainerRef });

  useEffect(() => {
    if (!isFullscreenEnabled) {
      if (!/iPhone/.test(navigator.userAgent)) {
        enableFullscreen();
      }
      setFullScreenInitialized(true);
    }
    //add event listener for escape
    const onEscapeHandler = (e: any) => {
      if (e.key === "Escape") {
        navigate(-1);
      }
    };

    document.addEventListener("keydown", onEscapeHandler);
    return () => {
      document.removeEventListener("keydown", onEscapeHandler);
    };
  }, []);

  useEffect(() => {
    if (fullScreenInitialized && !isFullscreenEnabled) {
      //if fullscreen was disabled, the escape key was pressed => go back to previous page
      navigate && navigate(-1);
    }
  }, [isFullscreenEnabled]);

  return (
    <div className="playerContainer" ref={fullscreenContainerRef}>
      <PlayerCloseButton />
      {!ready && (
        <Spinner
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto",
            width: (100 * window.innerWidth) / 1440,
            height: (100 * window.innerWidth) / 1440,
            borderWidth: (12 * window.innerWidth) / 1440,
            color: "rgba(255, 255, 255, 0.7)",
          }}
          animation={"border"}
        />
      )}
      {!ready && <button onClick={() => setReady(true)}>Ready</button>}
      {ready && (
        <VisionMoviePlayer
          visionMovie={exampleVisionMovieWithKaleidoscope}
          segmentsCoverBackgroundMusic={false}
          onFinishedVisionMovie={() => {
            navigate(-1);
          }}
        />
      )}
    </div>
  );
}
