import React from "react";
import { affirmationStyles } from "./StatementStyles";

export default function Header({ statementData, scalingFactor, headerRef }) {
  var header = statementData?.header;

  if (header === "" && statementData?.paragraph === "") {
    header = "YOU DIDN'T";
  }

  return (
    <div
      ref={headerRef}
      style={{
        opacity: 0,
        fontFamily: affirmationStyles.fontFamily,
        fontSize: scalingFactor * affirmationStyles.headerTextSize,
        fontWeight: affirmationStyles.headerFontWeight,
        color: "white",
        //opacity: opacity,
        lineHeight: scalingFactor * affirmationStyles.headerLineHeight,
        display: "inline",
        WebkitUserSelect: "none",
        textTransform: "uppercase",
      }}
    >
      {header}
    </div>
  );
}
