import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useTheme } from "@mui/material/styles";
import { useVisionMovieContext } from "../VisionMovieContext";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ modalOpen, setModalOpen, visionMovieId }) {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const { deleteVisionMovie } = useVisionMovieContext();
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            style={{ color: palette.primary.main }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {t("deleteVisionMovie")}
          </Typography>
          <Typography
            style={{ marginBottom: 25, color: palette.grey[600] }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            {t("cantBeUndone")}
          </Typography>
          <Button
            onClick={() => setModalOpen(false)}
            style={{ marginRight: 20, width: 130 }}
            disableElevation
            variant="outlined"
          >
            {t("keepIt")}
          </Button>
          <LoadingButton
            style={{ width: 130 }}
            onClick={() => {
              setLoading(true);
              deleteVisionMovie(visionMovieId).then(() => {
                setLoading(false);
                setModalOpen(false);
              });
            }}
            disableElevation
            variant="contained"
            loading={loading}
            //loadingPosition="start"
          >
            {t("delete")}
          </LoadingButton>
        </Box>
      </Modal>
    </div>
  );
}
