//Context API template
import React, { useContext, useState, useEffect, useRef } from "react";
import { firebaseStorage, firestore } from "../Firebase/firebase";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../Firebase/Contexts/AuthContext";

const UploadedVideosContext = React.createContext({
  currentTime: undefined,
  setCurrentTime: undefined,
});

export function useUploadedVideosContext() {
  return useContext(UploadedVideosContext);
}

//Creating the logic and the Providers
export function UploadedVideosContextProvider({ children, uploadedVideosInRows }) {
  // Get current loggedIn user from firebase
  const { currentUser } = useAuth();
  const userId = currentUser?.uid;

  const [videoFile, setVideoFile] = useState(null);
  const [videoFileDownloadLink, setVideoFileDownloadLink] = useState();
  //const [bigThumbnailDownloadURL, setBigThumbnailDownloadURL] = useState(); //not used anymore. Only small thumbnail is used and created
  const [smallThumbnailDownloadURL, setSmallThumbnailDownloadURL] = useState();
  const [uploadStatus, setUploadStatus] = useState();
  const [uploadProgress, setUploadProgress] = useState(undefined);
  const [errors, setErrors] = useState([]);

  // if error occur while uploading...
  const handleError = (err) => {
    setUploadStatus("error");
    setErrors([...errors, err]);
  };

  //Reset states and variables related to upload song to storage and firestore
  const resetUploadingStates = () => {
    setTimeout(() => {
      setVideoFile(undefined);
      setVideoFileDownloadLink(undefined);
      setSmallThumbnailDownloadURL(undefined);
      setUploadStatus(undefined);
      setUploadProgress(undefined);
      setErrors([]);
    }, 4500);
  };

  //Extract thumbnail from video file___________________________________________________________________________
  //These two refs refer to the video element and the canvas element that are used to extract the thumbnail
  //They are both hidden. (It didn't work with creatElement()
  const videoRef = useRef();
  const canvasRef = useRef();
  const videoSmallRef = useRef();
  const canvasSmallRef = useRef();
  const extractAndUploadThumbnail = (videoFile, width, height) => {
    var video;
    var canvas;
    if (width === 1920 && height === 1080) {
      video = videoRef.current;
      canvas = canvasRef.current;
    } else {
      video = videoSmallRef.current;
      canvas = canvasSmallRef.current;
    }
    video.src = URL.createObjectURL(videoFile);
    video.preload = "preload";
    video.load();
    video.play();
    //Play is used beacuse it is the only way to extract the thumbnail from the video
    //When using other methods the first frame is not really loaded yet, or at least when drawing a picture from it we don't get the correct image (only a grey image)
    video.onplay = function () {
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      //Timeout for image to be drawn
      setTimeout(() => {
        video.currentTime = 0;
        video.pause();
        setTimeout(() => {
          ctx.drawImage(video, 0, 0, width, height);
          const imageURL = canvas.toDataURL("image/png");
          const blob = dataURLtoBlob(imageURL);
          const fileName = videoFile.name + (width === 1920 ? "_BigThumbnail" : "_SmallThumbnail");

          //Upload thumbnail to storage
          const filePath = `/users/${userId}/uploadedVideos/${fileName}.png`;
          const uploadTask = firebaseStorage.ref(filePath).put(blob);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              //const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
              //setUploadProgress(progress);
            },
            (error) => {
              handleError(error);
            },
            () => {
              firebaseStorage
                .ref(filePath)
                .getDownloadURL()
                .then((downloadURL) => {
                  if (width === 1920 && height === 1080) {
                    //setBigThumbnailDownloadURL(downloadURL);
                  } else {
                    setSmallThumbnailDownloadURL(downloadURL);
                  }
                });
            }
          );
        }, 200);
      }, 700);
    };
  };

  //Convert dataURL to blob for uploading to firestore
  function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
  //END_________________________________________________________________________

  //2d array -> 1d array and placeholder on position 0 removed
  var formerUploadedVideos = [].concat(...uploadedVideosInRows);
  formerUploadedVideos.shift(); //remove placeholder obj in array that is needed for upload card

  //Upload video to firebase storage and firestore & create thumbnail
  function handleVideoSelected(event) {
    const videoFile = event.target.files[0];
    //Check if file is already uploaded
    if (formerUploadedVideos.filter((video) => video?.fileName === videoFile?.name).length != 0) {
      alert("This video is already uploaded!");
      return;
    }
    //Checks if file size is under 40mb
    if (videoFile.size < 60000000) {
      setVideoFile(videoFile);
      extractAndUploadThumbnail(videoFile, 1920 / 3, 1080 / 3); //small thumbnail size
      uploadToStorage(videoFile); //upload video to firebase storage
    } else {
      alert(
        "Your video is too big. Please upload a video with under 60MB. You can shorten the video by using https://online-video-cutter.com/."
      );
    }
  }

  // Uploading videoFile to storage...
  const uploadToStorage = (videoFile) => {
    // Create the file metadata. Needed for uploading to firestore
    var metadata = {
      contentType: "video/mp4",
    };

    // Location in storage you want to create/send file to
    const storagePath = `users/${userId}/uploadedVideos/${videoFile.name}`;
    const uploadingVideoFileHandler = firebaseStorage.ref(storagePath).put(videoFile, metadata);
    setUploadStatus("inProgress");
    uploadingVideoFileHandler.on(
      "state_changed",
      (snap) => handleProgress(snap),
      (err) => handleError(err),
      () => {
        uploadingVideoFileHandler.snapshot.ref
          .getDownloadURL()
          .then((storageLink) => {
            setVideoFileDownloadLink(storageLink);
          })
          .catch((err) => handleError(err));
      }
    );
  };

  // handle progress of uploading song
  const handleProgress = ({ bytesTransferred, totalBytes }) => {
    let tranferedBytes = bytesTransferred ? bytesTransferred : 0;
    let totalByte = totalBytes ? totalBytes : 0;
    const percentUploaded = totalBytes !== 0 ? Math.round((tranferedBytes / totalByte) * 100) : 0;
    setUploadProgress(percentUploaded);
  };

  //Trigger upload data to firestore when all uploads are done
  useEffect(() => {
    if (videoFileDownloadLink && smallThumbnailDownloadURL) {
      //Upload data obj to firestore
      createDataObj();
    }
  }, [videoFileDownloadLink, smallThumbnailDownloadURL]);

  // handle when song uploaded to Storage
  const createDataObj = () => {
    const videoURL = URL.createObjectURL(videoFile);
    const video = document.createElement("VIDEO");
    video.src = videoURL;
    video.preload = "metadata";
    video.onloadedmetadata = function () {
      const dataObj = [
        ...formerUploadedVideos,
        {
          uploadedVideo: true,
          image: smallThumbnailDownloadURL,
          video_files: [
            {
              height: 1080, //DUMMY DATA / PLACEHOLDER to not screw up already programmed alorithms
              link: videoFileDownloadLink,
              quality: "hd", //DUMMY DATA / PLACEHOLDER to not screw up already programmed alorithms
              width: 1920, //DUMMY DATA / PLACEHOLDER to not screw up already programmed alorithms
            },
          ],
          duration: video.duration,
          fileName: videoFile.name,
          id: uuidv4(),
        },
      ];
      saveToFirestore(dataObj);
    };
  };

  // firebase firestore reference
  const firestoreRef = firestore.collection("users").doc(userId);
  //save Song data to firestore
  const saveToFirestore = (videoData) => {
    firestoreRef
      .collection("UploadedVideos")
      .doc("all")
      .set({ uploadedVideos: videoData })
      .then(() => {
        console.log("STORED IN FIREBASE");
        setUploadStatus("uploadCompleted");
        resetUploadingStates();
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
        setUploadStatus("error");
      });
  };

  const functions = {
    handleVideoSelected,
    uploadStatus,
    uploadProgress,
    videoRef,
    canvasRef,
    videoSmallRef,
    canvasSmallRef,
  };

  return (
    <UploadedVideosContext.Provider value={functions}>{children}</UploadedVideosContext.Provider>
  );
}
