import Navigation from "../components/navigation";
import { useTheme } from "@mui/material/styles";
import Employees from "../components/coaching/Employees";

export default function EmployeesRoute({ proAccount }) {
  const theme = useTheme();

  return (
    <div
      style={{
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(8),
      }}
    >
      <Navigation proAccount={proAccount} />
      <div style={{ paddingTop: 60, overflow: "hidden" }}>
        <Employees />
      </div>
    </div>
  );
}
