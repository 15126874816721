import { useState } from "react";
import Login from "../Firebase/Authentication/Login";
import PaypalButtons from "./PaypaylButtons";
import { Container } from "react-bootstrap";

export default function LoginBeforePaying() {
  const [showOverlay, setShowOverlay] = useState(true);

  return (
    <>
      {showOverlay ? (
        <div style={{ height: "100%", width: "100%", position: "relative" }}>
          <div
            onClick={() => setShowOverlay(false)}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              zIndex: 10000,
              cursor: "pointer",
            }}
          ></div>
          <PaypalButtons
            currentUser={undefined}
            onPayed={undefined}
            price={undefined}
            discountCode={undefined}
          />
        </div>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1,
            background: "white",
          }}
        >
          <Container
            className="d-flex align-items-center justify-content-center"
            style={{ minHeight: "100vh", flexDirection: "column" }}
          >
            <div>Please log into your account first</div>
            <Login onLoggedIn={() => {}} />
          </Container>
        </div>
      )}
    </>
  );
}
