import { useLayoutEffect, useState, useRef } from "react";
import { Card } from "../visionmoviesScreen/VisionMovieCardStyled";
import { useTheme } from "@mui/material/styles";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import TextField from "@mui/material/TextField";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../Firebase/Contexts/AuthContext";
import { firestore } from "../Firebase/firebase";
import LoadingButton from "@mui/lab/LoadingButton";

const VIDEO_RATIO = 1080 / 1920;
export default function AddTextEditorCard({ data }) {
  const theme = useTheme();
  //Height setup
  const cardRef = useRef();
  const [cardHeight, setCardHeight] = useState(0);
  useLayoutEffect(() => {
    cardRef.current && setCardHeight(cardRef.current.offsetWidth * VIDEO_RATIO);
  }, [cardRef.current]);
  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    cardRef.current && setCardHeight(cardRef.current.offsetWidth * VIDEO_RATIO);
  });

  const plus = {
    fontSize: "7vw",
    fontFamily: "Poppins",
    fontWeight: 300,
    background: "linear-gradient(104.33deg, #FF005A 3.51%, #FF7B44 98.53%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    WebkitUserSelect: "none",
  };

  const [showPlus, setShowPlus] = useState(true);
  const [name, setName] = useState("");
  const { currentUser } = useAuth();
  const userId = currentUser?.uid;

  async function addNewDocument() {
    const documentId = uuidv4();
    const newData = [...data];
    newData.push({ id: documentId, name });
    firestore
      .collection("users")
      .doc(userId)
      .collection("textDocuments")
      .doc("allDocsNames")
      .set({ data: newData })
      .then((response) => {
        console.log("success creating new document");
      })
      .catch((error) => console.log("error occured: " + error));
  }

  const [loading, setLoading] = useState(false);

  return (
    <Card
      ref={cardRef}
      cardHeight={cardHeight}
      backgroundColor={theme.palette.gradient.cardBackgroundGradient}
      borderRadius={6}
    >
      {showPlus ? (
        <div
          onClick={() => setShowPlus(false)}
          style={{
            cursor: "pointer",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            WebkitUserSelect: "none",
          }}
        >
          <div style={plus}>+</div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "none" } }}
              style={{
                borderRadius: 4,
                fontSize: "1.5rem",
                background: "white",
                marginBottom: 20,
              }}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <LoadingButton
              style={{ width: 140, textTransform: "none", fontSize: 20 }}
              variant="contained"
              disableElevation
              onClick={() => {
                setLoading(true);
                addNewDocument().then(() => {
                  setLoading(false);
                  setShowPlus(true);
                });
              }}
              loading={loading}
              //loadingPosition="start"
            >
              create
            </LoadingButton>
          </div>
        </div>
      )}
    </Card>
  );
}
