import Cropper from "react-easy-crop";
import { useUploadedImagesContext } from "./UploadedImagesContext";

export default function CropperComponen({ zoom, setZoom, setChangedZoomValueInCropper }) {
  const { imageToCrop, handOverCropDataToContext, crop, setCrop } = useUploadedImagesContext();

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    handOverCropDataToContext(croppedArea, croppedAreaPixels, crop, zoom);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        paddingBlock: 100,
      }}
    >
      <Cropper
        style={{ containerStyle: { borderRadius: "6px 6px 0px 0px" } }}
        image={imageToCrop.image}
        crop={crop}
        zoom={zoom}
        aspect={1920 / 1080}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={(value) => {
          setChangedZoomValueInCropper(true);
          setZoom(value);
        }}
      />
    </div>
  );
}
