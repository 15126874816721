import React, { useContext, useState, useRef } from "react";

const PlayerSetupContext = React.createContext();

export function usePlayerSetupContext() {
  return useContext(PlayerSetupContext);
}

//Creating the logic and the Providers
export function PlayerSetupContextProvider({ children }) {
  //PLAYERSETUP CONTEXT
  const [showPlayer, setShowPlayer] = useState(false);
  const [resetPlayer, setResetPlayer] = useState(false);
  const playerWasSetup = useRef(false);
  const [finishedVisionMovie, setFinishedVisionMovie] = useState(false);
  const fullscreenStateBeforeOpenPlayer = useRef();

  const functions = {
    playerWasSetup,
    showPlayer,
    setShowPlayer,
    resetPlayer,
    setResetPlayer,
    finishedVisionMovie,
    setFinishedVisionMovie,
    fullscreenStateBeforeOpenPlayer,
  };

  return <PlayerSetupContext.Provider value={functions}>{children}</PlayerSetupContext.Provider>;
}
