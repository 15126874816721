import { useEffect } from "react";
import RecordProgress from "../components/progressTracking/recordProgress/index";

export default function ProgressTrackingRoute() {
  //Bug fix that makes the settings page scroll to the top when navigating to it
  //Before sometimes this was not the case
  useEffect(() => {
    document.body.scroll(0, 0);
  }, []);

  return (
    <div>
      <RecordProgress />
    </div>
  );
}
