export const affirmationStyles = {
  fontType: "Poppins",
  //Header
  headerTextSize: 130,
  headerFontWeight: 700,
  headerLineHeight: 1.15,
  //Paragraph
  paragraphTextSize: 46,
  paragraphLineHeight: 1.5,
  paragraphFontWeight: 500,
  //Line
  lineMarginTop: 50,
  lineMarginBottom: 50,
  lineHeight: 13,
  lineBorderRadius: 3,
  //Affirmation Container
  affirmationContainerMarginLeft: "8%", //player & segments styles doesn't read these styles -> they are still hardcoded
  affirmationContainerWidth: "86%",
};
