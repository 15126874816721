import React, { useRef, useState } from "react";
import Cards from "./Cards";
import Button from "@mui/material/Button";
import { TrackingData } from "./ProgressTracking";
import { useTranslation } from "react-i18next";

export default function VisionMovieProgressTracking({
  setVisionMovieSelected,
  trackingData,
  setTrackingData,
}: {
  setVisionMovieSelected: any;
  trackingData: TrackingData;
  setTrackingData: any;
}) {
  const { t } = useTranslation();

  return (
    <div style={{ paddingBottom: 40, marginTop: 20 }}>
      <Button
        onClick={() => {
          setVisionMovieSelected(false);
        }}
        style={{
          fontSize: 22,
          paddingLeft: 40,
          paddingRight: 40,
          marginBottom: 40,
          fontWeight: "bold",
          textTransform: "none",
        }}
        variant="contained"
        disabled={false}
        disableElevation
      >
        {t("arrowBack")}
      </Button>
      <Cards trackingData={trackingData} setTrackingData={setTrackingData} />
    </div>
  );
}
