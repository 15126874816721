import React, { useState } from "react";
import { useBackgroundSelectionContext } from "../backgroundSelection/BackgroundSelectionContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

export default function SearchCategories() {
  const theme = useTheme();
  const { palette } = theme;
  const { search, searchQuery, setSearchQuery } = useBackgroundSelectionContext();

  const { t } = useTranslation();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        search(searchQuery);
      }}
      style={{
        width: 250,
        background: "#F2F2F2",
        borderRadius: "6px",
        height: "37px",
        padding: "0 10px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <input
        style={{
          background: "#F2F2F2",
          fontWeight: "bold",
          color: palette.primary.main,
          borderWidth: 0,
          flex: 1,
        }}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder={t("searchVideos")}
      />
      <button
        type="submit"
        style={{
          border: "none",
          background: "transparent",
          width: "fit-content",
          height: "fit-content",
        }}
      >
        <FontAwesomeIcon style={{ color: palette.primary.main, fontSize: 20 }} icon={faSearch} />
      </button>
    </form>
  );
}
