import { useEffect, useState } from "react";
import { prepareDataForPlayer } from "../player/prepareDataForPlayer";
import videoLengthWithoutEmptyElements from "./videoLengthWithoutEmptyElements";

export default function useSegmentsToChoose(
  visionMovieData,
  currentVideoLength,
  estimatedVideoLength
) {
  //VIDEO TIME ALLOCATION AND CHOOSING ELEMENTS
  const [howManySegmentsStillToBeChoosenForSong, setHowManySegmentsStillToBeChoosenForSong] =
    useState();

  const minSegmentLengthThreshold = 11;
  const estimatedAverageElementsVideoLength = 12.5; //FOR ELEMENTS WITH NO BACKGROUND
  const segments = visionMovieData.visionMovie;

  //SEGMENTS TO BE CHOOSEN
  //Calculates how many segments can still be choosen for song
  useEffect(() => {
    const songLength = visionMovieData.backgroundMusic?.duration;
    if (songLength) {
      setHowManySegmentsStillToBeChoosenForSong({
        minAdd: minSegmentsAdd(),
        maxRemove: maxSegmentsRemove(),
        maxAdd: maxSegmentsAdd(),
        minRemove: minSegmentsRemove(),
      });
    }
  }, [currentVideoLength, visionMovieData.backgroundMusic]);

  function minSegmentsAdd() {
    const songLength = visionMovieData.backgroundMusic?.duration;
    const notCoveredSongLength = songLength - estimatedVideoLength;
    const segmentsToAdd = notCoveredSongLength / estimatedAverageElementsVideoLength;
    if (estimatedVideoLength > songLength - 3) {
      return 0;
    }
    return Math.ceil(segmentsToAdd);
  }

  //This function returns how many segments can be removed so that the segments still fit the choosen song
  function maxSegmentsRemove() {
    var maxRemoveElements = 0;
    //Create true copy of array, otherwise functions below are modifying the array in state directly
    const segmentsCopy = JSON.parse(JSON.stringify(segments));
    for (let i = 0; i < segmentsCopy.length; i++) {
      //Check if segments still fit song length
      const shortenedSegments = segmentsCopy.slice(0, segmentsCopy.length - i);
      const songLength = visionMovieData.backgroundMusic?.duration;
      const shortenedArrWithRightSegmentDurations = prepareDataForPlayer(
        {
          visionMovie: shortenedSegments,
          backgroundMusic: visionMovieData.backgroundMusic,
        },
        "maxSegmentsRemove"
      );
      const shortenedEstimatedVideoLength = videoLengthWithoutEmptyElements(
        shortenedArrWithRightSegmentDurations,
        estimatedAverageElementsVideoLength
      );
      //Check if too short
      if (shortenedEstimatedVideoLength <= songLength - 3) {
        //If now video is too short -> return last number of elements that could be removed where segements still covered song
        return maxRemoveElements;
      } else {
        maxRemoveElements = i;
      }
    }
  }

  function maxSegmentsAdd() {
    const songLength = visionMovieData.backgroundMusic?.duration;
    const videoLengthOfCompressedSegments = calculateMinCompressedVideoLength(
      segments,
      minSegmentLengthThreshold
    );
    const maxNumberToBeChoosenSegments = Math.floor(
      (songLength - videoLengthOfCompressedSegments) / minSegmentLengthThreshold
    );
    return maxNumberToBeChoosenSegments;
  }

  //REMOVE NUMBER OF SEGMENTS THAT MIN NEED TO BE REMOVED SO THAT NOT TOO MANY SEGMENTS GOT ADDED FOR SONG LENGTH
  function minSegmentsRemove() {
    const songLength = visionMovieData.backgroundMusic?.duration;
    for (let i = 0; i < segments.length; i++) {
      const shortenedSegments = segments.slice(0, segments.length - i);
      const lengthOfCompressedVideo = calculateMinCompressedVideoLength(
        shortenedSegments,
        minSegmentLengthThreshold
      );
      if (lengthOfCompressedVideo <= songLength) {
        return i;
      }
    }
  }

  return howManySegmentsStillToBeChoosenForSong;
}

//HELPER FUNCTION
//This function returns the length of the video when all videos are only allowed to have the length of max the minSegmentLengthThreshold
//This function is needed to calculate the video length baseline for the max to be choosen segments value
function calculateMinCompressedVideoLength(segments, minSegmentLengthThreshold) {
  let minVideoLength = 0;
  segments.forEach((segment) => {
    if (segment?.video?.duration && segment?.video?.duration > minSegmentLengthThreshold) {
      minVideoLength += minSegmentLengthThreshold;
    }
    if (segment?.video?.duration <= minSegmentLengthThreshold && !segment?.video?.placeholder) {
      minVideoLength += segment?.video.duration;
    }
    if (segment?.video?.placeholder) {
      minVideoLength += minSegmentLengthThreshold;
    }
    if (segment?.image) {
      minVideoLength += minSegmentLengthThreshold;
    }
  });
  return minVideoLength;
}
