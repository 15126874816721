import Typography from "@mui/material/Typography";
import { useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import { Button, Alert } from "react-bootstrap";
import { Products } from "./Products";
import { useTheme } from "@mui/material/styles";
import app from "../Firebase/firebase";
import { useAuth } from "../Firebase/Contexts/AuthContext";
import { Spinner } from "react-bootstrap";
const tryDiscountCode = app.functions().httpsCallable("tryDiscountCode");

export default function Price({
  product,
  price,
  setPrice,
  discountCode,
  setDiscountCode,
  onPayed,
}: {
  product: Products;
  price: any;
  setPrice: any;
  discountCode: any;
  setDiscountCode: any;
  onPayed: any;
}) {
  const theme = useTheme();
  const { currentUser } = useAuth();
  const userId = currentUser.uid;

  const priceRef = useRef<any>();
  const discountApplied = useRef(false);
  const [showAlert, setShowAlert] = useState(false);
  const [waitingForApplyButtonResponse, setWaitingForApplyButtonResponse] = useState(false);

  function handleApplyDiscount(product: Products, discountCode: string) {
    if (!discountApplied.current) {
      setWaitingForApplyButtonResponse(true);
      tryDiscountCode({ product, discountCode, userId })
        .then((response) => {
          //Free account
          if (response.data === "free account") {
            onPayed();
            return;
          }
          //Discount price
          if (typeof response.data === "number") {
            setPrice(Math.round(100 * response.data) / 100);
            discountApplied.current = true;
            if (priceRef.current) priceRef.current.style.color = `${theme.palette.primary.main}`;
            setWaitingForApplyButtonResponse(false);
            return;
          }
          //Wrong code
          if (
            response.data === "discount code not valid" ||
            response.data === "product not found" ||
            response.data === "something went wrong"
          ) {
            showAlertFunc();
            setWaitingForApplyButtonResponse(false);
            return;
          }
          //Default
          showAlertFunc();
          setWaitingForApplyButtonResponse(false);
        })
        .catch(() => {
          //Default
          showAlertFunc();
          setWaitingForApplyButtonResponse(false);
        });
    }
  }

  function showAlertFunc() {
    //Wrong discount code
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  }

  return (
    <>
      <Typography ref={priceRef} style={{ fontWeight: "bold", marginBottom: 30 }} variant="h5">
        Price: {price}€
      </Typography>
      {showAlert && <Alert variant="danger">discount code doesn't work</Alert>}
      <TextField
        style={{ width: "100%", marginBottom: 15 }}
        id="outlined-basic"
        size="small"
        label="discount code"
        variant="outlined"
        value={discountCode}
        onChange={(e) => setDiscountCode(e.target.value)}
      />
      {waitingForApplyButtonResponse ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Spinner
            style={{
              color: theme.palette.primary.main,
              marginBottom: 43,
            }}
            animation={"border"}
          />
        </div>
      ) : (
        <Button
          onClick={() => {
            handleApplyDiscount(product, discountCode);
          }}
          className="w-100 btn-primary"
          style={{ color: "white", fontWeight: "bold", marginBottom: 40 }}
          type="submit"
        >
          apply discount
        </Button>
      )}
    </>
  );
}
