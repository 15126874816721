import { useState, useEffect, useRef } from "react";
import WaveSurfer from "wavesurfer.js";
import { bootStrapClass as bs } from "./HelperFunctions";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const Waveform = ({
  id,
  song,
  playing,
  videoLength,
  time,
  currentPlayingSong,
  setPlaying,
  setReadyToPlay,
  setCurrentPlayingTime,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const waveform = useRef(undefined);

  const { duration, storageLink, waveformStorageLink, isUserUploaded } = song;

  const rectangularBarWidth =
    duration > videoLength ? Math.floor((videoLength / duration) * 100) : 100;

  const waveformHeight = 40;

  //Colors for the waveform___
  var ctx = document.createElement("canvas").getContext("2d");
  var linGradWaveColor = ctx.createLinearGradient(0, 0, 0, waveformHeight * 2);
  linGradWaveColor.addColorStop(0.5, "rgba(218, 217, 218, 1)");
  linGradWaveColor.addColorStop(0.5, "rgba(241, 241, 241, 1)");
  var linGradProgressColor = ctx.createLinearGradient(0, 0, 0, waveformHeight * 2);
  linGradProgressColor.addColorStop(0.5, theme.palette.primary.main);
  linGradProgressColor.addColorStop(0.5, theme.palette.primary.opacity40);
  //End Colors for the waveform____

  const wavesurferOptions = {
    barWidth: 1,
    barRadius: 1,
    barHeight: 2,
    cursorWidth: 1,
    waveColor: linGradWaveColor,
    container: `#waveform${id}`,
    backend: "MediaElement",
    preload: "none",
    height: waveformHeight,
    progressColor: linGradProgressColor,
    responsive: true,
    cursorColor: "transparent",
    // If true, normalize by the maximum peak instead of 1.0.
    normalize: true,
    // Use the PeakCache to improve rendering speed of large waveforms.
    partialRender: true,
    forceDecode: true,
    hideScrollbar: true,
  };

  const [waveWidth, setWaveWidth] = useState(0);
  useEffect(() => {
    setWaveWidth(
      duration < videoLength ? `${Math.floor((duration / videoLength) * 100)}%` : "100%"
    );
  });

  const [waveformFile, setWaveformFile] = useState(false);

  useEffect(() => {
    if (waveformStorageLink) {
      fetch(waveformStorageLink)
        .then((response) => response.json())
        .then((file) => {
          setWaveformFile(file);
        });
    }
  }, []);

  useEffect(() => {
    if (waveformFile || isUserUploaded) {
      const track = new Audio(storageLink);
      if (waveform && waveform.current) {
        waveform.current = waveform.current.destroy();
        waveform.current = undefined;
      }
      waveform.current = WaveSurfer.create(wavesurferOptions);
      if (isUserUploaded) {
        waveform.current.load(track);
      } else {
        waveform.current.load(track, waveformFile.data, "metadata");
      }
      waveform.current.on("audioprocess", (time) => {
        setCurrentPlayingTime(Math.ceil(time));
      });
      waveform.current.on("ready", () => {
        setReadyToPlay(true);
      });
      waveform.current.on("finish", () => {
        setPlaying(false);
        waveform.current.setCursorColor("transparent");
      });
      // unMount the wavesurfer reference from memory
      return () => {
        waveform.current && waveform.current.destroy();
        waveform.current = undefined;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [waveformFile, waveformStorageLink]);

  useEffect(() => {
    if (waveform.current) {
      if (playing && currentPlayingSong === id) {
        waveform.current.setCursorColor("rgba(225, 49, 99, 1)");
        waveform.current.play();
      } else if (currentPlayingSong !== id) {
        setCurrentPlayingTime(0);
        setPlaying(false);
        waveform.current.pause();
        waveform.current.skip(-time);
        waveform.current.setCursorColor("transparent");
      } else {
        waveform.current.pause();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing, currentPlayingSong]);

  return (
    <div className={`${bs.itemCenter} mainWaveFormView`} style={{ position: "relative" }}>
      <span
        id={`waveform${id}`}
        style={{
          width: waveWidth,
          height: waveformHeight,
        }}
      />
      {rectangularBarWidth > 0 && (
        <div
          className="videoRectangleBarContainer"
          style={{
            width: `${rectangularBarWidth}%`,
          }}
        >
          <span
            style={{ background: theme.palette.primary.opacity70 }}
            className={`${bs.itemCenter} videoRectangleBar`}
          >
            {rectangularBarWidth >= 35 ? t("currentVideoLength") : ""}
          </span>
        </div>
      )}
    </div>
  );
};
export default Waveform;
