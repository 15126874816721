export default function TransformedImage({ imageData, children, imgRef, onLoad }) {
  const croppedArea = imageData.transformDataObj.croppedArea;
  const scale = 100 / croppedArea.width;
  const transform = {
    x: `${-croppedArea.x * scale}%`,
    y: `${-croppedArea.y * scale}%`,
    scale,
    width: "calc(100% + 0.5px)",
    height: "auto",
  };

  const imageStyle = {
    transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
    width: transform.width,
    height: transform.height,
    position: "absolute",
    top: 0,
    left: 0,
    transformOrigin: "top left",
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        overflow: "hidden",
        paddingBottom: `${50 / (1080 / 1920)}%`,
      }}
    >
      <img src={imageData.image} style={imageStyle} ref={imgRef} onLoad={onLoad} />
      {children}
    </div>
  );
}
