import { AffirmationStyles } from "../affirmations/AffirmationStyles";
import { useVisionMovieContext } from "../VisionMovieContext";

export default function PlaceholderAffirmation({ imageWidth }) {
  const { segmentToSetSettingsFor } = useVisionMovieContext();

  const styles = AffirmationStyles.standardStyles;
  const scalingFactor = imageWidth / 1440;

  const lineStyle = {
    background: "white",
    height: styles.lineHeight * scalingFactor,
    minHeight: 2,
    borderRadius: 1,
    width: 285 * scalingFactor,
    marginTop: styles.lineMarginTop * scalingFactor,
    marginBottom: styles.lineMarginBottom * scalingFactor,
  };

  const noTextCondition =
    segmentToSetSettingsFor?.affirmation.header === "" &&
    segmentToSetSettingsFor?.affirmation.paragraph === "";
  const header = noTextCondition ? "NO TEXT" : segmentToSetSettingsFor?.affirmation.header;
  const paragraph = noTextCondition
    ? "typed in yet. Go to the former screen and type in a text."
    : segmentToSetSettingsFor?.affirmation.paragraph;

  return (
    <div
      style={{
        position: "absolute",
        marginLeft: styles.affirmationContainerMarginLeft,
        display: "flex",
        height: "100%",
        width: "92%",
        flexDirection: "column",
        justifyContent: "center",
        zIndex: 100,
      }}
    >
      <div
        style={{
          fontSize: styles.headerTextSize * scalingFactor,
          fontWeight: styles.headerFontWeight,
          color: "white",
          lineHeight: styles.headerLineHeight,
          WebkitUserSelect: "none",
          textTransform: "uppercase",
        }}
      >
        {header}
      </div>
      <div style={lineStyle} />
      <div
        style={{
          width: "96%",
          fontFamily: styles.fontType,
          fontWeight: styles.paragraphFontWeight,
          fontSize: styles.paragraphTextSize * scalingFactor,
          lineHeight: styles.paragraphLineHeight,
          color: "white",
          whiteSpace: "pre-wrap",
          WebkitUserSelect: "none",
        }}
      >
        {paragraph}
      </div>
    </div>
  );
}
