import { useEffect, useState } from "react";
import { ElementsContainer } from "../VisionMovieCardStyled";
import { Text, SettingContainer, OpenCardWithSettingsDiv } from "./OpenCardWithSettingsStyled";
import TextField from "@mui/material/TextField";
import { theme } from "../../../Theme";
import Switch from "@mui/material/Switch";
import MinTextField from "./MinTextField";
import { useAuth } from "../../Firebase/Contexts/AuthContext";
import { firestore } from "../../Firebase/firebase";
import KaleidoscopeTextFields from "./KaleidoscopeTextFields";

const fallbackPlaybackParametersForOldAccounts = {
  playVisionMovieNumberOfTimes: 1,
  playKaleidoscopeVideo: true,
  startKaleidoscopeVideoLength: 4,
  middleKaleidoscopeVideoLength: 0,
  endKaleidoscopeVideoLength: 0,
  showStatements: true,
};

export default function OpenCardWithSettings({ data, setClosedCard }) {
  const playbackParameters = data?.playbackParameters
    ? data.playbackParameters
    : fallbackPlaybackParametersForOldAccounts;

  const [timesPlayMM, setTimesPlayMM] = useState(playbackParameters.playVisionMovieNumberOfTimes);
  const [middleTextFieldDisabled, setMiddleTextFieldDisabled] = useState(false);
  function changeTimesPlayMM(times) {
    if (times < 1) {
      setTimesPlayMM(1);
    }
    if (times >= 1 && times <= 4) {
      setTimesPlayMM(Math.round(parseInt(times)));
    }
    if (times > 4) {
      setTimesPlayMM(4);
    }
  }
  useEffect(() => {
    if (Number(timesPlayMM) === 1) {
      setMiddleTextFieldDisabled(true);
    }
    if (Number(timesPlayMM) !== 1) {
      setMiddleTextFieldDisabled(false);
    }
  }, [timesPlayMM]);

  const [playKaleidoscopeVideo, setPlayKaleidoscopeVideo] = useState(
    playbackParameters.playKaleidoscopeVideo
  );
  const [kaleidoscopeVideoLength, setKaleidoscopeVideoLength] = useState({
    start: playbackParameters.startKaleidoscopeVideoLength,
    middle: playbackParameters.middleKaleidoscopeVideoLength,
    end: playbackParameters.endKaleidoscopeVideoLength,
  });

  const [showStatements, setShowStatements] = useState(playbackParameters.showStatements);

  const { currentUser } = useAuth();
  const userId = currentUser?.uid;

  function saveSettings() {
    firestore
      .collection("users")
      .doc(userId)
      .collection("VisionMovies")
      .doc(data.visionMovieId)
      .set({
        ...data,
        playbackParameters: {
          playVisionMovieNumberOfTimes: Number(timesPlayMM),
          playKaleidoscopeVideo,
          startKaleidoscopeVideoLength: kaleidoscopeVideoLength.start,
          middleKaleidoscopeVideoLength: kaleidoscopeVideoLength.middle,
          endKaleidoscopeVideoLength: kaleidoscopeVideoLength.end,
          showStatements,
        },
      })
      .then(() => {
        console.log("Settings updated successfully!");
      })
      .catch((error) => {
        alert("Something went wrong");
        console.error("Error updating settings: ", error);
      });
    setClosedCard(true);
  }

  return (
    <OpenCardWithSettingsDiv fixedPosition={window.innerWidth < 530 ? true : false}>
      <div
        onClick={() => saveSettings()}
        style={{
          zIndex: 10,
          position: "absolute",
          right: 15,
          top: 7,
          cursor: "pointer",
        }}
      >
        <img src="../../close.svg" />
      </div>
      <ElementsContainer>
        <SettingContainer marginTop={window.innerWidth > 480 ? 10 : 70}>
          <Text topPadding>Play VisionMovie</Text>
          <TextField
            value={Math.floor(Number(timesPlayMM))}
            sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "none" } }}
            inputProps={{
              style: {
                outline: "none",
                color: theme.palette.primary.main,
                fontWeight: "bold",
                fontSize: 20,
                paddingTop: 7,
                paddingBottom: 4,
              },
            }}
            id="outlined-number"
            type="number"
            style={{
              border: "none",
              background: "white",
              borderRadius: 4,
              width: 60,
              marginLeft: 15,
              marginRight: 15,
            }}
            size="small"
            onChange={(e) => changeTimesPlayMM(e.target.value)}
          />
          <Text topPadding>times</Text>
        </SettingContainer>
        <SettingContainer
          marginTop={window.innerWidth >= 900 && window.innerWidth < 1107 ? 10 : 25}
        >
          <Text style={{ marginRight: 10 }}>Play Kaleidoscope video</Text>
          <Switch
            style={{ color: "white" }}
            checked={playKaleidoscopeVideo}
            onChange={() => setPlayKaleidoscopeVideo((prev) => !prev)}
          />
        </SettingContainer>
        {playKaleidoscopeVideo && (
          <KaleidoscopeTextFields
            marginTop={window.innerWidth >= 900 && window.innerWidth < 1107 ? 3 : 8}
            middleTextFieldDisabled={middleTextFieldDisabled}
            kaleidoscopeVideoLength={kaleidoscopeVideoLength}
            setKaleidoscopeVideoLength={setKaleidoscopeVideoLength}
          />
        )}
        <SettingContainer
          marginTop={window.innerWidth >= 900 && window.innerWidth < 1107 ? 10 : 25}
        >
          <Text style={{ marginRight: 10 }}>
            Show statements
            {!(window.innerWidth >= 900 && window.innerWidth < 930) && " in VisionMovie"}
          </Text>
          <Switch
            style={{ color: "white" }}
            checked={showStatements}
            onChange={() => setShowStatements((prev) => !prev)}
          />
        </SettingContainer>
      </ElementsContainer>
    </OpenCardWithSettingsDiv>
  );
}
