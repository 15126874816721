import Button from "@mui/material/Button";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { useVisionMovieContext } from "../VisionMovieContext";
import { useWebinarContext } from "../softwareWebinar/WebinarContext";
import { Action } from "../softwareWebinar/Actions";
import WebinarButtonWrapper from "../softwareWebinar/WebinarButtonWrapper";
import { useTranslation } from "react-i18next";

export default function PlayVisionMovieButton() {
  const navigate = useWebinarContext().navigate_WebinarWrapper;
  const { actionEnabled } = useWebinarContext();
  const { visionMovieData } = useVisionMovieContext();
  const { t } = useTranslation();

  return (
    <WebinarButtonWrapper>
      <Button
        onClick={() => {
          if (actionEnabled(Action.PlayMindMovie)) navigate("/newplayer");
        }}
        style={{
          textTransform: "none",
          borderRadius: "6px",
          fontWeight: "bold",
        }}
        variant="contained"
        disableElevation
        disabled={visionMovieData?.visionMovie.length === 0 ? true : false}
        startIcon={<PlayCircleIcon />}
      >
        {t("playVisionMovie")}
      </Button>
    </WebinarButtonWrapper>
  );
}
