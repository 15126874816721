import "./videojs.css";
import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./customvideojs.css";
export const VideoJS = (props: any) => {
  const videoRef = React.useRef<any>(null);
  const playerRef = React.useRef<any>(null);
  const { options, onReady }: any = props;

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");
      videoElement.className = "videojs-big-play-centered";
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      }));

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;

      if (options.sources[0].src !== player.src()) {
        player.src(options.sources[0].src);
      }
      if (player.autoplay() !== options.autoplay) {
        player.autoplay(options.autoplay);
      }

      if (player.controls() !== options.controls) {
        player.controls(options.controls);
      }

      if (player.muted() !== options.muted && options.muted !== undefined) {
        player.muted(options.muted);
      }
    }
  }, [videoRef, options]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
};

export default VideoJS;
