import { useState, useRef, useEffect } from "react";
import { css } from "@emotion/css";
import { up, Breakpoint } from "./Breakpoint";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import { useLocation } from "react-router-dom";

export default function GrowingInputField({ style, value, onChange }) {
  const currentPathname = useLocation().pathname;
  const [width, setWidth] = useState(0);
  const span = useRef();

  useEffect(() => {
    setWidth(span.current.offsetWidth);
  }, [value]);
  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    setWidth(span.current.offsetWidth);
  });

  const responsiveFontSize = {
    fontSize: 40,

    /* [up(500)]: { fontSize: 40 }, */
  };

  return (
    <>
      <span
        id="hide"
        style={{
          position: "absolute",
          opacity: 1,
          zIndex: currentPathname === "/fortschritt" ? 100 : -100,
          userSelect: "none",
          whiteSpace: "pre",
          fontWeight: "bold",
          ...style,
        }}
        className={css(responsiveFontSize)}
        ref={span}
      >
        {value}
      </span>
      <input
        type="text"
        value={value}
        style={{
          width,
          fontWeight: "bold",
          minWidth: 1,
          padding: 0,
          borderTopStyle: "hidden",
          borderRightStyle: "hidden",
          borderLeftStyle: "hidden",
          borderBottomStyle: "hidden",
          background: "transparent",
          ...style,
        }}
        className={css(responsiveFontSize)}
        onChange={(evt) => onChange(evt)}
      />
    </>
  );
}
