import { useState } from "react";
import UploadIcon from "../../../icons/uploadIcon.svg";
import { Row, Form, InputGroup, Col, Button } from "react-bootstrap";
import { bootStrapClass as bs } from "../Utils/HelperFunctions";
import { useBackgroundMusicContext } from "../Context/BackgroundMusicContext";
import ProgressBar from "../Utils/ProgressBar";
import { useTranslation } from "react-i18next";

const UploadSongRow = () => {
  let initialState = {
    songTitle: "",
    artistName: "",
    url: "",
    file: undefined,
  };
  let initialValidations = {
    songTitleError: "",
    artistNameError: "",
    urlError: "",
    fileError: "",
  };

  const { t } = useTranslation();

  const [songData, setSongData] = useState(initialState);
  const [validations, setValidations] = useState(initialValidations);

  const { songTitle, artistName, url, file } = songData;
  const { songTitleError, urlError, fileError } = validations;
  const { uploadToStorage, uploadStatus, uploadProgress } = useBackgroundMusicContext();

  const handleChange = (e) => {
    if (e.target.name === "url") {
      setSongData({ ...songData, [e.target.name]: e.target.value, file: e.target.files[0] });
      setValidations({ ...validations, urlError: "", fileError: "" });
    } else {
      setSongData({ ...songData, [e.target.name]: e.target.value });
      setValidations({ ...validations, [`${e.target.name}Error`]: "" });
    }
  };

  const HasErrors = () => {
    setValidations({
      songTitleError: songTitle === "" ? "Please Enter Song Title." : "",
      urlError: url === "" ? "Please Select Song File." : "",
      fileError: !file ? "Song File not selected Correctly. Please Select Again." : "",
    });
    if (songTitle === "" || !file || url === "") {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let hasErrors = HasErrors();
    if (!hasErrors) {
      uploadToStorage(songData);
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="mb-2">
      <Row className="songUploadRow">
        <Form.Group as={Col} md="1" className="d-flex flex-start p-0">
          <Button type="submit" className="p-0" variant="default">
            <img src={UploadIcon} alt="icon" className="icon" />
          </Button>
        </Form.Group>
        <Form.Group as={Col} md="3" style={{ marginLeft: -60 }}>
          <InputGroup>
            <Form.Control
              type="text"
              name="artistName"
              className="input"
              placeholder={t("artistName")}
              value={artistName}
              onChange={handleChange}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} md="3">
          <InputGroup hasValidation>
            <Form.Control
              name="songTitle"
              className="input"
              placeholder={t("songTitle")}
              type="text"
              value={songTitle}
              onChange={handleChange}
              isInvalid={songTitleError !== ""}
            />
            <Form.Control.Feedback type="invalid" tooltip>
              {songTitleError}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Col md="5">
          <Row className="songUploadRow">
            <Form.Group as={Col} md="9">
              <InputGroup hasValidation>
                <Form.Control
                  name="url"
                  className="input"
                  value={url}
                  type="file"
                  accept=".AAC,.FLAC,.wav,.mp3,.m4a"
                  onChange={handleChange}
                  hidden={!(uploadStatus !== "inProgress" && !uploadProgress)}
                  isInvalid={urlError !== "" || fileError !== ""}
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  {urlError || fileError}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            {uploadStatus !== "inProgress" && !uploadProgress ? (
              <Col md="3">
                <button className="uploadButton" type="submit">
                  upload
                </button>
              </Col>
            ) : (
              <Col md="12" className={`${bs.columnCenter} progressView`}>
                <ProgressBar uploadStatus={uploadStatus} uploadProgress={uploadProgress} />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default UploadSongRow;
