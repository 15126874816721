import { PartialBlock } from "@blocknote/core";

export const template: PartialBlock[] = [
  {
    content: [
      {
        text: "1.  In which situations employeename works:",
        type: "text",
        styles: { bold: true, textColor: "green", backgroundColor: "green" },
      },
    ],
    id: "6aa4706b-efc7-418b-989a-7c59c72e539e",
    props: { backgroundColor: "default", textColor: "default", level: 2, textAlignment: "left" },
    children: [],
    type: "heading",
  },
  {
    id: "4da5c158-2993-495a-957f-212cbe4cda7e",
    children: [],
    props: { textAlignment: "left", textColor: "default", backgroundColor: "default" },
    content: [
      { styles: { italic: true, bold: true }, type: "text", text: "Note: " },
      {
        text: "Enter a clear description of the situation here.",
        styles: { italic: true },
        type: "text",
      },
    ],
    type: "paragraph",
  },
  {
    id: "2552685e-c070-47a4-a0e4-364139c2ebec",
    content: [],
    type: "paragraph",
    props: { textColor: "default", textAlignment: "left", backgroundColor: "default" },
    children: [
      {
        id: "6b500696-4d5f-4978-92c5-e65be0912069",
        children: [],
        type: "bulletListItem",
        content: [{ styles: {}, type: "text", text: "Enter situation here [1]." }],
        props: { backgroundColor: "default", textColor: "default", textAlignment: "left" },
      },
    ],
  },
  {
    props: { textAlignment: "left", backgroundColor: "default", textColor: "default" },
    content: [],
    type: "paragraph",
    children: [],
    id: "ec7d0fa4-d2e8-41b6-8656-d28050be24f2",
  },
  {
    type: "paragraph",
    id: "e58da20f-1e04-462a-87ae-78909ca7a705",
    content: [],
    props: { textColor: "default", textAlignment: "left", backgroundColor: "default" },
    children: [],
  },
  {
    content: [
      {
        styles: { bold: true, textColor: "blue", backgroundColor: "blue" },
        text: "2. Thoughts employeename shares with me in these situations:",
        type: "text",
      },
    ],
    type: "heading",
    props: { textColor: "default", level: 2, backgroundColor: "default", textAlignment: "left" },
    id: "347b24dc-22f9-4bed-8a4f-5ff44819aeb2",
    children: [],
  },
  {
    id: "0f1ace3b-f7b5-4aec-b382-09adba421b48",
    type: "paragraph",
    content: [
      { type: "text", styles: { bold: true, italic: true }, text: "Note: " },
      {
        text: "Enter the exact word here that employeename puts in your head.",
        styles: { italic: true },
        type: "text",
      },
    ],
    props: { backgroundColor: "default", textColor: "default", textAlignment: "left" },
    children: [],
  },
  {
    id: "59519718-fdd7-49db-89a4-985cf2a8e457",
    children: [
      {
        props: { textColor: "default", textAlignment: "left", backgroundColor: "default" },
        id: "a163153d-7950-4514-b7ad-d7147349e826",
        content: [
          {
            type: "text",
            styles: {},
            text: "Enter the exact word here that employeename puts in your head.",
          },
        ],
        type: "bulletListItem",
        children: [],
      },
    ],
    props: { textColor: "default", backgroundColor: "default", textAlignment: "left" },
    content: [],
    type: "paragraph",
  },
  {
    id: "7c3b4baf-8013-4857-b2ca-5dadb4829209",
    children: [],
    type: "paragraph",
    content: [],
    props: { backgroundColor: "default", textColor: "default", textAlignment: "left" },
  },
  {
    props: { textAlignment: "left", backgroundColor: "default", textColor: "default" },
    id: "ebc57e47-f81f-4de0-b5ab-7d7f3f9721c3",
    content: [],
    type: "paragraph",
    children: [],
  },
  {
    id: "d5c41256-1724-4931-8416-f15d0a22ed0f",
    content: [
      {
        styles: { textColor: "blue", backgroundColor: "blue", bold: true },
        type: "text",
        text: "3.",
      },
      {
        styles: { bold: true, textColor: "blue", backgroundColor: "blue", italic: true },
        text: " ",
        type: "text",
      },
      {
        type: "text",
        text: "Emotions employeename sends me in these situations:",
        styles: { textColor: "blue", backgroundColor: "blue", bold: true },
      },
    ],
    type: "heading",
    props: { level: 2, backgroundColor: "default", textAlignment: "left", textColor: "default" },
    children: [],
  },
  {
    content: [
      { type: "text", text: "Note: ", styles: { bold: true, italic: true } },
      {
        text: "Describe the emotions that employeename activates within your body. You don't need to use the normal emotional labels here. Describe it in whichever words work best for you.",
        styles: { italic: true },
        type: "text",
      },
    ],
    type: "paragraph",
    children: [],
    id: "b1d48b31-ef82-470d-a6a2-21a1df3e06e6",
    props: { backgroundColor: "default", textAlignment: "left", textColor: "default" },
  },
  {
    props: { backgroundColor: "default", textColor: "default", textAlignment: "left" },
    children: [
      {
        content: [{ type: "text", text: "Describe the emotions here", styles: {} }],
        children: [],
        id: "d6392d9d-562f-4229-a11e-238483462d82",
        props: { textAlignment: "left", textColor: "default", backgroundColor: "default" },
        type: "bulletListItem",
      },
    ],
    id: "9bdb4870-22a1-49cb-a6c9-f67c3ef49888",
    content: [],
    type: "paragraph",
  },
  {
    id: "00b3a9fd-67c7-4de6-9ada-7a7e68de6ddc",
    content: [],
    children: [],
    type: "paragraph",
    props: { backgroundColor: "default", textAlignment: "left", textColor: "default" },
  },
  {
    content: [],
    props: { textAlignment: "left", textColor: "default", backgroundColor: "default" },
    children: [],
    type: "paragraph",
    id: "b8079855-cb16-4546-b030-7e3be85fd50c",
  },
  {
    children: [],
    props: { textAlignment: "left", level: 2, backgroundColor: "default", textColor: "default" },
    id: "8f80b002-b903-464a-8d88-40724331c1e0",
    type: "heading",
    content: [
      {
        text: "4.",
        styles: { bold: true, textColor: "blue", backgroundColor: "green" },
        type: "text",
      },
      {
        text: " ",
        styles: { bold: true, italic: true, backgroundColor: "green", textColor: "blue" },
        type: "text",
      },
      {
        text: "Physiological impulses in your body:",
        styles: { backgroundColor: "blue", bold: true, textColor: "blue" },
        type: "text",
      },
    ],
  },
  {
    props: { textAlignment: "left", textColor: "default", backgroundColor: "default" },
    type: "paragraph",
    id: "3216254f-a5b4-4843-872d-a92700f61bed",
    children: [],
    content: [
      { type: "text", text: "Note: ", styles: { bold: true, italic: true } },
      {
        type: "text",
        text: "Describe how your body feels. Are there any areas of increased tension or activation. Please describe it here and also mention the exact location if possible.",
        styles: { italic: true },
      },
    ],
  },
  {
    content: [],
    children: [
      {
        content: [{ styles: {}, text: "Describe the physiological impulses here", type: "text" }],
        props: { textColor: "default", backgroundColor: "default", textAlignment: "left" },
        type: "bulletListItem",
        children: [],
        id: "7d90b5fa-e35f-469a-9de3-b8cd8bb1c2cf",
      },
    ],
    props: { textColor: "default", backgroundColor: "default", textAlignment: "left" },
    type: "paragraph",
    id: "e6dc7a8c-40bc-445c-b329-573c10a6113a",
  },
  {
    content: [],
    children: [],
    id: "3672f720-21c6-4cf8-8cdf-b7da2575b0f5",
    props: { textAlignment: "left", backgroundColor: "default", textColor: "default" },
    type: "paragraph",
  },
  {
    content: [],
    type: "paragraph",
    props: { backgroundColor: "default", textColor: "default", textAlignment: "left" },
    id: "ed3456d6-a518-41aa-9094-1480f425f396",
    children: [],
  },
  {
    type: "heading",
    children: [],
    content: [
      {
        text: "5.",
        styles: { bold: true, backgroundColor: "green", textColor: "blue" },
        type: "text",
      },
      {
        type: "text",
        styles: { backgroundColor: "blue", bold: true, italic: true, textColor: "blue" },
        text: " ",
      },
      {
        type: "text",
        styles: { bold: true, backgroundColor: "blue", textColor: "blue" },
        text: "Escaping the situation behaviors:",
      },
    ],
    props: { textColor: "default", backgroundColor: "default", level: 2, textAlignment: "left" },
    id: "6621ce88-77f3-45a7-9ceb-ed97cb5cac14",
  },
  {
    props: { textAlignment: "left", backgroundColor: "default", textColor: "default" },
    content: [
      { text: "Note: ", styles: { italic: true, bold: true }, type: "text" },
      {
        type: "text",
        text: "What does employeename want you to do now? What do you normally do in these situations? Sometimes, employees influence your behavior or give you a recommendation in the form of a thought or an urge to do something. These are the behaviors your employee has found for these situations to move you from what it perceives as a dangerous situation to a safer one.",
        styles: { italic: true },
      },
    ],
    type: "paragraph",
    children: [],
    id: "c4c0769e-eeaf-4fcc-9dc3-f26fc469e8a2",
  },
  {
    content: [],
    children: [
      {
        content: [{ text: 'Describe what "you want" to do now', type: "text", styles: {} }],
        id: "3b87833d-0255-4a4c-9618-9e17610167cb",
        children: [],
        props: { textColor: "default", backgroundColor: "default", textAlignment: "left" },
        type: "bulletListItem",
      },
    ],
    props: { textAlignment: "left", textColor: "default", backgroundColor: "default" },
    id: "e816b5a7-e0f7-42d4-928c-dd6ffa618e1e",
    type: "paragraph",
  },
  {
    props: { textAlignment: "left", textColor: "default", backgroundColor: "default" },
    content: [],
    id: "2c76c27f-b53c-4f1d-97ed-aa2350fef2ee",
    children: [],
    type: "paragraph",
  },
  {
    id: "dba0f3d8-6a91-4958-89e7-1dc04afb44d1",
    children: [],
    content: [],
    type: "paragraph",
    props: { textColor: "default", textAlignment: "left", backgroundColor: "default" },
  },
  {
    id: "841b597f-f330-4a5a-8412-70a0cbeaf276",
    children: [],
    content: [
      {
        text: "6.",
        styles: { textColor: "pink", bold: true, backgroundColor: "pink" },
        type: "text",
      },
      {
        text: " ",
        type: "text",
        styles: { bold: true, backgroundColor: "pink", italic: true, textColor: "pink" },
      },
      {
        type: "text",
        text: "How employeename wants to help me:",
        styles: { bold: true, textColor: "pink", backgroundColor: "pink" },
      },
    ],
    type: "heading",
    props: { backgroundColor: "default", textColor: "default", level: 2, textAlignment: "left" },
  },
  {
    id: "ef60b224-7866-434f-b149-6fd6e365073e",
    children: [],
    props: { textColor: "default", textAlignment: "left", backgroundColor: "default" },
    type: "paragraph",
    content: [
      { styles: { italic: true, bold: true }, text: "Note: ", type: "text" },
      {
        styles: { italic: true },
        type: "text",
        text: "What is the main reason and assumption of [enter Name]? Why is [enter Name] there? ",
      },
    ],
  },
  {
    props: { backgroundColor: "default", textAlignment: "left", textColor: "default" },
    children: [
      {
        type: "bulletListItem",
        children: [],
        content: [{ text: "Describe it here", styles: {}, type: "text" }],
        id: "d0c99aea-4281-4814-807e-7b42d99b2b7b",
        props: { backgroundColor: "default", textAlignment: "left", textColor: "default" },
      },
    ],
    content: [],
    id: "5874b260-6c84-432f-90cf-ffab03c48b00",
    type: "paragraph",
  },
  {
    children: [],
    props: { textAlignment: "left", backgroundColor: "default", textColor: "default" },
    content: [],
    id: "ced391e4-ca82-4077-8e39-d2681c8da4f3",
    type: "paragraph",
  },
  {
    type: "paragraph",
    props: { textAlignment: "left", textColor: "default", backgroundColor: "default" },
    content: [],
    id: "c9e48558-3472-4959-a513-783b143022de",
    children: [],
  },
  {
    children: [],
    props: { level: 1, textColor: "default", backgroundColor: "default", textAlignment: "left" },
    content: [
      {
        text: "7. Reasons for employment - your past:",
        styles: { bold: true, backgroundColor: "yellow", textColor: "orange" },
        type: "text",
      },
    ],
    type: "heading",
    id: "2093e780-92a2-46a8-9168-457d1c4b0f88",
  },
  {
    type: "paragraph",
    id: "f875388b-7b5c-4ba9-a51e-86cde084c4aa",
    props: { backgroundColor: "default", textColor: "default", textAlignment: "left" },
    content: [
      { type: "text", text: "Note: ", styles: { italic: true, bold: true } },
      {
        type: "text",
        text: "This is an optional part of the subconscious employees framework. We don't need to know everything about why your employee is there to effectively work with it. It however will help you to gain more empathy for him and yourself.",
        styles: { italic: true },
      },
    ],
    children: [],
  },
  {
    content: [],
    type: "paragraph",
    children: [],
    id: "8f001928-bb91-4572-aafa-674ca1464e49",
    props: { backgroundColor: "default", textColor: "default", textAlignment: "left" },
  },
  {
    id: "df967195-1ee1-43a8-a1df-b2627438909e",
    content: [
      {
        type: "text",
        styles: { textColor: "orange", backgroundColor: "yellow", bold: true },
        text: "Reason for employment:",
      },
    ],
    type: "heading",
    props: { level: 2, backgroundColor: "default", textAlignment: "left", textColor: "default" },
    children: [],
  },
  {
    id: "fbe99f62-a8b5-4f93-a023-57c748b16016",
    type: "paragraph",
    content: [
      { type: "text", text: "Note:", styles: { bold: true, italic: true } },
      { type: "text", text: " ", styles: { bold: true } },
      {
        styles: { italic: true },
        type: "text",
        text: "During your life one moment must have happened why this employee got employed?",
      },
    ],
    props: { textAlignment: "left", textColor: "default", backgroundColor: "default" },
    children: [],
  },
  {
    props: { backgroundColor: "default", textColor: "default", textAlignment: "left" },
    type: "paragraph",
    content: [],
    id: "9e855f6e-8630-4e51-8d1c-847c507d949d",
    children: [
      {
        children: [],
        content: [{ text: "Describe what happened here", type: "text", styles: {} }],
        type: "bulletListItem",
        props: { textAlignment: "left", textColor: "default", backgroundColor: "default" },
        id: "bc57f702-49c2-4fe0-8b92-f5d3be433494",
      },
    ],
  },
  {
    content: [],
    id: "f08e1069-b837-48d4-9f46-6a2257c53782",
    props: { backgroundColor: "default", textColor: "default", textAlignment: "left" },
    children: [],
    type: "paragraph",
  },
  {
    props: { level: 2, textColor: "default", textAlignment: "left", backgroundColor: "default" },
    children: [],
    content: [
      {
        type: "text",
        text: "Childhood:",
        styles: { backgroundColor: "yellow", bold: true, textColor: "orange" },
      },
    ],
    id: "e366b980-17cd-4b93-9881-fa8463f686e2",
    type: "heading",
  },
  {
    props: { textAlignment: "left", textColor: "default", backgroundColor: "default" },
    type: "paragraph",
    content: [
      { text: "Note:", type: "text", styles: { bold: true, italic: true } },
      { text: " ", type: "text", styles: {} },
      {
        styles: { italic: true },
        text: "Note which moments happened in your childhood that showed [enter Name] that he needs to work for you.",
        type: "text",
      },
    ],
    children: [],
    id: "bf1df8fb-6464-4fdc-9827-4b2f86e35c08",
  },
  {
    children: [
      {
        type: "bulletListItem",
        content: [{ text: "Describe what happened here", type: "text", styles: {} }],
        props: { backgroundColor: "default", textAlignment: "left", textColor: "default" },
        id: "bac119d4-5161-4dd9-a648-af6d198789ce",
        children: [],
      },
    ],
    id: "70432f31-7708-4f09-966b-7ec61d3b7d06",
    type: "paragraph",
    content: [],
    props: { backgroundColor: "default", textColor: "default", textAlignment: "left" },
  },
  {
    id: "4724d3ae-8832-48ef-b27e-587b3ba04d47",
    content: [],
    children: [],
    props: { backgroundColor: "default", textColor: "default", textAlignment: "left" },
    type: "paragraph",
  },
  {
    id: "309b913f-315f-4d1c-b3e1-949d86e5b4ce",
    props: { textAlignment: "left", backgroundColor: "default", textColor: "default", level: 2 },
    children: [],
    content: [
      {
        type: "text",
        text: "Youth:",
        styles: { backgroundColor: "yellow", textColor: "orange", bold: true },
      },
    ],
    type: "heading",
  },
  {
    type: "paragraph",
    props: { textAlignment: "left", backgroundColor: "default", textColor: "default" },
    children: [],
    id: "31714c46-a54c-4e3b-8a29-1e2f7a091959",
    content: [
      { text: "Note:", type: "text", styles: { italic: true, bold: true } },
      { type: "text", styles: { bold: true }, text: " " },
      {
        text: "Note which moments happened in your youth that showed [enter Name] that he needs to work for you.",
        styles: { italic: true },
        type: "text",
      },
    ],
  },
  {
    children: [],
    props: { backgroundColor: "default", textColor: "default", textAlignment: "left" },
    id: "a25023e1-77b2-41c1-8f5c-ec777200d668",
    type: "paragraph",
    content: [],
  },
  {
    content: [{ text: "Describe what happened here", type: "text", styles: {} }],
    props: { textAlignment: "left", textColor: "default", backgroundColor: "default" },
    type: "bulletListItem",
    id: "d21a7f03-fa51-4b7f-821b-fc26ed5f054d",
    children: [],
  },
  {
    props: { textColor: "default", textAlignment: "left", backgroundColor: "default" },
    content: [],
    children: [],
    type: "paragraph",
    id: "d36a7feb-1cfc-45f1-9311-9778e1a1688b",
  },
  {
    id: "27ce035d-3486-41ee-ba7c-16dadc2e026f",
    props: { backgroundColor: "default", textColor: "default", textAlignment: "left", level: 2 },
    content: [
      {
        text: "Adulthood:",
        type: "text",
        styles: { bold: true, textColor: "orange", backgroundColor: "yellow" },
      },
    ],
    children: [],
    type: "heading",
  },
  {
    children: [],
    id: "cd2be90f-ba99-43ac-b663-cc56c960658a",
    type: "paragraph",
    content: [
      { styles: { italic: true, bold: true }, type: "text", text: "Note:" },
      { text: " ", styles: {}, type: "text" },
      {
        type: "text",
        styles: { italic: true },
        text: "Note which moments happened in your adulthood that showed [enter Name] that he needs to work for you.",
      },
    ],
    props: { textColor: "default", backgroundColor: "default", textAlignment: "left" },
  },
  {
    children: [
      {
        type: "bulletListItem",
        content: [{ type: "text", text: "Describe what happened here", styles: {} }],
        props: { textAlignment: "left", backgroundColor: "default", textColor: "default" },
        children: [],
        id: "3e3a1d06-f0d6-4acd-a6a8-68720426f293",
      },
    ],
    type: "paragraph",
    content: [],
    props: { textAlignment: "left", backgroundColor: "default", textColor: "default" },
    id: "eda3a7d8-84b3-456d-9fdd-7538830c34f1",
  },
  {
    content: [],
    id: "58ae650f-c914-4f12-848e-bcabbd5ce3a4",
    children: [],
    props: { backgroundColor: "default", textColor: "default", textAlignment: "left" },
    type: "paragraph",
  },
];
