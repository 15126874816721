import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export default function Item({ index, dragging, showNumber }) {
  const theme = useTheme();
  const { palette } = theme;

  const { t } = useTranslation();

  const Container = styled.div({
    position: "relative",
    marginTop: "4.5vw",
    marginBottom: "4.5vw",
    background: "pink",
    height: 1,
  });

  const Line = styled.div({
    background: palette.grey.light,
    width: "100%",
    height: "0.2vw",
    position: "absolute",
    top: "50%",
    transform: "translate(0%,-50%)",
  });

  const ArchiveLabel = styled.div({
    background: palette.grey.light,
    display: "flex",
    alignItems: "center",
    paddingLeft: "0.7vw",
    paddingRight: "0.7vw",
    color: palette.primary.main,
    fontSize: "1.1vw",
    fontFamily: "Poppins",
    borderRadius: 5,
    position: "absolute",
    top: "50%",
    left: "0.7vw",
    transform: "translate(0%,-50%)",
    WebkitUserSelect: "none",
  });

  return (
    <Container>
      <Line />
      <ArchiveLabel>
        <FontAwesomeIcon style={{ marginRight: "0.3vw" }} icon={faArrowAltCircleDown} />

        {t("yourArchive")}
      </ArchiveLabel>
    </Container>
  );
}
