import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";

//Creating the Conexts
export const MultiButtonStateContext = React.createContext();

export function useMultiButtonState() {
  return useContext(MultiButtonStateContext);
}

//Creating the logic and the Providers
export function MultiButtonStateProvider({ children }) {
  const currentPathname = useLocation().pathname;
  const [multiButtonState, setMultiButtonState] = useState(() => {
    switch (currentPathname) {
      case "/backgroundmusic":
        return 1;
      case "/kaleidoscope":
        return 2;
      case "/employees":
        return 3;
      default:
        return 0;
    }
  });

  const functions = {
    multiButtonState,
    setMultiButtonState,
  };

  return (
    <MultiButtonStateContext.Provider value={functions}>
      {children}
    </MultiButtonStateContext.Provider>
  );
}
