import { useEffect, useLayoutEffect, useState } from "react";
import { firestore } from "../Firebase/firebase";
import { useAuth } from "../Firebase/Contexts/AuthContext";
import { useLocation } from "react-router-dom";
import { splitInRows } from "../backgroundSelection/helperFunctions";
import { FixedSizeList as List } from "react-window";
import { ListRow } from "../backgroundSelection/ListRow";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import { UploadedVideosContextProvider } from "./UploadedVideosContext";
import useCalculateGridCardSize from "../segments/helperHooks/useCalculateGridCardSize";

export default function UploadVideos() {
  const gridCardSize = useCalculateGridCardSize();

  //HEIGHT & WIDTH____
  //Calculates width, height + resulting rowHeight
  //It recalculates these values when window is resized
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  });
  function calculateRowHeight(windowWidth) {
    const number = gridCardSize === 3 ? 4 : gridCardSize === 4 ? 3 : 1.96;
    return (((windowWidth - 16 * 4) / number) * 1080) / 1920 + 16;
  }
  //____

  const currentPathname = useLocation().pathname;

  // Get current loggedIn user from firebase
  const { currentUser } = useAuth();
  const userId = currentUser?.uid;

  const [uploadedVideos, setUploadedVideos] = useState([]);
  const [uploadedVideosInRows, setUploadedVideosInRows] = useState([
    [{ uploadingPlaceholder: true }],
  ]);

  useEffect(() => {
    //Add placeholder to List of videos
    const preparedUploadedVideos =
      uploadedVideos?.length !== 0
        ? [{ uploadingPlaceholder: true }, ...uploadedVideos]
        : [{ uploadingPlaceholder: true }];
    //Split list of video in rows for the List
    const responsiveNrOfVideosPerRow = gridCardSize === 3 ? 4 : gridCardSize === 4 ? 3 : 2;
    const uploadedVideosSplitInRows = splitInRows(
      preparedUploadedVideos,
      responsiveNrOfVideosPerRow
    );
    setUploadedVideosInRows(uploadedVideosSplitInRows);
  }, [uploadedVideos, gridCardSize]);

  //Path to uploaded videos array in firebase
  const uploadedVideosRef = firestore
    .collection("users")
    .doc(userId)
    .collection("UploadedVideos")
    .doc("all");
  //Setup snapshot and get uploaded videos data from firebase before component renders
  useLayoutEffect(() => {
    if (currentUser?.uid) {
      uploadedVideosRef.onSnapshot((docRef) => {
        if (docRef?.data()?.uploadedVideos) {
          setUploadedVideos(docRef.data().uploadedVideos);
        }
      });
    }
  }, [currentUser]);

  return (
    <UploadedVideosContextProvider uploadedVideosInRows={uploadedVideosInRows}>
      <List
        height={windowHeight - 60}
        itemCount={uploadedVideosInRows.length}
        itemSize={calculateRowHeight(windowWidth)}
        overscanCount={7}
      >
        {ListRow(gridCardSize, uploadedVideosInRows, uploadedVideosInRows.length, currentPathname)}
      </List>
    </UploadedVideosContextProvider>
  );
}
