import React, { useState, useEffect } from "react";
import { useAuth } from "../../Firebase/Contexts/AuthContext";
import { firestore } from "../../Firebase/firebase";
import Stack from "@mui/material/Stack";
import Card from "../recordProgress/Card";
import Calendar from "../recordProgress/Calendar";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Index() {
  // Get current loggedIn user from firebase
  const { currentUser } = useAuth();
  const { t } = useTranslation();

  const [activeToDoIndex, setActiveToDoIndex] = React.useState(0);
  const [currentSegment, setCurrentSegment] = useState();
  const [toDos, setTodos] = useState([]);
  const [coachings, setCoachings] = useState(undefined);
  const [visionMoviesSegments, setVisionMoviesSegments] = useState([]);

  useEffect(() => {
    if (
      toDos[activeToDoIndex]?.visionMovieSegmentId &&
      toDos.length !== 0 &&
      visionMoviesSegments.length !== 0
    ) {
      const visionMovieSegmentId = toDos[activeToDoIndex]?.visionMovieSegmentId;
      const segment = visionMoviesSegments.find((elem) => elem.id === visionMovieSegmentId);
      setCurrentSegment(segment);
    }
  }, [activeToDoIndex, toDos, visionMoviesSegments]);

  useEffect(() => {
    if (currentUser?.uid) {
      updateTrackingData();
      updateCoachings();
      updateVisionMoviesData();
    }
  }, []);

  const updateTrackingData = () => {
    firestore
      .collection("users")
      .doc(currentUser?.uid)
      .collection("TrackedToDos")
      .onSnapshot((docRef) => {
        const toDosArray = [];
        if (docRef?.docs) {
          docRef.docs.map((doc) => {
            toDosArray.push(doc.data());
          });
          setTodos(toDosArray);
        }
      });
  };

  const updateCoachings = () => {
    firestore
      .collection("users")
      .doc(currentUser?.uid)
      .collection("Coachings")
      .onSnapshot((docRef) => {
        const coachingsArray = [];
        if (docRef?.docs) {
          docRef.docs.map((doc, index) => {
            coachingsArray.push(doc.data());
          });

          //Sort VisionMovies by displayIndexInApp
          coachingsArray.sort((a, b) => {
            return a.number - b.number;
          });
          setCoachings(coachingsArray);
        }
      });
  };

  const updateVisionMoviesData = () => {
    firestore
      .collection("users")
      .doc(currentUser?.uid)
      .collection("VisionMovies")
      .onSnapshot((docRef) => {
        const itemsArray = [];
        if (docRef?.docs) {
          const visionMoviesArray = docRef.docs
            .map((e) => e.data())
            .sort((a, b) => a.displayIndexInApp - b.displayIndexInApp);
          for (let i = 0; i < docRef.docs.length; i++) {
            visionMoviesArray[i].visionMovie.map((elem) =>
              itemsArray.push({
                id: elem.id,
                image: elem.image,
                contrastLayerOpacityValue: elem.contrastLayerOpacityValue,
                video: { image: elem?.video?.image, uploadedVideo: elem?.video?.uploadedVideo },
                affirmation: elem.affirmation,
              })
            );
            visionMoviesArray[i].archive.map((elem) =>
              itemsArray.push({
                id: elem.id,
                image: elem.image,
                contrastLayerOpacityValue: elem.contrastLayerOpacityValue,
                video: { image: elem?.video?.image, uploadedVideo: elem?.video?.uploadedVideo },
                affirmation: elem.affirmation,
              })
            );
          }
          setVisionMoviesSegments(itemsArray);
        }
      });
  };

  return (
    <Stack
      style={{
        width: "100%",
        minHeight: "100vh",
        paddingTop: 18,
        paddingBottom: 100,
        paddingLeft: 40,
        paddingRight: 40,
        background: "#F4F6F9",
      }}
      spacing={4}
      alignItems="center"
    >
      {toDos.map((toDo, index) => {
        const visionMovieSegmentId = toDo.visionMovieSegmentId;
        const segment = visionMoviesSegments.find((elem) => elem.id === visionMovieSegmentId);

        return (
          <Stack
            direction={"column"}
            style={{
              width: "100%",
              maxWidth: 1500,
            }}
            spacing={4}
          >
            {index === 0 && (
              <Link to={"/"}>
                <Button
                  style={{ textTransform: "none", borderRadius: "6px", fontWeight: "bold" }}
                  variant="contained"
                  disableElevation
                  startIcon={<ArrowBackIcon />}
                >
                  {t("SAVE")}
                </Button>
              </Link>
            )}
            <Stack
              direction={"row"}
              style={{
                width: "100%",
              }}
              spacing={4}
            >
              <Stack style={{ width: "45%" }}>{segment && <Card segment={segment} />}</Stack>

              <Stack style={{ width: "55%" }}>
                <Calendar
                  activeToDoIndex={activeToDoIndex}
                  toDo={toDo}
                  coachings={coachings}
                  showTrackingButtons={false}
                />
              </Stack>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
}
