import { useTheme } from "@mui/material/styles";
import { useState, useRef, useEffect } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { useVisionMovieContext } from "../VisionMovieContext";
import { AffirmationStyles } from "../affirmations/AffirmationStyles";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Affirmation({
  segment,
  scalingFactor,
  mouseOverCard,
  dragging,
  isDragOverlay,
  hasVideoOrImage,
}) {
  const { t } = useTranslation();

  const theme = useTheme();
  const { palette } = theme;

  const headerRef = useRef(null);
  const paragraphRef = useRef(null);

  const [header, setHeader] = useState(segment.affirmation.header);
  const [headerChanged, setHeaderChanged] = useState(false);
  const [paragraph, setParagraph] = useState(segment.affirmation.paragraph);
  const [paragraphChanged, setParagraphChanged] = useState(false);
  const { changeText } = useVisionMovieContext();

  useEffect(() => {
    setHeader(segment.affirmation.header);
    setParagraph(segment.affirmation.paragraph);
  }, [segment]);

  // Deactivates input fields onMouseLeave Card
  // Saves Text changes to database when leaving card
  useEffect(() => {
    //When leaving card
    if (headerRef && paragraphRef && !mouseOverCard) {
      headerRef.current.blur();
      paragraphRef.current.blur();
    }
  }, [mouseOverCard]);

  const handleHeaderInputfieldLeave = () => {
    if (headerChanged) {
      //Saves text changes to database
      changeText(segment.id, header, paragraph);
      setHeaderChanged(false);
      setParagraphChanged(false);
    }
  };

  const handleParagraphInputfieldLeave = () => {
    if (paragraphChanged) {
      //Saves text changes to database
      changeText(segment.id, header, paragraph);
      setHeaderChanged(false);
      setParagraphChanged(false);
    }
  };

  //Get header width for setting Line width
  const [headerWidth, setWidthHeader] = useState(0);
  const headerCopyToGetWidthRef = useRef();
  useEffect(() => {
    if (headerCopyToGetWidthRef.current && scalingFactor) {
      headerCopyToGetWidthRef.current.innerText = header;
      if (headerCopyToGetWidthRef.current.clientWidth < 285 * scalingFactor && header !== "") {
        setWidthHeader(headerCopyToGetWidthRef.current.clientWidth);
      } else {
        setWidthHeader(285 * scalingFactor);
      }
    }
  }, [header, headerCopyToGetWidthRef.current, scalingFactor]);

  const styling = AffirmationStyles.standardStyles;

  //STYLING
  const inputStyle = {
    border: "none",
    fontFamily: styling.fontType,
    caretColor: hasVideoOrImage ? "white" : palette.primary.main,
  };
  const headerInputFieldStyle = {
    ...inputStyle,
    color: hasVideoOrImage ? "white" : palette.primary.main,
    backgroundColor: !isDragOverlay
      ? (mouseOverCard && !dragging) || !header
        ? hasVideoOrImage
          ? "rgba(255, 255, 255, 0.2)"
          : "white"
        : "transparent"
      : header
      ? "transparent"
      : hasVideoOrImage
      ? "rgba(255, 255, 255, 0.2)"
      : "white",
    paddingBottom: 0,
    marginBottom: 0,
    textTransform: "uppercase",
    height: 165 * scalingFactor,
    width: 1200 * scalingFactor,
    fontSize: 130 * scalingFactor,
    fontWeight: 500,
    WebkitUserSelect: "none",
  };

  const lineStyle = {
    background: hasVideoOrImage
      ? "white"
      : header || paragraph
      ? palette.primary.main
      : palette.primary.opacity60,
    height: 13 * scalingFactor,
    minHeight: 2,
    borderRadius: 1,
    marginTop: 40 * scalingFactor,
    marginBottom: 45 * scalingFactor,
    WebkitUserSelect: "none",
  };

  const paragraphInputFieldStyle = {
    ...inputStyle,
    color: hasVideoOrImage ? "white" : palette.primary.main,
    backgroundColor: !isDragOverlay
      ? (mouseOverCard && !dragging) || !paragraph
        ? hasVideoOrImage
          ? "rgba(255, 255, 255, 0.2)"
          : "white"
        : "transparent"
      : paragraph
      ? "transparent"
      : hasVideoOrImage
      ? "rgba(255, 255, 255, 0.2)"
      : "white",
    //width: 1240 * scalingFactor,
    fontSize: styling.paragraphTextSize * scalingFactor,
    fontWeight: 500,
    resize: "none",
    WebkitUserSelect: "none",
  };

  const currentPathname = useLocation().pathname;

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        zIndex: 2,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: 1440 * 0.08 * scalingFactor,
          width: "89%",
        }}
      >
        {/* for "/meinfortschritt" only This div is just blocking the user from changing text in the input or Textfieldarea. Note: Using the disabled prop would bring in a pointer icon which I don't want. */}
        {currentPathname === "/meinfortschritt" && (
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "transparent",
              zIndex: 100,
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
        )}
        <input
          style={headerInputFieldStyle}
          className={`${hasVideoOrImage ? "placeholderWhite" : "placeholderPrimary60"}`}
          ref={headerRef}
          value={header}
          onChange={(event) => {
            setHeaderChanged(true);
            setHeader(event.target.value);
            headerCopyToGetWidthRef.current.innerText = event.target.value;
          }}
          type="text"
          placeholder={t("IAM")}
          onMouseLeave={handleHeaderInputfieldLeave}
        ></input>
        <div
          ref={headerCopyToGetWidthRef}
          style={{
            ...headerInputFieldStyle,
            width: "auto",
            display: "inline-block",
            visibility: "hidden",
            position: "fixed",
            overflow: "auto",
          }}
        />
        <div style={{ ...lineStyle, width: headerWidth }} />
        <TextareaAutosize
          style={{ ...paragraphInputFieldStyle }}
          className={`textareaScrollBar ${
            hasVideoOrImage ? "placeholderWhite" : "placeholderPrimary60"
          }`}
          ref={paragraphRef}
          rows="3"
          wrap="hard"
          maxLength="185"
          value={paragraph}
          onChange={(event) => {
            setParagraphChanged(true);
            setParagraph(event.target.value);
          }}
          type="text"
          placeholder={t("goalStatementSecondLine")}
          onMouseLeave={handleParagraphInputfieldLeave}
        ></TextareaAutosize>
      </div>
    </div>
  );
}
