import { useEffect } from "react";
import SeeProgress from "../components/progressTracking/analyzeProgess/index";
export default function ProgressTrackingRoute() {
  //Bug fix that makes the settings page scroll to the top when navigating to it
  //Before sometimes this was not the case
  useEffect(() => {
    document.body.scroll(0, 0);
  }, []);

  return (
    <div>
      <SeeProgress />
    </div>
  );
}
