import { useLayoutEffect, useState, useRef } from "react";
import { Card } from "./VisionMovieCardStyled";
import { useTheme } from "@mui/material/styles";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import ClosedCard from "./ClosedCard";
import OpenCardWithSettings from "./openCardWithSettings/OpenCardWithSettings";

const VIDEO_RATIO = 1080 / 1920;
export default function VisionMovieCard({ data, bigDevice, nextCoaching, setVisionMovieSelected }) {
  const theme = useTheme();
  //Height setup
  const cardRef = useRef();
  const [cardHeight, setCardHeight] = useState(0);
  useLayoutEffect(() => {
    cardRef.current && setCardHeight(cardRef.current.offsetWidth * VIDEO_RATIO);
  }, [cardRef.current, bigDevice]);

  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    cardRef.current && setCardHeight(cardRef.current.offsetWidth * VIDEO_RATIO);
  });

  const [overCard, setOverCard] = useState(false);
  const [closedCard, setClosedCard] = useState(true);

  return (
    <Card
      onMouseEnter={() => setOverCard(true)}
      onMouseOver={() => setOverCard(true)}
      onMouseLeave={() => setOverCard(false)}
      ref={cardRef}
      cardHeight={cardHeight}
      backgroundColor={!closedCard && theme.palette.gradient.cardBackgroundGradient80}
    >
      {closedCard ? (
        <ClosedCard
          data={data}
          cardHeight={cardHeight}
          overCard={overCard}
          setClosedCard={setClosedCard}
          bigDevice={bigDevice}
          nextCoaching={nextCoaching}
          setVisionMovieSelected={setVisionMovieSelected}
        />
      ) : (
        <OpenCardWithSettings data={data} setClosedCard={setClosedCard} />
      )}
    </Card>
  );
}
