import dayjs, { Dayjs } from "dayjs";
import { TrackingBoxType } from "../recordProgress/TrackingBoxType";

//DATA STRUCTURE
export interface TrackingData {
  coachings: Coaching;
  toDos: ToDo[];
}

export interface Coaching {
  id: string;
  number?: number;
  programm: CoachingPrograms;
  date: Date;
}

export interface ToDo {
  id: string | number;
  //startTrackingDate: Date;
  currentTrackingType: TrackingType;
  visionMovieId: string | null;
  visionMovieSegmentId: string;
  measurements: Measurement[];
  startTrackingDate: Dayjs;
}

export interface Measurement {
  id: string | number;
  date: Dayjs;
  trackingType: TrackingType;
  boxType: TrackingBoxType;
  value: number | string | boolean;
}

export enum TrackingType {
  none = "",
  weekly_once = "1x_pro_Woche_JA/NEIN",
  weekly_multiple_times = "Anzahl pro Woche",
  time_per_week = "verwendete Zeit pro Woche",
  daily_once = "1x täglich JA/NEIN",
  daily_multiple_times = "Anzahl pro Tag",
  time_per_day = "verwendete Zeit pro Tag",
  value = "Bewertung 0-10",
}

export enum CoachingPrograms {
  full_12_weeks_1on1 = "full_12_weeks_1on1",
  individual_session = "individual_session",
}

/* 
  //Data Structure v2
  export interface TrackingData {
    toDos: ToDo[];
  }
  
  export interface ToDo {
    id: string;
    currentTrackingType: TrackingType;
    visionMovieId: string | null;
    visionMovieSegmentId: string;
    trackedPeriods: TrackingPeriod[];
  }
  
  export interface TrackingPeriod {
    id: string;
    beginningDate: Date;
    endDate: Date;
    trackingType: TrackingType;
    measurements: Measurement[];
  } */
