import { useState, useRef, useLayoutEffect } from "react";
import useResponsiveCardHeight from "./helperHooks/useResponsiveCardHeight";
import { useTheme } from "@mui/material/styles";
import GradientBorder from "./GradientBorder";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import useResponsiveIconSize from "./helperHooks/useResponsiveIconSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPhotoVideo, faSlidersH } from "@fortawesome/free-solid-svg-icons";

import { useVisionMovieContext } from "../VisionMovieContext";
import { getModifiedPosterImageLink } from "../backgroundSelection/helperFunctions";

import Affirmation from "./Affirmation";
import TransformedImage from "../uploadImages/TransformedImage";
import { useWebinarContext } from "../softwareWebinar/WebinarContext";
import { Action } from "../softwareWebinar/Actions";
import WebinarButtonWrapper from "../softwareWebinar/WebinarButtonWrapper";
import { useLocation } from "react-router-dom";

export default function Item({ segment, index, dragging, showNumber, isDragOverlay, resize }) {
  const currentPathname = useLocation().pathname;
  const theme = useTheme();
  const navigate = useWebinarContext().navigate_WebinarWrapper;
  const { actionEnabled } = useWebinarContext();
  const { palette } = theme;
  const {
    deleteCard,
    setSegmentToChooseBackgroundFor,
    setSegmentToSetSettingsFor,
    setScrollPositionInSegments,
  } = useVisionMovieContext();

  const [mouseOverCard, setMouseOverCard] = useState(false);
  function handleSetMouseOverCard(bool) {
    currentPathname !== "/visionmovie-fortschritt" && setMouseOverCard(bool);
  }
  const cardRef = useRef(null);
  const [cardWidth, setCardWidth] = useState(0);

  useLayoutEffect(() => {
    setCardWidth(cardRef.current.offsetWidth);
  }, [cardRef, resize]);

  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    setCardWidth(cardRef.current.offsetWidth);
  });

  const scalingFactor = cardWidth / 1440;
  //const [hasVideoOrImage, setHasVideoOrImage] = useState(false);
  const hasVideoOrImage = segment?.video?.placeholder ? false : true;
  //Später ersetzen und muss von item kommen
  console.log(segment);
  const videoPlaceholderUrl =
    segment?.video?.placeholder || segment.id === "archivePlaceholder" //if no background
      ? null
      : segment?.image?.uploadedImage //if image background
      ? segment?.image?.image
      : getModifiedPosterImageLink(segment.video, 500); //if video background

  const CARD_BORDER_WIDTH = 3;

  const gradientBackground = {
    background: palette.gradient.cardBackgroundGradient,
  };
  const toBeAddedBackground = hasVideoOrImage ? null : gradientBackground;

  const cardHeight = useResponsiveCardHeight();
  const cardStyle = {
    ...toBeAddedBackground,
    touchAction: dragging ? "none" : null, //disable touch action when dragging (needed for touch devices)
    fontSite: 1,
    position: "relative",
    display: "flex",
    height: cardHeight,
    width: "100%",
    opacity: dragging ? 0.4 : 1,
    position: "relative",
    border: hasVideoOrImage ? null : `${CARD_BORDER_WIDTH}px solid ${palette.primary.main}`,
    borderRadius: 5,
    outline: "none",
    boxSizing: "border-box",
    overflow: "hidden",
    WebkitUserSelect: "none",
  };

  const segmentNumberStyle = {
    position: "absolute",
    fontSize: "1.5vw",
    fontWeight: 500,
    fontFamily: "Poppins",
    color: palette.primary.main,
    top: "0.15vw",
    right: "0.7vw",
    WebkitUserSelect: "none",
    zIndex: 2,
  };

  const iconsContainerStyle = {
    position: "absolute",
    color: hasVideoOrImage ? "white" : palette.primary.main,
    bottom: "0vw",
    width: "88%",
    display: "flex",
    justifyContent: "space-between",
    marginLeft: 1440 * 0.08 * scalingFactor,
    zIndex: 2,
  };

  const iconSize = useResponsiveIconSize();
  return (
    <div
      style={{
        width: "100%",
        height: cardHeight,
        background: "white",
        borderRadius: 6,
        WebkitUserSelect: "none",
      }}
    >
      {/*<GradientBorder dragging={dragging}> */}
      <div
        style={cardStyle}
        ref={cardRef}
        onMouseEnter={() => handleSetMouseOverCard(true)}
        onMouseOver={() => handleSetMouseOverCard(true)}
        onMouseLeave={() => handleSetMouseOverCard(false)}
      >
        {/* CONTRAST LAYER */}
        <div
          style={{
            backgroundColor: `rgba(0, 0, 0, ${segment?.contrastLayerOpacityValue})`,
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: cardHeight,
            borderRadius: 6,
            zIndex: 1,
            WebkitUserSelect: "none",
          }}
        />
        {showNumber && !dragging && <div style={segmentNumberStyle}>{index + 1}</div>}
        {/* IF image of video or not transformed regular image */}
        {!segment?.video?.placeholder && !segment?.image?.transformDataObj && (
          <img
            src={videoPlaceholderUrl}
            style={{
              transform: "scale(1)",
              objectPosition: "50% 50%",
              height: cardHeight,
              width: "100%",
              objectFit: "cover",
              borderRadius: 6,
              WebkitUserSelect: "none",
            }}
          />
        )}
        {/* Transformed Image */}
        {segment?.image?.transformDataObj && <TransformedImage imageData={segment.image} />}
        {/* Text */}
        {(mouseOverCard ||
          segment.affirmation?.header ||
          segment.affirmation?.paragraph ||
          segment?.video?.placeholder) && (
          <Affirmation
            hasVideoOrImage={hasVideoOrImage}
            segment={segment}
            scalingFactor={scalingFactor}
            mouseOverCard={mouseOverCard}
            dragging={dragging}
            isDragOverlay={isDragOverlay}
          />
        )}
        {mouseOverCard && !dragging && !isDragOverlay && (
          <div style={iconsContainerStyle}>
            <WebinarButtonWrapper actionType={Action.GoToSettingsPage}>
              <div>
                <FontAwesomeIcon
                  onClick={() => {
                    if (actionEnabled(Action.GoToSettingsPage)) {
                      setScrollPositionInSegments(document.body.scrollTop);
                      setSegmentToSetSettingsFor(segment);
                      hasVideoOrImage && navigate("/settings");
                    }
                  }}
                  style={{
                    color: hasVideoOrImage ? "white" : palette.primary.main,
                    opacity: hasVideoOrImage && actionEnabled(Action.GoToSettingsPage) ? 1 : 0.35,
                    fontSize: iconSize,
                    cursor: "pointer",
                    padding: "1vw",
                    marginLeft: "-1vw",
                    WebkitUserSelect: "none",
                  }}
                  icon={faSlidersH}
                />
              </div>
            </WebinarButtonWrapper>
            <WebinarButtonWrapper actionType={Action.GoToBackgroundSelectionPage}>
              <div>
                <FontAwesomeIcon
                  onClick={() => {
                    if (actionEnabled(Action.GoToBackgroundSelectionPage)) {
                      setScrollPositionInSegments(document.body.scrollTop);
                      setSegmentToChooseBackgroundFor(segment);
                      navigate("/backgroundselection");
                    }
                  }}
                  style={{
                    color: hasVideoOrImage ? "white" : palette.primary.main,
                    opacity: actionEnabled(Action.GoToBackgroundSelectionPage) ? 1 : 0.35,
                    fontSize: iconSize,
                    cursor: "pointer",
                    padding: "1vw",
                    WebkitUserSelect: "none",
                  }}
                  icon={faPhotoVideo}
                />
              </div>
            </WebinarButtonWrapper>
            <WebinarButtonWrapper actionType={Action.DeleteCard}>
              <div>
                <FontAwesomeIcon
                  onClick={() => actionEnabled(Action.DeleteCard) && deleteCard(segment.id)}
                  style={{
                    fontSize: iconSize,
                    cursor: "pointer",
                    padding: "1vw",
                    marginRight: "-0.7vw",
                    WebkitUserSelect: "none",
                  }}
                  icon={faTrashAlt}
                />
              </div>
            </WebinarButtonWrapper>
          </div>
        )}
      </div>
      {/* </GradientBorder> */}
    </div>
  );
}
