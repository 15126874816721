import * as React from "react";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

export default function BasicSelect({
  nextCoaching,
  setNextCoaching,
  nextCoachingNumber,
  setNextCoachingNumber,
}) {
  const theme = useTheme();

  const handleChange = (event) => {
    setNextCoaching((prev) => {
      const data = { ...prev };
      data.number = event.target.value;
      return data;
    });
  };

  return (
    <TextField
      value={nextCoaching?.number ? nextCoaching?.number : 1}
      inputProps={{
        step: 1,
        style: {
          outline: "none",
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontSize: 25,
          paddingTop: 10,
          paddingLeft: 17,
          paddingBottom: 10,
        },
      }}
      id="outlined-number"
      type="number"
      style={{
        background: "white",
        width: 70,
        marginTop: 9,
      }}
      onChange={handleChange}
    />
  );
}
