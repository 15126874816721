interface visionMovieGeneratorData {
  left: CategoryData[];
  right: CategoryData[];
}

interface CategoryData {
  categoryName: string;
  options: { name: string }[];
}

export const visionMovieGeneratorData: visionMovieGeneratorData = {
  left: [
    {
      categoryName: "Gesundheit & Sport",
      options: [
        { name: "jeden Morgen Yoga" },
        { name: "gesünder essen" },
        { name: "Workout machen" },
      ],
    },
    {
      categoryName: "Gesundheit & Sport",
      options: [
        { name: "jeden Morgen Yoga." },
        { name: "gesünder essen." },
        { name: "Workout machen." },
      ],
    },
    {
      categoryName: "Morgenroutine",
      options: [{ name: "jeden Morgen gesundes Frühstück" }, { name: "keine Süßigkeiten essen" }],
    },
  ],
  right: [
    {
      categoryName: "Morgenroutine",
      options: [{ name: "jeden Morgen gesundes Frühstück." }, { name: "keine Süßigkeiten essen." }],
    },
    {
      categoryName: "Gesundheit & Sport",
      options: [{ name: "Fokus auf bessere Ernährung" }],
    },
    {
      categoryName: "Gesundheit & Sport",
      options: [
        { name: "jeden Morgen Yoga.." },
        { name: "gesünder essen.." },
        { name: "Workout machen.." },
      ],
    },
  ],
};
