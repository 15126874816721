import React, { useContext, useState, useEffect, useLayoutEffect } from "react";
import { sortSongs, sortSoundCloudSongs } from "../Utils/HelperFunctions";
import { firebaseStorage, firestore, auth } from "../../Firebase/firebase";
import { useAuth } from "../../Firebase/Contexts/AuthContext";
import firebase from "firebase";
import { useVisionMovieContext } from "../../VisionMovieContext";

const BackgroundMusicContext = React.createContext();

export function useBackgroundMusicContext() {
  return useContext(BackgroundMusicContext);
}

//Creating the logic and the Providers
export function BackgroundMusicProvider({ children }) {
  //This shows the length of the currently selected videoclips & OR pictures
  //This will later move to another Context and will be dynamic
  const { estimatedVideoLength } = useVisionMovieContext();
  const [videoLength, setVideoLength] = useState(estimatedVideoLength);
  useEffect(() => {
    setVideoLength(estimatedVideoLength);
  }, [estimatedVideoLength]);

  //Songs related state
  const [songs, setSongs] = useState([]);
  const [topSongs, setTopSongs] = useState(0);
  const [platformSongs, setPlatformSongs] = useState([]);
  const [userUploadedSongs, setUserUploadedSongs] = useState([]);
  const [heartedSongs, setHeartedSongs] = useState([]);
  const [currentPlayingSong, setCurrentPlayingSong] = useState(undefined);

  //SoundCloud Songs related state
  const [soundCloudSongs, setSoundCloudSongs] = useState([]);
  const [sortedSoundCloudSongs, setSortedSoundCloudSongs] = useState([]);
  const [heartedSoundCloudSongs, setHeartedSoundCloudSongs] = useState([]);
  const [topSoundCloudSongs, setTopSoundCloudSongs] = useState(0);
  const [currentPlayingSoundCloudSong, setCurrentPlayingSoundCloudSong] = useState(undefined);

  // states used for uploading song to storage
  const [uploadProgress, setUploadProgress] = useState(undefined);
  const [uploadStatus, setUploadStatus] = useState("");
  const [errors, setErrors] = useState([]);

  // Get current loggedIn user from firebase
  const { currentUser } = useAuth();
  const userId = currentUser?.uid;

  // As defined in Mokup use '1' for visionMovieId
  let visionMovieId = "1";

  // firebase firestore reference
  const firestoreRef = firestore.collection("users").doc(userId);
  const platformSongsRef = firestore.collection("music").doc("backgroundMusicOnThePlatform");
  const heartedSongRef = firestoreRef
    .collection("VisionMovies")
    .doc(visionMovieId)
    .collection("heartedSongs");

  // firebase firestore reference for soundcloud Songs
  const soundcloudSongsRef = firestore.collection("music").doc("soundcloudSongs");

  // variable used for uploading song data to firestore
  let songData = undefined;

  //get Hearted songs from Firestore
  const getHeartedSongsFromFirestore = () => {
    heartedSongRef.doc("songsOnThePlatform").onSnapshot((docRef) => {
      if (docRef?.data()?.list) {
        setHeartedSongs(docRef.data().list);
      }
    });
  };

  //get Platform songs from Firestore
  const getPlatformSongsFromFirestore = () => {
    platformSongsRef.onSnapshot((docRef) => {
      if (docRef?.data()?.list) {
        setPlatformSongs(docRef.data().list);
      }
    });
  };

  //get User Uploaded songs from Firestore
  const getUserUploadedSongsFromFirestore = () => {
    firestoreRef.collection("uploadedSongs").onSnapshot((querySnapshot) => {
      let userSongs = [];
      querySnapshot.forEach((doc) => {
        userSongs.push({ ...doc.data(), id: doc.id });
      });
      setUserUploadedSongs(userSongs);
    });
  };

  //get Hearted songs from Firestore
  const getHeartedSoundCloudSongsFromFirestore = () => {
    heartedSongRef.doc("soundcloudSongs").onSnapshot((docRef) => {
      if (docRef?.data()?.list) {
        setHeartedSoundCloudSongs(docRef.data().list);
      }
    });
  };

  //This gets triggered when a song gets hearted
  const addSongToFavorities = (id) => {
    let heartedSong = {
      songId: id,
      timestamp: new Date(),
    };
    heartedSongRef
      .doc("songsOnThePlatform")
      .set({ list: firebase.firestore.FieldValue.arrayUnion(heartedSong) }, { merge: true })
      .then((docRef) => {})
      .catch((error) => {
        console.log("Error while hearting song: ", error);
      });
  };

  //This gets triggered when a song gets un-hearted
  const removeSongFromFavorities = (id) => {
    let updatedList = heartedSongs.filter((item) => item.songId !== id);
    heartedSongRef
      .doc("songsOnThePlatform")
      .update({ list: updatedList })
      .then(() => {})
      .catch((error) => {
        console.log("Error while unhearting song: ", error);
      });
  };

  //get soundcloud songs from Firestore
  const getSoundcloudSongsFromFirestore = () => {
    soundcloudSongsRef.onSnapshot((docRef) => {
      if (docRef?.data()?.list) {
        setSoundCloudSongs(docRef.data().list);
      }
    });
  };

  //This gets triggered when a soundcloud song gets hearted
  const addSoundCloudSongToFavorities = (id) => {
    let heartedSong = {
      soundcloudId: id,
      timestamp: new Date(),
    };
    heartedSongRef
      .doc("soundcloudSongs")
      .set({ list: firebase.firestore.FieldValue.arrayUnion(heartedSong) }, { merge: true })
      .then((docRef) => {})
      .catch((error) => {
        console.log("Error while hearting song: ", error);
      });
  };

  //This gets triggered when a soundcloud song gets un-hearted
  const removeSoundCloudSongFromFavorities = (id) => {
    let updatedList = heartedSoundCloudSongs.filter((item) => item.soundcloudId !== id);
    heartedSongRef
      .doc("soundcloudSongs")
      .update({ list: updatedList })
      .then(() => {})
      .catch((error) => {
        console.log("Error while unhearting song: ", error);
      });
  };

  // state for show and hide song Upload Row
  const [showUploadSongRow, setShowUploadSongRow] = useState(false);

  //This is used to show or hide the UploadSongsRow
  const toggleShowUploadSongRow = () => {
    setShowUploadSongRow(!showUploadSongRow);
  };

  //reset states and variables related to upload song to storage and firestore
  const resetUploadingStates = () => {
    setTimeout(() => {
      songData = undefined;
      setUploadStatus("");
      setUploadProgress(undefined);
      setShowUploadSongRow(false);
    }, 2000);
  };

  //save Song data to firestore
  const saveToFireStore = (songData) => {
    firestoreRef
      .collection("uploadedSongs")
      .add(songData)
      .then((docRef) => {
        setUploadStatus("done");
        resetUploadingStates();
      })
      .catch((error) => {
        setUploadStatus("error");
      });
  };

  // handle progress of uploading song
  const handleProgress = ({ bytesTransferred, totalBytes }) => {
    let tranferedBytes = bytesTransferred ? bytesTransferred : 0;
    let totalByte = totalBytes ? totalBytes : 0;
    const percentUploaded = totalBytes !== 0 ? Math.round((tranferedBytes / totalByte) * 100) : 0;
    setUploadProgress(percentUploaded);
  };

  // if error occur while uploading...
  const handleError = (err) => {
    setUploadStatus("error");
    setErrors([...errors, err]);
  };

  // handle condition when song uploaded to Storage
  const handleUploadCompleted = (songRef) => {
    songRef.snapshot.ref
      .getDownloadURL()
      .then((storageLink) => {
        const audio = document.createElement("AUDIO");
        audio.src = storageLink;
        const { songTitle, artistName } = songData;
        audio.addEventListener("loadedmetadata", (e) => {
          songData = { artistName, songTitle, storageLink, duration: e.target.duration };
          saveToFireStore(songData);
        });
      })
      .catch((err) => handleError(err));
  };

  // Uploading song to storage...
  const uploadToStorage = (song) => {
    songData = song;
    const { file } = song;
    // Create the file metadata
    var metadata = {
      contentType: "audio/mpeg",
    };
    // location in storage you want to create/send file to
    const storagePath = `users/${userId}/uploadedSongs/${file.name}`;
    const uploadingSongRef = firebaseStorage.ref(storagePath).put(file, metadata);
    setUploadStatus("inProgress");
    uploadingSongRef.on(
      "state_changed",
      (snap) => handleProgress(snap),
      (err) => handleError(err),
      () => handleUploadCompleted(uploadingSongRef)
    );
  };

  function getData() {
    if (currentUser) {
      getPlatformSongsFromFirestore();
      getHeartedSongsFromFirestore();
      getUserUploadedSongsFromFirestore();
      getSoundcloudSongsFromFirestore();
      getHeartedSoundCloudSongsFromFirestore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }
  //Sets up the state with firestore data
  useEffect(() => {
    getData();
  }, [currentUser]);
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Sort songs on page open and then Resorts Songs when the user hearts a song
  useEffect(() => {
    let { sortedSongs, topSongsLength } = sortSongs({
      platformSongs,
      userSongs: userUploadedSongs,
      likedSongs: heartedSongs,
      videoLength: videoLength,
    });
    setSongs([...sortedSongs]);
    setTopSongs(topSongsLength);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformSongs, userUploadedSongs, heartedSongs]);

  //Sort soundcloud songs on page open and then Resorts soundcloud Songs when the users hearts a soundcloud song
  useEffect(() => {
    let { sortedSongs, topSongsLength } = sortSoundCloudSongs({
      soundCloudSongs,
      heartedSoundCloudSongs,
      videoLength,
    });
    setSortedSoundCloudSongs([...sortedSongs]);
    setTopSoundCloudSongs(topSongsLength);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soundCloudSongs, heartedSoundCloudSongs]);

  const functions = {
    //platform song related states and function
    showUploadSongRow,
    songs,
    topSongs,
    platformSongs,
    userUploadedSongs,
    heartedSongs,
    videoLength,
    currentPlayingSong,
    setCurrentPlayingSong,
    addSongToFavorities,
    removeSongFromFavorities,
    toggleShowUploadSongRow,
    // firebase states and functions
    uploadProgress,
    errors,
    uploadStatus,
    uploadToStorage,
    //soundcloud songs related states and functions
    sortedSoundCloudSongs,
    topSoundCloudSongs,
    heartedSoundCloudSongs,
    currentPlayingSoundCloudSong,
    setCurrentPlayingSoundCloudSong,
    addSoundCloudSongToFavorities,
    removeSoundCloudSongFromFavorities,
  };

  return (
    <BackgroundMusicContext.Provider value={functions}>{children}</BackgroundMusicContext.Provider>
  );
}
