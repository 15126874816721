import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useAuth } from "../../Firebase/Contexts/AuthContext";
import { firestore } from "../../Firebase/firebase";

export default function BasicTimePicker({ nextCoaching }) {
  const { currentUser } = useAuth();
  console.log("DATE");
  console.log(nextCoaching?.date);

  function handleChange(choosenDateTime) {
    var toBeStoredCoaching = nextCoaching;
    const date = new Date(choosenDateTime);
    toBeStoredCoaching.date = date.toString();

    firestore
      .collection("users")
      .doc(currentUser?.uid)
      .collection("Coachings")
      .doc(nextCoaching.id)
      .set(toBeStoredCoaching)
      .then(() => {
        //console.log("Document successfully written!");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["TimePicker"]}>
        <TimePicker onAccept={handleChange} label="Coaching Startzeit auswählen" />
      </DemoContainer>
    </LocalizationProvider>
  );
}

/* export interface Coaching {
    id: string;
    number?: number;
    programm: CoachingPrograms;
    date: Date;
  } */
