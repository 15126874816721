import { useState } from "react";
import { Alert } from "react-bootstrap";
import Button from "@mui/material/Button";
import { useAuth } from "../Contexts/AuthContext";
import { useWebinarContext } from "../../softwareWebinar/WebinarContext";
import { useTranslation } from "react-i18next";

export default function LogoutButton() {
  const [error, setError] = useState("");
  const { logout } = useAuth();
  const navigate = useWebinarContext().navigate_WebinarWrapper;

  const { t } = useTranslation();

  async function handleLogout() {
    setError("");

    try {
      await logout();
      navigate.push("/login");
    } catch {
      setError("Failed to log out");
    }
  }

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      <Button
        onClick={handleLogout}
        color="grey"
        style={{
          textTransform: "none",
          borderRadius: "6px",
          fontWeight: "bold",
        }}
        variant="contained"
        disableElevation
      >
        {t("logout")}
      </Button>
    </>
  );
}
