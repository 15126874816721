import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import { TrackingBoxType } from "./TrackingBoxType";
import { useAuth } from "../../Firebase/Contexts/AuthContext";
import recordAndStoreToDoMeasurement from "./recordAndStoreToDoMeasurement";
import { useElementOnScreen } from "./useElementOnScreen";
import { rowMarginParameters } from "./rowMarginParameters";

export default function AddNumber({
  index,
  scalingFactor,
  toDoData,
  setActiveToDoIndex,
  nextClicked,
}) {
  const theme = useTheme();
  const { currentUser } = useAuth();

  const circle = useRef();
  const [circleWidth, setCircleWidth] = useState(0);
  const [savedRequested, setSavedRequested] = useState(false);

  useLayoutEffect(() => {
    setCircleWidth(circle?.current?.offsetWidth);
  }, [circle]);

  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    setCircleWidth(circle.current.offsetWidth);
  });

  const [value, setValue] = useState("");

  const [inputRef, isVisible] = useElementOnScreen({
    rootMargin: "0px",
    threshold: 0.5,
  });

  useEffect(() => {
    if (isVisible && inputRef.current) {
      inputRef.current.focus();
    }
    if (!isVisible && inputRef.current) {
      inputRef.current.blur();
    }
  }, [isVisible]);

  const handleEnter = (event) => {
    event && event.preventDefault();
    setSavedRequested(true);
    if (value !== "") {
      recordAndStoreToDoMeasurement(
        currentUser,
        toDoData,
        TrackingBoxType.number,
        value,
        setActiveToDoIndex
      );
      setValue("");
    }
  };

  useEffect(() => {
    !savedRequested && value && handleEnter();
    !savedRequested && value && alert("save");
  }, [nextClicked]);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      direction="row"
      ref={circle}
      style={{
        borderColor: "#E0E0E0",
        borderWidth: 4 * scalingFactor,
        borderStyle: "solid",
        borderRadius: 1000,
        height: circleWidth,

        backgroundImage: theme.palette.gradient.text,
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        userSelect: "none",
        fontWeight: "bold",
        position: "relative",
        width: `${79 / 7}%`,
        marginTop: rowMarginParameters.marginTopNOTFirstRow * scalingFactor,
        /*   index <= 6
            ? rowMarginParameters.marginTopFirstRow * scalingFactor
            : rowMarginParameters.marginTopNOTFirstRow * scalingFactor, */
        marginRight: (index + 1) % 7 === 0 ? 0 : "3.5%",
      }}
    >
      <form onSubmit={handleEnter}>
        <input
          ref={inputRef}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          type="text"
          //autoFocus
          style={{
            fontSize: 33 * scalingFactor,
            width: "100%",
            minWidth: 1,
            padding: 0,
            color: "pink",
            borderTopStyle: "hidden",
            borderRightStyle: "hidden",
            borderLeftStyle: "hidden",
            borderBottomStyle: "hidden",
            background: "transparent",
            fontWeight: "bold",
            textAlign: "center",
          }}
        />
      </form>
    </Stack>
  );
}
