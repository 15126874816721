import { useState, useEffect } from "react";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import { columnNumberBreakpoints } from "../../../Theme";

export default function useResponsiveIconSize() {
  function calculateIconSize() {
    if (window.innerWidth < columnNumberBreakpoints.twoColumns) {
      return "2.3vw";
    } else if (window.innerWidth < columnNumberBreakpoints.threeColumns) {
      return "1.6vw";
    } else {
      return "1.2vw";
    }
  }

  const [iconSize, setIconSize] = useState(() => calculateIconSize());

  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    setIconSize(calculateIconSize());
  });

  useEffect(() => {
    setIconSize(calculateIconSize());
  }, []);

  return iconSize;
}
