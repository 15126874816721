import { createClient } from "pexels";

//creates a client for accessing the pexels API. The key should be added to the .env in the root level of the project.
const client = createClient("563492ad6f91700001000001869672e3ac3344d2b1c046ae73c5f47d");

//number of results for each page
const PAGE_SIZE = 80;

//number of pages to fetch at once
const PAGES = 1;

//Minimum size
const MIN_SIZE = "medium";

//fetch popular videos to display initially
export const getPopularVideos = async (page) => {
  let res = null;
  let videos = [];
  let initalPage = page || 1;
  for (let i = 0; i < PAGES; i++) {
    res = await client.collections.media({
      per_page: PAGE_SIZE,
      page: initalPage,
      type: "videos",
      id: "tselvzg", //"7efj954",
      size: MIN_SIZE,
    });
    initalPage++;
    //filter out videos with no hd video file from res.media array. Needed because hls also gets classified by the API as hd, but we are not supporting hls for now
    const hdVideos = res.media.filter(
      (video) => video.video_files.filter((file) => file.quality.includes("hd")).length > 0
    );
    videos = [...videos, ...hdVideos];
  }

  return {
    ...res,
    videos: videos.filter((e) => e.duration >= 8),
  };
};

//fetch videos when searched for
export const searchVideosByKeywords = async (query, page) => {
  const res = await client.videos.search({
    query,
    orientation: "landscape",
    per_page: PAGE_SIZE,
    size: MIN_SIZE,
    ...(page && { page }),
  });
  //filter out videos with no hd video file from res.media array. Needed because hls also gets classified by the API as hd, but we are not supporting hls for now
  const hdVideos = res.videos.filter(
    (video) => video.video_files.filter((file) => file.quality.includes("hd")).length > 0
  );
  return { ...res, videos: hdVideos.filter((e) => e.duration >= 8) };
};

//get the single video once it is selected
export const getSingleVideo = async (id) => await client.videos.show({ id });
