import { useBackgroundMusicContext } from "../BackgroundMusic/Context/BackgroundMusicContext";
import { useTranslation } from "react-i18next";

export default function UploadSongButton() {
  //Imports all necessary data from BackgroundMusicContext
  const { showUploadSongRow, toggleShowUploadSongRow } = useBackgroundMusicContext();
  const { t } = useTranslation();
  return (
    <div
      className="uploadSongButton"
      style={{
        backgroundColor: showUploadSongRow ? "rgba(255, 0, 90, 1)" : "#f2f2f2",
        color: showUploadSongRow ? "#fff" : "rgba(255, 0, 90, 1)",
      }}
      onClick={toggleShowUploadSongRow}
    >
      {t("uploadMusic")}
    </div>
  );
}
