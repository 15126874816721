import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import Stack from "@mui/material/Stack";
import { useEffect, useState, useRef } from "react";
import GrowingUnstyledInputField from "./GrowingUnstyledInputField";
import { firestore } from "../Firebase/firebase";
import { useLocation } from "react-router-dom";

export default function VisionMovieName({ data, userId }) {
  const currentPathname = useLocation().pathname;
  const [over, setOver] = useState(false);
  const [visionMovieName, setVisionMovieName] = useState(data?.name);
  const allowUpdates = useRef(true);

  useEffect(() => {
    allowUpdates.current && setVisionMovieName(data?.name);
  }, [data]);

  function changeVisionMovieName(newName) {
    allowUpdates.current = false;
    firestore
      .collection("users")
      .doc(userId)
      .collection("VisionMovies")
      .doc(data.visionMovieId)
      .set({
        ...data,
        name: newName,
      })
      .then((response) => {
        console.log("success changed VisionMovie name");
        allowUpdates.current = true;
      })
      .catch((error) => console.log("error occured: " + error));
    setVisionMovieName(newName);
  }

  return (
    <div
      onMouseEnter={() => setOver(true)}
      onMouseLeave={() => setOver(false)}
      style={{
        width: "fit-content",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <GrowingUnstyledInputField
        value={visionMovieName}
        onChange={(e) => changeVisionMovieName(e.target.value)}
        style={{ color: "white", fontWeight: 600, marginRight: 8 }}
      />
      {over && currentPathname !== "/fortschritt" && (
        <FontAwesomeIcon style={{ color: "white" }} icon={faPen} />
      )}
    </div>
  );
}
