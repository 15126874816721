import { firestore } from "../../Firebase/firebase";
import dayjs from "dayjs";
import { ToDo } from "../setProgressTracking/ProgressTracking";
import { TrackingBoxType } from "./TrackingBoxType";

export default function recordAndStoreToDoMeasurement(
  currentUser: any,
  toDo: ToDo,
  boxType: TrackingBoxType,
  toBeRecordedValue: number | boolean | string,
  setActiveToDoIndex: any
) {
  const lastMeasurement = toDo?.measurements?.slice(-1)[0];

  //Adds new record to data structure
  toDo?.measurements
    ? toDo.measurements.push({
        id: Number(lastMeasurement.id) + 1,
        date: dayjs(lastMeasurement.date).add(1, "day"),
        trackingType: toDo.currentTrackingType,
        boxType,
        value: toBeRecordedValue,
      })
    : (toDo.measurements = [
        {
          id: 1,
          date: dayjs(toDo?.startTrackingDate),
          trackingType: toDo.currentTrackingType,
          boxType,
          value: toBeRecordedValue,
        },
      ]);

  //Translates dayjs() objects to String for storage in firestore
  function dayjsObjsToStrings(toDo: any) {
    for (let i = 0; i < toDo.measurements.length; i++) {
      toDo.measurements[i].date = toDo.measurements[i].date.toString();
    }
    return toDo;
  }
  const toBeStoredData = dayjsObjsToStrings(toDo);

  //Stores data in firestore
  firestore
    .collection("users")
    .doc(currentUser?.uid)
    .collection("TrackedToDos")
    .doc(String(toDo.id))
    .set(toBeStoredData)
    .then(() => {
      console.log("STORED IN FIREBASE");
    })
    .catch((error) => {
      console.error("Error writing document: ", error);
    });
  //Could be used to jump to next one
  /*  setTimeout(() => {
    setActiveToDoIndex((prev: any) => prev + 1);
  }, 1000); */
}
