import React, { useContext, useState, useRef, useEffect } from "react";
import { visionMoviePlaceholderData } from "./segments/visionMoviePlaceholderData";
import { findContainer } from "./segments/helperFunctions";
import { v4 as uuidv4 } from "uuid";

import { firestore } from "./Firebase/firebase";
import { useAuth } from "./Firebase/Contexts/AuthContext";

import { prepareDataForPlayer } from "./player/prepareDataForPlayer";
import { usePlayerSetupContext } from "./player/contexts/PlayerSetupContext";
import useSegmentsCoverSong from "./VisionMovieContext/useSegmentsCoverSong";
import useVideoLengths from "./VisionMovieContext/useVideoLengths";
import useSegmentsToChoose from "./VisionMovieContext/useSegmentsToChoose";
import { useSessionstorageState } from "rooks";
import { getSingleVideo } from "./backgroundSelection/pexels";

const VisionMovieContext = React.createContext({});

export function useVisionMovieContext() {
  return useContext(VisionMovieContext);
}

export function VisionMovieContextProvider({ children }) {
  // Get current loggedIn user from firebase
  const { currentUser } = useAuth();
  const userId = currentUser?.uid;

  //Also stores currentlyActiveVisionMovieId when page gets refreshed
  const [currentlyActiveVisionMovieId, setCurrentlyActiveVisionMovieId] = useSessionstorageState(
    "mindmovie-app:currentlyActiveVisionMovieId",
    null
  );
  const [visionMovies, setVisionMovies] = useState([]);
  useEffect(() => {
    if (currentUser?.uid) updateVisionMoviesData();
  }, [currentUser]);

  const allowSnapshotUpdates = useRef(true);
  function setAllowSnapshotUpdates(bool) {
    allowSnapshotUpdates.current = bool;
  }

  useEffect(() => {
    visionMovies.map((visionMovieDoc, index) => {
      console.log(visionMovieDoc);
      //Manipulate .visionMovie
      const visionMovieArr = visionMovieDoc.visionMovie;
      var highestIndexWithVimeoLink = null;
      for (let i = 0; i < visionMovieArr.length; i++) {
        if (
          visionMovieArr[i].video &&
          !visionMovieArr[i].video.placeholder &&
          visionMovieArr[i].video.video_files.length > 1 &&
          visionMovieArr[i].video.video_files[0].link.includes("vimeo")
        ) {
          highestIndexWithVimeoLink = i;
        }
      }
      //If one segment with vimeo link still exists
      if (highestIndexWithVimeoLink) {
        const promises = [];
        for (let i = 0; i < visionMovieArr.length; i++) {
          if (
            visionMovieArr[i].video &&
            !visionMovieArr[i].video.placeholder &&
            visionMovieArr[i].video.video_files.length > 1 &&
            visionMovieArr[i].video.video_files[0].link.includes("vimeo")
          ) {
            promises[i] = getSingleVideo(visionMovieArr[i].video.id).then((doc) => {
              visionMovieArr[i].video = doc;
              /*  if (highestIndexWithVimeoLink === i) {
                  console.log("done Arr:");
                  console.log(visionMovieArr);
                } */
            });
          }
        }
        /*  Promise.all(promises).then((values) => {
            console.log(visionMovies);
          }); */
      }

      //Update hearted video_________
      for (let i = 0; i < visionMovieArr.length; i++) {
        for (let y = 0; y < visionMovieArr[i]?.heartedVideos?.length; y++) {
          if (visionMovieArr[i]?.heartedVideos[y].video_files[0].link.includes("vimeo")) {
            getSingleVideo(visionMovieArr[i]?.heartedVideos[y].id).then((doc) => {
              visionMovieArr[i].heartedVideos[y] = doc;
            });
          }
        }
      }
      //END: Update hearted video_________

      //Manipulate .archive
      const visionMovieArchiveArr = visionMovieDoc.archive;
      var highestIndexWithVimeoLink = null;
      for (let i = 0; i < visionMovieArchiveArr.length; i++) {
        if (
          visionMovieArchiveArr[i].video &&
          !visionMovieArchiveArr[i].video.placeholder &&
          visionMovieArchiveArr[i].video.video_files.length > 1 &&
          visionMovieArchiveArr[i].video.video_files[0].link.includes("vimeo")
        ) {
          highestIndexWithVimeoLink = i;
        }
      }
      //If one segment with vimeo link still exists
      if (highestIndexWithVimeoLink) {
        const promises = [];
        for (let i = 0; i < visionMovieArchiveArr.length; i++) {
          if (
            visionMovieArchiveArr[i].video &&
            !visionMovieArchiveArr[i].video.placeholder &&
            visionMovieArchiveArr[i].video.video_files.length > 1 &&
            visionMovieArchiveArr[i].video.video_files[0].link.includes("vimeo")
          ) {
            promises[i] = getSingleVideo(visionMovieArchiveArr[i].video.id).then((doc) => {
              visionMovieArchiveArr[i].video = doc;
              /*  if (highestIndexWithVimeoLink === i) {
                  console.log("done Arr:");
                  console.log(visionMovieArr);
                } */
            });
          }
        }
        Promise.all(promises).then((values) => {
          console.log(visionMovies);
        });
      }
    });
  }, [visionMovies]);

  const updateVisionMoviesData = () => {
    firestore
      .collection("users")
      .doc(userId)
      .collection("VisionMovies")
      .onSnapshot((docRef) => {
        const newVisionMoviesArr = [];
        if (docRef?.docs && allowSnapshotUpdates.current) {
          docRef.docs.map((doc, index) => {
            newVisionMoviesArr.push(doc.data());
          });

          //Sort VisionMovies by displayIndexInApp
          newVisionMoviesArr.sort((a, b) => {
            return a.displayIndexInApp - b.displayIndexInApp;
          });
          setVisionMovies(newVisionMoviesArr);
        }
      });
  };

  async function deleteVisionMovie(visionMovieId, onDeleted) {
    const indexOfVisionMovie = visionMovies.findIndex(
      (visionMovie) => visionMovie.visionMovieId === visionMovieId
    );
    setAllowSnapshotUpdates(false);
    await moveDisplayIndexInAppOfOldVMsBackFromIndex(indexOfVisionMovie + 1);
    setAllowSnapshotUpdates(true);
    firestore
      .collection("users")
      .doc(userId)
      .collection("VisionMovies")
      .doc(visionMovieId)
      .delete()
      .then(() => {
        console.log("Successfully deleted!");
        onDeleted && onDeleted();
      })
      .catch((error) => {
        alert("Something went wrong");
        console.error("Error removing document: ", error);
      });
  }

  async function moveDisplayIndexInAppOfOldVMsBackFromIndex(fromIndex) {
    const allCalls = [];
    for (let i = fromIndex; i < visionMovies.length; i++) {
      allCalls.push(
        firestore
          .collection("users")
          .doc(userId)
          .collection("VisionMovies")
          .doc(visionMovies[i].visionMovieId)
          .set({
            ...visionMovies[i],
            displayIndexInApp: visionMovies[i].displayIndexInApp - 1,
          })
      );
    }
    await Promise.all(allCalls);
  }

  async function moveDisplayIndexInAppOfOldVMs() {
    const allCalls = [];
    for (let i = 0; i < visionMovies.length; i++) {
      allCalls.push(
        firestore
          .collection("users")
          .doc(userId)
          .collection("VisionMovies")
          .doc(visionMovies[i].visionMovieId)
          .set({
            ...visionMovies[i],
            displayIndexInApp: visionMovies[i].displayIndexInApp + 1,
          })
      );
    }
    await Promise.all(allCalls);
  }

  const [visionMovieData, setVisionMovieData] = useState(visionMoviePlaceholderData);
  useEffect(() => {
    if (visionMovies.length !== 0 && currentlyActiveVisionMovieId) {
      setVisionMovieData(
        visionMovies.find(
          (visionMovie) => visionMovie.visionMovieId === currentlyActiveVisionMovieId
        )
      );
    }
  }, [visionMovies, currentlyActiveVisionMovieId]);

  //Firestore path to active VisionMovie
  const firestoreVisionMovieRefComp = useRef();
  const firestoreVisionMovieRef = firestoreVisionMovieRefComp.current;
  useEffect(() => {
    if (currentlyActiveVisionMovieId) {
      firestoreVisionMovieRefComp.current = firestore
        .collection("users")
        .doc(userId)
        .collection("VisionMovies")
        .doc(currentlyActiveVisionMovieId);
    }
  }, [currentlyActiveVisionMovieId]);

  //Change data of active VisionMovie
  function changeVisionMovieData(newVisionMovieData) {
    newVisionMovieData.visionMovie = prepareDataForPlayer(
      JSON.parse(JSON.stringify(newVisionMovieData), "changeVisionMovieData")
    );

    firestoreVisionMovieRef
      .set(newVisionMovieData)
      .then(() => {
        //console.log("Document successfully written!");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
    setVisionMovieData(newVisionMovieData);
  }

  function changeText(segmentId, headerStr, paragraphStr) {
    //Find Container of segment
    const container = findContainer(segmentId, visionMovieData);
    //Find Index
    const index = visionMovieData[container].findIndex((segment) => segment.id === segmentId);
    //Change Data
    const newVisionMovieData = JSON.parse(JSON.stringify(visionMovieData));
    newVisionMovieData[container][index].affirmation["header"] = headerStr;
    newVisionMovieData[container][index].affirmation["paragraph"] = paragraphStr;
    // Store new Data in firestore
    firestoreVisionMovieRef
      .set(newVisionMovieData)
      .then(() => {})
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
    setVisionMovieData(newVisionMovieData);
  }

  //ADD VIDEO
  //Also stores currentlyActiveVisionMovieId when page gets refreshed
  const [segmentToChooseBackgroundFor, setSegmentToChooseBackgroundFor] = useSessionstorageState(
    "mindmovie-app:segmentToChooseBackgroundFor",
    null
  );
  //const [segmentToChooseBackgroundFor, setSegmentToChooseBackgroundFor] = useState();
  //UPDATE segmentToChooseBackgroundFor when visionMovieData changes
  //Is needed to store hearted videos!!! Otherwise the selected videos won't be shown, because the heartedVideos page uses segmentToChooseBackgroundFor
  useEffect(() => {
    if (segmentToChooseBackgroundFor && visionMovieData) {
      //Find Container of segment
      const container = findContainer(segmentToChooseBackgroundFor.id, visionMovieData);
      //Find Segment
      if (container) {
        const segment = visionMovieData[container].find(
          (segment) => segment.id === segmentToChooseBackgroundFor.id
        );
        setSegmentToChooseBackgroundFor(segment);
      }
    }
  }, [visionMovieData]);

  //FOR SETTINGS PAGE - SAVES THE OPACITY VALUE FOR THE CONTRAST LAYER_____
  const [segmentToSetSettingsFor, setSegmentToSetSettingsFor] = useState();

  function saveOpacityValueForSegment(segment, contrastLayerOpacityValue) {
    //Find Container of segment
    const container = findContainer(segment.id, visionMovieData);
    const index = visionMovieData[container].findIndex((elem) => elem.id === segment.id);
    //Change Data
    const newVisionMovieData = JSON.parse(JSON.stringify(visionMovieData));
    newVisionMovieData[container][index].contrastLayerOpacityValue = contrastLayerOpacityValue;
    console.log("value in save function: " + contrastLayerOpacityValue);

    // Store new Data in firestore
    firestoreVisionMovieRef
      .set(newVisionMovieData)
      .then(() => {
        //console.log("Document successfully written!");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }
  //FOR SETTINGS PAGE END_____

  function addBackgroundToSegment(backgroundAssetData) {
    //Find Container of segment
    const container = findContainer(segmentToChooseBackgroundFor.id, visionMovieData);
    //Find Index
    const index = visionMovieData[container].findIndex(
      (segment) => segment.id === segmentToChooseBackgroundFor.id
    );
    //Change Data
    const newVisionMovieData = JSON.parse(JSON.stringify(visionMovieData));
    //Add image as background to segment
    if (backgroundAssetData.uploadedImage === true) {
      newVisionMovieData[container][index].video = null;
      newVisionMovieData[container][index].image = backgroundAssetData;
      newVisionMovieData[container][index].contrastLayerOpacityValue = 0;
    }
    //Add video as background to segment
    else {
      newVisionMovieData[container][index].image = null;
      newVisionMovieData[container][index].video = backgroundAssetData;
      newVisionMovieData[container][index].contrastLayerOpacityValue = 0;
    }

    //Sets playing duration of segments and containing affirmations
    newVisionMovieData.visionMovie = prepareDataForPlayer(
      JSON.parse(JSON.stringify(newVisionMovieData)),
      "addBackgroundToSegment"
    );

    // Store new Data in firestore
    firestoreVisionMovieRef
      .set(newVisionMovieData)
      .then(() => {
        //console.log("Document successfully written!");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
    setVisionMovieData(newVisionMovieData);
  }

  /*   useEffect(() => {
    console.log(visionMovieData);
  }, [visionMovieData]); */

  //HEART VIDEO
  function toggleVideoHeartedState(videoData) {
    //Find Container of segment
    const container = findContainer(segmentToChooseBackgroundFor.id, visionMovieData);
    //Find Index
    const index = visionMovieData[container].findIndex(
      (segment) => segment.id === segmentToChooseBackgroundFor.id
    );
    //Change Data
    const newVisionMovieData = JSON.parse(JSON.stringify(visionMovieData));
    const segment = newVisionMovieData[container][index];
    //Toggle hearted state
    //UNHEARTE VIDEO
    if (segment.heartedVideos) {
      if (segment.heartedVideos.find((elem) => elem.id === videoData.id)) {
        segment.heartedVideos = newVisionMovieData[container][index].heartedVideos.filter(
          (elem) => elem.id !== videoData.id
        );
      }
      //HEART VIDEO
      else {
        segment.heartedVideos.push(videoData);
        //Save to all hearted videos_____
        if (newVisionMovieData?.allHeartedVideos) {
          if (!newVisionMovieData.allHeartedVideos.find((video) => video.id === videoData.id)) {
            newVisionMovieData.allHeartedVideos = [
              ...newVisionMovieData.allHeartedVideos,
              videoData,
            ];
          }
        } else {
          newVisionMovieData.allHeartedVideos = [videoData];
        }
        //End: Save to all hearted videos_____
      }
    } else {
      segment.heartedVideos = [];
      segment.heartedVideos.push(videoData);
      //Save to all hearted videos_____
      if (newVisionMovieData?.allHeartedVideos) {
        if (!newVisionMovieData.allHeartedVideos.find((video) => video.id === videoData.id)) {
          newVisionMovieData.allHeartedVideos = [...newVisionMovieData.allHeartedVideos, videoData];
        }
      } else {
        newVisionMovieData.allHeartedVideos = [videoData];
      }
      //End: Save to all hearted videos_____
    }

    // Store new Data in firestore
    firestoreVisionMovieRef
      .set(newVisionMovieData)
      .then(() => {
        //console.log("Document successfully written!");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }

  function createNewCard() {
    const newVisionMovieData = JSON.parse(JSON.stringify(visionMovieData));
    newVisionMovieData.visionMovie.push({
      id: uuidv4(),
      video: {
        placeholder: true,
        video_files: [
          {
            quality: "hd",
            width: 1920,
            link: "https://firebasestorage.googleapis.com/v0/b/visionmovie-94a95.appspot.com/o/black_placeholder_video_7sec.mp4?alt=media&token=638dafff-b168-4425-9f64-d9716d479e6e",
          },
        ],
        startOffset: 0,
        duration: 8,
      },
      affirmation: {
        header: "",
        paragraph: "",
        styling: {
          affirmationStyle: null,
          readability: null,
          headerSize: null,
          paragraphSize: null,
          fontColor: null,
          font: null,
        },
      },
    });

    //Sets playing duration of segments and containing affirmations
    newVisionMovieData.visionMovie = prepareDataForPlayer(
      JSON.parse(JSON.stringify(newVisionMovieData)),
      "createNewCard"
    );
    // Store new Data in firestore
    firestoreVisionMovieRef
      .set(newVisionMovieData)
      .then(() => {
        //console.log("Document successfully written!");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }

  function deleteCard(segmentId) {
    setSegmentToChooseBackgroundFor(null); //Otherwise would crash the application when deleting segment that is still stored as segmentToChooseBackgroundFor

    const container = findContainer(segmentId, visionMovieData);
    const newVisionMovieData = JSON.parse(JSON.stringify(visionMovieData));
    newVisionMovieData[container] = newVisionMovieData[container].filter(
      (segment) => segment.id !== segmentId
    );

    //Sets playing duration of segments and containing affirmations
    newVisionMovieData.visionMovie = prepareDataForPlayer(
      JSON.parse(JSON.stringify(newVisionMovieData)),
      "deleteCard"
    );

    // Store new Data in firestore
    firestoreVisionMovieRef
      .set(newVisionMovieData)
      .then(() => {
        //console.log("Document successfully written!");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }

  function addSongToVisionMovie(songObj) {
    const newVisionMovieData = JSON.parse(JSON.stringify(visionMovieData));
    newVisionMovieData.backgroundMusic = songObj;
    newVisionMovieData.visionMovie = prepareDataForPlayer(
      JSON.parse(JSON.stringify(newVisionMovieData)),
      "addSongToVisionMovie"
    );

    //This fixes the bug that when hearted song gets added to the vision movie "isUserUploaded: undefined" can't get stored in the database. Would need more investigation to see why this gets added in the first place when hearted. TODO for the future
    if (!newVisionMovieData.backgroundMusic?.isUserUploaded) {
      newVisionMovieData.backgroundMusic.isUserUploaded = false;
    }
    // Store new Data in firestore
    firestoreVisionMovieRef
      .set(newVisionMovieData)
      .then(() => {
        //console.log("Document successfully written!");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }

  //PREVIEW SONG FUNCTIONALITY____
  const [previewSongWithVisionMovie, setPreviewSongWithVisionMovie] = useState(false);
  const [previousSongObj, setPreviousSongObj] = useState(null);
  const { resetPlayer } = usePlayerSetupContext();
  function addSongToVisionMovieForPreview(newSongObj) {
    const newVisionMovieData = JSON.parse(JSON.stringify(visionMovieData));
    setPreviousSongObj(newVisionMovieData.backgroundMusic);
    newVisionMovieData.backgroundMusic = newSongObj;
    setVisionMovieData(newVisionMovieData);
    setPreviewSongWithVisionMovie(true);
  }

  //REMOVES Music track again after
  useEffect(() => {
    if (resetPlayer && previewSongWithVisionMovie) {
      const newVisionMovieData = JSON.parse(JSON.stringify(visionMovieData));
      newVisionMovieData.backgroundMusic = previousSongObj;
      setPreviousSongObj(null);
      setVisionMovieData(newVisionMovieData);
      setPreviewSongWithVisionMovie(false);
      console.log("reseted und gelöscht");
    }
  }, [resetPlayer]);
  //END PREVIEW SONG FUNCTIONALITY____

  //SEGMENTS TO CHOOSE FROM
  const { currentVideoLength, estimatedVideoLength } = useVideoLengths(visionMovieData);
  const howManySegmentsStillToBeChoosenForSong = useSegmentsToChoose(
    visionMovieData,
    currentVideoLength,
    estimatedVideoLength
  );

  const segmentsCoverSong = useSegmentsCoverSong(
    visionMovieData.backgroundMusic?.duration,
    currentVideoLength
  );

  useEffect(() => {
    /*   console.log("currentVideoLength: ", currentVideoLength);
    console.log("segmentsCoverSong: ", segmentsCoverSong);
    console.log(
      "visionMovieData.backgroundMusic?.duration: ",
      visionMovieData.backgroundMusic?.duration
    ); */
  }, [currentVideoLength, segmentsCoverSong]);

  /*   console.log("segmentsCoverSong: ", segmentsCoverSong);
  console.log("currentVideoLength: ", currentVideoLength);
  console.log("songLength: ", visionMovieData.backgroundMusic?.duration);
  console.log("howManySegmentsStillToBeChoosenForSong: ", howManySegmentsStillToBeChoosenForSong);
  console.log("visionMovieData: ");
  console.log(visionMovieData.visionMovie);
  console.log(
    visionMovieData.visionMovie.map((elem) => {
      return elem.video.showingDuration;
    })
  );
  console.log(calculateCurrentVideoLength(visionMovieData.visionMovie)); */

  function calculateCurrentVideoLength(segments) {
    const arrayOfShowingDurations = segments.map((elem) => {
      return elem.video.showingDuration;
    });
    const newCurrentVideoLength = arrayOfShowingDurations.reduce(
      (total, showingDuration) => total + showingDuration,
      0
    );
    return newCurrentVideoLength;
  }

  //This is needed in the segments page that when coming back from another subpage the scrollposition
  // is set again to the posiion where one was before
  const [scrollPositionInSegments, setScrollPositionInSegments] = useState(0);

  const functions = {
    visionMovieData,
    changeVisionMovieData,
    setVisionMovieData,
    changeText,
    createNewCard,
    deleteCard,
    segmentToChooseBackgroundFor,
    setSegmentToChooseBackgroundFor,
    addBackgroundToSegment,
    toggleVideoHeartedState,
    addSongToVisionMovie,
    addSongToVisionMovieForPreview,
    currentVideoLength,
    estimatedVideoLength,
    howManySegmentsStillToBeChoosenForSong,
    segmentsCoverSong,
    segmentToSetSettingsFor,
    setSegmentToSetSettingsFor,
    saveOpacityValueForSegment,
    scrollPositionInSegments,
    setScrollPositionInSegments,
    currentlyActiveVisionMovieId,
    setCurrentlyActiveVisionMovieId,
    visionMovies,
    moveDisplayIndexInAppOfOldVMs,
    setAllowSnapshotUpdates,
    deleteVisionMovie,
  };
  return <VisionMovieContext.Provider value={functions}>{children}</VisionMovieContext.Provider>;
}
