import { useMultiButtonState } from "./MultiButtonStateContext";
import { useVisionMovieContext } from "../VisionMovieContext";
import { useTheme } from "@mui/material/styles";
import { useWebinarContext } from "../softwareWebinar/WebinarContext";
import { Action } from "../softwareWebinar/Actions";
import WebinarButtonWrapper from "../softwareWebinar/WebinarButtonWrapper";
import { useTranslation } from "react-i18next";

export default function MultiButton() {
  const theme = useTheme();
  const navigate = useWebinarContext().navigate_WebinarWrapper;
  const { actionEnabled } = useWebinarContext();
  const { palette } = theme;
  const { setScrollPositionInSegments } = useVisionMovieContext();

  const { t } = useTranslation();

  const buttonStyle = {
    margin: 0,
    padding: "0px 16px 0px 16px",
    height: 35,
    fontSize: 14,
    border: "none",
    outline: "none",
    cursor: "pointer",
    fontWeight: [700],
    WebkitUserSelect: "none",
  };

  const buttonStyleHiglighted = {
    color: "rgba(255, 255, 255, 1)",
    backgroundColor: palette.primary.main,
    transform: "scale(1.07)",
    //boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.35)',
    ...buttonStyle,
  };
  const buttonStyleNotHiglighted = {
    color: palette.primary.main,
    backgroundColor: "rgba(242, 242, 242, 1)",
    padding: "10px 13px 10px 13px",
    ...buttonStyle,
  };

  const { multiButtonState, setMultiButtonState } = useMultiButtonState();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <div
        style={{
          borderRadius: "5px 5px 5px 5px",
        }}
      >
        <button
          onClick={() => {
            if (actionEnabled(Action.GoToBackgroundMusicPage)) {
              navigate("/edit");
              setMultiButtonState(0);
            }
          }}
          style={
            multiButtonState === 0
              ? { ...buttonStyleHiglighted, borderRadius: "5px 0px 0px 5px" }
              : {
                  ...buttonStyleNotHiglighted,
                  borderRadius: "5px 0px 0px 5px",
                }
          }
        >
          {t("selectVisionMovies")}
        </button>
        <WebinarButtonWrapper actionType={Action.GoToBackgroundMusicPage}>
          <button
            onClick={() => {
              if (actionEnabled(Action.GoToBackgroundMusicPage)) {
                navigate("/backgroundmusic");
                setMultiButtonState(1);
                setScrollPositionInSegments(document.body.scrollTop);
              }
            }}
            style={
              multiButtonState === 1
                ? { ...buttonStyleHiglighted, borderRadius: "0px 5px 5px 0px" }
                : {
                    ...buttonStyleNotHiglighted,
                    borderRadius: "0px 5px 5px 0px",
                  }
            }
          >
            {t("selectMusic")}
          </button>
        </WebinarButtonWrapper>
      </div>
    </div>
  );
}
