import "../../../styles.scss";
import { useVisionMovieContext } from "../../VisionMovieContext";
import useCalculateGridCardSize from "../../segments/helperHooks/useCalculateGridCardSize";
import SetTracking from "./SetTracking";

import Grid from "@mui/material/Grid";

import Card from "../../segments/Card";
import { useEffect, useState } from "react";
import useWindowResize from "beautiful-react-hooks/useWindowResize";

export default function Segments({ trackingData, setTrackingData }) {
  const gridCardSize = useCalculateGridCardSize();
  //Make cursor visible if was set to not show when player was closed
  document.body.style.cursor = "auto";

  //Shows cursor again, if it was hidden in "/player" before
  useEffect(() => {
    document.body.style.cursor = "auto";
  }, []);

  const [resize, setResize] = useState(false);
  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    setResize((prev) => !prev);
  });

  const { visionMovieData } = useVisionMovieContext();
  console.log(visionMovieData);
  const cards = [...visionMovieData.visionMovie, ...visionMovieData.archive];

  return (
    <>
      <Grid container spacing={2}>
        {cards.map((item, index) => (
          <Grid style={{ position: "relative" }} key={item.id + "gridItem"} item xs={gridCardSize}>
            <Card
              key={item.id + "card"}
              segment={item}
              index={index}
              showNumber={true}
              resize={resize}
            />
            <SetTracking
              segment={item}
              trackingData={trackingData}
              setTrackingData={setTrackingData}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
