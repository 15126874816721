import React, { useEffect, useState } from "react";
import videojs from "video.js";
import CanvasOverlay from "../Canvas/CanvasOverlay";
import computeFadeValueForCurrentTimeMs from "../helperFunctions/computeFadeValue";
import { VideoClip, VisionMovieSegment } from "../interfaces/VisionMovieSegment";
import PlayerSettings from "../PlayerSettings";
import VideoJS from "../VideoJs/VideoJS";

interface IVideoJsPlayerParameters {
  isActive: boolean;
  passiveBuffering: boolean;
  segment: VisionMovieSegment;
  onBufferedUntilEnd: () => void;
  onVideoFinished?: () => void;
  fadeInVideo?: boolean;
  fadeOutVideo?: boolean;
  fadeOutAudio?: boolean;
  index: number;
  fadeInAudio?: boolean;
  fadeOutAudioDuration?: number;
  setAudioVolume?: (newVolume: number) => void;
  videoClip: VideoClip;
  paused?: boolean;
  muted?: boolean;
  onBuffering?: () => void;
  onPlaying?: () => void;
  audioRef?: any;
  onAlmostFinished?: () => void; //is triggered when the current segment has only 2 more seconds to play
  prepareForPlayback?: boolean; //if is set to true, the video current time will be set to starting time and playback for buffering will be interrupted in order to make sure that the video can play from the start without any apparent jumps (especially on safari)
}
export default function VideoJsPlayer({
  isActive,
  segment,
  passiveBuffering,
  onBufferedUntilEnd,
  onVideoFinished,
  fadeInVideo = false,
  fadeOutVideo = false,
  index,
  fadeOutAudio,
  fadeInAudio,
  fadeOutAudioDuration = 7000,
  setAudioVolume,
  videoClip,
  paused,
  muted = true,
  onBuffering,
  onPlaying,
  audioRef,
  prepareForPlayback = false,
  onAlmostFinished = () => {},
}: IVideoJsPlayerParameters) {
  const playerRef = React.useRef<any>(null);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [player, setPlayer] = React.useState<videojs.Player | null>(null);
  const [fullyBuffered, setFullyBuffered] = useState(false);
  const [bufferedTime, setBufferedTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(-1);
  const [finished, setFinished] = useState(false);
  const [isBuffering, setIsBuffering] = useState(true);
  const [isResetToStart, setIsResetToStart] = useState(false);

  const startTimeMs = videoClip.startOffsetInSec * 1000;
  const endTimeMs = videoClip.startOffsetInSec * 1000 + videoClip.showingDurationInSec * 1000;

  const updateBufferState = () => {
    if (player) {
      const bufferedEndMs = player.bufferedEnd() * 1000;
      setBufferedTime(Math.round(bufferedEndMs));

      const bufferedUntilEnd =
        bufferedEndMs >= endTimeMs || bufferedEndMs >= player.duration() * 1000;

      if (!fullyBuffered && bufferedUntilEnd) {
        setFullyBuffered(true);
        onBufferedUntilEnd();
        if (!isActive) {
          if (!isResetToStart) {
            player.currentTime(startTimeMs / 1000);
            setIsResetToStart(true);
          }

          player.pause();
        }
      } else if (prepareForPlayback) {
        if (!isResetToStart) {
          player.currentTime(startTimeMs / 1000);
          setIsResetToStart(true);
          player.pause();
        }
      }
    }
  };

  useEffect(() => {
    if (player) {
      player.play();
    }
  }, [player]);

  useEffect(() => {
    return () => {
      if (player) {
        //player.dispose();
        player.off();
      }
    };
  }, [player]);

  useEffect(() => {
    if (player && isActive) {
      if (paused) {
        player.pause();
      } else {
        player.play();
      }
    }

    if (isActive && isBuffering) {
      onBuffering && onBuffering();
    }
  }, [player, paused, isActive]);

  useEffect(() => {
    //Set VisionMovieAudio to currentTime 0 for next VisionMovie playback
    if (audioRef && !passiveBuffering && segment?.videoClip?.isKaleidoscope) {
      audioRef.current.currentTime = 0;
    }

    if (!finished && player && canvasRef.current) {
      let canvasOverlay: null | CanvasOverlay = null;
      canvasOverlay = new CanvasOverlay(
        canvasRef.current,
        player.el().children[0],
        segment,
        fadeInVideo,
        fadeOutVideo,
        index
      );

      canvasOverlay.setActive(isActive);
      canvasOverlay.update();

      player.on("ready", () => {
        if (!isResetToStart) {
          player.currentTime(startTimeMs / 1000);
          setIsResetToStart(true);
        }
        player.play();
      });

      // You can handle player events here, for example:
      player.on("waiting", () => {
        setIsBuffering(true);
        //video url for video
        if (isActive) {
          videojs.log("---waiting" + index.toString());
          onBuffering && onBuffering();
        }
      });

      player.on("playing", () => {
        setIsBuffering(false);
        //if not reliably working, move to timeupdate and continously call
        if (isActive) {
          videojs.log("---playing" + index.toString());
          onPlaying && onPlaying();
        }
      });

      player.on("progress", () => {
        updateBufferState();
      });

      /*
      //POSSIBLE VIDEOJS EVENTS
      player.on("dispose", () => {
      });

      player.on("loadstart", () => {
      });

      player.on("loadeddata", () => {
      });

      player.on("emptied", () => {
      });

      player.on("canplay", () => {
      });
      */

      player.on("canplaythrough", () => {
        if (isActive && !paused) {
          player.play();
        }
      });

      const onTimeUpdate = () => {
        setCurrentTime(Math.round(player.currentTime() * 1000));
        updateBufferState();

        if (endTimeMs - currentTime < 2000) {
          onAlmostFinished();
        }

        const videoFinished =
          player.currentTime() * 1000 >= endTimeMs ||
          player.currentTime() * 1000 >= player.duration() * 1000;
        if (!finished && isActive && videoFinished) {
          setFinished(true);
          onVideoFinished && onVideoFinished();
        }

        //compute audio dynamically
        let fadeInDuration: number | null = null;
        let fadeOutDuration: number | null = null;
        if (fadeInAudio) {
          if (segment.videoClip?.isKaleidoscope) {
            if (index === 0) {
              fadeInDuration = PlayerSettings.firstKaleidoscopeVideoFadeInDuration_AUDIO_Ms;
            } else {
              fadeInDuration = PlayerSettings.middleAndEndkaleidoscopeVideoFadeInDuration_AUDIO_Ms;
            }
          } else {
            fadeInDuration = PlayerSettings.visionMovieFadeInDurationMs_AUDIO_Ms;
          }
        }
        if (fadeOutAudio) {
          if (segment.videoClip?.isKaleidoscope) {
            fadeOutDuration = PlayerSettings.kaleidoscopeFadeOutDuration_AUDIO_Ms;
          } else {
            fadeOutDuration = PlayerSettings.visionMovieFadeOutDurationMs_AUDIO_Ms;
          }
        }

        const newAudioVolume = computeFadeValueForCurrentTimeMs(
          segment.videoClip!.showingDurationInSec * 1000,
          player.currentTime() * 1000 - segment.videoClip!.startOffsetInSec * 1000,
          fadeInDuration,
          fadeOutDuration
        );
        if (!muted) {
          player.volume(newAudioVolume);
        } else if (setAudioVolume) {
          setAudioVolume(newAudioVolume);
        }
      };

      player.on("timeupdate", () => {
        setIsBuffering(false);
        onPlaying && onPlaying();
        onTimeUpdate();
      });

      //timeupdate fires too infrequently
      const intervalOnTimeUpdate = window.setInterval(onTimeUpdate, 1000 / 60);

      return () => {
        player.off();
        canvasOverlay && canvasOverlay.destroy();
        window.clearInterval(intervalOnTimeUpdate);
      };
    } else {
      player?.off();
    }
  }, [
    player,
    canvasRef,
    finished,
    isActive,
    fullyBuffered,
    onBufferedUntilEnd,
    onVideoFinished,
    passiveBuffering,
    isResetToStart,
    startTimeMs,
    endTimeMs,
  ]);

  useEffect(() => {
    if (player) {
      if (!isResetToStart) {
        player.currentTime(startTimeMs / 1000);
        setIsResetToStart(true);
      }
      player.play();
      if (isActive) {
        if (fullyBuffered) {
          onBufferedUntilEnd();
        }
      }
    }
  }, [isActive, passiveBuffering, player]);

  const isiPhone = () => {
    return navigator.platform && /iPhone|iPod/.test(navigator.platform);
  };

  const videoJsOptions: videojs.PlayerOptions = {
    autoplay: false,
    controls: false,
    muted: isiPhone() || !isActive ? true : false,
    playsinline: true,
    responsive: false,
    preload: "auto",
    fluid: true,
    sources: [
      {
        src: videoClip.availableVideoFilesFromAPI[0].videoLink,
        type: "video/mp4",
      },
    ],
  };

  const handlePlayerReady = (player: videojs.Player) => {
    setPlayer(player);
    playerRef.current = player;
    if (!isResetToStart) {
      player.currentTime(startTimeMs / 1000);
      setIsResetToStart(true);
    }
  };

  const opacity = 1; //finished ? 0 : 1;

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        maxHeight: "100%",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        backgroundColor: "black",
        opacity: opacity,
      }}
    >
      <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
      <canvas style={{ position: "absolute", top: 0, left: 0 }} ref={canvasRef}></canvas>
    </div>
  );
}
