import { easeLinear } from "d3-ease";
import _ from "lodash";

export default function computeFadeValueForCurrentTimeMs(
  showingDurationMs: number,
  currentTimeMs: number,
  fadeInDurationMs: number | null,
  fadeOutDurationMs: number | null
): number {
  let fadeoutTimeMs = -1;
  if (fadeOutDurationMs !== null) {
    fadeoutTimeMs = showingDurationMs - fadeOutDurationMs;
  }

  if (fadeInDurationMs !== null && currentTimeMs < fadeInDurationMs) {
    //fade in
    return easeLinear(currentTimeMs / fadeInDurationMs);
  } else if (fadeOutDurationMs !== null && currentTimeMs > fadeoutTimeMs) {
    //fade out
    return (
      1 -
      _.clamp(
        easeLinear((currentTimeMs - fadeoutTimeMs) / fadeOutDurationMs),
        0,
        1
      )
    );
  } else {
    return 1;
  }
}
