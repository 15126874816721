import Button from "@mui/material/Button";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useBackgroundSelectionContext } from "../backgroundSelection/BackgroundSelectionContext";
import { useTranslation } from "react-i18next";

export default function HeartedVideosButton({ allHeartedVideos }) {
  const currentPathname = useLocation().pathname;
  const { rememberBackgroundSelectionListScrollPosition } = useBackgroundSelectionContext();
  const { t } = useTranslation();

  return (
    <Link
      to={
        allHeartedVideos
          ? "/backgroundselection/allheartedvideos"
          : "/backgroundselection/heartedvideos"
      }
    >
      <Button
        style={{ textTransform: "none", borderRadius: "6px", fontWeight: "bold" }}
        variant="contained"
        disableElevation
        startIcon={<FavoriteIcon />}
        onClick={() => {
          if (currentPathname === "/backgroundselection")
            rememberBackgroundSelectionListScrollPosition();
        }}
      >
        {allHeartedVideos && t("all")}
        {t("videos")}
      </Button>
    </Link>
  );
}
