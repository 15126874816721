import { WebinarData, DivOverlayType } from "./SoftwareWebinarInterfaces";
import { Action } from "./Actions";

export const WebinarPartsDummyData: WebinarData = {
  forMobileDevicesVideo: {
    url: "https://firebasestorage.googleapis.com/v0/b/visionmovie-94a95.appspot.com/o/assetsForTesting%2FvideoPart3.mp4?alt=media&token=50a74a88-8f38-4c0a-88c9-b1ba2d5f00db",
  },
  parts: [
    {
      url: "https://firebasestorage.googleapis.com/v0/b/visionmovie-94a95.appspot.com/o/assetsForTesting%2FvideoPart1.mp4?alt=media&token=2864cdd3-5ec5-495f-b49f-04cfa2bf4790",
    },
    {
      routeString: "/signup",
      audioOverlays: [
        {
          url: "https://firebasestorage.googleapis.com/v0/b/visionmovie-94a95.appspot.com/o/assetsForTesting%2Fsoftware1Audio1.m4a?alt=media&token=ef82278a-4d5b-4f65-a9da-6f61e5beff8e",
          startTime: 1,
        },
      ],
    },
    {
      url: "https://firebasestorage.googleapis.com/v0/b/visionmovie-94a95.appspot.com/o/assetsForTesting%2FvideoPart2.mp4?alt=media&token=40b702bb-02ac-4b09-8514-7e7a8df3a0e3",
    },
    {
      routeString: "/edit",
      actions: {
        enabledActions: [Action.DeleteCard, Action.AddCard],
        controllingActions: [{ actionType: Action.AddCard, timesActionAllowed: 3 }],
      },
      showingDuration: 70,
      divOverlays: [{ type: DivOverlayType.NextPartButton, showTimeInSec: 10 }],
      audioOverlays: [
        {
          url: "https://firebasestorage.googleapis.com/v0/b/visionmovie-94a95.appspot.com/o/assetsForTesting%2Fsoftware2audio1.m4a?alt=media&token=dde421bb-65c8-47d7-b381-47d87483503c",
          startTime: 2,
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/visionmovie-94a95.appspot.com/o/assetsForTesting%2Fsoftware2audio2.m4a?alt=media&token=37810b47-79c3-4aec-b3eb-4d5bdc08c759",
          startTime: 50,
        },
      ],
    },
    {
      url: "https://firebasestorage.googleapis.com/v0/b/visionmovie-94a95.appspot.com/o/assetsForTesting%2FvideoPart3.mp4?alt=media&token=50a74a88-8f38-4c0a-88c9-b1ba2d5f00db",
    },
  ],
};
