import React from "react";
import { useVisionMovieContext } from "../VisionMovieContext";
import VideoSequencePlayer from "./VideoSequencePlayer";
import { VisionMovie, VisionMovieSequence } from "./VisionMovie";
import { VisionMovieSegment } from "./VisionMovieSegment";
import transformDataIntoPlayerDataFormat from "./helperFunctions/transformDataIntoPlayerDataFormat";

//DUMMY DATA
const imageSegment: VisionMovieSegment = {
  contrastLayerOpacityValue: 0.3,
  id: "e2-4446-8cd5-3dc6e46ebb5d",
  statement: {
    header: "I WILL",
    hideAffirmationAtSec: 9,
    paragraph: "protect my nut WHATEVER it takes ",
    showAffirmationAtSec: 1,
  },
  imageClip: {
    showingDurationInSec: 10,
    url: "https://www.pennlive.com/resizer/yLUMG51sJ9nTEKD6MBSaPAFo0c4=/1280x0/smart/cloudfront-us-east-1.images.arcpublishing.com/advancelocal/CYL7QDPNWNBJREHCTGY2TB525M.jpg",
    cropParameters: {
      zoom: 1,
      crop: { x: 0, y: 42 },
      croppedArea: {
        height: 81.19525047905832,
        width: 100,
        x: 0,
        y: 2.2704149308100647,
      },
      croppedAreaPixels: {
        height: 2966,
        width: 5273,
        x: 0,
        y: 0,
      },
    },
  },
};
const visionMovieWithImageSegment: VisionMovie = {
  audio: {
    audioDuration: "119",
    audioUrl:
      "https://firebasestorage.googleapis.com/v0/b/visionmovie-94a95.appspot.com/o/music%2FplatformSongs%2Fmp3Files%2Fsong5.mp3?alt=media&token=b50a2c6e-1125-48bc-8381-f4bf369e87e2",
    segmentsCoverSong: false,
  },
  segments: [
    imageSegment,
    {
      contrastLayerOpacityValue: 0.2,
      id: "4b0ec3e8-6fe2-4446-8cd5-3dc6e46ebb5d",
      statement: {
        header: "I AM",
        hideAffirmationAtSec: 9,
        paragraph:
          "living in Bali for the next months of winter. One two three four five six seven",
        showAffirmationAtSec: 1,
      },
      videoClip: {
        availableVideoFilesFromAPI: [
          {
            fileType: "video/mp4",
            height: 1080,
            quality: "hd",
            videoLink:
              "https://player.vimeo.com/external/517816974.hd.mp4?s=058e8cd79b26b12c416361e97f7c9ec751eb8cfa&profile_id=175&oauth2_token_id=57447761",
            width: 1920,
          },
        ],
        durationOfVideoFileInSec: 35,
        placeholder: undefined,
        showingDurationInSec: 14,
        startOffsetInSec: 0,
      },
    },
  ],
};
//Including Kaleidoscope (note: bad kaleidoscope from pexels for sake of simplicity)
const visionMovieSequence: VisionMovieSequence = {
  sequence: [
    {
      videoUrl:
        "https://firebasestorage.googleapis.com/v0/b/visionmovie-94a95.appspot.com/o/assetsForTesting%2FKaleidoscope%20for%20Testing.mp4?alt=media&token=d9d706c1-5405-408f-8b55-ccf99e9d416d",
      showingDuration: 20,
    },
    visionMovieWithImageSegment,
  ],
};

export default function Player() {
  const visionMovieContextOutput: any = useVisionMovieContext();
  const visionMovie: VisionMovie = transformDataIntoPlayerDataFormat(visionMovieContextOutput);
  return <VideoSequencePlayer visionMovie={visionMovie} width={1980} height={1080} />;
}
