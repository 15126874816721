import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceSmileBeam } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

export default function PaymentSuccessful() {
  const theme = useTheme();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        paddingBottom: "20vh",
      }}
    >
      <FontAwesomeIcon
        style={{ fontSize: 120, color: theme.palette.primary.main, marginBottom: "25" }}
        icon={faFaceSmileBeam}
      />
      <Typography style={{ fontWeight: "bold" }} variant="h3">
        Your payment was successful!
      </Typography>
    </div>
  );
}
