import "../../styles.scss";
import { useVisionMovieContext } from "../VisionMovieContext";
import useCalculateGridCardSize from "./helperHooks/useCalculateGridCardSize";

import DnDContext from "./DnDContext";
import SortableContainer from "./SortableContainer";
import SortableItem from "./SortableItem";

import Grid from "@mui/material/Grid";

import Card from "./Card";
import Divider from "./Divider";
import AddCard from "./addCard";
import ArchivePlaceholderCard from "./ArchivePlaceholderCard";
import { useEffect, useState } from "react";
import useWindowResize from "beautiful-react-hooks/useWindowResize";

export default function Segments() {
  const gridCardSize = useCalculateGridCardSize();
  //Make cursor visible if was set to not show when player was closed
  document.body.style.cursor = "auto";

  //Shows cursor again, if it was hidden in "/player" before
  useEffect(() => {
    document.body.style.cursor = "auto";
  }, []);

  const [resize, setResize] = useState(false);
  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    setResize((prev) => !prev);
  });

  const { visionMovieData } = useVisionMovieContext();

  return (
    <DnDContext>
      <SortableContainer
        visionMovieContainer={true}
        id="visionMovie"
        items={visionMovieData.visionMovie.map((item) => item.id)}
      >
        <Grid container spacing={2}>
          {visionMovieData.visionMovie.map((item, index) => (
            <Grid key={item.id + "gridItem"} item xs={gridCardSize}>
              <SortableItem key={item.id + "sortableItem"} id={item.id}>
                <Card
                  key={item.id + "card"}
                  segment={item}
                  index={index}
                  showNumber={true}
                  resize={resize}
                />
              </SortableItem>
            </Grid>
          ))}
          <Grid key={"AddCard"} item xs={gridCardSize}>
            <AddCard visionMovieData={visionMovieData} />
          </Grid>
        </Grid>
      </SortableContainer>
      <Divider />
      <SortableContainer id="archive" items={visionMovieData.archive.map((item) => item.id)}>
        <Grid container spacing={2}>
          {visionMovieData.archive.map((item) =>
            item.id !== "archivePlaceholder" ? (
              <Grid key={item.id} item xs={gridCardSize}>
                <SortableItem id={item.id}>
                  <Card segment={item} />
                </SortableItem>
              </Grid>
            ) : visionMovieData.archive.length === 1 ? (
              <Grid key={"ArchivePlaceholderCard"} item xs={gridCardSize}>
                <SortableItem disabled={true} key={item.id} id={item.id}>
                  <ArchivePlaceholderCard />
                </SortableItem>
              </Grid>
            ) : null
          )}
        </Grid>
      </SortableContainer>
    </DnDContext>
  );
}
