import { useState } from "react";
import TransformedImage from "./TransformedImage";
import ImageCardOverlayButtons from "./ImageCardOverlayButtons";

export default function ImageCard({ videoData }) {
  const [overCard, setOverCard] = useState(false);

  //If no transform data is available / the image is not cropped
  if (!videoData?.transformDataObj) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          overflow: "hidden",
          position: "relative",
        }}
        onMouseEnter={() => setOverCard(true)}
        onMouseOver={() => setOverCard(true)}
        onMouseLeave={() => setOverCard(false)}
      >
        <img
          src={videoData.image}
          style={{
            transform: "scale(1)",
            objectPosition: "50% 50%",
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
        />
        <ImageCardOverlayButtons videoData={videoData} overCard={overCard} />
      </div>
    );
  }

  //If the image is cropped, the image gets transformed
  if (videoData?.transformDataObj) {
    return (
      <div
        onMouseEnter={() => setOverCard(true)}
        onMouseOver={() => setOverCard(true)}
        onMouseLeave={() => setOverCard(false)}
      >
        <TransformedImage imageData={videoData}>
          {/* Overlays can be passed as children */}
          <ImageCardOverlayButtons videoData={videoData} overCard={overCard} />
        </TransformedImage>
      </div>
    );
  }
}
