import React, { useContext, useEffect, useState } from "react";
import { Action } from "./Actions";
import { Actions } from "./SoftwareWebinarInterfaces";
import { useLocation, useNavigate } from "react-router-dom";
import { WebinarData } from "./SoftwareWebinarInterfaces";
import useUndo from "use-undo";
import { useTrialAccount } from "../Firebase/Authentication/useTrialAccount";
import { trialAccountAllowedActions } from "./trialAccountAllowedActions";

interface ParametersToBePassed {
  setActions: (allowedActions: any) => void;
  actionEnabled: (action: Action) => boolean;
  navigate_WebinarWrapper: (destinationString: string | number) => void;
  setRouteString: any;
  routeString: any;
  trialAccount: any;
}

export const WebinarContext = React.createContext<ParametersToBePassed | null>(null);

export function useWebinarContext() {
  return useContext(WebinarContext);
}

//Creating the logic and the Providers
export function WebinarContextProvider({ children }: { children: any }) {
  const pathname = useLocation().pathname;

  //Enabling and Disabeling Actions______________
  const [actions, setActions] = useState<Actions>();

  const trialAccount = false; //useTrialAccount();
  useEffect(() => {
    trialAccount && setActions(trialAccountAllowedActions);
  }, [trialAccount]);

  function actionEnabled(action: Action) {
    //If not in webinar then enabling and disabling actions is not applicable
    if (!actions?.disabledActions && !actions?.enabledActions && !actions?.controllingActions)
      return true;
    if (actions?.enabledActions) {
      //Check if enabled
      const includedInENABLEDActions = actions?.enabledActions.includes(action);
      if (includedInENABLEDActions) {
        //actionPerformed(action);
        return true;
      } else {
        //If other actions are enabled but not this one it is automatically
        //actionPerformed(action);
        return false;
      }
    }
    //Check if disabled
    if (actions?.disabledActions) {
      const includedInDISABLEDActions = actions?.disabledActions.includes(action);
      if (includedInDISABLEDActions) return false;
    }
    //Automatically enabled, if nothing is defined
    return true;
  }
  //END: Enabling and Disabeling Actions______________

  //Routing Wrapper______
  const [routeString, { set: setRouteString, undo: undoRouteString }] = useUndo("/signup");
  //const [routeString, setRouteString] = useState<string | number>("/signup");
  const navigate = useNavigate();

  function navigate_WebinarWrapper(path: string | number) {
    //Navigate for normal app use
    if (pathname !== "/webinar") {
      if (typeof path == "string") {
        navigate(path);
        console.log("string");
      }
      if (path === -1) {
        console.log("navigate -1");
        navigate(-1);
      }
    }
    //Navigate for webinar app use
    if (pathname === "/webinar") {
      if (typeof path == "string") {
        setRouteString(path);
      }
      if (path === -1) {
        undoRouteString();
      }
    }
  }
  //End: Routing Wrapper______

  const parametersToBePassed: ParametersToBePassed = {
    setActions,
    actionEnabled,
    navigate_WebinarWrapper,
    routeString: routeString.present,
    setRouteString,
    trialAccount,
  };

  return <WebinarContext.Provider value={parametersToBePassed}>{children}</WebinarContext.Provider>;
}

/*   function anotherActionAllowed(action: Action): boolean {
    return false;
  }

  //This function counts how many times an action is performed
  function actionPerformed(action: Action): void {
    const actionsCopy = JSON.parse(JSON.stringify(actions));
    const index = actionsCopy.controllingActions.findIndex(
      (elem: any) => elem.actionType === action
    );
    const controllingActionsObj = actionsCopy.controllingActions[index];
    //Add 1 to timesActionPerformed and if not defined set to 1
    controllingActionsObj.timesActionPerformed = controllingActionsObj.timesActionPerformed
      ? controllingActionsObj.timesActionPerformed + 1
      : 1;
    console.log(actionsCopy);
    setActions(actionsCopy);
  } */
