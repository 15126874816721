import { VisionMovieSegment } from "./VisionMovieSegment";
import _ from "lodash";
import Line from "./CanvasElements/Line";
import SingleLineString from "./CanvasElements/SingleLineString";
import MultiLineString from "./CanvasElements/MultiLineString";
import { createEaseInOutFunction } from "./animationUtils";
import parameters from "./CanvasOverlayParameters";
import { easeLinear } from "d3-ease";

export default class CanvasOverlay {
  private context: CanvasRenderingContext2D | null | any = null;
  private ratio = 1;

  private title: string;
  private paragraph: string;
  private overlayAlpha: number;

  private videoWidth: number;
  private videoHeight: number;
  private offsetTop: number;

  private segmentClipObj: any;

  private lineElement: Line | null = null;
  private titleElement: SingleLineString | null = null;
  private paragraphElement: MultiLineString | null = null;

  private animationOpacityTitle: (timeMs: number) => number = (timeMs: number) => 1;
  private animationOpacityPararaph: (timeMs: number) => number = (timeMs: number) => 1;
  private animationOpacityLine: (timeMs: number) => number = (timeMs: number) => 1;
  private animationLengthOfLine: (timeMs: number) => number = (timeMs: number) => 1;
  private destroyed: boolean = false;
  constructor(
    private canvas: HTMLCanvasElement,
    private video: any,
    private visionMovieSegment: VisionMovieSegment,
    private lastSegment: boolean
  ) {
    this.visionMovieSegment = visionMovieSegment;
    this.lastSegment = lastSegment;
    this.segmentClipObj = visionMovieSegment?.videoClip
      ? visionMovieSegment?.videoClip
      : visionMovieSegment?.imageClip;

    //Initiate header & paragraph
    //If no header & paragraph is choosen && no video -> show placeholder text
    const placeholderTextNeeded =
      this.segmentClipObj?.placeholder &&
      visionMovieSegment.statement.header === "" &&
      visionMovieSegment.statement.paragraph === "";

    this.title = placeholderTextNeeded
      ? "YOU DIDN'T"
      : visionMovieSegment.statement.header.toUpperCase();
    this.paragraph = placeholderTextNeeded
      ? "TYPE IN ANY TEXT YET. DO THIS AND IT WILL BE SHOWN HERE"
      : visionMovieSegment.statement.paragraph;

    this.overlayAlpha = visionMovieSegment.contrastLayerOpacityValue;

    this.canvas = canvas;
    this.video = video;

    this.context = canvas.getContext("2d")!;

    if (!this.canvas || !this.video || !this.context) {
      console.error("CanvasOverlay failed to initialize");
    }

    const elementBounds = this.video.getBoundingClientRect();

    this.videoWidth = elementBounds.width;
    this.videoHeight = elementBounds.height;
    this.offsetTop = elementBounds.top;

    //get currentOffsetTop for video element
    this.canvas.style.top = elementBounds.top + "px";
    this.canvas.style.left = elementBounds.left + "px";

    this.adjustToDevicePixelRatio(this.videoWidth, this.videoHeight);

    this.initializeAnimations();

    this.updateLayout();
  }
  initializeAnimations() {
    this.animationOpacityTitle = createEaseInOutFunction(
      this.visionMovieSegment.statement.showAffirmationAtSec * 1000,
      parameters.titleAnimation.fadeInDurationMs,
      this.visionMovieSegment.statement.hideAffirmationAtSec * 1000,
      parameters.titleAnimation.fadeOutDurationMs
    );
    this.animationOpacityPararaph = createEaseInOutFunction(
      this.visionMovieSegment.statement.showAffirmationAtSec * 1000 +
        parameters.paragraphAnimation.fadeInDelayMs,
      parameters.paragraphAnimation.fadeInDurationMs,
      this.visionMovieSegment.statement.hideAffirmationAtSec * 1000 +
        parameters.paragraphAnimation.fadeOutDelayMs,
      parameters.paragraphAnimation.fadeOutDurationMs
    );
    this.animationOpacityLine = createEaseInOutFunction(
      this.visionMovieSegment.statement.showAffirmationAtSec * 1000 +
        parameters.lineOpacity.fadeInDelayMs,
      parameters.lineOpacity.fadeInDurationMs,
      this.visionMovieSegment.statement.hideAffirmationAtSec * 1000 +
        parameters.lineOpacity.fadeOutDelayMs,
      parameters.lineOpacity.fadeOutDurationMs
    );
    this.animationLengthOfLine = createEaseInOutFunction(
      this.visionMovieSegment.statement.showAffirmationAtSec * 1000 +
        parameters.lineWidthAnimation.startDelayMs,
      parameters.lineWidthAnimation.startDurationMs,
      this.visionMovieSegment.statement.hideAffirmationAtSec * 1000 +
        parameters.lineWidthAnimation.endDelayMs,
      parameters.lineWidthAnimation.endDurationMs
    );
  }

  updateLayout() {
    const scaleFactor = 1 / this.ratio;

    //compute title element
    const fontSizeTitle = (parameters.title.fontSize * (scaleFactor * this.canvas.width)) / 1920;
    const titleFont = `700 ${fontSizeTitle}px Poppins`;
    this.context.font = titleFont;
    this.context.fillStyle = "white";
    this.context.textAlign = "left";
    const titleDimensions = this.context.measureText(this.title);

    const marginLeft = this.canvas.width * parameters.general.paddingLeft * scaleFactor;
    this.titleElement = {
      x: marginLeft,
      y: 0,
      text: this.title,
      font: titleFont,
      color: "#ffffff",
      width: titleDimensions.width,
      height: titleDimensions.actualBoundingBoxAscent,
    };

    //compute line element

    //width of line is at least length of title, but at most length of string "I AM"
    const maxAbsoluteLineWidth = Math.min(
      titleDimensions.width,
      this.context.measureText("I AM").width
    );

    const yLine =
      this.titleElement.y +
      this.titleElement.height +
      this.canvas.height * parameters.line.relativeTopMarginTitle * scaleFactor;

    this.lineElement = {
      x: marginLeft,
      y: yLine,
      color: "#fffff",
      width: maxAbsoluteLineWidth,
      height: this.canvas.height * parameters.line.relativeHeight * scaleFactor,
    };

    //compute paragraph element
    const maxWidthParagraph = this.canvas.width * parameters.paragraph.maxRelativeWidth;

    const fontSizeParagraph =
      (parameters.paragraph.fontSize * (scaleFactor * this.canvas.width)) / 1920;

    this.paragraphElement = this.createMultiLineCanvasElement(
      this.paragraph,
      fontSizeParagraph,
      maxWidthParagraph,
      marginLeft,
      this.lineElement.y +
        this.lineElement.height +
        this.canvas.height * parameters.paragraph.relativeTopMarginParagraph * scaleFactor
    );

    const minY = this.titleElement.y;
    const maxY = this.paragraphElement.y + this.paragraphElement.height;
    const totalVerticalSpacing = this.canvas.height * scaleFactor - (maxY - minY);
    const offsetTop = totalVerticalSpacing * 0.5; //centers the content vertically

    /*  console.log("maxWidthParagraph: ", maxWidthParagraph);
    console.log("this.paragraph: ", this.paragraph);
    console.log("fontSizeParagraph: ", fontSizeParagraph);
    console.log("marginLeft: ", marginLeft);
    console.log(
      "multiple parameters added ",
      this.lineElement.y +
        this.lineElement.height +
        this.canvas.height * parameters.paragraph.relativeTopMarginParagraph * scaleFactor
    );
    console.log("minY: ", minY);
    console.log("maxY: ", maxY);
    console.log("totalVerticalSpacing: ", totalVerticalSpacing);
    console.log("offsetTop: ", offsetTop); */

    this.titleElement.y += offsetTop;
    this.lineElement.y += offsetTop;
    this.paragraphElement.y += offsetTop;
  }

  createMultiLineCanvasElement(
    text: string,
    fontSize: number,
    maxWidth: number,
    x: number,
    y: number
  ): MultiLineString {
    const singleLineStrings: SingleLineString[] = [];
    const font = `500 ${fontSize}px Poppins`;
    this.context.font = font;
    this.context.textAlign = "left";
    const textMetrics = this.context.measureText(text);
    const actualHeight = textMetrics.actualBoundingBoxAscent;
    const textWidth = textMetrics.width;
    //if textWidth is more then X% of the canvas width, draw multiple lines

    let paragraphHeight = 0;
    let paragraphWidth = 0;
    if (textWidth > maxWidth) {
      const words = text.split(" ");
      let line = "";
      let currentY = 0;
      for (let n = 0; n < words.length; n++) {
        const testLine = line + words[n] + " ";
        const metrics = this.context.measureText(testLine);
        const testWidth = metrics.width;
        if (testWidth > maxWidth && n > 0) {
          singleLineStrings.push({
            x: 0,
            y: currentY,
            text: line,
            color: "#ffffff",
            font: font,
            width: metrics.width,
            height: metrics.actualBoundingBoxAscent,
          });

          paragraphWidth = _.max([metrics.width, paragraphWidth]);
          paragraphHeight += fontSize * parameters.paragraph.maxRelativeWidth;
          this.context.fillText(line, x, y);
          line = words[n] + " ";
          currentY += fontSize * parameters.paragraph.wrappingLineHeight;
        } else {
          line = testLine;
        }
      }

      singleLineStrings.push({
        x: 0,
        y: currentY,
        text: line,
        color: "#ffffff",
        font: font,
        width: this.context.measureText(line).width,
        height: this.context.measureText(line).actualBoundingBoxAscent,
      });

      this.context.fillText(line, x, y);
    } else {
      const metrics = this.context.measureText(text);
      singleLineStrings.push({
        x: 0,
        y: 0,
        text: text,
        width: metrics.width,
        font: font,
        color: "#ffffff",
        height: metrics.actualBoundingBoxAscent,
      });
      paragraphHeight = metrics.actualBoundingBoxAscent;
      paragraphWidth = metrics.width;
    }

    return {
      x: x,
      y: y,
      width: paragraphWidth,
      height: paragraphHeight,
      lines: singleLineStrings,
    };
  }

  updateCanvasSize() {
    const elementBounds = this.video.getBoundingClientRect();

    const width = elementBounds.width;
    const height = elementBounds.height;
    const top = elementBounds.top;

    if (this.videoWidth !== width || this.videoHeight !== height) {
      this.videoWidth = width;
      this.videoHeight = height;

      this.adjustToDevicePixelRatio(this.videoWidth, this.videoHeight);
      this.updateLayout();
    }

    if (this.offsetTop !== top) {
      this.offsetTop = top;
      this.canvas.style.top = this.offsetTop + "px";
    }
  }

  adjustToDevicePixelRatio(width: number, height: number) {
    //taken from https://gist.github.com/callumlocke/cc258a193839691f60dd

    if (this.context) {
      const devicePixelRatio = window.devicePixelRatio || 1;

      // determine the 'backing store ratio' of the canvas context
      const backingStoreRatio =
        this.context.webkitBackingStorePixelRatio ||
        this.context.mozBackingStorePixelRatio ||
        this.context.msBackingStorePixelRatio ||
        this.context.oBackingStorePixelRatio ||
        this.context.backingStorePixelRatio ||
        1;

      const ratio = ((devicePixelRatio / backingStoreRatio) * 2) / window.devicePixelRatio;

      if (devicePixelRatio !== backingStoreRatio) {
        // set the 'real' canvas size to the higher width/height
        this.canvas.width = width * ratio;
        this.canvas.height = height * ratio;

        // ...then scale it back down with CSS
        this.canvas.style.width = width + "px";
        this.canvas.style.height = height + "px";
      } else {
        // this is a normal 1:1 device; just scale it simply
        this.canvas.width = width;
        this.canvas.height = height;
        this.canvas.style.width = "";
        this.canvas.style.height = "";
      }

      // scale the drawing context so everything will work at the higher ratio
      this.context.scale(ratio, ratio);
      this.ratio = ratio;
    }
  }

  destroy() {
    this.destroyed = true;
  }

  drawTitle(currentTimeMs: number) {
    if (this.titleElement) {
      this.context.globalAlpha = this.animationOpacityTitle(currentTimeMs);
      this.context.font = this.titleElement?.font;
      this.context.fillStyle = this.titleElement?.color;
      this.context.textAlign = "left";
      this.context.fillText(
        this.titleElement?.text,
        this.titleElement?.x,
        this.titleElement?.y + this.titleElement.height
      );
    }
  }

  drawLine(currentTimeMs: number) {
    if (this.lineElement) {
      this.context.globalAlpha = this.animationOpacityLine(currentTimeMs);

      //minimum length of line is header, maximum length is length of "I AM"
      this.context.fillStyle = this.lineElement.color;
      const lineWidth = this.animationLengthOfLine(currentTimeMs) * this.lineElement.width;
      this.context.strokeStyle = "green";
      this.context.beginPath();
      this.context.fillRect(
        this.lineElement.x,
        this.lineElement.y,
        lineWidth,
        this.lineElement.height
      );

      this.context.closePath();
    }
  }

  drawParagraph(currentTimeMs: number) {
    if (this.paragraphElement) {
      this.context.globalAlpha = this.animationOpacityPararaph(currentTimeMs);
      let globalX = this.paragraphElement.x;
      let globalY = this.paragraphElement.y;

      for (const line of this.paragraphElement.lines) {
        this.context.font = line.font;
        this.context.textAlign = "left";
        this.context.fillStyle = line.color;
        let x = globalX + line.x;
        let y = globalY + line.y + line.height;
        this.context.fillText(line.text, x, y);
      }
    }
  }

  update() {
    this.updateCanvasSize();
    const currentTimeMs = this.video?.currentTime * 1000;

    //If video is choosen, but no text => don't show text
    const dontShowText =
      !this.segmentClipObj?.placeholder &&
      this.visionMovieSegment.statement.header === "" &&
      this.visionMovieSegment.statement.paragraph === "";
    const showText = !dontShowText;

    if (this.context) {
      //clear everything
      this.context.beginPath();
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

      //draw overlay mask
      this.context.globalAlpha = this.lastSegment
        ? this.fadeoutLastSegment(this.overlayAlpha)
        : this.overlayAlpha;
      this.context.fillStyle = "black";
      this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);

      if (showText) {
        this.drawTitle(currentTimeMs);
        this.drawLine(currentTimeMs);
        this.drawParagraph(currentTimeMs);
      }
    }

    if (!this.destroyed) {
      window.requestAnimationFrame(() => this.update());
    }
  }

  fadeoutLastSegment(overlayAlpha: number): number {
    const FADEOUT_LAST_VIDEO_TIME_MS = 5000;
    const currentTimeMs = this.video.currentTime * 1000;
    const fadeoutTimeMs = this.segmentClipObj?.showingDurationInSec
      ? this.segmentClipObj.showingDurationInSec * 1000 - FADEOUT_LAST_VIDEO_TIME_MS
      : 0;

    if (currentTimeMs > fadeoutTimeMs) {
      return _.clamp(
        easeLinear((currentTimeMs - fadeoutTimeMs) / FADEOUT_LAST_VIDEO_TIME_MS),
        0,
        1
      );
    } else {
      return overlayAlpha;
    }
  }
}
