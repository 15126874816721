import React, { useRef, useLayoutEffect, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Box from "./Box";
import { TrackingBoxType } from "./TrackingBoxType";
import { TrackingType } from "../setProgressTracking/ProgressTracking";
import DoneNotDoneButton from "./DoneNotDoneButton";
import AddNumber from "./AddNumber";
import dayjs from "dayjs";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import ScrollList from "./ScrollList";
import { MonthContextProvider } from "./MonthContext";
import MonthName from "./MonthName";
import { useTranslation } from "react-i18next";

const weekDaysDe = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];
const weekDaysEn = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const days = [
  { day: 1, boxType: TrackingBoxType.placeholder },
  { day: 2, boxType: TrackingBoxType.placeholder },
  { day: 3, boxType: TrackingBoxType.number },
  { day: 4, boxType: TrackingBoxType.done },
  { day: 5, boxType: TrackingBoxType.timesVisionMovieWatched },
  { day: 6, boxType: TrackingBoxType.done },
  { day: 7, boxType: TrackingBoxType.empty },
  { day: 8, boxType: TrackingBoxType.missed },
  { day: 9, boxType: TrackingBoxType.done },
  { day: 10, boxType: TrackingBoxType.missed },
];

/* export interface ToDo {
    id: string;
    currentTrackingType: TrackingType;
    visionMovieId: string | null;
    visionMovieSegmentId: string;
    measurements: Measurement[];
  }
  
  export interface Measurement {
    id: string;
    date: Date;
    trackingType: TrackingType;
    value: number | string | boolean;
  }
  
  export enum TrackingType {
    none = "",
    weekly_once = "1x_pro_Woche_JA/NEIN",
    weekly_multiple_times = "Anzahl pro Woche",
    time_per_week = "verwendete Zeit pro Woche",
    daily_once = "1x täglich JA/NEIN",
    daily_multiple_times = "Anzahl pro Tag",
    time_per_day = "verwendete Zeit pro Tag",
    value = "Bewertung 0-10",
  } */

const dummyData = {};

const startingDate = dayjs("2023-08-10 19:18");
const dummyToDo = {
  id: "id",
  currentTrackingType: TrackingType.daily_once,
  visionMovieId: "vm1",
  visionMovieSegmentId: "4e3d42dd-ada0-4788-8324-ee8f35cb3bcb",
  measurements: null /* [
    {
      id: 1,
      date: startingDate,
      trackingType: TrackingType.daily_once,
      value: 1,
    },
    {
      id: 2,
      date: startingDate.add(1, "day"),
      trackingType: TrackingType.daily_once,
      value: 1,
    },
    {
      id: 3,
      date: startingDate.add(2, "day"),
      trackingType: TrackingType.daily_once,
      value: 2,
    },
    {
      id: 4,
      date: startingDate.add(3, "day"),
      trackingType: TrackingType.daily_once,
      value: 3,
    },
    {
      id: 5,
      date: startingDate.add(1, "day"),
      trackingType: TrackingType.daily_once,
      value: 4,
    },
    {
      id: 6,
      date: startingDate.add(4, "day"),
      trackingType: TrackingType.daily_once,
      value: 5,
    },
    {
      id: 7,
      date: startingDate.add(5, "day"),
      trackingType: TrackingType.daily_once,
      value: 2,
    },
    {
      id: 8,
      date: startingDate.add(6, "day"),
      trackingType: TrackingType.daily_once,
      value: 1,
    },
  ], */,
};

export default function Calendar({
  toDo,
  coachings,
  showTrackingButtons,
  setActiveToDoIndex,
  nextClicked,
}) {
  const { i18n } = useTranslation();

  const weekDays = i18n.language === "de-DE" ? weekDaysDe : weekDaysEn;
  //CALCULATE SCALING FACTOR
  const calendarContainer = useRef();
  const [scalingFactor, setScalingFactor] = useState();
  const optimizedForWidth = 713;
  useLayoutEffect(() => {
    setScalingFactor(calendarContainer?.current?.offsetWidth / optimizedForWidth);
  }, [calendarContainer.current]);

  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    setScalingFactor(calendarContainer?.current?.offsetWidth / optimizedForWidth);
  });

  function addPlaceholderDays(toDo) {
    const dayJsObj = toDo?.measurements
      ? dayjs(toDo?.measurements[0].date)
      : dayjs(toDo?.startTrackingDate);
    const dayOfWeek = dayJsObj?.day();
    const placeholdersToBeAdded = dayOfWeek > 0 ? dayOfWeek - 1 : 6; //-1 because I want to start my weeks at Monday not Sunday
    const toBeAddedPlacholderDays = [];
    for (let i = 0; i < placeholdersToBeAdded; i++) {
      toBeAddedPlacholderDays.push({
        date: dayOfWeek > 0 ? dayJsObj.day(i + 1) : dayJsObj.subtract(6, "day").day(i + 1), //+1 because I want to start my weeks at Monday not Sunday
        boxType: TrackingBoxType.placeholder,
      });
    }
    const newMeasurementsArray = toDo?.measurements
      ? toBeAddedPlacholderDays.concat(toDo?.measurements)
      : toBeAddedPlacholderDays;
    return newMeasurementsArray;
  }

  const [placeholdersAndMeasurements, setPlaceholdersAndMeasurements] = useState();

  useEffect(() => {
    toDo && setPlaceholdersAndMeasurements(addPlaceholderDays(toDo));
  }, [toDo]);

  return (
    <Stack
      ref={calendarContainer}
      direction={"column"}
      sx={{
        paddingTop: scalingFactor ? (15 / 8) * scalingFactor : 15 / 8,
        paddingLeft: scalingFactor ? (25 / 8) * scalingFactor : 25 / 8,
        paddingRight: scalingFactor ? (25 / 8) * scalingFactor : 25 / 8,
        paddingBottom: scalingFactor ? (20 / 8) * scalingFactor : 20 / 8,
        height: "100%",
        width: "100%",
        background: "white",
        borderRadius: scalingFactor ? (10 / 8) * scalingFactor : 10 / 8,
        opacity: 1,
        overflow: "hidden",
      }}
    >
      <MonthContextProvider>
        <MonthName
          scalingFactor={scalingFactor}
          placeholdersAndMeasurements={placeholdersAndMeasurements}
        />
        <Stack
          direction={"row"}
          style={{
            position: "realtive",
            paddingRight: scalingFactor ? 20 * scalingFactor : 20,
          }}
        >
          {weekDays.map((day, index) => (
            <Typography
              key={index}
              style={{
                fontSize: scalingFactor ? 19 * scalingFactor : 19,
                fontWeight: "bold",
                width: `${100 / 7}%`,
                textAlign: "center",
                width: `${85 / 7}%`,
                marginRight: index === 6 ? 0 : "3.5%",
                userSelect: "none",
                paddingBottom: scalingFactor ? 8 * scalingFactor : 8,
              }}
              variant="h5"
            >
              {day}
            </Typography>
          ))}
        </Stack>
        <ScrollList
          placeholdersAndMeasurements={placeholdersAndMeasurements}
          scalingFactor={scalingFactor}
          coachings={coachings}
          toDo={toDo}
          showTrackingButtons={showTrackingButtons}
          setActiveToDoIndex={setActiveToDoIndex}
          nextClicked={nextClicked}
        />
      </MonthContextProvider>
    </Stack>
  );
}
