import MenuButton from "./MenuButton";
import MultiButton from "./MultiButton";
import MultiButtonMainScreen from "./MultiButtonMainScreen";
import { useTheme } from "@mui/material/styles";
import LogoutButton from "../Firebase/Authentication/LogoutButton";
import BackButton from "./BackButton";
import Button from "@mui/material/Button";
import RocketLaunch from "@mui/icons-material/RocketLaunch";
import PsychologyIcon from "@mui/icons-material/Psychology";
import SchoolIcon from "@mui/icons-material/School";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import Stack from "@mui/material/Stack";
import SearchBar from "./SearchBar";
import HeartedVideosButton from "./HeartedVideosButton";
import PlayVisionMovieButton from "./PlayVisionMovieButton";
import UploadSongButton from "./UploadSongButton";
import UploadVideosButton from "./UploadVideosButton";
import UploadImagesButton from "./UploadImagesButton";
import { useFullscreen } from "rooks";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useMultiButtonState } from "./MultiButtonStateContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand, faCompress } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export default function Navigation({ proAccount }) {
  const { t } = useTranslation();

  const currentPathname = useLocation().pathname;
  const theme = useTheme();
  const { toggleFullscreen, isFullscreenEnabled } = useFullscreen(document.body);
  const navigate = useNavigate();
  const { setMultiButtonState } = useMultiButtonState();

  if (currentPathname == "/" || currentPathname == "/employees") {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        style={{
          height: 60,
          width: "100%",
          backgroundColor: currentPathname !== "/settings" ? "white" : "transparent",
          position: "fixed",
          top: 0,
          zIndex: 100,
          display: "flex",
          alignItems: "center",
          //justifyContent: "space-between",
          paddingRight: theme.spacing(4),
          paddingLeft: theme.spacing(0),
        }}
      >
        <Stack spacing={2} direction={"row"}>
          {proAccount && (
            <>
              {/*  <Button
                style={{
                  hidden: true,
                  textTransform: "none",
                  borderRadius: "6px",
                  fontWeight: "bold",
                }}
                variant="contained"
                disableElevation
                startIcon={<SchoolIcon />}
                onClick={() => {
                  navigate("/coaching");
                }}
              >
                {t("coaching")}
              </Button> */}
              <Button
                style={{
                  hidden: true,
                  textTransform: "none",
                  borderRadius: "6px",
                  fontWeight: "bold",
                }}
                variant="contained"
                disableElevation
                startIcon={<OndemandVideoIcon />}
                onClick={() => {
                  navigate("/");
                }}
              >
                {t("Mindmovie")}
              </Button>
              <Button
                style={{
                  hidden: true,
                  textTransform: "none",
                  borderRadius: "6px",
                  fontWeight: "bold",
                }}
                variant="contained"
                disableElevation
                startIcon={<RocketLaunch />}
                onClick={() => {
                  navigate("/fortschrittmessen");
                }}
              >
                {t("measure")}
              </Button>
              <Button
                style={{
                  hidden: true,
                  textTransform: "none",
                  borderRadius: "6px",
                  fontWeight: "bold",
                }}
                variant="contained"
                disableElevation
                startIcon={<RocketLaunch />}
                onClick={() => {
                  navigate("/meinfortschritt");
                }}
              >
                {t("analyze")}
              </Button>
              <Button
                style={{
                  hidden: true,
                  textTransform: "none",
                  borderRadius: "6px",
                  fontWeight: "bold",
                }}
                variant="contained"
                disableElevation
                startIcon={<PsychologyIcon />}
                onClick={() => {
                  navigate("/employees");
                }}
              >
                {t("employees")}
              </Button>{" "}
            </>
          )}
        </Stack>
        <LogoutButton />
      </Stack>
    );
  }

  if (currentPathname === "/fortschritt") {
    return (
      <div
        style={{
          height: 60,
          width: "100%",
          backgroundColor: currentPathname !== "/settings" ? "white" : "transparent",
          position: "fixed",
          top: 0,
          zIndex: 100,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            height: 60,
            backgroundColor: currentPathname !== "/settings" ? "white" : "transparent",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: theme.spacing(4) }}>
            <MenuButton
              onClick={() => {
                setMultiButtonState(0);
                navigate("/");
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  if (currentPathname !== "/") {
    return (
      <div
        style={{
          height: 60,
          width: "100%",
          backgroundColor: currentPathname !== "/settings" ? "white" : "transparent",
          position: "fixed",
          top: 0,
          zIndex: 100,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            height: 60,
            backgroundColor: currentPathname !== "/settings" ? "white" : "transparent",
            display: "flex",
            alignItems: "center",
          }}
        >
          {currentPathname === "/backgroundselection" ? (
            <div style={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: theme.spacing(4) }}>
                  <BackButton />
                </div>
                <div style={{ marginRight: theme.spacing(4) }}>
                  <SearchBar />
                </div>
                <div style={{ marginRight: theme.spacing(4) }}>
                  <HeartedVideosButton allHeartedVideos={false} />
                </div>
                <div style={{ marginRight: theme.spacing(4) }}>
                  <HeartedVideosButton allHeartedVideos={true} />
                </div>
                <div style={{ marginRight: theme.spacing(4) }}>
                  <UploadVideosButton />
                </div>
                <UploadImagesButton />
              </div>
            </div>
          ) : currentPathname === "/backgroundselection/heartedvideos" ||
            currentPathname === "/backgroundselection/allheartedvideos" ||
            currentPathname === "/settings" ||
            currentPathname === "/backgroundselection/uploadvideos" ||
            currentPathname === "/backgroundselection/uploadimages" ? (
            <>
              <div style={{ marginRight: theme.spacing(4) }}>
                <BackButton />
              </div>
            </>
          ) : (
            <>
              <div style={{ marginRight: theme.spacing(4) }}>
                <MenuButton
                  onClick={() => {
                    setMultiButtonState(0);
                    navigate("/");
                  }}
                />
              </div>
              <div style={{ marginRight: theme.spacing(4), paddingLeft: 6 }}>
                <MultiButton />
              </div>
              <PlayVisionMovieButton />
              {currentPathname === "/backgroundmusic" && <UploadSongButton />}
            </>
          )}
        </div>
        {currentPathname === "/edit" && (
          <div style={{ marginRight: 36 }}>
            <FontAwesomeIcon
              icon={isFullscreenEnabled ? faCompress : faExpand}
              color={theme.palette.primary.main}
              style={{ cursor: "pointer", fontSize: 22, userSelect: "none" }}
              onClick={() => {
                toggleFullscreen();
              }}
            />
          </div>
        )}
        {currentPathname === "/backgroundselection" && <PexelsBacklink />}
      </div>
    );
  }

  return <></>;
}

function PexelsBacklink() {
  const theme = useTheme();
  return (
    <a
      style={{
        marginRight: theme.spacing(4),
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
      }}
      href="https://www.pexels.com/"
      target="_blank"
    >
      <div style={{ fontWeight: "bold", marginRight: 8, color: "grey" }}>videos powered by</div>
      <img style={{ height: 35 }} src="pexels-logo.png" />
    </a>
  );
}
