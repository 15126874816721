import UploadSongRow from "./Components/UploadSongRow";
import SongItem from "./Components/SongItem";
import SoundCloudSongItem from "./Components/SoundCloudSongItem";
import TitleView from "./Utils/SongsPlatformTitle";
import { useBackgroundMusicContext } from "./Context/BackgroundMusicContext";
import "./Styles/backgroundStyles.scss";
import { useVisionMovieContext } from "../VisionMovieContext";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function BackgroundMusic() {
  const { t } = useTranslation();
  //Shows cursor again, if it was hidden in "/player" before
  useEffect(() => {
    document.body.style.cursor = "auto";
  }, []);

  //Imports all necessary data from BackgroundMusicContext
  const { showUploadSongRow, songs, topSongs, sortedSoundCloudSongs, topSoundCloudSongs } =
    useBackgroundMusicContext();
  const { visionMovieData } = useVisionMovieContext();
  return (
    <div className="container mainView">
      {showUploadSongRow ? <UploadSongRow /> : <TitleView title={t("songsOnThePlatform")} />}
      {visionMovieData.backgroundMusic && (
        <SongItem
          key={visionMovieData.backgroundMusic.id + "choosenSongItem"}
          number={""}
          song={visionMovieData.backgroundMusic}
          isLast={false}
          isChoosenSongItem={true}
        />
      )}
      {songs.map((song, index) => (
        <SongItem
          key={song.id}
          number={index + 1 > topSongs ? index + 1 - topSongs : ""}
          song={song}
          isLast={index + 1 === songs.length}
        />
      ))}
      <TitleView title={t("songsToUpload")} />
      {sortedSoundCloudSongs.map((song, index) => (
        <SoundCloudSongItem
          key={song.soundcloudId}
          number={index + 1 > topSoundCloudSongs ? index + 1 - topSoundCloudSongs : ""}
          song={song}
          isLast={index + 1 === sortedSoundCloudSongs.length}
        />
      ))}
    </div>
  );
}
