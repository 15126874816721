import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useAuth } from "../../Firebase/Contexts/AuthContext";
import { firestore } from "../../Firebase/firebase";
import dayjs, { Dayjs } from "dayjs";

/* export interface Coaching {
    id: string;
    number?: number;
    programm: CoachingPrograms;
    date: Date;
  } */

export default function BasicDateTimePicker({ nextCoaching }) {
  const { currentUser } = useAuth();

  function handleChange(choosenDateTime) {
    var toBeStoredCoaching = nextCoaching;
    const date = new Date(choosenDateTime);
    toBeStoredCoaching.date = date.toString();

    firestore
      .collection("users")
      .doc(currentUser?.uid)
      .collection("Coachings")
      .doc(nextCoaching.id)
      .set(toBeStoredCoaching)
      .then(() => {
        //console.log("Document successfully written!");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DateTimePicker"]}>
        <DateTimePicker
          value={nextCoaching?.date ? dayjs(nextCoaching?.date) : undefined}
          onAccept={handleChange}
          label="MM/DD/YYYY --- hh:mm - aa"
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
