import React from "react";
import { useWebinarContext } from "../softwareWebinar/WebinarContext";

export default function Start() {
  const navigate = useWebinarContext().navigate_WebinarWrapper;

  return (
    <div>
      <button onClick={() => navigate("/player4")}>start / go to player</button>
    </div>
  );
}
