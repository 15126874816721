import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { firestore } from "../../Firebase/firebase";
import { useWebinarContext } from "../../softwareWebinar/WebinarContext";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";

export default function UploadPlatformSong() {
  const { t } = useTranslation();

  const navigate = useWebinarContext().navigate_WebinarWrapper;
  //Files
  const [artistName, setArtistName] = useState("");
  const [songTitle, setSongTitle] = useState("");
  const [duration, setDuration] = useState(undefined);
  const [storageLink, setStorageLink] = useState("");
  const [waveformStorageLink, setWaveformStorageLink] = useState("");

  const handleUpload = (e) => {
    e.preventDefault();
    //Create object of all state variables
    const newPlatformSong = {
      artistName: artistName,
      songTitle: songTitle,
      duration: duration,
      storageLink: storageLink,
      waveformStorageLink: waveformStorageLink,
      id: uuid(),
    };
    //get current array of songs from firestore
    const platformSongsRef = firestore.collection("music").doc("backgroundMusicOnThePlatform");

    platformSongsRef.get().then((doc) => {
      const newPlatformSongs = doc.data().list;
      //Add new song to array
      newPlatformSongs.push(newPlatformSong);
      //Upload new array to firestore
      platformSongsRef.set({ list: newPlatformSongs });
    });
  };

  function resetForm() {
    setArtistName("");
    setSongTitle("");
    setDuration("");
    setStorageLink("");
    setWaveformStorageLink("");
  }

  return (
    <div className="w-100" style={{ maxWidth: "500px", paddingBottom: 70 }}>
      <Button onClick={() => navigate("/sortplatformsongs")}>go to platform songs list</Button>
      <Form onSubmit={handleUpload}>
        <Form.Group style={{ marginBottom: 15 }} id="header">
          <Form.Label>Artist</Form.Label>
          <Form.Control
            className="form-control"
            type="text"
            value={artistName}
            onChange={(event) => setArtistName(event.target.value)}
          />
        </Form.Group>
        <Form.Group style={{ marginBottom: 15 }} id="header">
          <Form.Label> {t("songTitle")}</Form.Label>
          <Form.Control
            className="form-control"
            type="text"
            value={songTitle}
            onChange={(event) => setSongTitle(event.target.value)}
            required
          />
        </Form.Group>
        <Form.Group style={{ marginBottom: 10 }} id="header">
          <Form.Label>Duration</Form.Label>
          <Form.Control
            className="form-control"
            type="text"
            value={duration}
            onChange={(event) => setDuration(event.target.value)}
            required
          />
        </Form.Group>
        <Form.Group style={{ marginBottom: 10 }} id="header">
          <Form.Label>Storage Link</Form.Label>
          <Form.Control
            className="form-control"
            type="text"
            value={storageLink}
            onChange={(event) => setStorageLink(event.target.value)}
            required
          />
        </Form.Group>
        <Form.Group style={{ marginBottom: 10 }} id="header">
          <Form.Label>Waveform Storage Link</Form.Label>
          <Form.Control
            className="form-control"
            type="text"
            value={waveformStorageLink}
            onChange={(event) => setWaveformStorageLink(event.target.value)}
            required
          />
        </Form.Group>
        <Button variant="primary" onClick={resetForm} style={{ marginBottom: 10 }}>
          reset form
        </Button>
        <Button
          className="btn-primary"
          style={{ color: "white", background: "rgba(255, 0, 90, 1)" }}
          type="submit"
        >
          upload
        </Button>
      </Form>
    </div>
  );
}
