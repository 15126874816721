import { useState } from "react";
import VideoPartComponent from "./VideoPartComponent";
import {
  VideoPart,
  SoftwarePart,
  isVideoPart,
  isSoftwarePart,
  WebinarData,
} from "./SoftwareWebinarInterfaces";
import SoftwarePartComponent from "./SoftwarePartComponent";
import StartScreen from "./StartScreen";
import { useWebinarContext } from "./WebinarContext";

export default function SoftwareWebinar({ webinarData }: { webinarData: WebinarData }) {
  const setActions = useWebinarContext()?.setActions;
  const setRouteString = useWebinarContext()?.setRouteString;
  const [parts] = useState(webinarData.parts);
  const [activePartIndex, setActivePartIndex] = useState(0);

  function onFinishedPart() {
    if (activePartIndex < parts.length - 1) {
      //If next part is software Part and setEnabledORDisabledActions exists set them in WebinarContext.tsx
      //This is needed to set or enable certain actions in the software
      if (isSoftwarePart(parts[activePartIndex + 1])) {
        const part = parts[activePartIndex + 1] as SoftwarePart;
        setActions && part.actions && setActions(part.actions);
      }
      //Switch to next part
      setActivePartIndex((prev) => prev + 1);
      if (webinarData.parts) {
      }
    }
  }

  //For first part
  if (activePartIndex === 0) {
    return (
      <StartScreen
        forMobileDevicesVideo={webinarData.forMobileDevicesVideo}
        part={parts[0] as VideoPart}
        onFinishedPart={onFinishedPart}
      />
    );
  }

  //For Video Parts (not first segment)
  if (isVideoPart(parts[activePartIndex])) {
    return (
      <VideoPartComponent
        part={parts[activePartIndex] as VideoPart}
        onFinishedPart={onFinishedPart}
      />
    );
  }

  //For Software Parts (not first segment)
  if (isSoftwarePart(parts[activePartIndex])) {
    const part = parts[activePartIndex] as SoftwarePart;
    setRouteString(part.routeString);
    return <SoftwarePartComponent softwarePart={part} onFinishedPart={onFinishedPart} />;
  }

  return <></>;
}
