import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(255, 0, 90, 1)",
      opacity80: "rgba(255, 0, 90, 0.8)",
      opacity70: "rgba(255, 0, 90, 0.7)",
      opacity60: "rgba(255, 0, 90, 0.6)",
      opacity50: "rgba(255, 0, 90, 0.5)",
      opacity40: "rgba(255, 0, 90, 0.4)",
      opacity30: "rgba(255, 0, 90, 0.3)",
      opacity20: "rgba(255, 0, 90, 0.2)",
      opacity15: "rgba(255, 0, 90, 0.15)",
      opacity10: "rgba(255, 0, 90, 0.1)",
    },
    grey: {
      normal: "rgba(167, 167, 167, 1)",
      medium: "rgba(200, 200, 200, 1)",
      light: "rgba(242, 242, 242, 1)",
      lightTextGrey: "#BDBDBD",
      lightCalendarGrey: "#E0E0E0",
    },
    gradient: {
      main: "#FF005A", //"linear-gradient(104.33deg, #FF005A 3.51%, #FF7B44 98.53%)",
      text: "linear-gradient(0deg, #FF005A 0%, #FF7B44 100%)",
      cardBackgroundGradient100:
        "linear-gradient(104.33deg, rgba(255, 0, 90, 1) 3.51%, rgba(255, 123, 68, 1) 98.53%)",
      cardBackgroundGradient80:
        "linear-gradient(104.33deg, rgba(255, 0, 90, 0.8) 3.51%, rgba(255, 123, 68, 0.8) 98.53%)",
      cardBackgroundGradient20: "linear-gradient(104.33deg, #FF005A 3.51%, #FF7B44 98.53%)",
      cardBackgroundGradient: "linear-gradient(104.33deg, #FFEDF4 3.51%, #FFF6F2 98.53%)",
    },
    darkBlue: {
      main: "rgba(26, 30, 60, 1)",
    },
    orange: {
      main: "#FF7B44",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#11cb5f",
    },
    green: {
      main: "rgba(111, 207, 151, 1)",
      gradient: "linear-gradient(0deg, rgba(12, 255, 113, 1) 0%, rgba(104, 255, 167, 1) 100%)",
    },
    red: {
      gradient: "linear-gradient(0deg, rgba(255, 57, 112, 1) 0%, rgba(255, 133, 84, 1) 100%)",
    },
  },
});

theme.typography.h3 = {
  color: theme.palette.grey.normal,
};
theme.typography.h4 = {
  color: theme.palette.grey.normal,
};
theme.typography.h5 = {
  color: theme.palette.grey.normal,
};

export const columnNumberBreakpoints = {
  twoColumns: 1200,
  threeColumns: 1600,
};
