import { useState, useRef } from "react";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NotAllDaysFullyTrackedModal from "./NotAllDaysFullyTrackedModal";
import dayjs from "dayjs";
import { useWebinarContext } from "../../../components/softwareWebinar/WebinarContext";
import { checkIfDateADayBeforeNow } from "./checkIfDateADayBeforeNow";
import { useTranslation } from "react-i18next";

export default function SaveButton({ toDos, activeToDoIndex, setActiveToDoIndex }) {
  const navigate = useWebinarContext().navigate_WebinarWrapper;
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);

  const toDosWithMissingValuesArrRef = useRef();
  function toDosForWhichNotAllValuesHaveBeenChoosen() {
    const toDosWithMissingValuesArr = toDos.filter((toDo) => {
      //console.log(toDo);
      if (!toDo?.measurements) {
        //CASE 1: no measurements - no needed
        //console.log("no measurments");

        if (checkIfDateADayBeforeNow(toDo?.startTrackingDate)) {
          //CASE 2: no measurements - measurements needed
          //console.log("measurments needed");
          return true;
        } else {
          //console.log("no measurments needed");
          return false;
        }
      }

      //CASE 3: measurements - all measurements until today
      //CASE 4: measurements - not all measurements until today
      if (toDo?.measurements) {
        //console.log("measurments exist already");
        const allValuesChoosen = checkIfDateADayBeforeNow(
          toDo.measurements[toDo.measurements.length - 1].date
        );
        return allValuesChoosen;
      }
    });
    toDosWithMissingValuesArrRef.current = toDosWithMissingValuesArr;
    return toDosWithMissingValuesArr;
  }

  function handleLeaveRecordTracking() {
    //console.log(toDosForWhichNotAllValuesHaveBeenChoosen());
    if (toDosForWhichNotAllValuesHaveBeenChoosen()?.length > 0) {
      setModalOpen(true);
    } else {
      //Navigate back to "/"
      navigate("/");
    }
  }

  function moveToFirstToDoWithMissingMesaurements() {
    const toDoWithMissingMesaurementsIndex = toDos.findIndex(
      (toDo) => toDo.id === toDosWithMissingValuesArrRef.current[0].id
    );
    setActiveToDoIndex(toDoWithMissingMesaurementsIndex);
  }

  return (
    <div>
      <Button
        size="large"
        style={{
          textTransform: "none",
          borderRadius: "6px",
          fontWeight: "bold",
          visibility: toDos?.length === activeToDoIndex + 1 ? "visible" : "hidden",
        }}
        variant="contained"
        disableElevation
        startIcon={<ArrowBackIcon />}
        onClick={handleLeaveRecordTracking}
      >
        {t("SAVE")}
      </Button>
      <NotAllDaysFullyTrackedModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        moveToFirstToDoWithMissingMesaurements={moveToFirstToDoWithMissingMesaurements}
      />
    </div>
  );
}
