import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useTranslation } from "react-i18next";

export default function TextMobileStepper({ maxSteps, activeStep, setActiveStep, setNextClicked }) {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <MobileStepper
      sx={{
        width: { xs: "100%", lg: "30%" },
        maxWidth: 550,
        fontWeight: "bold",
        color: theme.palette.grey.normal,
        userSelect: "none",
        background: "#F4F6F9",
      }}
      variant="text"
      steps={maxSteps}
      position="static"
      activeStep={activeStep}
      nextButton={
        <Button
          onClick={handleNext}
          style={{ textTransform: "none", fontSize: 22 }}
          disabled={activeStep === maxSteps - 1}
        >
          {t("next")}
          {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </Button>
      }
      backButton={
        <Button
          onClick={handleBack}
          style={{ textTransform: "none", fontSize: 22 }}
          disabled={activeStep === 0}
        >
          {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          {t("back")}
        </Button>
      }
    />
  );
}
