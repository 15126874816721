import { useLayoutEffect, useState } from "react";
import PaypalButtons from "./PaypaylButtons";
import { useAuth } from "../Firebase/Contexts/AuthContext";
import LoginBeforePaying from "./LoginBeforePaying";
import Price from "./Price";
import { firestore } from "../Firebase/firebase";

export default function Pay({ product, onPayed }) {
  const { currentUser } = useAuth();

  const [price, setPrice] = useState(undefined);
  useLayoutEffect(() => {
    firestore
      .collection("products")
      .doc(product)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setPrice(doc.data()?.price);
        } else {
          // doc.data() will be undefined in this case
          console.log("product not found");
        }
      })
      .catch((error) => {
        console.log("Error getting product price:", error);
      });
  }, []);

  const [discountCode, setDiscountCode] = useState("");

  return (
    <>
      <Price
        product={product}
        price={price}
        setPrice={setPrice}
        discountCode={discountCode}
        setDiscountCode={setDiscountCode}
        onPayed={onPayed}
      />
      {currentUser ? (
        <div style={{ height: "100%", width: "100%" }}>
          <PaypalButtons
            currentUser={currentUser}
            onPayed={onPayed}
            price={price}
            discountCode={discountCode}
          />
        </div>
      ) : (
        <LoginBeforePaying />
      )}
    </>
  );
}
