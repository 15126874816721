import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      de: {
        /* translation: {
          logout: "ausloggen",
          measure: "Messen",
          analyze: "Ansehen",
          coaching: "Coaching",
          employees: "Mitarbeiter",
          Mindmovie: "Visionmovie",
          next: "nächstes",
          back: "zurück",
          SAVE: "SPEICHERN",
          deleteVisionMovie: "Willst Du diesen VisionMovie wirklich löschen?",
          cantBeUndone: "Dies kann nicht mehr rückgängig gemacht werden!",
          keepIt: "behalten",
          delete: "löschen",
          back: "zurück",
          selectMusic: "Musik auswählen",
          selectVisionMovies: "Statements & Videos",
          playVisionMovie: "VisionMovie abspielen",
          IAM: "ICH BIN",
          goalStatementSecondLine: "dein Zielstatement. Klicke hier und bearbeite mich!",
          arrowBack: "<- zurück",
          yourArchive: "Dein Archiv - wird nicht im VisionMovie abgespielt",
          searchVideos: "Nach Videos suchen",
          videos: "Videos",
          allVideos: "Alle Videos",
          all: "alle ",
          pictures: "Bilder",
          select: "auswählen",
          currentVideoLength: "momentane Videolänge",
          uploadMusic: "Musik hochladen",
          songsOnThePlatform: "Songs auf der Platform",
          songsToUpload: "Wundervolle Musikvorschläge zum hochladen",
          addToVisionMovie: "zum VisionMovie hinzufügen",
          buySong: "Song kaufen",
          artistName: "Artist Name (optional)",
          songTitle: "Song Titel",
          songOnYoutube: "Song auf Youtube",
          songOnSoundcloud: "Song auf Soundcloud",
          upload: "hochladen",
          uploadVideo: "Video hochladen",
          videoUploaded: "Video hochgeladen",
          percentUploadedVideo: "% des Videos hochgeladen",
          uploadPicture: "Bild hochladen",
          pictureUploaded: "Bild hochgeladen",
          percentUploadedPicture: "% des Bildes hochgeladen",
          noVideosLiked: "Noch keine Videos markiert",
          create: "erstellen",
          visionMovieName: "VisionMovie Name",
          chooseLongerName: "Bitte wähle einen längeren Namen",
          moveHere: "hier hin bewegen",
          noVideosFound:
            "Wird haben leider keine Videos für dein Keyword gefunden. Versuche es mit einem anderen Keyword nochmals. (Hinweis: Bitte bedenke, dass Du die Suche in englischer Sprache benutzen musst.",
          complete: "vervollständigen",
          notAllValuesEntered: "Du hast noch nicht alles eingetragen",
          goBackAnyhow: "Willst Du trotzdem zurück oder noch alles vervollständigen?",
        }, */
        translation: {
          logout: "logout",
          measure: "measure",
          analyze: "analyze",
          coaching: "coaching",
          employees: "employees",
          Mindmovie: "Mindmovie",
          next: "next",
          back: "back",
          SAVE: "SAVE",
          deleteVisionMovie: "Do you really want to delete this MindMovie?",
          cantBeUndone: "This can't be undone!",
          keepIt: "keep it",
          delete: "delete",
          back: "back",
          selectMusic: "select music",
          selectVisionMovies: "statements & videos",
          playVisionMovie: "play MindMovie",
          IAM: "I AM",
          goalStatementSecondLine: "your goal statement. Click here to edit me!",
          arrowBack: "<- back",
          yourArchive: "Your archive - not a part of the MindMovie",
          searchVideos: "search",
          videos: "videos",
          allVideos: "all videos",
          all: "all ",
          pictures: "pictures",
          select: "select",
          currentVideoLength: "current length of video",
          uploadMusic: "upload music",
          songsOnThePlatform: "Songs on the platform",
          songsToUpload: "Wonderful songs to upload",
          addToVisionMovie: "add to MindMovie",
          buySong: "buy song",
          artistName: "artist name (optional)",
          songTitle: "song title",
          songOnYoutube: "song on Youtube",
          songOnSoundcloud: "song on soundcloud",
          upload: "upload",
          uploadVideo: "upload video",
          videoUploaded: "video uploaded",
          percentUploadedVideo: "% of video uploaded",
          uploadPicture: "upload picture",
          pictureUploaded: "picture uploaded",
          percentUploadedPicture: "% of picture uploaded",
          noVideosLiked: "no videos liked yet",
          create: "create",
          visionMovieName: "MindMovie name",
          chooseLongerName: "choose a longer name",
          moveHere: "move here",
          noVideosFound: "no videos found for this keyword. Try it with another one.",
          complete: "enter all",
          notAllValuesEntered: "You didn't enter all values yet",
          goBackAnyhow: "Do you wanna go back or continue?",
        },
      },
      en: {
        translation: {
          logout: "logout",
          measure: "measure",
          analyze: "analyze",
          coaching: "coaching",
          employees: "employees",
          Mindmovie: "Mindmovie",
          next: "next",
          back: "back",
          SAVE: "SAVE",
          deleteVisionMovie: "Do you really want to delete this MindMovie?",
          cantBeUndone: "This can't be undone!",
          keepIt: "keep it",
          delete: "delete",
          back: "back",
          selectMusic: "select music",
          selectVisionMovies: "statements & videos",
          playVisionMovie: "play MindMovie",
          IAM: "I AM",
          goalStatementSecondLine: "your goal statement. Click here to edit me!",
          arrowBack: "<- back",
          yourArchive: "Your archive - not a part of the MindMovie",
          searchVideos: "search",
          videos: "videos",
          allVideos: "all videos",
          all: "all ",
          pictures: "pictures",
          select: "select",
          currentVideoLength: "current length of video",
          uploadMusic: "upload music",
          songsOnThePlatform: "Songs on the platform",
          songsToUpload: "Wonderful songs to upload",
          addToVisionMovie: "add to MindMovie",
          buySong: "buy song",
          artistName: "artist name (optional)",
          songTitle: "song title",
          songOnYoutube: "song on Youtube",
          songOnSoundcloud: "song on soundcloud",
          upload: "upload",
          uploadVideo: "upload video",
          videoUploaded: "video uploaded",
          percentUploadedVideo: "% of video uploaded",
          uploadPicture: "upload picture",
          pictureUploaded: "picture uploaded",
          percentUploadedPicture: "% of picture uploaded",
          noVideosLiked: "no videos liked yet",
          create: "create",
          visionMovieName: "MindMovie name",
          chooseLongerName: "choose a longer name",
          moveHere: "move here",
          noVideosFound: "no videos found for this keyword. Try it with another one.",
          complete: "enter all",
          notAllValuesEntered: "You didn't enter all values yet",
          goBackAnyhow: "Do you wanna go back or continue?",
        },
      },
    },
  });
